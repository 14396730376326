import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import AppMobile from './AppMobile';
import { isMobile } from 'react-device-detect';
import './fonts/Montserrat Light 300.ttf';
//import './js/bootstrap.bundle.min';

import { registerLicense } from '@syncfusion/ej2-base';
registerLicense(
  'ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0diXX5fcHFUR2lcU0I='
);

if (isMobile) ReactDOM.render(<AppMobile />, document.getElementById('root'));
else ReactDOM.render(<App />, document.getElementById('root'));
