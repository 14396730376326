// * ---------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SUHAIL - 2023-09-04 - 95 - weekly status review meeting
// * SUHAIL - 2023-09-04 - 25 - meeting with fabio on issue of period list coming blank after upgrading package
// * SUHAIL - 2023-09-04 - 360 - Save word template in database for respective correspondence menu sub items
// * SUHAIL - 2023-09-05 - 300 - Save word template in database for correspondence menu Contract sub menu
// * SUHAIL - 2023-09-05 - 180 - Option to delete existing word template from the dialog box
// * SUHAIL - 2023-09-07 - 480 - Option to select fields in Tree structure on correspondence menu - Contract  context menu for mail merge
// * SUHAIL - 2023-09-08 - 60 - meeting with fabio on current work status and contract component
// * SUHAIL - 2023-09-08 - 420 - Display respective word template from the database in document viewer when employee is selected from selector
// * SUHAIL - 2023-09-11 - 75 - weekly status review meeting
// * SUHAIL - 2023-09-11 - 15 - meeting with fabio on to show merged template on dossier double click
// * SUHAIL - 2023-09-11 - 390 - Display respective word template from the database in document viewer when employee is selected from selector
// * SUHAIL - 2023-09-27 - 120 - Research on adding custom item on Word component context menu
// * SUHAIL - 2023-09-27 - 70 - attended weekly status meeting
// * SUHAIL - 2023-09-27 - 25 - attended sprint planning meeting
// * SUHAIL - 2023-09-27 - 65 - meeting with fabio and sandeep on employee create api issue, period selector issue and custom context menu to insert date on word template
// * SUHAIL - 2023-09-27 - 140 - Context menu on group menu to create sub group menu or child menu
// * SUHAIL - 2023-09-27 - 60 - Add custom context menu Insert -> Date to insert today's date in word template

import React from 'react';
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from '@syncfusion/ej2-react-documenteditor';
import Common from '../../Util/Common';
import { TitleBar } from '../Layout/title-bar';
import '../Layout/titlebar.css';
import './WordTemplate.css';
import { Dialog, DialogUtility } from '@syncfusion/ej2-react-popups';
// import { ListView } from '@syncfusion/ej2-react-lists';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
DocumentEditorContainerComponent.Inject(Toolbar);

// * ------------------------------------------------------------------------------------------------------------------3.Style

// * ---------------------------------------------------------------------------------------------------------------2.Initialize objects
const { REACT_APP_ENDPOINT_API, REACT_APP_ENDPOINT_FOXSYSTEMSERVICE } =
  process.env;

interface Props {
  id: string;
  accountid: number;
  name: string;
  showindialogbox: boolean;
  jsonTemplate: any;
}

// * -------------------------------------------------------------------------------------------------------------------CLASS DECLARATION
class WordTemplate extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private hostUrl: string =
    'https://services.syncfusion.com/react/production/api/documenteditor/';
  public container: DocumentEditorContainerComponent | any;
  public titleBar: any;
  public wordcontentchanged: boolean = false;
  private mailmergedata: any;
  private mailmergefields: string[];
  // private listView: any;
  private arr: any = [];
  private treeviewdata: any = [];

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.container = null;
    this.hostUrl =
      'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/';
    this.mailmergedata = null;
    this.mailmergefields = [];
    this.treeviewdata = [];
  }

  static defaultProps = {
    showindialogbox: false,
  };

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = { isLoading: false, data: null, fields: undefined };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount() {
    if (this.state.data === null) {
      let flter: any = this.props.jsonTemplate.children.filter(
        (x: any) => x.field === this._structure.Field.FileType.Word
      );

      if (flter.length > 0) {
        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Sfdt/Read`,
          {
            object: flter[0].object,
            field: this._structure.Field.FileType.Word,
          },
          Common.getToken() || '',
          this.user
        )
          .then((response: any) => {
            return response.data;
          })
          .then((response: any) => {
            if (response !== '') {
              this.setState({
                data: response,
              });
            }

            setTimeout(() => {
              this.created();
              this.bindEvents();
            }, 1000);
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {});
      } else {
        setTimeout(() => {
          this.created();
          this.bindEvents();
        }, 1000);
      }
    }

    if (!this.mailmergedata) {
      this.arr = [];
      Common.ApiCallAsync(
        'GET',
        `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/JsonTreeForImport?objectid=${this._structure.Cluster.Identity.Employee}`,
        '',
        Common.getToken() || '',
        this.user
      )
        .then((response: any) => {
          let objEmp: any = response.data;
          //let arr: string[] = [];

          let objflds: any = objEmp[0].children.filter(
            (x: any) => x.object === this._structure.Field.Document.Contract
          );
          if (objflds.length > 0) {
            this.treeviewdata.push(objflds[0]);
          }
          Common.ApiCallAsync(
            'GET',
            `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/JsonTreeForImport?objectid=${this._structure.Cluster.Correspondence.Dossier}`,
            '',
            Common.getToken() || '',
            this.user
          )
            .then((response: any) => {
              let objDossier: any = response.data;

              let objflds: any = objDossier[0].children.filter(
                (x: any) => x.object === this._structure.Field.Personal.Document
              );
              if (objflds.length > 0) {
                this.treeviewdata.push(objflds[0]);
              }

              if (this.treeviewdata.length > 0) {
                this.GetArray(this.treeviewdata, '');

                this.mailmergedata = this.arr.sort().map((x: any) => {
                  let obj: any = {
                    text: x,
                    tooltip: 'Click the field to insert.',
                  };
                  return obj;
                });
              }

              this.mailmergefields = this.arr.map((x: any) => x.value);

              const fields: object = {
                dataSource: this.treeviewdata,
                id: 'idx',
                text: 'value',
                child: 'children',
                iconCss: 'iconCss',
                imageUrl: 'image',
              };

              this.setState({ fields: fields });
              return response.data;
            })

            .catch((error: any) => {
              console.error(error);
            })
            .finally(() => {});

          return response.data;
        })

        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
    this.SaveWord();
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  public created() {
    if (this.container !== null) {
      if (this.state.data !== null) {
        this.container.documentEditor.open(
          JSON.parse(JSON.stringify({ sfdt: this.state.data }))
        );
      } else {
        this.container.documentEditor.openBlank();
      }

      //set device pixel ratio for print quality (Max : 10)
      this.container.documentEditor.documentEditorSettings.printDevicePixelRatio = 10;

      if (this.props.showindialogbox === true) {
        const dlg: any = this.container.element
          .closest(`#word-template-${this.props.id}`)
          .closest('.e-dialog').offsetHeight;
        const hdr: any = this.container.element
          .closest(`#word-template-${this.props.id}`)
          .closest('.e-dialog')
          .querySelector('.e-dlg-header-content').offsetHeight;
        this.container.resize(100, dlg - hdr - 20 - 94);
      } else {
        this.container.resize(100, 100);
      }
      this.titleBar = new TitleBar(
        //document.getElementById('documenteditor_titlebar'),
        document.querySelector('.word-template #documenteditor_titlebar'),
        this.container.documentEditor,
        true
      );

      this.container.documentEditor.documentName = ` ${this.props.name}`;

      this.titleBar.updateDocumentTitle();

      this.container.toolbarClick = (args: any) => {
        switch (args.item.id) {
          case 'MergeDocument':
            //this.mergeDocument();
            break;
          // case 'InsertField':
          //   this.showInsertFielddialog(this.container);
          //   break;
        }
      };

      this.container.documentEditor.beforeFileOpen = (e: any) => {
        this.wordcontentchanged = true;
        window.removeEventListener('beforeunload', this.onUnload);
        window.addEventListener('beforeunload', this.onUnload);
      };

      let cmenu: HTMLElement = document.querySelector(
        '#wordtemplatecontainer_editore-de-contextmenu-list'
      )?.parentElement as HTMLElement;
      let cmenufirst: any = cmenu.querySelectorAll(
        'ul:not([id="wordtemplatecontainer_editore-de-contextmenu-list"])'
      );

      if (cmenufirst.length > 0) {
        cmenufirst[0].remove();
      }

      let cmenuitm: any = document.querySelectorAll(
        '#wordtemplatecontainer_editor_contextmenu_lock'
      );

      if (cmenuitm.length > 1) {
        let cmenuitm1: HTMLElement = cmenuitm[1] as HTMLElement;

        let nextsibling: any = cmenuitm1.nextElementSibling;
        while (nextsibling) {
          let nextsiblingtmp: any = nextsibling.nextElementSibling;
          nextsibling.remove();
          nextsibling = nextsiblingtmp;
        }
        cmenuitm1.remove();
      }

      // create Custom menu item
      let customMenuItems = [
        {
          text: 'Insert',
          id: '1000',
          iconCss: 'fa-regular fa-square-plus',
          items: [
            {
              text: 'Date',
              id: '1001',
              iconCss: 'fa-regular fa-calendar-plus',
            },
          ],
        },
      ];
      // adding Custom menu item
      this.container.documentEditor.contextMenu.addCustomMenu(
        customMenuItems,
        false
      );
      this.container.documentEditor.customContextMenuSelect = (e: any) => {
        console.log(e);
        if (e.id === '1001') {
          this.container.documentEditor.editor.insertText(
            Common.formatDate(new Date(), this.user.settings.formats.DateShort)
          );
        }
      };
      // this.container.documentEditor.contextMenu.select = (args: any) => {
      //   console.log(args);
      //   //handleCustomMenuId(item);
      // };
      // this.container.documentEditor.contextMenu.onContextMenuInternal = (
      //   args: any
      // ) => {
      //   console.log(args);
      // };
    }
  }

  public bindEvents() {
    let paragraphmenu: HTMLElement = document.querySelector(
      '#wordtemplatecontainer_editor_contextmenu_paragraph_dialog'
    ) as HTMLElement;
    if (paragraphmenu) {
      paragraphmenu.removeEventListener('click', this.bindParagraphMenuEvent);
      paragraphmenu.addEventListener('click', this.bindParagraphMenuEvent);
      // document
      //   .getElementById('wordtemplatecontainer_editore-de-contextmenu-list')
      //   ?.addEventListener('click', (e: any) => {
      //     console.log('eee');
      //   });
    }
  }

  public bindParagraphMenuEvent(e: any) {
    let modaldlg: any = document.querySelectorAll(
      '.e-de-dlg-target.e-control.e-dialog.e-lib.e-draggable.e-dlg-modal'
    );
    if (modaldlg) {
      modaldlg.forEach((element: any) => {
        element.parentElement.style.zIndex = 3000;
      });
    }
  }

  public insertField(fieldName: string) {
    let fileName = fieldName
      .replace(/\n/g, '')
      .replace(/\r/g, '')
      .replace(/\r\n/g, '');
    let fieldCode = 'MERGEFIELD  ' + fileName + '  \\* MERGEFORMAT ';
    this.container.documentEditor.editor.insertField(
      fieldCode,
      '«' + fieldName + '»'
    );
    this.container.documentEditor.focusIn();
  }

  public onWrapText(text: string) {
    let content = '';
    let index = text.lastIndexOf(' ');
    content = text.slice(0, index);
    text.slice(index);
    content += '<div class="e-de-text-wrap">' + text.slice(index) + '</div>';
    return content;
  }

  onUnload = (e: any) => {
    window.removeEventListener('beforeunload', this.onUnload);
    e.preventDefault();
    e.returnValue = '';

    this.SaveWord();
  };

  public onWordContentChanged() {
    this.wordcontentchanged = true;
    window.removeEventListener('beforeunload', this.onUnload);
    window.addEventListener('beforeunload', this.onUnload);
  }

  public SaveWord() {
    if (this.wordcontentchanged === true) {
      this.wordcontentchanged = false;
      window.removeEventListener('beforeunload', this.onUnload);

      if (this.container === null) {
        let obj: any = document.getElementById(
          'wordtemplatecontainer'
        ) as Element;
        if (obj !== null) {
          this.container = obj
            .ej2_instances[0] as DocumentEditorContainerComponent;
        }
      }

      let payload: any = {
        object: this.props.jsonTemplate.object,
        field: this._structure.Field.FileType.Word,
        value: this.container.documentEditor.serialize(),
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Template/Save`,
        payload,
        Common.getToken() || '',
        this.user
      )
        .then((response: any) => {
          let flter: any = this.props.jsonTemplate.children.filter(
            (x: any) => x.field === this._structure.Field.FileType.Word
          );

          if (flter.length <= 0) {
            this.props.jsonTemplate.children.push({
              object: Number(response.data.fileId),
              field: this._structure.Field.FileType.Word,
              rFieldId: this._structure.Field.Identif.Template,
              children: [],
            });
          }

          this.setState({ data: response.data.value });
          return response.data;
        })

        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  }

  private GetArray(json: any, prevfield: string) {
    json.forEach((x: any) => {
      let fld: string = `${prevfield ? `${prevfield}.${x.value}` : x.value}`;

      if (x.children.length > 0) {
        this.GetArray(x.children, fld);
      } else {
        this.arr.push({ id: x.idx, value: fld });
      }
    });
  }

  render() {
    let treeViewComponent: TreeViewComponent;
    let items: any = [
      //toolItem,
      'Open',
      'Separator',
      'Undo',
      'Redo',
      'Separator',
      'LocalClipboard',
      // 'Separator',
      // {
      //   prefixIcon: 'sf-icon-FinishMerge',
      //   tooltipText: 'Merge Document',
      //   text: this.onWrapText('Merge Document'),
      //   id: 'MergeDocument',
      // },
      'Separator',
      'Image',
      'Table',
      'Hyperlink',
      'Bookmark',
      'Comments',
      'TableOfContents',
      'Separator',
      'Header',
      'Footer',
      'PageSetup',
      'PageNumber',
      //'Break',
      'Separator',
      'Find',
      //"RestrictEditing"
    ];

    // * ------------------------------------------------------------------------------------------------------------------6.Adapter
    let cnfDlg: Dialog;
    const confirmYesClick = (e: any) => {
      deleteDocument();
    };

    const confirmNoClick = (e: any) => {
      cnfDlg.hide();
    };

    const deleteDocument = () => {
      let payload: any = {
        object: this.props.jsonTemplate.object, //this.props.accountid,
        field: this._structure.Field.Identif.Template, //this._structure.Field.FileType.Word,
        value: this.props.accountid.toString(), //'',
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Delete`,
        payload,
        Common.getToken() || '',
        this.user
      )
        .then((response: any) => {
          this.setState({ data: null });

          let flter: any = this.props.jsonTemplate.children.filter(
            (x: any) => x.field === this._structure.Field.FileType.Word
          );

          if (flter.length > 0) {
            this.props.jsonTemplate.children.splice(
              this.props.jsonTemplate.children.indexOf(flter[0])
            );
          }

          this.wordcontentchanged = false;
          this.container.documentEditor.openBlank();

          cnfDlg.hide();

          return response;
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    const metadataClick = (e: any) => {
      let cnvas: HTMLElement = document.getElementById(
        `metadata-offcanvasRight-${this.props.accountid}`
      ) as HTMLElement;

      let dlg: DOMRect = (
        document.getElementById(`#${this.props.accountid}`) as HTMLElement
      ).getBoundingClientRect();

      cnvas.style.top = `${(dlg.top + 10).toString()}px`;
      cnvas.style.height = `${(dlg.height - 20).toString()}px`;
      cnvas.style.left = `${(
        dlg.right -
        cnvas.getBoundingClientRect().width -
        10
      ).toString()}px`;
    };

    const nodeClick = (e: any) => {
      let selected: any = this.arr.filter((x: any) => x.id == e.nodeData.id);
      if (selected.length > 0) {
        treeViewComponent.selectedNodes = [];
        // let cnvas: HTMLElement = document.getElementById(
        //   `metadata-offcanvasRight-${this.props.accountid}`
        // ) as HTMLElement;
        // cnvas.classList.toggle('show');
        // let bkdrp: any = document.querySelector(
        //   `#metadata-offcanvasRight-${this.props.accountid} ~ .offcanvas-backdrop`
        // );
        // bkdrp.classList.toggle('show');
        // cnvas.removeAttribute('aria-modal');
        // cnvas.removeAttribute('role');
        // cnvas.style.left = '-1000px';
        let btn: any = document.querySelector(`.offcanvas-title ~ .btn-close`);
        btn.click();
        this.insertField(selected[0].value);
        this.wordcontentchanged = true;
      }
    };

    // * --------------------------------------------------------------------------------------------------------------------HTML
    return this.state.isLoading ? (
      <>{Common.ShowSpinner(this.props.showindialogbox === false ? '' : '')}</>
    ) : (
      <>
        {Common.HideSpinner(this.props.showindialogbox === false ? '' : '')}
        <div id={`word-template-${this.props.id}`} className='word-template'>
          <div className='control-pane'>
            <div className='control-section'>
              <div
                id='documenteditor_titlebar'
                className='e-de-ctn-title'
              ></div>
              <div id='documenteditor_container_body'>
                <DocumentEditorContainerComponent
                  id='wordtemplatecontainer'
                  ref={(scope) => {
                    this.container = scope;
                    this.created.bind(this);
                  }}
                  className='d-block'
                  height={'calc(100vh - 197px)'}
                  serviceUrl={this.hostUrl}
                  enableToolbar={true}
                  locale='en-US'
                  toolbarItems={items}
                  showPropertiesPane={false}
                  contentChange={this.onWordContentChanged.bind(this)}
                />
              </div>
            </div>
          </div>

          <div className='divwordfooterpanel d-flex justify-content-end'>
            {this.state.data !== null ? (
              <div
                id='divDeleteDoc'
                title='Delete Document'
                onClick={(e: any) => {
                  cnfDlg = DialogUtility.confirm({
                    animationSettings: { effect: 'Zoom' },
                    showCloseIcon: true,
                    closeOnEscape: true,
                    cssClass: 'deletedoc',
                    title:
                      ' Are you sure you want to delete the attached Word template? ',
                    content: '',
                    cancelButton: {
                      text: 'No',
                      click: confirmNoClick,
                    },
                    okButton: {
                      text: 'Yes',
                      click: confirmYesClick,
                    },
                  });
                }}
              >
                <label
                  className={`fa-regular fa-trash-can p-2 icon border-active color-active `}
                ></label>
              </div>
            ) : null}
            <div
              id='divMetadata'
              data-bs-toggle='offcanvas'
              data-bs-target={`#metadata-offcanvasRight-${this.props.accountid}`}
              aria-controls='metadata-offcanvasRight'
              title='Insert Mail Merge Fields'
              className={'d-block'}
              onClick={metadataClick}
            >
              <i
                className={
                  'fa-solid fa-envelope-open-text p-2 icon border-active color-active'
                }
              ></i>
            </div>
          </div>
        </div>

        <div
          className='offcanvas offcanvas-end'
          tabIndex={-1}
          id={`metadata-offcanvasRight-${this.props.accountid}`}
          aria-labelledby='offcanvasRightLabel'
        >
          <div className='offcanvas-header'>
            <h5 className='offcanvas-title' id='offcanvasRightLabel'>
              Select Field to Insert
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='offcanvas'
              aria-label='Close'
            ></button>
          </div>
          <div className='offcanvas-body'>
            {/* <div id='listview' className='d-none'></div> */}
            <TreeViewComponent
              id='word-treeview'
              fields={this.state.fields}
              nodeSelected={nodeClick}
              enablePersistence={true}
              title='Click the leaf node to insert field.'
              ref={(obj: any) => (treeViewComponent = obj)}
            />
          </div>
        </div>
      </>
    );
  }
}

export default WordTemplate;
