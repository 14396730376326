/*
  ---------------------------------------------------------------------------------------------------------------INIT - DO NOT EDIT
*/
import React, { ReactNode, useEffect } from 'react';
import * as ReactDOMServer from 'react-dom/server';

/*
  ----------------------------------------------------------------------------------------------------------LIB IMPORT
*/

// SAMPLE
// import { [...] } from '@syncfusion/...';
import axios from 'axios';
import { ContextMenuItemModel } from '@syncfusion/ej2-react-grids';
import { MenuItemModel } from '@syncfusion/ej2-react-navigations';

/*
  ----------------------------------------------------------------------------------------------------------3.Style IMPORT
*/

// SAMPLE
// import './filename.css';

/*
----------------------------------------------------------------------------------------------------------UNCOMMENT IF XML2JS ARE REQUIRED
*/
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

/*
----------------------------------------------------------------------------------------------------------DOTENV VAR DECLARATION
*/
const {
  REACT_APP_ENDPOINT_API,
  REACT_APP_ENDPOINT_SUBSCRIBERPORT,
  REACT_APP_ENDPOINT_PORT,
} = process.env;

// * SUHAIL - 2023-04-05 - 10
export interface JsonTree {
  object: number;
  field: number;
  fieldValue: string;
  value: string;
  rFieldId: number;
  rFieldValue: string;
  idx: number;
  parentidx: number;
  children?: JsonTree[];
}

export interface SaveDto {
  object: number;
  field: number;
  value: string;
  unique: boolean;
  table: string;
}

export interface GridColumnStructure {
  idx: number;
  object: number;
  label: string;
  source: string;
  width: number | undefined;
}

/*
----------------------------------------------------------------------------------------------------------CLASS DECLARATION
*/
class Common extends React.Component {
  constructor(props: any) {
    super(props);

    /*
      ----------------------------------------------------------------------------------------------------------EVENT BINDING
    */
    // SAMPLE ON EVENT BINDING
    // this.onInputFocus = this.onInputFocus.bind(this);
  }

  /*
    ----------------------------------------------------------------------------------------------------------5.State
    // NAME - DATE - TIME SPENT
    // SAMPLE // DIMITRI - 2023-02-17 - 2.00 
  */
  state = {
    //{...}
  };

  /*
  ----------------------------------------------------------------------------------------------------------OP. AT MOUNT
    // NAME - DATE - TIME SPENT
    // SAMPLE // DIMITRI - 2023-02-17 - 2.00
  */
  componentDidMount() {
    // TODO ACTIONS
  }

  /*
  ----------------------------------------------------------------------------------------------------------4.Api
    // NAME - DATE - TIME SPENT
    // SAMPLE // DIMITRI - 2023-02-17 - 2.00
    */
  private MODEL_API(): any {
    // TODO API
  }

  /*
  ----------------------------------------------------------------------------------------------------------READER
  // NAME - DATE - TIME SPENT
  // SAMPLE // DIMITRI - 2023-02-17 - 2.00
  */
  private MODEL_READER(): any {
    // TODO READER
  }

  /*
  ----------------------------------------------------------------------------------------------------------6.Adapter
  // NAME - DATE - TIME SPENT
  // SAMPLE // DIMITRI - 2023-02-17 - 2.00
  */
  private MODEL_ADAPTER(): any {
    // TODO ADAPTER
  }

  /*
  ----------------------------------------------------------------------------------------------------------COMMON METHODS 
  // NAME - DATE - TIME SPENT
  // SAMPLE // DIMITRI - 2023-02-17 - 2.00
  // * SUHAIL - 2023-02-20 - 00:10
  // * SUHAIL - 2023-02-23 - 1.00
  // * SUHAIL - 2023-02-24 - 0.10
  */
  public static ApiCallSync(
    method: string,
    getpath: string,
    requestdata?: any,
    token: string = ''
  ) {
    let url = new URL(REACT_APP_ENDPOINT_API + getpath);
    url.port = '' + REACT_APP_ENDPOINT_PORT;
    axios({
      method: method,
      url: url.toString(), //'' + REACT_APP_ENDPOINT_API + getpath,
      data: requestdata,
      headers: {
        Authorization: `${
          token !== null || token !== '' ? `Bearer ${token}` : null
        } `,
      },
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        console.error(`error response : ${error}`);
        throw error;
      });
  }

  public static async ApiCallAsync(
    method: string,
    getpath: string,
    requestdata?: any,
    token: string = '',
    usr: any = null
  ) {
    let url = new URL(REACT_APP_ENDPOINT_API + getpath);
    url.port = '' + REACT_APP_ENDPOINT_PORT;
    return await axios({
      method: method,
      url: '' + REACT_APP_ENDPOINT_API + getpath,
      data: requestdata,
      headers: {
        Authorization: `${
          token !== null || token !== '' ? `Bearer ${token}` : null
        } `,
        user: usr !== null ? JSON.stringify(usr) : null,
      },
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        console.error(`error response : ${error}`);
        throw error;
      });
  }

  // return the token from the session storage
  // * SUHAIL - 2023-02-23 - 0.10
  public static getToken() {
    return sessionStorage.getItem('token');
  }

  // return the token from the session storage
  // * SUHAIL - 2023-02-24 - 0.10
  public static getUser() {
    return sessionStorage.getItem('user');
  }

  // * SUHAIL - 2023-03-28 - 10
  public static getStructure() {
    return sessionStorage.getItem('structure');
  }

  // * SUHAIL - 2023-05-12 - 10
  public static getFullJson() {
    return sessionStorage.getItem('fulljson');
  }

  public static setItem(key: string, value: any) {
    return sessionStorage.setItem(key, JSON.stringify(value));
  }

  public static getItem(key: string) {
    return sessionStorage.getItem(key);
  }

  // set the token and user from the session storage
  // * SUHAIL - 2023-02-23 - 0.20
  // * SUHAIL - 2023-03-28 - 10
  public static setUserSession = (
    token: string,
    user: any,
    structure: any,
    fulljson: any
  ) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('structure', JSON.stringify(structure));
    sessionStorage.setItem('fulljson', JSON.stringify(fulljson));
  };

  // remove the token and user from the session storage
  // * SUHAIL - 2023-02-23 - 0.20
  public static removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('structure');
    sessionStorage.removeItem('fulljson');
    localStorage.clear();
    localStorage.removeItem('tabdataviewer-tabcomponent');
  };

  // * SUHAIL - 2023-03-29 - 20
  public static FindJsonByIndex(jsons: any, idx: number): any {
    let js: any = jsons.find((x: any) => x.idx === idx);
    if (js !== undefined) {
      return js;
    }

    if (jsons.length === 0) {
      return null;
    }

    let jsnfinal: any = null;
    jsons.forEach((jsn: any) => {
      let js1: any = this.FindJsonByIndex(jsn.children, idx);
      if (js1 !== null) {
        jsnfinal = js1;
      }
    });
    return jsnfinal;
  }

  // * SUHAIL - 2023-03-29 - 20
  // * SUHAIL - 2023-04-03 - 60
  public static FindJsonByObjectId(
    jsons: any,
    id: number,
    user: any,
    fld: number = 0,
    rfld: number = 0
  ): any {
    try {
      //let js: any = jsons.filter((x: any) => x.object === id);
      let js: any = jsons.filter((x: any) =>
        x.object === id && fld > 0
          ? x.field === fld
          : x.object === id && rfld > 0
          ? x.rFieldId === rfld
          : x.object === id
      );
      // if (js !== undefined) {
      //   return js;
      // }
      if (js.length > 0 && (user == null || js.length == 1)) {
        return js[0];
      } else if (js.length > 0 && user !== null && js.length > 1) {
        if (fld > 0) {
          let jsFld: any = js.filter((x: any) => x.field === fld);
          if (jsFld.length > 0) {
            if (jsFld.length == 1) {
              return jsFld[0];
            } else {
              js = jsFld;
            }
          } else {
            return null;
          }
        }

        if (rfld > 0) {
          let jsFld: any = js.filter((x: any) => x.rFieldId === rfld);
          if (jsFld.length > 0) {
            if (jsFld.length == 1) {
              return jsFld[0];
            } else {
              js = jsFld;
            }
          } else {
            return null;
          }
        }

        let js2: any = js.filter((x: any) => x.field === user.language);
        if (js2.length > 0) {
          return js2[0];
        }

        js2 = js.filter((x: any) => x.field === 101);
        if (js2.length > 0) {
          return js2[0];
        }
      }

      if (jsons.length === 0) {
        return null;
      }

      let jsnfinal: any = null;
      jsons.forEach((jsn: any) => {
        let js1: any = this.FindJsonByObjectId(
          jsn.children,
          id,
          user,
          fld,
          rfld
        );
        if (js1 !== null) {
          jsnfinal = js1;
        }
      });
      return jsnfinal;
    } catch (error: any) {
      console.error(
        `error in FindJsonByObjectId for objectid : ${id}; error : ${error}`
      );
    }
  }

  // * SANDEEP - 2023-10-06 - 20
  public static FindJsonByObjectValue(
    jsons: any,
    value: string,
    user: any,
    fld: number = 0,
    rfld: number = 0,
    ignoreCase: boolean = false
  ): any {
    try {
      //let js: any = jsons.filter((x: any) => x.object === id);
      let js: any = jsons.filter((x: any) =>
        ignoreCase === false
          ? x.value === value && fld > 0
            ? x.field === fld
            : x.value === value && rfld > 0
            ? x.rFieldId === rfld
            : x.value === value
          : x.value.toLowerCase() === value.toLowerCase() && fld > 0
          ? x.field === fld
          : x.value.toLowerCase() === value.toLowerCase() && rfld > 0
          ? x.rFieldId === rfld
          : x.value.toLowerCase() === value.toLowerCase()
      );
      // if (js !== undefined) {
      //   return js;
      // }
      if (js.length > 0 && (user == null || js.length == 1)) {
        return js[0];
      } else if (js.length > 0 && user !== null && js.length > 1) {
        if (fld > 0) {
          let jsFld: any = js.filter((x: any) => x.field === fld);
          if (jsFld.length > 0) {
            if (jsFld.length == 1) {
              return jsFld[0];
            } else {
              js = jsFld;
            }
          } else {
            return null;
          }
        }

        if (rfld > 0) {
          let jsFld: any = js.filter((x: any) => x.rFieldId === rfld);
          if (jsFld.length > 0) {
            if (jsFld.length == 1) {
              return jsFld[0];
            } else {
              js = jsFld;
            }
          } else {
            return null;
          }
        }

        let js2: any = js.filter((x: any) => x.field === user.language);
        if (js2.length > 0) {
          return js2[0];
        }

        js2 = js.filter((x: any) => x.field === 101);
        if (js2.length > 0) {
          return js2[0];
        }
      }

      if (jsons.length === 0) {
        return null;
      }

      let jsnfinal: any = null;
      jsons.forEach((jsn: any) => {
        let js1: any = this.FindJsonByObjectValue(
          jsn.children,
          value,
          user,
          fld,
          rfld,
          ignoreCase
        );
        if (js1 !== null) {
          jsnfinal = js1;
        }
      });
      return jsnfinal;
    } catch (error: any) {
      console.error(
        `error in FindJsonByObjectValue for valueid : ${value}; error : ${error}`
      );
    }
  }

  // * SUHAIL - 2023-03-29 - 20
  public static FindJsonByRelationFieldId(
    jsons: any,
    id: number,
    user: any = null
  ): any {
    // let js: any = jsons.find((x: any) => x.rFieldId === id);
    // if (js !== undefined) {
    //   return js;
    // }
    let js: any = jsons.filter((x: any) => x.rFieldId === id);

    if (js.length > 0 && (user == null || js.length == 1)) {
      return js[0];
    } else if (js.length > 0 && user !== null && js.length > 1) {
      let js2: any = js.filter((x: any) => x.field === user.language);
      if (js2.length > 0) {
        return js2[0];
      }

      js2 = js.filter((x: any) => x.field === 101);
      if (js2.length > 0) {
        return js2[0];
      }
    }
    if (jsons.length === 0) {
      return null;
    }

    let jsnfinal: any = null;
    jsons.forEach((jsn: any) => {
      let js1: any = this.FindJsonByRelationFieldId(jsn.children, id, user);
      if (js1 !== null) {
        jsnfinal = js1;
      }
    });
    return jsnfinal;
  }

  // * SUHAIL - 2023-04-03 - 70
  public static FindJsonByFieldId(jsons: any, id: number): any {
    let js: any = jsons.find((x: any) => x.field === id);
    if (js !== undefined) {
      return js;
    }

    if (jsons.length === 0) {
      return null;
    }

    let jsnfinal: any = null;
    jsons.forEach((jsn: any) => {
      let js1: any = this.FindJsonByFieldId(jsn.children, id);
      if (js1 !== null) {
        jsnfinal = js1;
      }
    });
    return jsnfinal;
  }

  // * SUHAIL - 2023-04-03 - 70
  public static GetJsonByRelationFieldId(jsons: any, id: number): any {
    let jsnfinal: any = [];

    let js: any = jsons.filter((x: any) => x.rFieldId === id);
    if (js.length > 0) {
      js.forEach((j: any) => {
        jsnfinal.push(j);
      });
    }

    if (jsons.length === 0) {
      return null;
    }

    // let jsnfinal: any = null;
    jsons.forEach((jsn: any) => {
      let js1: any = this.GetJsonByRelationFieldId(jsn.children, id);
      if (js1 !== null) {
        js1.forEach((j: any) => {
          jsnfinal.push(j);
        });
      }
    });
    return jsnfinal;
  }

  public static GetContextMenu(
    cntxmenu: any,
    structure: any,
    user: any,
    skipforobjectids: string[] = [],
    skipifnochildren: boolean = false,
    defaultPreMenuItems: any = [],
    skipdefaultpremenuitemsforobjectid: number = 0,
    idx: number = 0,
    extrafieldforid: string = ''
  ) {
    let cntxMenuItems: ContextMenuItemModel[] | MenuItemModel[] | undefined =
      [];
    let cntmenu: any = cntxmenu.filter(
      (x: any) =>
        x.rFieldId === structure.Element.Child ||
        x.rFieldId === structure.Element.Group ||
        x.value.toLowerCase() === 'group' ||
        x.rFieldId === structure.MenuElements.CntxtMenuElements.CntxMenuObject
    );
    if (cntmenu.length > 0) {
      cntmenu.forEach((x: any) => {
        let cntxMenuItemsFlter: any = cntxMenuItems!.filter((xx: any) =>
          extrafieldforid
            ? xx.id === x.object.toString() + '-' + x[extrafieldforid]
            : xx.id === x.object.toString()
        );
        if (cntxMenuItemsFlter.length <= 0) {
          idx = idx + 1;
          if (
            skipforobjectids.length > 0 &&
            skipforobjectids.includes(x.object.toString()) //x.object.toString() === skipforobjectid.toString()
          ) {
            return;
          }
          let sprtr: boolean =
            x.value.toLowerCase() === 'separator' ? true : false;

          if (cntxmenu.filter((xx: any) => xx.object === x.object).length > 1) {
            let itm: any = Common.FindJsonByObjectId(cntxmenu, x.object, user);
            if (itm !== null) {
              if (
                itm.value.toLowerCase() !== 'group' &&
                x.rFieldId !==
                  structure.MenuElements.CntxtMenuElements.CntxMenuObject
              ) {
                let subitms: any =
                  x.rFieldId === structure.Element.Group ||
                  x.value.toLowerCase() === 'group'
                    ? this.GetContextMenu(
                        itm.children,
                        structure,
                        user,
                        skipforobjectids,
                        skipifnochildren,
                        defaultPreMenuItems,
                        skipdefaultpremenuitemsforobjectid,
                        idx,
                        extrafieldforid
                      )
                    : [];
                if (
                  skipifnochildren === false ||
                  subitms.length > 0 ||
                  x.rFieldId === structure.Element.Child
                ) {
                  let sbitm: any = subitms;
                  if (
                    subitms.length > 0 &&
                    skipdefaultpremenuitemsforobjectid > 0 &&
                    x.object.toString() ===
                      skipdefaultpremenuitemsforobjectid.toString()
                  ) {
                    sbitm = [...subitms];
                  } else if (
                    // subitms.length > 0 &&
                    // defaultPreMenuItems.length > 0
                    x.rFieldId === structure.Element.Group
                  ) {
                    //sbitm = [...defaultPreMenuItems, ...subitms];
                    let tmpitm: any = defaultPreMenuItems.map((x: any) => {
                      return {
                        ...x,
                        id: x.id.toString().startsWith('__')
                          ? x.id.toString() +
                            itm.object +
                            '--' +
                            itm.value +
                            idx.toString()
                          : x.id.toString(),
                      };
                    });
                    sbitm = [...tmpitm, ...subitms];
                  }
                  cntxMenuItems!.push({
                    text: sprtr ? '' : itm.value,
                    //id: itm.object.toString(),
                    id: extrafieldforid
                      ? itm.object.toString() + '-' + itm[extrafieldforid]
                      : itm.object.toString(),
                    separator: sprtr,
                    iconCss:
                      itm.children.filter(
                        (x: any) =>
                          x.rFieldId ===
                            structure.MenuElements.CntxtMenuElements
                              .CntxMenuItemType &&
                          x.value.toLowerCase() === 'checkbox'
                      ).length > 0
                        ? 'checkbox'
                        : '',
                    //items: subitms,
                    items: sbitm,
                    // items:
                    //   x.rFieldId === structure.Element.Group ||
                    //   x.value.toLowerCase() === 'group'
                    //     ? this.GetContextMenu(
                    //         itm.children,
                    //         structure,
                    //         user,
                    //         skipforobjectid
                    //       )
                    //     : [],
                  });
                }
              } else {
                cntxMenuItems = this.GetContextMenu(
                  itm.children,
                  structure,
                  user,
                  skipforobjectids,
                  skipifnochildren,
                  defaultPreMenuItems,
                  skipdefaultpremenuitemsforobjectid,
                  idx,
                  extrafieldforid
                );
              }
            }
          } else {
            if (
              x.value.toLowerCase() !== 'group' &&
              x.rFieldId !==
                structure.MenuElements.CntxtMenuElements.CntxMenuObject
            ) {
              let subitms: any =
                x.rFieldId === structure.Element.Group ||
                x.value.toLowerCase() === 'group'
                  ? this.GetContextMenu(
                      x.children,
                      structure,
                      user,
                      skipforobjectids,
                      skipifnochildren,
                      defaultPreMenuItems,
                      skipdefaultpremenuitemsforobjectid,
                      idx,
                      extrafieldforid
                    )
                  : [];
              if (
                skipifnochildren === false ||
                subitms.length > 0 ||
                x.rFieldId === structure.Element.Child
              ) {
                let sbitm: any = subitms;
                if (
                  subitms.length > 0 &&
                  skipdefaultpremenuitemsforobjectid > 0 &&
                  x.object.toString() ===
                    skipdefaultpremenuitemsforobjectid.toString()
                ) {
                  sbitm = [...subitms];
                } else if (
                  // subitms.length > 0 &&
                  // defaultPreMenuItems.length > 0
                  x.rFieldId === structure.Element.Group
                ) {
                  //sbitm = [...defaultPreMenuItems, ...subitms];
                  let tmpitm: any = defaultPreMenuItems.map((xx: any) => {
                    return {
                      ...xx,
                      id: xx.id.toString().startsWith('__')
                        ? xx.id.toString() +
                          x.object +
                          '--' +
                          x.value +
                          idx.toString()
                        : xx.id.toString(),
                    };
                  });
                  sbitm = [...tmpitm, ...subitms];
                }
                cntxMenuItems!.push({
                  text: sprtr ? '' : x.value,
                  //id: x.object.toString(),
                  id: extrafieldforid
                    ? x.object.toString() + '-' + x[extrafieldforid]
                    : x.object.toString(),
                  separator: sprtr,
                  iconCss:
                    x.children.filter(
                      (x: any) =>
                        x.rFieldId ===
                          structure.MenuElements.CntxtMenuElements
                            .CntxMenuItemType &&
                        x.value.toLowerCase() === 'checkbox'
                    ).length > 0
                      ? 'checkbox'
                      : '',
                  //items: subitms,
                  items: sbitm,
                  // items:
                  //   x.rFieldId === structure.Element.Group ||
                  //   x.value.toLowerCase() === 'group'
                  //     ? this.GetContextMenu(
                  //         x.children,
                  //         structure,
                  //         user,
                  //         skipforobjectid
                  //       )
                  //     : [],
                });
              }
            } else {
              cntxMenuItems = this.GetContextMenu(
                x.children,
                structure,
                user,
                skipforobjectids,
                skipifnochildren,
                defaultPreMenuItems,
                skipdefaultpremenuitemsforobjectid,
                idx,
                extrafieldforid
              );
            }
          }
        }
      });

      return cntxMenuItems;
    } else {
      return [];
    }
  }

  public static GetSelectedContextMenuRootMenu(
    item: any,
    maxRootID: number = 0
  ) {
    let rootMenu: any = item;
    if (
      item.parentObj &&
      item.parentObj.text &&
      item.parentObj.id.toString().includes('-')
        ? Number(item.parentObj.id.toString().split('-')[0]) > maxRootID
        : Number(item.parentObj.id) > maxRootID
    ) {
      rootMenu = this.GetSelectedContextMenuRootMenu(item.parentObj, maxRootID);
    }
    return rootMenu;
  }

  public static toCamelCase(str: string): string {
    //return str.charAt(0).toLowerCase() + str.slice(1).toLowerCase();
    return str
      .split('.')
      .map((s: string) => {
        return s.charAt(0).toLowerCase() + s.slice(1);
      })
      .join('.');
  }

  public static toTitleCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  // * SUHAIL - 2023-04-07 - 10
  public static ClearStatusBar() {
    let statusbar: any = document.getElementById(
      'layout-footer-right-div'
    ) as Element;
    if (statusbar !== null) {
      statusbar.innerText = '';
    }
  }

  // * SUHAIL - 2023-04-07 - 20
  public static SetStatusBar(msg: string) {
    let statusbar: any = document.getElementById(
      'layout-footer-right-div'
    ) as Element;
    if (statusbar !== null) statusbar.innerText = msg;
  }

  // * SUHAIL - 2023-05-09 - 20
  public static ShowSpinner(targetDivId: string = '') {
    let spinner: HTMLElement = document.getElementById(
      'rootspinner'
    ) as HTMLElement;
    if (spinner !== null) {
      spinner.style.visibility = 'visible';
      if (targetDivId) {
        let targetDiv: HTMLElement = document.getElementById(
          targetDivId
        ) as HTMLElement;
        let divarea: any = targetDiv.getBoundingClientRect();
        spinner.style.top = `${divarea.top.toString()}px`;
        spinner.style.left = `${divarea.left.toString()}px`;
        spinner.style.width = `${divarea.width.toString()}px`;
        spinner.style.height = `${divarea.height.toString()}px`;
      } else {
        spinner.style.top = `0px`;
        spinner.style.left = `0px`;
        spinner.style.width = `100%`;
        spinner.style.height = `100%`;
      }
    }
  }

  // * SUHAIL - 2023-05-09 - 20
  public static HideSpinner(targetDivId: string = '') {
    let spinner: HTMLElement = document.getElementById(
      'rootspinner'
    ) as HTMLElement;
    if (spinner !== null) {
      spinner.style.visibility = 'hidden';
      if (targetDivId) {
        let targetDiv: HTMLElement = document.getElementById(
          targetDivId
        ) as HTMLElement;
        let divarea: any = targetDiv.getBoundingClientRect();
        spinner.style.top = `${divarea.top.toString()}px`;
        spinner.style.left = `${divarea.left.toString()}px`;
        spinner.style.width = `${divarea.width.toString()}px`;
        spinner.style.height = `${divarea.height.toString()}px`;
      } else {
        spinner.style.top = `0px`;
        spinner.style.left = `0px`;
        spinner.style.width = `100%`;
        spinner.style.height = `100%`;
      }
    }
  }

  // * SUHAIL - 2023-05-25 - 10
  //public static datescolumns: string[] = ['date', 'startdate', 'enddate'];
  public static DatesColumns(_structure: any): string[] {
    return Object.keys(_structure.Field.Chrono).map((x: any) =>
      x.toLowerCase()
    );
  }

  public static FindJsonByIdKeyChildren(
    jsons: any,
    id: any,
    idfield: string,
    childrenfield: string
  ): any {
    let js: any = jsons.filter((x: any) => x[`${idfield}`] == id);

    if (js.length > 0) {
      return js[0];
    }

    if (jsons.length === 0) {
      return null;
    }

    let jsnfinal: any = null;
    jsons.forEach((jsn: any) => {
      if (jsn[`${childrenfield}`] && jsn[`${childrenfield}`].length > 0) {
        let js1: any = this.FindJsonByIdKeyChildren(
          jsn[`${childrenfield}`],
          id,
          idfield,
          childrenfield
        );
        if (js1 !== null) {
          jsnfinal = js1;
        }
      }
    });
    return jsnfinal;
  }

  public static FindParentJsonByIdKeyChildren(
    jsons: any,
    id: any,
    idfield: string,
    childrenfield: string,
    parentobj: any = null // parentid: number = 0
  ): any {
    let js: any = jsons.filter((x: any) => x[`${idfield}`] == id);

    if (js.length > 0) {
      return parentobj;
    }

    if (jsons.length === 0) {
      return null;
    }

    let jsnfinal: any = null;
    jsons.forEach((jsn: any) => {
      if (jsn[`${childrenfield}`] && jsn[`${childrenfield}`].length > 0) {
        let js1: any = this.FindParentJsonByIdKeyChildren(
          jsn[`${childrenfield}`],
          id,
          idfield,
          childrenfield,
          //jsn[idfield]
          jsn
        );
        if (js1 !== null) {
          jsnfinal = js1;
        }
      }
    });
    return jsnfinal;
  }

  public static DeleteRestJsonById(
    jsons: any,
    id: number,
    idfield: string,
    childrenfield: string,
    parentid: number
  ): any {
    let js: any = jsons.filter((x: any) => x[`${idfield}`] == id);

    if (js.length > 0) {
      let jsdel: any = jsons.filter((x: any) => x[`${idfield}`] != id);
      if (jsdel.length > 0) {
        jsdel.forEach((x: any) => {
          jsons.splice(jsons.indexOf(x), 1);
        });
      }
      return jsons;
    }

    if (jsons.length === 0) {
      return null;
    }

    let jsnfinal: any = null;
    jsons.forEach((jsn: any) => {
      if (jsn[`${childrenfield}`] && jsn[`${childrenfield}`].length > 0) {
        return this.DeleteRestJsonById(
          jsn[`${childrenfield}`],
          id,
          idfield,
          childrenfield,
          jsn.id
        );
      }
    });
    return jsnfinal;
  }

  // * SANDEEP - 2023-05-30 - 30
  public static formatDate(date: Date, pattern: string) {
    if (!pattern) {
      pattern = 'dd-MMM-yyyy';
    }
    let day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear(),
      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds(),
      miliseconds = date.getMilliseconds(),
      dow = date.getDay(),
      h = hour % 12,
      hh = this.twoDigitPad(h.toString()),
      HH = this.twoDigitPad(hour.toString()),
      mm = this.twoDigitPad(minute.toString()),
      ss = this.twoDigitPad(second.toString()),
      aaa = hour < 12 ? 'AM' : 'PM',
      EEEE = date.toLocaleString('default', { weekday: 'long' }),
      EEE = date.toLocaleString('default', { weekday: 'short' }),
      dd = this.twoDigitPad(day),
      M = month + 1,
      MM = this.twoDigitPad(M.toString()),
      MMMM = date.toLocaleString('default', { month: 'long' }),
      MMM = date.toLocaleString('default', { month: 'short' }),
      yyyy = year + '',
      yy = yyyy.substring(2),
      yyy = yyyy.substring(1);

    pattern = pattern
      .replace('hh', hh)
      .replace('h', h.toString())
      .replace('HH', HH)
      .replace('H', hour.toString())
      .replace('mm', mm)
      .replace('m', minute.toString())
      .replace('ss', ss)
      .replace('s', second.toString())
      .replace('S', miliseconds.toString())
      .replace('dd', dd)
      .replace('d', day.toString())
      .replace('dow', dow.toString())

      .replace('EEEE', EEEE)
      .replace('EEE', EEE)
      .replace('yyyy', yyyy)
      .replace('yyy', yyy)
      .replace('yy', yy)
      .replace('aaa', aaa);

    if (pattern.indexOf('MMM') > -1) {
      pattern = pattern.replace('MMMM', MMMM).replace('MMM', MMM);
    } else if (pattern.indexOf('MM') > -1) {
      pattern = pattern.replace('MM', MM).replace('M', M.toString());
    }
    return pattern;
  }

  public static FormatNumber(value: number, format: string) {
    var num = '';
    //if (value) {
    if (format.includes('.')) {
      var digits = format.split('.')[1].length;
      num = parseFloat(value.toString()).toFixed(digits);
    } else {
      num = parseFloat(value.toString()).toFixed(0);
    }
    num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    //}
    return num;
  }

  public static RemoveTimezoneOffsetFromDate(date: Date) {
    return new Date(
      new Date(date).setMinutes(
        date.getMinutes() + date.getTimezoneOffset() * -1
      )
    );
  }

  public static twoDigitPad(num: any) {
    return num < 10 ? '0' + num : num;
  }

  public static flattenObject(
    obj: { [key: string]: any },
    attrib: string
  ): Array<any> {
    const result: Array<any> = [];

    for (const key in obj) {
      const value: any = obj[key];

      if (typeof value === 'object' && value !== null) {
        result.push(...this.flattenObject(value, attrib));
      } else if (key === attrib) {
        result.push(value);
      }
    }

    return result;
  }

  public static flattenJSON = (
    obj: any = {},
    res: any = {},
    extraKey: string = '',
    user: any = null,
    DatesColumn: string[] = []
  ) => {
    for (const key in obj) {
      if (typeof obj[key] !== 'object') {
        if (
          DatesColumn &&
          DatesColumn.length > 0 &&
          DatesColumn.includes(key.toLowerCase()) === true &&
          user
        ) {
          res[extraKey + key] = Common.formatDate(
            new Date(obj[key]),
            user?.settings.formats.DateShort
          );
        } else {
          res[extraKey + key] = obj[key];
        }
      } else if (obj[key] === null) {
        res[extraKey + key] = null;
      } else {
        this.flattenJSON(obj[key], res, `${extraKey}${key}.`);
      }
    }
    return res;
  };

  public static parseJwt(token: string) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  }

  public static base64ToBlob(base64String: string, contentType: string) {
    const byteCharacters = atob(base64String); // Decode the Base64 string
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  public static getSubstringBetweenTwoChars(
    text: string,
    char1: string,
    char2: string
  ) {
    return text.slice(text.indexOf(char1) + 1, text.lastIndexOf(char2));
  }

  public static GetContextMenuFromXML(
    cntxmenu: any,
    idx: number = 0
  ): ContextMenuItemModel[] {
    let cntxMenuItems: ContextMenuItemModel[] = [];
    cntxmenu = Array.isArray(cntxmenu) ? cntxmenu : [cntxmenu];
    cntxmenu.forEach((x: any) => {
      idx = idx + 1;
      cntxMenuItems.push({
        text: x.$.type === 'separator' ? '' : x.$.text,
        id: x.$.id ?? idx.toString(),
        separator: x.$.type === 'separator',
        items: x.Item ? this.GetContextMenuFromXML(x.Item, idx) : [],
        iconCss: x.$.type === 'separator' ? '' : `noicon {${x.$.columnname}}`,
      });
    });

    return cntxMenuItems;
  }

  static validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  /*
  ----------------------------------------------------------------------------------------------------------MAIN 
  // NAME - DATE - TIME SPENT
  // SAMPLE // DIMITRI - 2023-02-17 - 2.00
  */
  public MODEL_MAIN(): any {
    // TODO MAIN
  }
}

export default Common;
