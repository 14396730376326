// * ---------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SUHAIL - 2023-03-01 - 240 - Research on maintaining state between user context menu and layout area to hide contextmenu when clicked outside
// * SUHAIL - 2023-03-02 - 120 - Research on maintaining state between user context menu and layout area to hide contextmenu when clicked outside
// * SUHAIL - 2023-04-12 - 450 - Research on flicker issue on selectorand sidebar when any item selected on selector
// * SUHAIL - 2023-04-13 - 300 - Research on flicker issue on selectorand sidebar when any item selected on selector
// * SUHAIL - 2023-04-14 - 420 - Research on flicker issue on selectorand sidebar when any item selected on selector
// * SUHAIL - 2023-04-17 - 480 - Layout - Redesign of layout panel structure
// * SUHAIL - 2023-04-18 - 480 - Research on flicker issue on selectorand sidebar when any item selected on selector
// * SUHAIL - 2023-04-19 - 480 - Research on flicker issue on selectorand sidebar when any item selected on selector
// * SUHAIL - 2023-04-20 - 480 - Research on flicker issue on selectorand sidebar when any item selected on selector
// * SUHAIL - 2023-04-21 - 430 - Changes in sidebar and selector and dataviewer to adapt neew sidebar structure and correspondence menu
// * SUHAIL - 2023-05-05 - 60 - Research on showing spinner on fetching API
// * SUHAIL - 2023-05-09 - 30 - meeting with fabio on spinner and word package of syncfusion
// * SUHAIL - 2023-05-09 - 350 - Research on showing spinner on fetching API
// * SUHAIL - 2023-05-11 - 30 - sprint planning meeting
// * SUHAIL - 2023-05-11 - 200 - meeting with fabio on new subscriber service changes testing
// * SUHAIL - 2023-05-11 - 250 - Research on adding record on selector with infinite scroll
// * SANDEEP - 2023-06-01 - 60 - Research on call back function and setting state in class base components
// * SUHAIL - 2023-06-19 - 150 - adapting to package 1.5.36 and fixing logo issue after logo structure change
// * SANDEEP - 2023-08-09 - 300 - Debugging and checking for gantt component not showing and fixing it by step by step
// * SANDEEP - 2023-08-10 - 300 - Working on layout, sidebar & Dataviewer to hide selector and show only dataviewer and component under it.

// * ---------------------------------------------------------------------------------------------------------------2.Initialize
// * SUHAIL - 2023-02-23 - 10
// * SUHAIL - 2023-02-24 - 10
// * SUHAIL - 2023-02-28 - 10
// * SUHAIL - 2023-03-01 - 10
// * SUHAIL - 2023-03-02 - 10

import React, { ReactNode, useCallback, useEffect } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { Navigate, useNavigate } from 'react-router-dom';
//import { useLoginContext } from '../Components/Login/LoginReducer';
import Sidebar from '../Components/Layout/Sidebar';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {
  PaneDirective,
  PanesDirective,
  SplitterComponent,
} from '@syncfusion/ej2-react-layouts';
//import { enableRipple } from '@syncfusion/ej2-base'
import {
  AppBarColor,
  TreeViewComponent,
} from '@syncfusion/ej2-react-navigations';
import {
  AppBarComponent,
  MenuComponent,
  MenuItemModel,
  MenuEventArgs,
} from '@syncfusion/ej2-react-navigations';
// import {
//   DropDownButtonComponent,
//   ItemModel,
// } from '@syncfusion/ej2-react-splitbuttons'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import {
  TreeGridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Sort,
  Reorder,
  Inject,
  ITreeData,
} from '@syncfusion/ej2-react-treegrid';
import { IFilter } from '@syncfusion/ej2-react-grids';
import {
  QueryCellInfoEventArgs,
  ActionEventArgs,
  getObject,
} from '@syncfusion/ej2-grids';
//import { addClass, isNullOrUndefined } from '@syncfusion/ej2-base'
//import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import '../Layout.css';
import { AnyARecord } from 'dns';
import Common from '../Util/Common';
import SelectorPanel from '../Components/Layout/SelectorPanel';
import { CalendarOutline, Notifications } from 'react-ionicons';
import DPButton from '../Components/DPButton';
import DataViewer from '../Components/Layout/DataViewer';
import Calendar from '../Components/Calendar';
import { DialogUtility } from '@syncfusion/ej2-popups';
let dialogInstance: DialogComponent | any;

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

const { REACT_APP_ENDPOINT_FOXSYSTEMSERVICE, REACT_APP_VERSION } = process.env;

const countries = [
  {
    name: 'TEST1',
    states: [],
  },
  {
    name: 'TEST2',
    states: [],
  },
  {
    name: 'TEST3',
    states: [],
  },
];

const items = [
  {
    title: 'Profile',
    iconCss: 'fa-regular fa-user',
    href: '',
  },
  {
    title: 'Settings',
    iconCss: 'fa-solid fa-gear',
    href: '',
  },
  {
    title: 'Logout',
    iconCss: 'fa-solid fa-power-off',
    href: '',
  },
];

const notifitems = [
  {
    title: 'Tasks',
    iconCss: 'fa-solid fa-list-check',
    href: '',
  },
  {
    title: 'Reminders',
    iconCss: 'fa-regular fa-clock',
    href: '',
  },
  {
    title: 'Messages',
    iconCss: 'fa-regular fa-message',
    href: '',
  },
  {
    title: 'Notifications',
    iconCss: 'fa-regular fa-bell',
    href: '',
  },
];

//enableRipple(true)

// * ------------------------------------------------------------------------------------------------------------------3.Style
interface Props {
  selectorSize: number;
}
// * ------------------------------------------------------------------------------------------------------------------4.Api
// * SUHAIL - 2023-03-01 - 20
class Layout extends React.Component<Props> {
  //<{}, {apiGetXmlStructure: {}}> {
  private innerSplitterSidebar: any;
  private innerSplitterSelectorDataviewer: any;
  private innerSplitterSelector: any;
  private innerSplitterDataViewer: any;
  private user: any;
  private _structure: any;
  private refSelector: SelectorPanel | any;
  private dlgUtility: any;
  // * ----------------------------------------------------------------------------------------------------------------2.Initialize Component

  constructor(props: any) {
    super(props);

    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.beforeItemRender = this.beforeItemRender.bind(this);
    //this.innerSplitterElementContent = this.innerSplitterElementContent.bind(this);
    this.splitterSidebar = this.splitterSidebar.bind(this);
    //this.hPaneContentMain = this.hPaneContentMain.bind(this)
    this.splitterSelector = this.splitterSelector.bind(this);
    this.splitterDataViewer = this.splitterDataViewer.bind(this);
    this.SplitterTop = this.SplitterTop.bind(this);
    this.SplitterBottom = this.SplitterBottom.bind(this);
    this.LayoutHeaderLeft = this.LayoutHeaderLeft.bind(this);
    this.LayoutHeaderRight = this.LayoutHeaderRight.bind(this);
    //this.Selector = this.Selector.bind(this);
    this.refSelector = React.createRef();

    this.selectedtab = this.selectedtab.bind(this);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');

    let versneed: any = Common.getItem('version-update-needed');
    if (versneed && versneed === 'true') {
      sessionStorage.removeItem('version-update-needed');
      sessionStorage.setItem('version-updated', 'true');
      window.location.reload();
    }
  }

  // * ------------------------------------------------------------------------------------------------------------------5.State
  // * SANDEEP - 2023-08-10 - 05
  state = {
    // bLoaded: false,
    // oLoginResponse: {},
    // xmlStructure: {},
    // oSidebar: {},

    // selector: ['', '', '', ''],
    // i: -1,
    isSelectorLoading: false,
    rootmenu: null,
    currmenu: null,
    selectedmenu: null,
    jsonSelector: null,
    selectedAllocations: [],
    serviceName: '',
    jsonSidebar: [],
    selectorSize: 0,
    selectorKey: 0,
    jsonDataViewer: null,
    dataviewerKey: 0,
    showdialog: false,
    selectedtabid: 0,
    jsonDocument: null,
    companylogo: null,
    showganttchart: false,
    showdashboard: false,
  };
  private isSelectorLoadingg: any;
  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  // * SUHAIL - 2023-02-24 - 90

  componentDidMount() {
    let versupdated: any = Common.getItem('version-updated');
    if (versupdated && versupdated === 'true') {
      console.log('versupdated', versupdated);
      setTimeout(() => {
        this.dlgUtility = DialogUtility.alert({
          title: 'Version Update',
          content: `New version <strong>${REACT_APP_VERSION}</strong> updated!`,
          isModal: false,
          cssClass: 'versionupdate',
          okButton: { text: 'Dismiss', cssClass: 'd-none' },
          closeOnEscape: true,
          position: { X: 'right', Y: 'bottom' },
          animationSettings: { effect: 'SlideBottom' },
        });

        let versupdt: HTMLElement = document.querySelector(
          '.versionupdate'
        ) as HTMLElement;
        if (versupdt) {
          versupdt.style.top = 'unset';
          versupdt.style.left = 'unset';
          versupdt.style.bottom = '0px';
          versupdt.style.right = '0px';
        }
      }, 2000);

      setTimeout(() => {
        sessionStorage.removeItem('version-updated');
        this.dlgUtility.hide();
      }, 5000);
    }
    if (
      this.state.companylogo === null &&
      this.user.company.branding.logo.length > 0 &&
      this.user.company.branding.logo[0]
    ) {
      // LOGIN
      // const requestOptions = {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   //body: JSON.stringify({ title: 'React POST Request Example' })
      // }
      // fetch(
      //   '' +
      //   REACT_APP_ENDPOINT_API +
      //   REACT_APP_ENDPOINT_SUBSCRIBERPATH +
      //   '?username=taylor.kari',
      //   requestOptions,
      // )
      //   .then((response) => this.setState({ oLoginResponse: response }))
      //   .then(() => {
      //     // ask for xmlStruct
      //     fetch('' + REACT_APP_ENDPOINT_API + REACT_APP_ENDPOINT_XMLPATH)
      //       .then((response) => {
      //         var xmlStructure: any
      //         response
      //           .text()
      //           .then((text) => {
      //             parser.parseString(text, function (err: any, result: any) {
      //               if (Object.keys(result).length > 0) {
      //                 console.log('XML STRUCT LOADED')
      //                 xmlStructure = result
      //               } else return null
      //             })
      //           })
      //           .then((response) =>
      //             this.setState({ bLoaded: true, xmlStructure: xmlStructure }),
      //           )
      //         //.then(()=>{console.log(this.state.apiGetXmlStructure)});
      //       })
      //       .catch((error) => {
      //         // handle your errors here
      //         console.error(error)
      //       })

      //     //ask for sidebarstring
      //     fetch(
      //       '' +
      //       REACT_APP_ENDPOINT_API +
      //       REACT_APP_ENDPOINT_SIDEBARPATH +
      //       '?id=90003',
      //     )
      //       .then((response) => response.json())
      //       .then((response) =>
      //         this.setState({ bLoaded: true, oSidebar: response }),
      //       )
      //       .then(() => console.log('SIDEBAR STRING LOADED', this.state.oSidebar))
      //       .catch((error) => {
      //         console.error(error)
      //       })
      //   })
      //   .catch((error) => {
      //     // handle your errors here
      //     console.error(error)
      //   })
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Image/Read`,
        {
          object: this.user.company.branding.logo[0].id,
          field: this._structure.Field.FileType.Jpg,
        },
        Common.getToken() || '',
        this.user
      )
        .then((resopnse: any) => {
          return resopnse.data;
        })
        .then((resopnse: any) => {
          this.LayoutHeaderLeft = this.LayoutHeaderLeft.bind(this);
          this.LayoutHeaderRight = this.LayoutHeaderRight.bind(this);
          this.setState({ companylogo: resopnse });
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }

    window.addEventListener('resize', this.updateDimensions);

    // if (document.getElementById('root') !== undefined && document.getElementById('root') !== null) {
    //   // let spinner: any = document.getElementById('root')?.querySelector('#root > .e-spin-hide');
    //   // if (spinner === null) {
    //   createSpinner({
    //     target: document.getElementById('root')!,
    //   });
    //   // }
    // }
    return null;
  }

  // static ShowSpinner() {
  //   if (document.getElementById('root') !== undefined && document.getElementById('root') !== null) {
  //     // createSpinner({
  //     //   target: document.getElementById('root')!,
  //     // });
  //     // let spinner: any = document.getElementById('root')?.querySelector('#root > .e-spin-hide');
  //     // if (spinner !== null) {
  //     let spnr: any = document.getElementById('root');
  //     console.log('show');
  //     showSpinner(spnr);
  //     // }
  //   }
  // }

  // static HideSpinner() {
  //   if (document.getElementById('root') !== undefined && document.getElementById('root') !== null)
  //     console.log('hide');
  //   hideSpinner(document.getElementById('root')!);
  // }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  public updateDimensions = () => {
    let header: HTMLElement = document.querySelector(
      '#splitterTop'
    ) as HTMLElement;
    let body: HTMLElement = document.querySelector(
      '#innerSplitterSidebar'
    ) as HTMLElement;
    let footer: HTMLElement = document.querySelector(
      '#splitterBottom'
    ) as HTMLElement;

    if (body !== null) {
      body.style.height =
        window.innerHeight - header.offsetHeight - footer.offsetHeight + 'px';
    }
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  // * SANDEEP - 2023-06-01 - 30 -- Created Event (selectedtab) and setting up the state and passing it in dataviewer component
  public onInputFocus(args: React.FocusEvent) {
    ((args.target as HTMLElement).parentElement as HTMLElement).classList.add(
      'e-input-focus'
    );
  }
  public onInputBlur(args: React.FocusEvent) {
    (
      (args.target as HTMLElement).parentElement as HTMLElement
    ).classList.remove('e-input-focus');
  }
  public beforeItemRender(args: MenuEventArgs): void {
    if (
      args.element.children.length > 0 &&
      args.element.children[0].classList.contains('e-more-vertical-1')
    ) {
      args.element.setAttribute('aria-label', 'more vertical');
    }
  }

  public selectedtab(args: any) {
    this.setState({ selectedtabid: args });
  }

  calClick(args: any) {
    // showdialog

    this.setState({ showdialog: true });

    // dialogInstance.show()
  }
  private dialogClose(): void {
    this.setState({ showdialog: false });
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter

  public Selector(): JSX.Element {
    return (
      <div className='control-pane'>
        <div className='control-section'>
          <TreeGridComponent
            dataSource={countries}
            childMapping='states'
            height='400'
            allowReordering={true}
            allowFiltering={true}
            allowSorting={true}
            filterSettings={{ type: 'Menu', hierarchyMode: 'Parent' }}
          >
            <ColumnsDirective>
              <ColumnDirective
                field='name'
                headerText='Nome'
                width='195'
              ></ColumnDirective>
            </ColumnsDirective>
            <Inject services={[Filter, Sort, Reorder]} />
          </TreeGridComponent>
        </div>
      </div>
    );

    /*switch (type) {
      case 'treeview':
  
        break;
    
      default:
        break;
    }
    return (
      <>auto panel: {index}</>
    );*/
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter

  // * SUHAIL - 2023-03-02 - 10

  public LayoutHeaderLeft(): JSX.Element {
    return (
      <div>
        {this.user.company.branding &&
        this.user.company.branding.logo.length > 0 &&
        this.user.company.branding.logo[0] &&
        this.state.companylogo ? (
          <img
            src={`data:image/png;base64,${this.state.companylogo}`}
            className='company-logo'
          ></img>
        ) : (
          <img src={'images/company-logo.png'} className='company-logo' />
        )}
      </div>
    );
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter

  // * SUHAIL - 2023-03-02 - 10

  public LayoutFooterLeft(): JSX.Element {
    return (
      <div className='pt-0'>
        <img src={'images/Signature.svg'} className='aruti-logo' />
      </div>
    );
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SUHAIL - 2023-04-07 - 30
  public LayoutFooterRight(): JSX.Element {
    return <div id='layout-footer-right-div' className='px-1 pt-0'></div>;
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter

  // * SUHAIL - 2023-03-02 - 10
  // * SUHAIL - 2023-04-25 - 40
  public LayoutHeaderRight(): JSX.Element {
    return (
      <div className='control-pane' style={{ lineHeight: 0 }}>
        <div className='col-lg-12 control-section color-appbar-section'>
          <div className='control appbar-sample'>
            <div className='color-appbar-container'>
              <div key='0'>
                <div
                  className='row me-0 alignmentVCenter_'
                  style={{ alignItems: 'center', marginTop: '3px' }}
                >
                  <div className='col-md-2 '>
                    {/* <AppBarComponent colorMode={'Inherit'} > */}
                    <ButtonComponent
                      cssClass='e-inherit home e-appbar-menu'
                      onClick={this.calClick.bind(this)}
                    >
                      <CalendarOutline
                        color={'#000'}
                        title={'Calendar'}
                        height='25px'
                        width='25px'
                      />
                    </ButtonComponent>
                    <div className='e-appbar-spacer'></div>

                    <div className='e-appbar-separator'></div>

                    {/* </AppBarComponent> */}
                  </div>
                  <div
                    className='col-md-8 d-flex pt-1'
                    style={{ textAlign: 'right', marginTop: '2px' }}
                  >
                    <div style={{ width: '100%', marginRight: '10px' }}>
                      <span
                        className='e-input-group e-control-wrapper e-inherit'
                        style={{ background: 'none' }}
                      >
                        <input
                          type='text'
                          className='e-searchinput e-input'
                          placeholder='Search'
                        />
                        <span className='e-icons e-search e-input-group-icon'></span>
                      </span>
                    </div>
                  </div>

                  {/* <div
                    className="col-md-1 d-flex pt-2"
                    style={{ textAlign: 'center' }}
                  >
                    <Notifications
                      color={'#000'}
                      title={'notifications'}
                      height="25px"
                      width="25px"
                      cssClasses={'mx-2'}
                    />
                  </div>

                  <div
                    className="col-md-1 d-flex pt-2"
                    style={{ textAlign: 'center' }}
                  >
                    <DPButton item={items} open={false} mainIcon={"PersonCircleSharp"} />
                  </div> */}
                  <div
                    className='col-md-2 d-flex pt-2 px-4'
                    style={{
                      textAlign: 'center',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {/* <Notifications
                      color={'#000'}
                      title={'notifications'}
                      height="25px"
                      width="25px"
                      cssClasses={'mx-2'}
                    /> */}

                    <DPButton
                      item={notifitems}
                      open={false}
                      mainIcon={'fa-solid fa-bell'}
                    />
                    <DPButton
                      item={items}
                      open={false}
                      mainIcon={'fa-solid fa-circle-user'}
                    />
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter

  // * SUHAIL - 2023-02-27 - 60
  // * SUHAIL - 2023-03-01 - 120
  // * SUHAIL - 2023-03-02 - 10
  // * SUHAIL - 2023-03-14 - 30
  private SplitterLeft(): JSX.Element {
    return (
      <SplitterComponent
        id='splitterLeft'
        height='calc(100vh - 4px)'
        width='100%'
        orientation='Vertical'
        separatorSize={0}
      >
        <PanesDirective>
          <PaneDirective
            size='60px'
            min='60px'
            content={this.LayoutHeaderLeft}
            resizable={false}
          />
          <PaneDirective
            size='60px'
            min='60px'
            content={this.LayoutHeaderRight}
            resizable={false}
          />
        </PanesDirective>
      </SplitterComponent>
    );
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SUHAIL - 2023-03-14 - 60
  private SplitterTop(): JSX.Element {
    return (
      <SplitterComponent
        id='splitterTop'
        width='100%'
        height='60px'
        orientation='Horizontal'
        separatorSize={0}
        cssClass='border-0'
      >
        <PanesDirective>
          <PaneDirective
            cssClass='splitterTop-FirstPane'
            size='15%'
            content={this.LayoutHeaderLeft}
            resizable={false}
          />
          <PaneDirective
            min='50px'
            cssClass='d-inline-flex splitter-top-right'
            content={this.LayoutHeaderRight}
            resizable={false}
          />
        </PanesDirective>
      </SplitterComponent>
    );
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SUHAIL - 2023-03-14 - 60
  private SplitterBottom(): JSX.Element {
    return (
      <SplitterComponent
        id='splitterBottom'
        width='100%'
        orientation='Horizontal'
        separatorSize={0}
        cssClass='border-0'
      >
        <PanesDirective>
          <PaneDirective
            size='15%'
            content={this.LayoutFooterLeft}
            resizable={false}
          />
          <PaneDirective
            min='10%'
            content={this.LayoutFooterRight}
            resizable={false}
          />
        </PanesDirective>
      </SplitterComponent>
    );
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SUHAIL - 2023-03-01 - 30
  // * SUHAIL - 2023-03-02 - 10
  // * SANDEEP - 2023-08-10 - 40
  // private SplitterRight(): JSX.Element {
  //   return (
  //     <SplitterComponent
  //       id="splitterRight"
  //       height="calc(100vh - 4px)"
  //       width="100%"
  //       orientation="Vertical"
  //       separatorSize={0}
  //     >
  //       <PanesDirective>
  //         {/* <PaneDirective size="20%" min="10%" content={this.hPaneContent1} /> */}
  //         <PaneDirective
  //           size="60px"
  //           min="60px"
  //           content={this.LayoutHeaderRight}
  //           resizable={false}
  //         />
  //         {/* <PaneDirective
  //           size="80%"
  //           min="40%"
  //           // content={this.hPaneTopbarRight}
  //           content={() => <LayoutHeaderRight />}
  //           cssClass="hPaneTopbarRight"
  //         /> */}
  //         <PaneDirective min="10%" content={() => <LayoutBody />} />
  //         <PaneDirective size="30px" max="40px" resizable={false} />
  //       </PanesDirective>
  //     </SplitterComponent>
  //   )
  // }

  private splitterSelector(): JSX.Element {
    const handleSelectorRowSelected = (data: any) => {
      //this.hPaneContentMain = this.hPaneContentMain.bind(this)
      this.splitterDataViewer = this.splitterDataViewer.bind(this);
      // switch (this.state.serviceName.toLocaleLowerCase()) {
      //   case '5007':
      //   case '5221/correspondence':
      //   case 'dataprovider/correspondence':
      //   case 'correspondence':
      //   case '5221/job':
      //   case 'dataprovider/job':
      //     Common.ApiCallAsync(
      //       'POST',
      //       `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Sfdt/Read`, // ///Image/Get
      //       {
      //         // id: data.id,
      //         // filetype: this._structure.Field.FileType.Word,
      //         object: data.id,
      //         field: this._structure.Field.FileType.Word,
      //       },
      //       Common.getToken() || '',
      //       this.user
      //     )
      //       .then((resopnse: any) => {
      //         return resopnse.data;
      //       })
      //       .then((resopnse: any) => {
      //         this.setState({
      //           jsonDocument: resopnse,
      //           dataviewerKey: Math.random(),
      //           jsonDataViewer: data,
      //         });
      //       })
      //       .catch((error: any) => {
      //         console.error(error);
      //         this.setState({
      //           jsonDocument: '',
      //           dataviewerKey: Math.random(),
      //           jsonDataViewer: '',
      //         });
      //       })
      //       .finally(() => {});

      //     break;

      //   default:
      //     this.setState({
      //       jsonDocument: '',
      //       dataviewerKey: Math.random(),
      //       jsonDataViewer: data,
      //     });
      //     break;
      // }
      this.setState({
        jsonDocument: null,
        dataviewerKey: Math.random(),
        jsonDataViewer: data,
      });
    };

    return (
      <div>
        {this.state.showganttchart == false ? (
          <SelectorPanel
            key={this.state.selectorKey}
            ref={this.refSelector}
            rootmenu={this.state.rootmenu}
            currmenu={this.state.currmenu}
            isSelectorLoading={this.state.isSelectorLoading}
            serviceName={this.state.serviceName}
            jsonSelector={this.state.jsonSelector}
            selectedAllocations={this.state.selectedAllocations}
            selectorRowSelected={handleSelectorRowSelected}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }

  private splitterDataViewer(): JSX.Element {
    const refreshSelector = (e: any) => {
      console.log(this.refSelector);
      this.refSelector.current.refreshSelector(e);
    };

    // const handleDocumentSave = (
    //   jsnAttach: [{ object: number; field: number }]
    // ) => {
    //   if (this.state.jsonSelector) {
    //     let jselector: any = (
    //       this.state.jsonSelector as [{ object: number; field: number }]
    //     ).filter((x: any) => x.id == jsnAttach[0].object);

    //     if (jselector) {
    //       jselector = jselector[0];
    //     }

    //     if (jselector && jselector.attachments) {
    //       jselector.attachments = null;
    //     }
    //     if (jselector) {
    //       jselector.attachments = jsnAttach;
    //     }
    //   }
    // };

    return (
      <div>
        <DataViewer
          key={this.state.dataviewerKey}
          serviceName={this.state.serviceName}
          jsonDataViewer={this.state.jsonDataViewer}
          rootmenu={this.state.rootmenu}
          selectedmenu={this.state.selectedmenu}
          selectedtab={this.selectedtab}
          selectedtabindex={this.state.selectedtabid}
          //jsonDocument={this.state.jsonDocument}
          //handleDocumentSave={handleDocumentSave}
          showganttchart={this.state.showganttchart}
          refreshSelector={refreshSelector}
          showdashboard={this.state.showdashboard}
        />
      </div>
    );
  }

  // * SUHAIL - 2023-02-27 - 10
  private hPaneContentMain(): JSX.Element {
    //   let stringa =
    //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ornare, dui vitae consectetur bibendum, turpis tellus facilisis ex, et blandit nisi diam at mi. Ut hendrerit ultrices sapien in pretium. Etiam maximus felis lacinia ultrices blandit.'

    //   let nuovastringa = ''
    //   for (var i = 0; i < 1; i++) nuovastringa += stringa

    //   //return nuovastringa;
    //   //queryCellInfo={this.queryCellinfo.bind(this)}
    //   //template={this.flagtemplate} filter={this.Filter}
    //   //let pd: JSX.Element = <PaneDirective size="80%" min="40%" content = "" />;
    //   var oPD = []
    //   let _MAX = 3
    //   let _SPC = 100 / _MAX
    //   let _SSPC = _SPC.toString() + '%'
    //   for (let i = 0; i < _MAX; i++) {
    //     //let temp = <PaneDirective size="50%" min="50%" content = {this.fSelector} />
    //     //let content = "panel auto "+i;
    //     //let temp = <PaneDirective  size={_SSPC} min={_SSPC} content = {<Selector type="treeview" index={i} />}  />

    //     let content
    //     if (i == 0) content = this.Selector

    //     let temp = <PaneDirective key={i} size={_SSPC} min={_SSPC} content={content} />
    //     oPD.push(temp)
    // const handleSelectorRowSelected = (data: any) => {
    //   this.setState({ jsonDataViewer: data });
    // }

    return (
      <>
        <SplitterComponent
          id='innerSplitter'
          orientation='Horizontal'
          height='calc(100vh - 102px)'
          width='100%'
          separatorSize={0}
          cssClass='border-0'
        >
          <PanesDirective>
            {/* {this.state.isSelectorLoading || (this.state.selectorSize > 0 || (this.state.jsonSelector && this.state.jsonSelector['length'] > 0)) ?
              <PaneDirective min="10%" size={this.state.selectorSize > 0 ? this.state.selectorSize.toString() + '%' : '20%'} collapsible={true} content={{ template: this.splitterSelector }} cssClass="layout-body-left" />
              : null} */}
            <PaneDirective
              min='10%'
              size={
                this.state.selectorSize > 0
                  ? this.state.selectorSize.toString() + '%'
                  : '20%'
              }
              collapsible={true}
              content={this.splitterSelector}
              cssClass='layout-body-left'
            />
            <PaneDirective
              min='15%'
              size='100%'
              content={this.splitterDataViewer}
            />
          </PanesDirective>
        </SplitterComponent>
        {/* <Split lineBar style={{ height: 'calc(100vh - 91px)', border: '1px solid #d5d5d5', borderRadius: 3 }}>
          {this.state.isSelectorLoading || (this.state.selectorSize > 0 || (this.state.jsonSelector && this.state.jsonSelector['length'] > 0)) ?
            <div className='e-resizable' style={{ width: `${this.state.selectorSize > 0 ? this.state.selectorSize.toString() + '%' : '20%'} ` }}>
              <div>
                <SelectorPanel key={this.state.selectorKey} rootmenu={this.state.rootmenu} currmenu={this.state.currmenu} isSelectorLoading={this.state.isSelectorLoading} serviceName={this.state.serviceName} jsonSelector={this.state.jsonSelector} selectedAllocations={this.state.selectedAllocations} selectorRowSelected={handleSelectorRowSelected} />
              </div>
            </div>
            : null}
          <div style={{ width: '100%' }}>
            {this.state.jsonDataViewer !== null ?
              <DataViwer key={this.state.dataviewerKey} serviceName={this.state.serviceName} jsonDataViewer={this.state.jsonDataViewer} rootmenu={this.state.rootmenu} />
              : null}
          </div>
        </Split> */}
      </>
    );
  }

  //   /*return (
  //     <>{this.Selector("treeview", 0)}</>
  //   );*/
  //   //var countries =  this.countries;
  //   return (
  //     <SplitterComponent
  //       id="innerSplitter"
  //       height="calc(100vh - 60px - 30px - 5px)"
  //       width="100%"
  //       separatorSize={4}
  //     >

  //       <PanesDirective>{oPD}</PanesDirective>
  //     </SplitterComponent>
  //   )
  // }

  // private fRecursiveElement(e: any, force: boolean): Object[] {
  //   if (e.OnExpand != null || force) {
  //     var aReturnElems: Object[] = []
  //     let o = e
  //     if (!force) o = e.OnExpand
  //     //console.log("recursive", o);
  //     if (typeof o === 'undefined') return [{}]

  //     Object.entries(o).forEach(([key, value], index) => {
  //       var subElems = this.fRecursiveElement(value, true)

  //       let temp = {
  //         id: key, //Math.floor(Math.random() * index * 50), //index, // probabilmente va cambiato per ogni elemento!
  //         text: key,
  //         child: subElems,
  //       }
  //       aReturnElems.push(temp)
  //     })
  //     return aReturnElems
  //   } else return [{}]
  // }

  //private hPaneSideMain(): JSX.Element {
  //   let aElems: any = []
  //   Object.entries(this.state.oSidebar).forEach(([key, value], index) => {
  //     //console.log(key, value, index);
  //     let rtemp = this.fRecursiveElement(value, false)
  //     var temp: object
  //     //console.log(key, rtemp.length, rtemp);
  //     if (Object.keys(rtemp[0]).length != 0)
  //       temp = {
  //         id: key, //index,
  //         text: key,
  //         child: rtemp,
  //       }
  //     else
  //       temp = {
  //         id: key, //index,
  //         text: key,
  //       }

  //     aElems.push(temp)
  //     //console.log(this.fRecursiveElement(value, false));
  //   })
  //   aElems = [{ id: '0', text: 'ROOT', child: aElems, expanded: true }]
  //   let oElems = { localData: aElems }
  //   const fields: object = {
  //     dataSource: oElems.localData,
  //     id: 'id',
  //     text: 'text',
  //     child: 'child',
  //   }
  //   return (
  //     <div>
  //       <TreeViewComponent fields={fields} />
  //     </div>
  //   )

  // return (
  //   <Sidebar jsonSidebar={this.state.jsonSidebar} sidebarClick={handleSidebarClick} />
  // )
  // }

  // private hPaneContent1(): JSX.Element {
  //   return (
  //     <div className="splitter-content">
  //       <div>PANEL</div>
  //     </div>
  //   )
  // }

  // * SUHAIL - 2023-02-23 - 30
  // * SUHAIL - 2023-02-24 - 20
  // private hPaneTopbarRight(): JSX.Element {
  //   return (
  //     <div className="control-pane ">
  //       <div className="col-lg-12 control-section color-appbar-section">
  //         <div className="control appbar-sample">
  //           <div className="color-appbar-container">
  //             <div key="0">
  //               <div className="row">
  //                 <div className="col-md-12">
  //                   <AppBarComponent colorMode={'Inherit'}>
  //                     <ButtonComponent cssClass="e-inherit home e-appbar-menu">
  //                       Dashboard
  //                     </ButtonComponent>
  //                     <div className="e-appbar-spacer"></div>
  //                     <div style={{ width: '200px', marginRight: '10px' }}>
  //                       <span className="e-input-group e-control-wrapper e-inherit">
  //                         <input
  //                           type="text"
  //                           className="e-searchinput e-input"
  //                           placeholder="Search"
  //                         />
  //                         <span className="e-icons e-search e-input-group-icon"></span>
  //                       </span>
  //                     </div>
  //                     <div className="e-appbar-separator"></div>
  //                     <ButtonComponent isPrimary={true} cssClass={'login'} onClick={() => { Common.removeUserSession(); window.location.href = "/"; }}>
  //                       Logout
  //                     </ButtonComponent>
  //                   </AppBarComponent>
  //                 </div>
  //               </div>
  //               <br />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div >
  //   )
  // }

  // private innerSplitterElementTop(): JSX.Element {
  //   return (
  //     <SplitterComponent
  //       id="innerSplitter"
  //       height="60px"
  //       width="100%"
  //       separatorSize={4}
  //     >
  //       <PanesDirective>
  //         {/* <PaneDirective size="20%" min="10%" content={this.hPaneContent1} /> */}
  //         <PaneDirective size="20%" min="10%" content={() => <LayoutHeaderLeft />} />
  //         <PaneDirective
  //           size="80%"
  //           min="40%"
  //           // content={this.hPaneTopbarRight}
  //           content={() => <LayoutHeaderRight />}
  //           cssClass="hPaneTopbarRight"
  //         />
  //       </PanesDirective>
  //     </SplitterComponent>
  //   )
  // }

  private splitterSidebar(): JSX.Element {
    const handleSelectorLoading = (
      isSelectorLoading: boolean,
      rootmenu: any,
      currmenu: any,
      selectedmenu: any,
      selectedAllocations: any,
      serviceName: any,
      jsonSidebar: any,
      selectorSize: number,
      showganttchart: boolean = false,
      showdashboard: boolean = false
    ) => {
      if (showganttchart == false) {
        this.splitterSelector = this.splitterSelector.bind(this);
        this.splitterDataViewer = this.splitterDataViewer.bind(this);
      } else {
        this.splitterDataViewer = this.splitterDataViewer.bind(this);
        Common.SetStatusBar('');
      }
      //this.splitterDataViewer = this.splitterDataViewer.bind(this);
      const cont =
        this.innerSplitterSelectorDataviewer.element.querySelectorAll(
          '.paneselector'
        )[0];
      if (cont !== undefined) {
        cont.classList.remove('d-none');
      }

      this.setState({
        isSelectorLoading: isSelectorLoading,
        rootmenu: rootmenu,
        currmenu: currmenu,
        selectedmenu: selectedmenu,
        selectedAllocations: selectedAllocations,
        serviceName:
          serviceName !== null ? serviceName : this.state.serviceName,
        jsonSidebar: jsonSidebar,
        selectorSize:
          selectorSize !== null ? selectorSize : this.state.selectorSize,
        selectorKey: isSelectorLoading ? Math.random() : this.state.selectorKey,
        dataviewerKey:
          isSelectorLoading || selectorSize === null
            ? Math.random()
            : this.state.dataviewerKey,
        jsonDataViewer: null,
        showganttchart: showganttchart,
        showdashboard: showdashboard,
      });
    };

    const handleSidebarClick = (jsonSelector: any) => {
      //this.setState({ rootmenu: rootmenu, currmenu: currmenu, jsonSelector: jsonSelector, selectedAllocations: selectedAllocations, serviceName: serviceName, jsonSidebar: jsonSidebar })
      //this.innerSplitterElementContent = this.innerSplitterElementContent.bind(this)
      //this.splitterSelector = this.splitterSelector.bind(this);
      //this.splitterDataViewer = this.splitterDataViewer.bind(this);
      this.setState({
        jsonSelector: jsonSelector,
        jsonDataViewer: null,
        dataviewerKey: Math.random(),
      });
    };

    const handleNoSelector = () => {
      this.setState({
        isSelectorLoading: false,
        selectorSize: null,
        jsonSelector: null,
        jsonDataViewer: null,
        rootmenu: null,
        currmenu: null,
        dataviewerKey: Math.random(),
      });
      const cont =
        this.innerSplitterSelectorDataviewer.element.querySelectorAll(
          '.paneselector'
        )[0];
      if (cont !== undefined) {
        cont.classList.add('d-none');
      }
      Common.SetStatusBar('');
    };

    return (
      <div>
        <Sidebar
          jsonSidebar={this.state.jsonSidebar}
          selectorLoading={handleSelectorLoading}
          sidebarClick={handleSidebarClick}
          noSelector={handleNoSelector}
        />
      </div>
    );
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SUHAIL - 2023-03-14 - 60
  private innerSplitterElementContent(): JSX.Element {
    return (
      <div>
        <SplitterComponent
          id='innerSplitter'
          orientation='Horizontal'
          height='calc(100vh - 102px)'
          width='100%'
          separatorSize={0}
          cssClass='border-0'
        >
          <PanesDirective>
            <PaneDirective
              size='15%'
              //content={this.hPaneSideMain.bind(this)}
              content={this.splitterSidebar}
              cssClass='layout-body-left'
            />
            {/* <PaneDirective min="10%" content={() => <SelectorPanel isSelectorLoading={this.state.isSelectorLoading} />} /> */}
            {/* <PaneDirective size="80%" min="40%" content={this.hPaneContentMain} /> */}
          </PanesDirective>
        </SplitterComponent>
        {/* <Split lineBar style={{ height: 'calc(100vh - 91px)', border: '1px solid #d5d5d5', borderRadius: 3 }}>
          <div className='e-resizable' style={{ width: '15%' }}>
            <div>
              <Sidebar jsonSidebar={this.state.jsonSidebar} selectorLoading={handleSelectorLoading} sidebarClick={handleSidebarClick} />
            </div>
          </div>
          <div id='layout-content-main' style={{ width: '85%' }}>
            {this.hPaneContentMain()}
          </div>
        </Split> */}
        <SplitterComponent
          id='innerSplitter'
          orientation='Horizontal'
          height='calc(100vh - 102px)'
          width='100%'
          separatorSize={0}
          cssClass='border-0'
          delayUpdate={true}
        >
          <PanesDirective>
            {/* {this.state.isSelectorLoading || (this.state.selectorSize > 0 || (this.state.jsonSelector && this.state.jsonSelector['length'] > 0)) ?
              <PaneDirective min="10%" size={this.state.selectorSize > 0 ? this.state.selectorSize.toString() + '%' : '20%'} collapsible={true} content={{ template: this.splitterSelector }} cssClass="layout-body-left" />
              : null} */}
            <PaneDirective
              min='10%'
              size={
                this.state.selectorSize > 0
                  ? this.state.selectorSize.toString() + '%'
                  : '20%'
              }
              collapsible={true}
              content={this.splitterSelector}
              cssClass='layout-body-left'
            />
            <PaneDirective
              min='15%'
              size='100%'
              content={this.splitterDataViewer.bind(this)}
            />
          </PanesDirective>
        </SplitterComponent>
      </div>
    );
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SUHAIL - 2023-03-14 - 60
  // handleServiceLoading = (loading: boolean) => {
  //   //this.isSelectorLoadingg = loading;
  //   this.setState({ isSelectorLoading: loading });
  //   //return null;
  // }

  // handleServiceNameChange = (servicename: string) => {
  //   // alert(servicename);
  // }

  // private innerSplitterElementBottom(): JSX.Element {
  //   return (
  //     <SplitterComponent
  //       id="innerSplitter"
  //       height="30px"
  //       width="100%"
  //       separatorSize={4}
  //     >
  //       <PanesDirective>
  //         <PaneDirective size="20%" min="10%" content={this.hPaneContent1} />
  //         <PaneDirective size="80%" min="40%" content={this.hPaneContent1} />
  //       </PanesDirective>
  //     </SplitterComponent>
  //   )
  // }

  public onSelectorDataviewerCreate() {
    // Initialize Splitter component
    const cont = this.innerSplitterSidebar.element.querySelectorAll(
      '.paneselectoranddataviewer'
    )[0];
    if (cont !== undefined)
      cont.appendChild(this.innerSplitterSelectorDataviewer.element);
  }

  public onSelectorCreate() {
    // Initialize Splitter component
    const cont =
      this.innerSplitterSelectorDataviewer.element.querySelectorAll(
        '.paneselector'
      )[0];
    if (cont !== undefined)
      cont.appendChild(this.innerSplitterSelector.element);
  }

  public onDataViewerCreate() {
    // Initialize Splitter component
    const cont =
      this.innerSplitterSelectorDataviewer.element.querySelectorAll(
        '.panedataviewer'
      )[0];
    if (cont !== undefined)
      cont.appendChild(this.innerSplitterDataViewer.element);
  }

  // * --------------------------------------------------------------------------------------------------------------------HTML
  // * SUHAIL - 2023-03-02 - 10
  // * SUHAIL - 2023-03-14 - 60
  // * SANDEEP - 2023-08-10 - 20
  public render(): JSX.Element {
    let spinner: any = null;

    let cntnt: any;
    if (this.state.showdialog == true) {
      cntnt = () => {
        return <Calendar />;
      };
    }

    return (
      // <SplitterComponent
      //   id="splitter"
      //   height="100vh"
      //   width="100%"
      //   orientation="Horizontal"
      //   separatorSize={0}
      // >
      //   <PanesDirective>
      //     <PaneDirective size="15%" content={this.SplitterLeft.bind(this)} />
      //     <PaneDirective content={this.SplitterRight.bind(this)} />
      //     {/* <PaneDirective
      //       size="60px"
      //       min="60px"
      //       max="60px"
      //       content={this.innerSplitterElementTop.bind(this)}
      //     />
      //     <PaneDirective
      //       min="80%"
      //       content={this.innerSplitterElementContent.bind(this)}
      //     />
      //     <PaneDirective
      //       size="30px"
      //       min="30px"
      //       max="30px"
      //       content={this.innerSplitterElementBottom.bind(this)}
      //     /> */}
      //   </PanesDirective>
      // </SplitterComponent>
      // <div>
      //   <SplitterComponent
      //     id="splitter"
      //     width="100%"
      //     orientation="Vertical"
      //     separatorSize={0}
      //     delayUpdate={true}
      //   >
      //     <PanesDirective>
      //       <PaneDirective content={this.SplitterTop} />
      //       <PaneDirective
      //         content={this.innerSplitterElementContent}
      //         resizable={false}
      //       />
      //       <PaneDirective
      //         content={this.SplitterBottom}
      //         resizable={false}
      //       />
      //       {/* <PaneDirective content={this.SplitterRight.bind(this)} /> */}
      //       {/* <PaneDirective
      //       size="60px"
      //       min="60px"
      //       max="60px"
      //       content={this.innerSplitterElementTop.bind(this)}
      //     />
      //     <PaneDirective
      //       min="80%"
      //       content={this.innerSplitterElementContent.bind(this)}
      //     />
      //     <PaneDirective
      //       size="30px"
      //       min="30px"
      //       max="30px"
      //       content={this.innerSplitterElementBottom.bind(this)}
      //     /> */}
      //     </PanesDirective>
      //   </SplitterComponent>
      // </div>
      <div id='loader'>
        <SplitterComponent
          id='splitterTop'
          width='100%'
          height='60px'
          orientation='Horizontal'
          separatorSize={0}
          //cssClass="border-0"
        >
          <PanesDirective>
            <PaneDirective
              cssClass='splitterTop-FirstPane'
              size='15%'
              content={this.LayoutHeaderLeft}
              resizable={false}
            />
            <PaneDirective
              min='50px'
              cssClass='d-inline-flex splitter-top-right'
              content={this.LayoutHeaderRight}
              resizable={false}
            />
          </PanesDirective>
        </SplitterComponent>
        {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
        {/* <div style={{ width: '100%', border: '1px solid lightgrey' }}> */}
        <SplitterComponent
          id='innerSplitterSidebar'
          orientation='Horizontal'
          height='calc(100vh - 86px)'
          width='100%'
          separatorSize={0}
          //cssClass="border-0"
          //style={{ border: '1px solid lightgrey !important' }}
          ref={(splitter) => {
            this.innerSplitterSidebar = splitter;
          }}
        >
          <PanesDirective>
            <PaneDirective
              size='15%'
              //content={this.hPaneSideMain.bind(this)}
              content={this.splitterSidebar}
              cssClass='layout-body-left layout-body-left-minwidth'
            />
            {/* <PaneDirective min="10%" content={() => <SelectorPanel isSelectorLoading={this.state.isSelectorLoading} />} /> */}
            {/* <PaneDirective size="80%" min="40%" content={this.hPaneContentMain} /> */}
            <PaneDirective cssClass='paneselectoranddataviewer' />
          </PanesDirective>
        </SplitterComponent>
        {/* </div> */}

        {/* <div id='selectoranddataviewerparent' style={{ width: '85%', display: 'flex' }}> */}
        <SplitterComponent
          id='innerSplitterSelectorDataviewer'
          orientation='Horizontal'
          height='100%'
          width='100%'
          separatorSize={0}
          cssClass='border-0 selectordataviewer'
          ref={(splitter) => {
            this.innerSplitterSelectorDataviewer = splitter;
          }}
          created={
            (this.onSelectorDataviewerCreate =
              this.onSelectorDataviewerCreate.bind(this))
          }
        >
          <PanesDirective>
            <PaneDirective
              cssClass='paneselector d-none'
              collapsible={true}
              //size={this.state.selectorSize > 0 ? this.state.selectorSize.toString() + '%' : '20%'}
              size={
                this.state.showganttchart == true
                  ? '0%'
                  : this.state.isSelectorLoading ||
                    this.state.selectorSize > 0 ||
                    (this.state.jsonSelector &&
                      this.state.jsonSelector['length'] > 0)
                  ? this.state.selectorSize > 0
                    ? this.state.selectorSize.toString() + '%'
                    : '20%'
                  : '0px'
              }
            />
            <PaneDirective cssClass='panedataviewer' />
          </PanesDirective>
        </SplitterComponent>

        <SplitterComponent
          id='innerSplitterMain'
          orientation='Horizontal'
          height='100%'
          width='100%'
          separatorSize={0}
          cssClass='border-0'
          //style={{ float: 'left' }}
          ref={(splitter) => {
            this.innerSplitterSelector = splitter;
          }}
          //created={this.onSelectorDataviewerCreate = this.onSelectorDataviewerCreate.bind(this)}
          created={(this.onSelectorCreate = this.onSelectorCreate.bind(this))}
          //delayUpdate={true}
        >
          <PanesDirective>
            {/* {this.state.isSelectorLoading || (this.state.selectorSize > 0 || (this.state.jsonSelector && this.state.jsonSelector['length'] > 0)) ? */}
            <PaneDirective
              min='10%'
              collapsible={true}
              content={this.splitterSelector}
              cssClass='layout-body-left'
            />
            {/* : null} */}

            {/* <PaneDirective min="10%"
                  //size={this.state.isSelectorLoading || (this.state.selectorSize > 0 || (this.state.jsonSelector && this.state.jsonSelector['length'] > 0)) ? this.state.selectorSize > 0 ? this.state.selectorSize.toString() + '%' : '20%' : '0px'}
                  size='20%'
                  collapsible={true} content={this.splitterSelector} cssClass="layout-body-left" /> */}

            {/* <PaneDirective min="10%" size={this.state.selectorSize > 0 ? this.state.selectorSize.toString() + '%' : '20%'} collapsible={true} content={this.splitterSelector.bind(this)} cssClass="layout-body-left" /> */}
            {/* <PaneDirective min="15%" size='100%' content={this.splitterDataViewer} /> */}
            {/* <PaneDirective cssClass='panedataviewer' /> */}
            <PaneDirective size='0px' resizable={false} cssClass='e-hide' />
          </PanesDirective>
        </SplitterComponent>

        {/* {this.state.jsonDataViewer !== null ? */}
        <SplitterComponent
          id='innerSplitterDataViewer'
          orientation='Horizontal'
          height='calc(100vh - 89px)'
          //width="100%"
          separatorSize={0}
          cssClass='border-0'
          ref={(splitter) => {
            this.innerSplitterDataViewer = splitter;
          }}
          //created={this.onCreate = this.onCreate.bind(this)}
          created={
            (this.onDataViewerCreate = this.onDataViewerCreate.bind(this))
          }
          //delayUpdate={true}
        >
          <PanesDirective>
            {/* {this.state.jsonDataViewer !== null ? */}
            <PaneDirective
              min='15%'
              size={this.state.jsonDataViewer !== null ? '100%' : undefined}
              content={this.splitterDataViewer}
              collapsible={false}
            />
            {/* : null} */}
            <PaneDirective size='0px' resizable={false} />
          </PanesDirective>
        </SplitterComponent>
        {/* : null} */}
        {/* </div> */}
        {/* </div> */}
        {/* <Split lineBar style={{ height: 'calc(100vh - 91px)', border: '1px solid #d5d5d5', borderRadius: 3 }}>
          <div className='e-resizable' style={{ width: '15%' }}>
            <div>
              <Sidebar jsonSidebar={this.state.jsonSidebar} selectorLoading={handleSelectorLoading} sidebarClick={handleSidebarClick} />
            </div>
          </div>
          <div id='layout-content-main' style={{ width: '85%' }}>
            {this.hPaneContentMain()}
          </div>
        </Split> */}

        <SplitterComponent
          id='splitterBottom'
          width='100%'
          orientation='Horizontal'
          separatorSize={0}
          cssClass='border-0'
          style={{ alignItems: 'center' }}
        >
          <PanesDirective>
            <PaneDirective
              size='15%'
              content={this.LayoutFooterLeft}
              resizable={false}
            />
            <PaneDirective
              min='10%'
              content={this.LayoutFooterRight}
              resizable={false}
            />
          </PanesDirective>
        </SplitterComponent>

        <div
          id='rootspinner'
          style={{
            position: 'fixed',
            height: '100%',
            width: '100%',
            left: '0px',
            top: '0px',
            zIndex: '100000',
            visibility: 'hidden',
            backdropFilter: 'blur(2px)',
          }}
        >
          <div
            style={{
              position: 'fixed',
              left: '50%',
              top: '50%',
              height: '25%',
              width: '25%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              fontSize: '100px',
            }}
          >
            <i className='fa-solid fa-spinner fa-spin'> </i>
          </div>
        </div>

        {this.state.showdialog == true ? (
          <DialogComponent
            id='defaultDialog'
            showCloseIcon={true}
            visible={true}
            width={'80vw'}
            height='83vh'
            enableResize={true}
            resizeHandles={['All']}
            ref={(dialog) => (dialogInstance = dialog)}
            isModal={true}
            close={this.dialogClose.bind(this)}
            content={cntnt}
          ></DialogComponent>
        ) : (
          ''
        )}
      </div>
      // <Split visiable={false} mode='vertical' lineBar style={{ border: '1px solid #d5d5d5', borderRadius: 3 }}>
      //   <div className='e-resizable' style={{ height: '15%' }}>
      //     {this.SplitterTop()}
      //   </div>
      //   <div>
      //     {this.innerSplitterElementContent()}
      //   </div>
      //   <div className='e-resizable' style={{ height: '15%' }}>
      //     {this.SplitterBottom()}
      //   </div>
      // </Split>
    );
  }
}

export default Layout;
