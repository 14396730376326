// * ---------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SUHAIL - 2023-05-29 - 310 - New common Grid component to be used for selector and dossier, loan info
// * SUHAIL - 2023-05-29 - 50 - status review meeting
// * SUHAIL - 2023-05-30 - 240 - New common Grid component to be used for selector and dossier, loan info
// * SUHAIL - 2023-05-30 - 120 - show dossier list group by account name
// * SUHAIL - 2023-05-31 - 50 - weekly status meeting
// * SUHAIL - 2023-05-31 - 430 -  Create sample xml for Contract
// * SUHAIL - 2023-06-01 - 330 -  Display contract inof on card

// * ---------------------------------------------------------------------------------------------------------------2.Initialize
import React, { Component } from 'react';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  row,
  Inject,
  Resize,
  ContextMenuItemModel,
  EditSettingsModel,
  Filter,
  Edit,
  Sort,
  Reorder,
  ContextMenu,
  InfiniteScroll,
  PageSettingsModel,
  GroupSettingsModel,
  Group,
  Page,
} from '@syncfusion/ej2-react-grids';
import Common, { GridColumnStructure } from '../Util/Common';
import SelectorSearch from './Layout/SelectorSearch';
import { PageSettings } from '@syncfusion/ej2-react-treegrid';

interface Props {
  id: string;
  height?: string;
  width?: string;
  parentDivHeight?: string;
  marginTop?: string;
  dataSource: any;
  showSearchBar?: boolean;
  columns?: GridColumnStructure[];
  jsonGridSettings?: any;
  recordDoubleClick?: any;
  onMouseOver?: any;
  dataBound?: any;
  rowSelected?: any;
  enableInfiniteScrolling?: boolean;
  contextMenuItems?: ContextMenuItemModel[];
  contextMenuOpen?: any;
  contextMenuClick?: any;
  actionBegin?: any;
  editSettings?: EditSettingsModel | undefined;
  pageSettings?: PageSettingsModel | undefined;
  allowGrouping?: boolean;
  groupSettings?: GroupSettingsModel | undefined;
  DateDescendingColumn?: string;
  actionComplete?: any;
  dataContextmenuid: string;
  selectedRowIndex: number;
  classname?: string;
}

class DataGrid extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private GridInstance: GridComponent | any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
  }

  static defaultProps = {
    dataContextmenuid: '',
    selectedRowIndex: -1,
  };

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    //dataSource: this.props.dataSource,
    dataSource: this.props.DateDescendingColumn
      ? this.props.dataSource.sort((a: any, b: any) => {
          return (
            new Date(b[this.props.DateDescendingColumn!]).getTime() -
            new Date(a[this.props.DateDescendingColumn!]).getTime()
          );
        })
      : this.props.dataSource,
  };

  // -------------------------------------------------------Event Handler
  componentDidMount() {}

  // *  ------------------------------------------------------------------------------------------------------------------API

  // *  ------------------------------------------------------------------------------------------------------------------6.Adapter
  public updateDimensions = () => {
    const divElement: HTMLElement = document.getElementById(
      this.props.id
    ) as HTMLElement;
    if (!divElement) return;

    const gridBody: HTMLElement = divElement?.querySelector(
      '.e-gridcontent .e-content'
    ) as HTMLElement;
    const hasScrollBar: boolean = gridBody.scrollHeight > gridBody.clientHeight;

    if (!hasScrollBar) {
      const gridHeader: HTMLElement = divElement?.querySelector(
        '.e-gridheader.e-lib.e-droppable'
      ) as HTMLElement;
      gridHeader.style.paddingRight = '0px';
    }
  };
  public refreshData() {
    this.GridInstance.refresh();
  }
  // * --------------------------------------------------------------------------------------------------------------------HTML
  render() {
    let gridInstance: GridComponent | any;
    this.GridInstance = gridInstance;

    const databound = () => {
      // if (this.props.onMouseOver) {
      //     gridInstance.addEventListener('mouseover', this.props.onMouseOver(gridInstance));
      // }

      this.updateDimensions();

      if (this.props.dataBound) {
        this.props.dataBound();
      }
    };

    const icontemplate = (props: any) => {
      return props.document !== undefined ? (
        <div>
          <i className={props.column.field} />
        </div>
      ) : (
        <div></div>
      );
    };

    return (
      <div
        className={`control-pane ${this.props.classname ?? null}`}
        style={{
          marginTop: `${this.props.marginTop ?? undefined}`,
          width: `${this.props.width ?? undefined}`,
          float: 'left',
        }}
      >
        <div className='control-section'>
          <div
            id='divgridparent'
            style={{
              height: `${
                this.props.parentDivHeight ? this.props.parentDivHeight : '100%'
              }`,
              overflow: 'auto',
            }}
          >
            <div
              data-contextmenuid={this.props.dataContextmenuid}
              onMouseOver={(e) =>
                this.props.onMouseOver ? this.props.onMouseOver(e) : null
              }
            >
              <GridComponent
                id={this.props.id}
                dataSource={this.props.dataSource}
                ref={(obj) => (this.GridInstance = obj)}
                selectionSettings={{ persistSelection: true }}
                recordDoubleClick={this.props.recordDoubleClick ?? null}
                dataBound={databound}
                rowSelected={this.props.rowSelected ?? null}
                contextMenuItems={this.props.contextMenuItems}
                contextMenuOpen={this.props.contextMenuOpen}
                contextMenuClick={this.props.contextMenuClick}
                actionBegin={
                  this.props.actionBegin
                    ? (e) => this.props.actionBegin(e)
                    : undefined
                }
                actionComplete={
                  this.props.actionComplete
                    ? (e) => this.props.actionComplete(e)
                    : undefined
                }
                allowResizing={true}
                allowSorting={true}
                sortSettings={
                  this.props.DateDescendingColumn
                    ? {
                        columns: [
                          {
                            field: this.props.DateDescendingColumn,
                            direction: 'Descending',
                          },
                        ],
                      }
                    : undefined
                }
                gridLines={'None'}
                height={this.props.height ?? undefined}
                //width={this.props.width ?? undefined}
                style={{ overflow: 'auto' }}
                //enablePersistence={true}
                editSettings={this.props.editSettings ?? undefined}
                enableInfiniteScrolling={
                  this.props.enableInfiniteScrolling ?? false
                }
                pageSettings={this.props.pageSettings ?? undefined}
                allowGrouping={this.props.allowGrouping}
                groupSettings={this.props.groupSettings}
                selectedRowIndex={
                  this.props.selectedRowIndex >= 0
                    ? this.props.selectedRowIndex
                    : undefined
                }
              >
                <ColumnsDirective>
                  {this.props.jsonGridSettings ? (
                    this.props.jsonGridSettings.Grid.Columns.Column.map(
                      (x: any) => (
                        <ColumnDirective
                          key='id'
                          field={x.$.source}
                          headerText={x.$.label}
                          width={`${x.$.width}%`}
                          toolTip={x.$.label}
                          type={
                            x.$.type
                              ? x.$.type
                              : Common.DatesColumns(this._structure).includes(
                                  x.$.source.toString().toLowerCase()
                                )
                              ? 'datetime'
                              : 'string'
                          }
                          format={
                            x.$.type === 'number'
                              ? x.$.decimal
                                ? `N${x.$.decimal}`
                                : 'N2'
                              : Common.DatesColumns(this._structure).includes(
                                  x.$.source.toString().toLowerCase()
                                )
                              ? this.user.settings.formats.DateShort
                              : ''
                          }
                          template={
                            x.$.icon && x.$.icon === 'true'
                              ? icontemplate
                              : null
                          }
                          textAlign={
                            x.$.type === 'number' ? 'Right' : undefined
                          }
                        ></ColumnDirective>
                      )
                    )
                  ) : this.props.columns ? (
                    this.props.columns.length <= 0 ? (
                      <ColumnDirective
                        key='id'
                        field='name'
                        headerText='Name'
                      ></ColumnDirective>
                    ) : (
                      this.props.columns.map((x: any) => (
                        <ColumnDirective
                          key='id'
                          field={x.source.toString().toLowerCase()}
                          headerText={x.label}
                          width={`${x.width}%`}
                          type={
                            x.source.toString().toLowerCase() === 'date'
                              ? 'datetime'
                              : 'string'
                          }
                          format={
                            x.source.toString().toLowerCase() === 'date'
                              ? this.user.settings.formats.DateShort
                              : ''
                          }
                        ></ColumnDirective>
                      ))
                    )
                  ) : (
                    <ColumnDirective
                      key='id'
                      field='name'
                      headerText='Name'
                    ></ColumnDirective>
                  )}
                </ColumnsDirective>
                <Inject
                  services={[
                    Filter,
                    Edit,
                    Sort,
                    Reorder,
                    ContextMenu,
                    InfiniteScroll,
                    Resize,
                    Page,
                    Group,
                  ]}
                />
              </GridComponent>
            </div>
            {this.props.showSearchBar ? (
              <SelectorSearch grid_id={this.props.id} />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default DataGrid;
