const addresslist = `<?xml version="1.0" encoding="UTF-8"?>
<AddressList>
	<Person id="100012">
		<Address type="Address">
			<Grid rows="1" class="classOne classTwo classThree">
				<Contacts source="address">
					<Cards id="1" name="Home">
						<Card  flow="left" flow="left" margin="20,0,0,40">
							<Grid  col="12" rows="2" padding="1" margin="5" spacing="1" lineheight="1">															
								<Text cols="12">
									<Font family="calibri" size="22" style="1" weight="bold"/>																				
									<Value source="name" control="textbox" />
								</Text>								
								<Text cols="11">
									<Value field="items" match="item" fieldid="id" source="value" control="textbox"/>																		
									<Font size="16"  />																			
								</Text>								
								<Text cols="12">
									<Properties alignment="right" padding="5" margin="1" spacing="1" lineheight="1"/>
									<Value source="date" type="date"/>
									<Font size="13"  />			
								</Text>
								<Label cols="12">
									<Properties alignment="right" padding="1" margin="1" spacing="1" lineheight="1" />
									<Font size="20" color="black" icon="fa-edit" show="false" />
								</Label>
							</Grid>						
						</Card>
					</Contact>										
				</Contacts>
			</Grid>
		</Address>
		<Address type="Emergency">
			<Grid rows="1" class="classOne classTwo classThree">
				<Contacts source="contact">
					<Contact id="1" source="name" title="Sister">
						<Cards>							
							<Card  col="5" rows="2" flow="left" margin="20,0,0,40">
								<Grid  col="12" rows="2">
									<Text cols="11">
										<Font family="calibri" size="22" style="1" weight="bold"/>																				
										<Value source="name" icon="person" control="textbox" />
									</Text>									
									<Text  cols="11">
										<Value source="kinship" icon="relation" control="select"/>
									</Text>																	
									<Text  cols="11">
										<Value field="items" match="item" source="value" fieldid="id" control="textbox"/>										
										<Font size="16"  />												
									</Text>									
									<Text cols="12">
										<Properties alignment="right" padding="5" margin="1" spacing="1" lineheight="1"/>										
										<Value source="date" type="date"/>
										<Font size="13"  />			
									</Text>
									<Label cols="12">
										<Properties alignment="right" padding="1" margin="1" spacing="1" lineheight="1" />
										<Font size="20" color="black" icon="fa-edit" show="false" />
									</Label>
								</Grid>								
							</Card>
						</Cards>
					</Contact>
				</Contacts>
			</Grid>
		</Address>
		<Address type="Reference">
			<Grid rows="1" class="classOne classTwo classThree">
				<Contacts source="reference">
					<Contact id="1" source="name" title="Lecturer">
						<Cards>
							<Card  col="5" rows="2" flow="left" margin="20,0,0,40">
								<Grid  col="12" rows="2">
									<Text   cols="11">
										<Font family="calibri" size="22" style="1" weight="bold"/>										
										<Value source="name" icon="person" control="textbox"/>
									</Text>									
									<Text  cols="11">
										<Value source="title" icon="education" control="textbox"/>
									</Text>									
									<Text  cols="11">
										<Value field="items" match="item" fieldid="id" source="value" control="textbox"/>										
										<Font size="16"  />												
									</Text>
									<Text cols="12">
										<Properties alignment="right" padding="5" margin="1" spacing="1" lineheight="1"/>										
										<Value source="date" type="date"/>
										<Font size="13"  />			
									</Text>
									<Label cols="12">
										<Properties alignment="right" padding="1" margin="1" spacing="1" lineheight="1" />
										<Font size="20" color="black" icon="fa-edit" show="false" />
									</Label>
								<Grid>								
							</Card>
						</Cards>
					</Contact>
				</Contacts>
			</Grid>
		</Address>
	</Person>
</AddressList>`;

export default addresslist;
