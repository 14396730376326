// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SUHAIL - 2023-03-02 - 120 - meeting - sprint planning
// * SUHAIL - 2023-03-02 - 75 - meeting - fabio - planning time spent
// * SUHAIL - 2023-03-03 - 40 - meeting - sprint review
// * SUHAIL - 2023-03-03 - 70 - meeting - fabio - composer and genral
// * SUHAIL - 2023-03-06 - 85 - meeting - fabio - status review meeting
// * SUHAIL - 2023-03-09 - 90 - meeting - fabio - Composer and sidebar, composer
// * SUHAIL - 2023-03-10 - 30 - meeting - fabio - sidebar
// * SUHAIL - 2023-03-13 - 80 - meeting - fabio - weekly status
// * SUHAIL - 2023-03-14 - 60 - Changes in foxsystem serive for performance issue and deployed to server
// * SUHAIL - 2023-03-15 - 150 - meeting - weekly catchup
// * SUHAIL - 2023-03-16 - 60 - sprint planning meeting
// * SUHAIL - 2023-03-17 - 20 - sprint review meeting
// * SUHAIL - 2023-03-17 - 40 - meeting with fabio - Layout
// * SUHAIL - 2023-03-20 - 90 - weekly status meeting
// * SUHAIL - 2023-03-21 - 180 - changes in JsonTree in foxsystem service to get column label and source
// * SUHAIL - 2023-03-21 - 30 - meeting - fabio - selector
// * SUHAIL - 2023-03-22 - 40 - weekly catchup meeting
// * SUHAIL - 2023-03-24 - 120 - Aruti 1.0 - working on muwas issue of nothing happen on saving salary change due to salary increment voided from backend but salary history not voided
// * SUHAIL - 2023-03-24 - 60 - Aruti 1.0 - working on kcmu issue of one employee coming on global payment after excluding from payroll
// * SUHAIL - 2023-03-24 - 120 - Aruti 1.0 - working and contacting host gator for the issue of recruitment server goes down
// * SUHAIL - 2023-03-24 - 180 - Working on changes in employee service and deploying to digital ocean and research on login with rious.martin user
// * SUHAIL - 2023-03-27 - 60 - Aruti 2.0 2 candidate interview
// * SUHAIL - 2023-03-27 - 80 - weekly status review meeting
// * SUHAIL - 2023-03-28 - 30 - meeting - Aruti 2.0 - Employee
// * SUHAIL - 2023-03-28 - 60 - new end point for system object in json format and deployed to digital ocean
// * SUHAIL - 2023-03-29 - 60 - weekly catchup meeting
// * SUHAIL - 2023-03-30 - 90 - sprint planning meeting
// * SUHAIL - 2023-03-31 - 25 - sprint review meeting
// * SUHAIL - 2023-04-03 - 70 - weekly status review meeting
// * SUHAIL - 2023-04-03 - 30 - meeting - Aruti 2.0 - Sidebar - Filter
// * SUHAIL - 2023-04-05 - 30 - weekly status review meeting
// * SUHAIL - 2023-04-07 - 50 - meeting - Aruti 2.0 - Selector
// * SUHAIL - 2023-04-10 - 100 - weekly status review meeting
// * SUHAIL - 2023-04-21 - 50 - meeting - Aruti 2.0 - Document

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
// * SUHAIL - 2023-02-23 - 20
import React, { useState, useEffect, useReducer, useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginOuter from './Pages/LoginPage';
import PrivateRoute from './Util/PrivateRoute';
// import {
//   LoginContext,
//   loginReducer,
//   LoginState,
// } from './Components/Login/LoginReducer';
import { LoginState } from './Components/Login/LoginReducer';
import { AppProvider } from './Components/Login/AppState';

import Layout from './Pages/Layout';
// * ------------------------------------------------------------------------------------------------------------------3.Style
import './App.css';
import Common from './Util/Common';

import { GoogleOAuthProvider } from '@react-oauth/google';
// * -----------------------------------------------------------------------------------------------------------------2.Initialize
const initialState: LoginState = {
  password: '',
  username: '',
  isLoading: false,
  error: '',
  isLoggedIn: false,
};

// * SUHAIL - 2023-02-22 - 240
export default function App() {
  // * ------------------------------------------------------------------------------------------------------------------5.State
  //const [state, dispatch] = React.useReducer(loginReducer, initialState);
  //et { username, password, isLoading, error, isLoggedIn } = state;

  // const providerState = {
  //   state,
  //   dispatch,
  // };

  // * ------------------------------------------------------------------------------------------------------------------Adapter
  // * SUHAIL - 2023-04-11 - 120
  useEffect(() => {
    window.onpopstate = (e) => {
      // *** Logout user when back button is clicked
      alert('You are logged out. Please login again.');
      Common.removeUserSession();

      // *** Reload the current page when back button is clicked
      //window.history.go(1);
    };
  }, []);

  // *  --------------------------------------------------------------------------------------------------------------------HTML
  return (
    <GoogleOAuthProvider clientId='704211739292-lhoj7cagdmchdmiadevis4s4kb04mgum.apps.googleusercontent.com'>
      <AppProvider>
        {/* <LoginContext.Provider value={providerState}> */}

        {/* <div className="row App">
        <div className="col-6 ">
          <div className="login-container"> */}
        <>
          <BrowserRouter>
            <Routes>
              {/* <Route path="/" element={<Layout />} /> */}
              <Route
                path='/'
                element={<PrivateRoute component={Layout}></PrivateRoute>}
              />
              <Route path='/login' element={<LoginOuter />} />

              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
          </BrowserRouter>
          {/* {isLoggedIn ? (
          <>
            <Layout />
            <button
              type="button"
              onClick={() => dispatch({ type: "logout" })}
            >
              Log out
            </button>
          </>
        ) : (
          <LoginOuter /> //Function Base Component
          //<LoginOuter state={providerState.state} dispatch={providerState.dispatch} /> // Class Base Component

          // <Login />  //Function Base Component
          //<Login state={providerState.state} dispatch={providerState.dispatch} /> // Class Base Component
        )} */}
        </>
        {/* </div>
        </div>
        <div className="col-6 loginright-container"></div>
      </div> */}
        {/* </LoginContext.Provider> */}
      </AppProvider>
    </GoogleOAuthProvider>
  );
}
