import * as React from 'react';
import { useState } from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

import './GSignup.css';

interface _AccordianProps {
  _user: any;
}
interface props2 {
  nextStep: () => void;
  prevStep: () => void;
  handleFormData: (
    input: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  values: {
    name: string;
    birthday: string;
    gender: string;
    phone: string;
  };
}

const Details = (props2: any, _AccordianProps: any) => {
  const [error, setError] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const gender: string[] = ['Male', 'Female'];
  const submitFormData = (e: any) => {
    e.preventDefault();
    props2.nextStep();
  };

  // const handlePrevious = () => {
  //     prevStep()
  // }

  // const handleExit = () => {
  //   Props.exitHandler()
  // }

  const handleArutiTosClick = () => {
    setIsDialogOpen(true);
  };
  return (
    <>
      <div className='row mx-5' id='details-page'>
        <div className='col-12'>
          <form onSubmit={submitFormData}>
            <div
              className='row'
              // style={{
              //   height: '250px',
              // }}
            >
              <div className='col-md-8 '>
                {/* start */}

                <div className='row  mx-5 '>
                  <div className='col-md-10 my-2'>
                    <input
                      type='text'
                      className='form-control'
                      id='full-name'
                      placeholder='Full Name'
                      name='name'
                      value={props2.values.name}
                      onChange={props2.handleFormData('name')}
                      style={{
                        border: 'none',
                        borderBottom: '1px solid rgb(237,125,49)',
                        outline: 'none',
                        fontSize: '14px',
                      }}
                    />
                  </div>
                </div>
                <div className='row mx-5'>
                  <div className='col-md-10 my-2'>
                    {' '}
                    <DatePickerComponent
                      showClearButton={false}
                      cssClass='e-custom-date e-hidedate-icon'
                      //cssClass='e-hidedate-icon'
                      openOnFocus={true}
                      showTodayButton={false}
                      name='birthday'
                      format='dd MMMM yyyy'
                      placeholder='Birth Date'
                      value={props2.values.birthday}
                      onChange={props2.handleFormData('birthday')}
                    />
                  </div>
                </div>
                <div className='row  mx-5 '>
                  <div className='col-md-10 my-2'>
                    {/* <DropDownListComponent
                      id='dropdownelement'
                      cssClass='e-custom-gender'
                      dataSource={gender}
                      popupHeight='200px'
                      popupWidth='250px'
                      placeholder='Gender'
                      name='gender'
                      value={props2.values.gender}
                      onChange={props2.handleFormData('gender')}
                    /> */}

                    <input
                      type='text'
                      className='form-control'
                      id='gender'
                      name='gender'
                      value={props2.values.gender}
                      placeholder='Gender'
                      onChange={props2.handleFormData('gender')}
                      autoComplete='off'
                      style={{
                        border: 'none',
                        borderBottom: '1px solid rgb(237,125,49)',
                        fontSize: '14px',
                      }}
                      required
                    />
                  </div>
                </div>
                <div className='row  mx-5 '>
                  <div className='col-md-10 my-2'>
                    {' '}
                    <input
                      type='text'
                      className='form-control'
                      id='phone'
                      name='phone'
                      value={props2.values.phone}
                      placeholder='Phone'
                      onChange={props2.handleFormData('phone')}
                      autoComplete='off'
                      style={{
                        border: 'none',
                        borderBottom: '1px solid rgb(237,125,49)',
                        fontSize: '14px',
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-4 detail-center1 px-5'>
                <div className='row '>
                  <div className='col-md-12'></div>
                </div>
                <p>
                  By Entering your Data or 'Continue with Google', you agree to
                  the{' '}
                  <span
                    className='aruti-tos mx-1'
                    onClick={handleArutiTosClick}
                  >
                    Aruti TOS
                  </span>{' '}
                  and
                  <span
                    className='aruti-tos mx-1'
                    onClick={handleArutiTosClick}
                  >
                    Privacy Policy
                  </span>
                  .
                </p>
              </div>
            </div>
            <div className='row' style={{ marginTop: '70px' }}>
              <div
                className='col-md-12'
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'center',
                }}
              >
                <button className='flat-button fs-6'>Exit</button>

                <button type='submit' className='flat-button fs-6 mx-1'>
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div>
        <div>
          {isDialogOpen ? (
            <DialogComponent
              id='contract-dialog'
              showCloseIcon={true}
              visible={isDialogOpen}
              width={'25vw'}
              height='auto'
              close={() => setIsDialogOpen(false)}
              isModal={true}
              statelessTemplates={[]}
            ></DialogComponent>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default Details;
