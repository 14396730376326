// * ---------------------------------------------------------------------------------------------------------------2.Initialize
// * SUHAIL - 2023-02-23 - 10
// * SOURAV - 2023-11-23 - 30
// * SOURAV - 2023-11-29 - 70
import React, { useContext } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { useState, useEffect } from 'react';
import Login from '../Components/Login/Login';
import Composer from '../Components/Composer';
//import Register from './Register'
//import Login from './LoginCO';

import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import { googleLogout, GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';

// import {
//   LoginContext,
//   loginReducer,
//   LoginState,
//   useLoginContext,
// } from '../Components/Login/LoginReducer';
import { AppContext } from '../Components/Login/AppState';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import Common from '../Util/Common';
import Regwizard from '../Components/Login/GSignup/Regwizard';
import Main from '../Components/Login/Signup/Main';
import Splash from '../Components/Login/Splash';
import axios from 'axios';
// * ----------------------------------------------------------------------------------------------------------------- HTML
// * SUHAIL - 2023-02-23 - 30
const {
  REACT_APP_ENDPOINT_SUBSCRIBERPATH,
  REACT_APP_ENDPOINT_FULLJSONPATH,
  REACT_APP_VERSION,
  REACT_APP_ENDPOINT_XMLPATH,
} = process.env;
export default function LoginOuter(props: any) {
  const [res, setRes] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [credit, setCredit] = useState({ name: '', email: '', aud: '' });
  const [errorMsg, setErrorMsg] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  let leftBottomImage = './images/Signature.svg';
  //const { state, dispatch } = useLoginContext();
  const { state, dispatch } = useContext(AppContext);
  let { username, password, isLoading, error, isLoggedIn } = state.login;
  var intrvl: any;
  var splashInterval = 0;
  var maxInterval = 4;
  const handleClick = () => {
    setDialog(true);
  };

  const handleLoginGoogle = (e: any) => {
    dispatch({ type: 'login' });

    intrvl = setInterval(() => {
      splashInterval += 1;
    }, 1000);

    login();
  };

  // const responseGoogle = (response: any) => {
  //   if (response.error) {
  //     console.log(response.error);
  //   } else {
  //     // setRes(true);
  //     try {
  //       const credential = response.credential;
  //       let cred = Common.parseJwt(credential);
  //       setCredit(cred);
  //       let requestdata: any = {
  //         name: credit.email,
  //         password: credit.aud,
  //       };
  //       Common.ApiCallAsync(
  //         'post',
  //         '' + REACT_APP_ENDPOINT_SUBSCRIBERPATH,
  //         requestdata
  //       )
  //         .then((response: any) => {
  //           let user: any = response.data;
  //           let token: string = user.token;
  //           Common.ApiCallAsync(
  //             'get',
  //             '' + REACT_APP_ENDPOINT_XMLPATH,
  //             '',
  //             token,
  //             user
  //           )
  //             .then((response: any) => {
  //               let structure: any = response.data;
  //               Common.ApiCallAsync(
  //                 'get',
  //                 '' + REACT_APP_ENDPOINT_FULLJSONPATH,
  //                 '',
  //                 token,
  //                 user
  //               )
  //                 .then((response: any) => {
  //                   let fulljson: any = response.data;
  //                   Common.setUserSession(token, user, structure, fulljson);

  //                   if (
  //                     REACT_APP_VERSION !==
  //                     response.headers['x-custom-header-frontendversion']
  //                   ) {
  //                     Common.setItem('version-update-needed', true);
  //                   }
  //                   if (splashInterval < maxInterval) {
  //                     setTimeout(() => {
  //                       setErrorMsg(true);
  //                       navigate('/');

  //                       clearInterval(intrvl);
  //                     }, (maxInterval - splashInterval) * 1000);
  //                   } else {
  //                     setErrorMsg(true);
  //                     navigate('/');
  //                     clearInterval(intrvl);
  //                   }
  //                 })
  //                 .catch((error: any) => {
  //                   console.error('error in getting fulljson');
  //                   setErrorMsg(true);
  //                   // googleLogout();
  //                 });
  //             })
  //             .catch((error: any) => {
  //               console.error('error in getting structure');
  //               setErrorMsg(true);
  //               clearInterval(intrvl);
  //             });
  //         })
  //         .catch((error: any) => {
  //           console.error('error in getting user');
  //           setErrorMsg(true);
  //           clearInterval(intrvl);
  //         });
  //     } catch (error) {
  //       console.log('Error decoding token:', error);
  //     }
  //   }
  // };
  //new customise google login function ---------------
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse: any) => {
      try {
        const res = await axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo`, {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
              Accept: 'application/json',
            },
          })
          .then((res) => {
            setUser(res.data);
            let requestdata: any = {
              name: res.data.email,
              password: res.data.id,
            };
            Common.ApiCallAsync(
              'post',
              '' + REACT_APP_ENDPOINT_SUBSCRIBERPATH,
              requestdata
            )
              .then((response: any) => {
                let user: any = response.data;
                let token: string = user.token;
                Common.ApiCallAsync(
                  'get',
                  '' + REACT_APP_ENDPOINT_XMLPATH,
                  '',
                  token,
                  user
                )
                  .then((response: any) => {
                    let structure: any = response.data;
                    Common.ApiCallAsync(
                      'get',
                      '' + REACT_APP_ENDPOINT_FULLJSONPATH,
                      '',
                      token,
                      user
                    )
                      .then((response: any) => {
                        let fulljson: any = response.data;
                        Common.setUserSession(token, user, structure, fulljson);

                        if (
                          REACT_APP_VERSION !==
                          response.headers['x-custom-header-frontendversion']
                        ) {
                          Common.setItem('version-update-needed', true);
                        }
                        if (splashInterval < maxInterval) {
                          setTimeout(() => {
                            clearInterval(intrvl);
                            setErrorMsg(true);
                            dispatch({ type: 'success' });
                            navigate('/');
                          }, (maxInterval - splashInterval) * 1000);
                        } else {
                          clearInterval(intrvl);
                          setErrorMsg(true);
                          dispatch({ type: 'success' });
                          navigate('/');
                        }
                      })
                      .catch((error: any) => {
                        // dispatch({ type: 'login' });
                        setErrorMsg(true);
                        clearInterval(intrvl);
                        dispatch({ type: 'error' });
                        // googleLogout();
                        console.error('error in getting fulljson');
                      });
                  })
                  .catch((error: any) => {
                    setErrorMsg(true);
                    clearInterval(intrvl);
                    dispatch({ type: 'error' });
                    console.error('error in getting structure');
                  });
              })
              .catch((error: any) => {
                setErrorMsg(true);
                clearInterval(intrvl);
                dispatch({ type: 'error' });
                console.error('error in getting user');
              });
          })
          .catch((err: any) => {
            clearInterval(intrvl);
            dispatch({ type: 'error' });
            console.error(err);
          });
      } catch (error) {
        clearInterval(intrvl);
        dispatch({ type: 'error' });
        console.error('Error decoding token:', error);
      }
    },
    onError(errorResponse: any) {
      clearInterval(intrvl);
      dispatch({ type: 'error' });
      console.log('errorResponse', errorResponse);
    },
    onNonOAuthError(nonOAuthError: any) {
      clearInterval(intrvl);
      dispatch({ type: 'error' });
      console.log('nonOAuthError', nonOAuthError);
    },
  });
  // useEffect(() => {
  //   if (user) {
  //     try {
  //       let requestdata: any = {
  //         name: credit.email,
  //         password: credit.aud,
  //       };
  //       console.log(requestdata);
  //       Common.ApiCallAsync(
  //         'post',
  //         '' + REACT_APP_ENDPOINT_SUBSCRIBERPATH,
  //         requestdata
  //       )
  //         .then((response: any) => {
  //           let user: any = response.data;
  //           let token: string = user.token;
  //           Common.ApiCallAsync(
  //             'get',
  //             '' + REACT_APP_ENDPOINT_XMLPATH,
  //             '',
  //             token,
  //             user
  //           )
  //             .then((response: any) => {
  //               let structure: any = response.data;
  //               Common.ApiCallAsync(
  //                 'get',
  //                 '' + REACT_APP_ENDPOINT_FULLJSONPATH,
  //                 '',
  //                 token,
  //                 user
  //               )
  //                 .then((response: any) => {
  //                   let fulljson: any = response.data;
  //                   Common.setUserSession(token, user, structure, fulljson);

  //                   if (
  //                     REACT_APP_VERSION !==
  //                     response.headers['x-custom-header-frontendversion']
  //                   ) {
  //                     Common.setItem('version-update-needed', true);
  //                   }
  //                   if (splashInterval < maxInterval) {
  //                     setTimeout(() => {
  //                       setErrorMsg(true);
  //                       navigate('/');

  //                       clearInterval(intrvl);
  //                     }, (maxInterval - splashInterval) * 1000);
  //                   } else {
  //                     setErrorMsg(true);
  //                     navigate('/');
  //                     clearInterval(intrvl);
  //                   }
  //                 })
  //                 .catch((error: any) => {
  //                   console.error('error in getting fulljson');
  //                   setErrorMsg(true);
  //                   // googleLogout();
  //                 });
  //             })
  //             .catch((error: any) => {
  //               console.error('error in getting structure');
  //               setErrorMsg(true);
  //               clearInterval(intrvl);
  //             });
  //         })
  //         .catch((error: any) => {
  //           console.error('error in getting user');
  //           setErrorMsg(true);
  //           clearInterval(intrvl);
  //         });
  //     } catch (error) {
  //       console.log('Error decoding token:', error);
  //     }
  //   }
  // }, [user]);

  return (
    <>
      {/* <div className='row App'>
        <div className='col-sm-12 offset-md-3 col-md-6 offset-lg-0 col-lg-6 leftLoginPanel'>
          <div className='row d-md-none d-lg-block '></div>
          <div className='row'>
            <div className='col-lg-4 offset-lg-4'>
              <div className='login-container'>
                {
                  <Login /> //Function Base Component
                  //<Login state={props.state} dispatch={props.dispatch} /> //Class Base Component
                }
              </div>
              <div style={{ textAlign: 'center' }}>
                Not having an account?{' '}
                <a
                  // onClick={handleClick}
                  style={{
                    color: '#0D6EFD',
                    cursor: 'pointer',
                    padding: '2px',
                  }}
                >
                  Register
                </a>
                or
              </div>
              <div>
                {' '}
                <GoogleLogin
                  text={'signin_with'}
                  onSuccess={responseGoogle}
                  //onFailure={responseGoogle}
                  //scope='profile email'
                  //responseType='code'
                  theme={'outline'}
                  logo_alignment={'center'}
                  shape={'rectangular'}
                  size={'large'}
                  // width={'260px'}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            {' '}
            <div className='col-lg-4 offset-lg-4  leftBottomImage'>
              <img src={leftBottomImage} />
            </div>
          </div>
          {/* <div className='row' style={{ border: '1px solid blue' }}>
          <div className='col-md-12 col-lg-4 offset-lg-4 my-2' id='d1'>
            <div className='row'>
              <div className=' col-md-8 '>Not having an account?</div>
              <div className='col-md-4 '>
                <a
                  // onClick={handleClick}
                  style={{
                    color: '#0D6EFD',
                    cursor: 'pointer',
                    padding: '5px',
                  }}
                >
                  Register
                </a>
                or
              </div>
            </div>
          </div>
        </div> */}
      {/* <div className='row' style={{ border: '1px solid gray' }}>
          <div className='col-md-12 offset-lg-4 my-3'>
            <GoogleLogin
              text={'signin_with'}
              onSuccess={responseGoogle}
              //onFailure={responseGoogle}
              //scope='profile email'
              //responseType='code'
              theme={'outline'}
              logo_alignment={'center'}
              shape={'rectangular'}
              size={'large'}
              width={'290px'}
            />
          </div>
        </div> */}

      {/* <div className='row' style={{ border: '1px solid red' }}>
          <div className='col-lg-4 offset-lg-4  leftBottomImage'>
            <img src={leftBottomImage} />
          </div>
        </div> */}
      {/* </div>
        <div className='d-none d-lg-block col-lg-6 loginright-container'></div>
      </div> */}

      {/* ---------New Code for login ---------------------------------------- */}
      {isLoading && !error ? (
        <Splash />
      ) : (
        <div id='loginContainer'>
          <div id='containerChild1'>
            <div id='containerSubChild1'>
              <div style={{ width: '100%' }}>
                <Login googleErrorMsg={errorMsg} />
              </div>
              <div id='forgot-msg'>
                <a type='default' className='forgot-password'>
                  {' '}
                  Forgot your password?
                </a>
              </div>
              <div id='google-login-btn'>
                <div>
                  {/* <GoogleLogin
                    text={'signin_with'}
                    onSuccess={(responseGoogle) => console.log(responseGoogle)}
                    //onFailure={responseGoogle}
                    theme={'outline'}
                    logo_alignment={'center'}
                    shape={'rectangular'}
                    size={'large'}
                    width={'275'}
                    // auto_select={false}
                    //useOneTap
                  /> */}

                  <button
                    type='button'
                    onClick={handleLoginGoogle}
                    id='google-btn'
                  >
                    <img
                      src='../images/google-logo.png'
                      style={{
                        width: '1.25rem',
                        height: '1.25rem',
                        marginRight: '7px',
                      }}
                    />
                    Sign in with Google
                  </button>
                </div>
              </div>
            </div>

            <div id='containerSubChild2'>
              <div>
                <img src={leftBottomImage} id='leftBottomImage' />
              </div>
            </div>
          </div>
          <div
            className='d-none d-lg-block col-lg-6 loginright-container'
            id='containerChild2'
          ></div>
        </div>
      )}

      {/* FOR MODAL DIALOGBOX */}

      <div>
        {res ? (
          <DialogComponent
            id='signupDialog1'
            showCloseIcon={true}
            visible={res}
            width={'57vw'}
            height='57vh'
            close={() => setRes(false)}
            isModal={true}
            content={() => (
              <Regwizard name={credit.name} email={credit.email} />
            )}
            statelessTemplates={[]}
          >
            {/* <Wizard
              name={credit.name}
              email={credit.email}
              photo={credit.picture}
            /> */}
          </DialogComponent>
        ) : null}
      </div>

      {/* <div>
        {dialog ? (
          <DialogComponent
            id='signupDialog2'
            showCloseIcon={true}
            visible={dialog}
            width={'58vw'}
            height='57vh'
            close={() => setDialog(false)}
            isModal={true}
            content={() => <Main />}
            statelessTemplates={[]}
            //style={{ border: '2px solid black' }}
          ></DialogComponent>
        ) : null}
      </div> */}
    </>
  );
}
