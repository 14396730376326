export const dossier_data = `<?xml version="1.0" encoding="UTF-8"?>
<Dossier>
	<Grid>
		<Columns>
			<Column label='' source='fa-regular fa-file-lines' width='3' icon='true'></Column>
			<Column label='Date' source='date' width='10'></Column>
			<Column label='Account' source='account.name' width='30'></Column>			
			<Column label='Note' source='note'></Column>			
		</Columns>
		<Contextmenu>
			<Item text='Group By' >
				<Item text='Account' columnname='account.name' ></Item>				
			</Item>			
		</Contextmenu>	
	</Grid>
</Dossier>`;

export const leave_type_data = `<?xml version="1.0" encoding="UTF-8"?>
<LeaveType>
	<Grid>
		<Contextmenu>
			<Item text='Annual Leave'></Item>
			<Item text='Casual Leave'></Item>
			<Item text='Maternity Leave'></Item>
			<Item text='Sick Leave'></Item>
			<Item text='Study Leave'></Item>
			<Item text='Work From Home Leave'></Item>
		</Contextmenu>
	</Grid>
</LeaveType>`;

export const contract_data = `<?xml version="1.0" encoding="UTF-8"?>
<Contract>
	<Grid width='27%'>
		<Columns>
			<Column label='Contract Name' source='name' width='35'></Column>
			<Column label='Start Date' source='dateStart' width='35'></Column>						
			<Column label='Salary' source='salary' type='number' decimal='2'></Column>			
		</Columns>		
	</Grid>
</Contract>`;

export const contractcard_data = `<?xml version="1.0" encoding="UTF-8"?>
<ContractCard>
	<Grid rows="1" class="classOne classTwo classThree" mtop="40">		
		<Line mode="1" width="1" color="#e7e1e1" style="solid" flow="left" margin="0,0,0,50"  />
		<Card row="1" col="10" rows="2" flow="left" margin="0,0,0,30">
			<Grid row="1" cols="12" rows="2">				
				<Label row="1" cols="3" >
					<Font family="calibri" size="15" color="black" style="1" />
					<Value>Contract</Value>					
				</Label>
				<Text row="1" cols="7">					
					<Value source="name"></Value>
					<Font family="calibri" size="17" color="black" style="1" />
				</Text>				
				<Label row="2" cols="3">
					<Font family="calibri" size="15" color="black" style="1"  />
					<Value>Start Date</Value>
				</Label>
				<Text row="2" cols="7">
					<Value source="startDate" type="date"></Value>
					<Font family="calibri" size="17" color="black" style="1" />
				</Text>
				<Label row="3" cols="3">				
					<Font family="calibri" size="15" color="black" style="1" />
					<Value>Branch</Value>
				</Label>
				<Text row="3" cols="7">
					<Value source="branch.name"></Value>
					<Font family="calibri" size="17" color="black" style="1" />			
				</Text>
				<Label row="4"  cols="3">
					<Properties alignment="2,2" padding="1" margin="1" spacing="1" lineheight="1" />
					<Font family="calibri" size="15" color="black" style="1" />
					<Value>Salary</Value>
				</Label>
				<Text row="4" cols="7">
					<Value source="salary"></Value>
					<Font family="calibri" size="17" color="black" style="1" />				
				</Text>								
				<Accordion row="6" cols="11" flow="left">
					<Grid row="1" col="4" rows="2" height="200">
						<Item source="allowances">
							<Header row="2">						
								<label row="1" cols="7">
									<Font family="calibri" size="15" color="black" style="1" weight="bold" />
									<Value placeholder="Select Name" source="name" />
								</label>					
							</Header>
							<Content row="2">
								<Grid row="1" cols="12" rows="2">
									<Label row="1" col="2" cols="2">
										<Font family="calibri" size="15" color="black" style="1" weight="bold" />
										<Value>Type</Value>
									</Label>
									<Text row="1" cols="4">							
										<Font family="calibri" size="15" color="black" style="1" weight="bold" />
										<Value placeholder="Select Date" source="account.name" />
									</Text>
									<Label row="2" col="2" cols="2">
										<Font family="calibri" size="15" color="black" style="1" weight="bold" />
										<Value>_Value</Value>
									</Label>
									<Text row="2" cols="4">
										<Font family="calibri" size="15" color="black" style="1" weight="bold" />
										<Value placeholder="Select Date" source="value" />
									</Text>
									<Label row="3" col="2" cols="2">
										<Font family="calibri" size="15" color="black" style="1" weight="bold" />
										<Value>_Base</Value>
									</Label>
									<Text row="3" cols="4">
										<Font family="calibri" size="15" color="black" style="1" weight="bold" />
										<Value placeholder="Select Date" source="base" />
									</Text>
									<Label row="4" col="2" cols="2">
										<Font family="calibri" size="15" color="black" style="1" weight="bold" />
										<Value>_Amount</Value>
									</Label>
									<Text row="4" cols="4">
										<Font family="calibri" size="15" color="black" style="1" weight="bold" />
										<Value placeholder="Select Date" source="amount" />
									</Text>
								</Grid>
							</Content>
						</Item>
					</Grid>					
				</Accordion>
				<Label row="7" cols="11">
					<Properties alignment="right" padding="1" margin="1" spacing="1" lineheight="1" />
					<Font size="20" color="black" icon="fa-edit" show="false" />
				</Label>			
			</Grid>
		</Card>
	</Grid>
</ContractCard>`;

export const loan_data = `<?xml version="1.0" encoding="UTF-8"?>
<Loan>
	<Grid>
		<Columns>
			<Column label='Employee' source='employee' ></Column>
			<Column label='Loan Scheme' source='account.name' width='23' ></Column>
			<Column label='Voucher#' source='name' width='9' ></Column>
			<Column label='Start Date' source='date' width='9' ></Column>			
			<Column label='Loan Amount' source='amount' type='number' decimal='2' ></Column>
			<Column label='Tenure' source='tenure' type='number' decimal='0' width='7' ></Column>
			<Column label='Rem.Tenure' source='remain' type='number' decimal='0' width='7' ></Column>
			<Column label='Paid Amount' source='paid' type='number' decimal='2' ></Column>			
			<Column label='Due Amount' source='due' type='number' decimal='2' ></Column>			
		</Columns>		
	</Grid>
</Loan>`;

export const contract_alldata = `<?xml version="1.0" encoding="UTF-8"?>
<ContractAllData>
	<Grid>
		<Columns>
			<Column label='Contract Name' source='name' width='40' ikey="" formula = ""></Column>
			<Column label='Start Date' source='dateStart' width='30' ikey="" formula = ""></Column>						
			<Column label='Salary' source='salary' type='number' decimal='2' ikey="" formula = ""></Column>
			<Column label="Branch" source="branch.name" width='40' ikey="" formula = ""></Column>
			<Column label="Job" source="job.title" width='40' ikey="" formula = ""></Column>
			<Column label="End Date" source="dateEnd" type="date" width='20' ikey="" formula = ""></Column>
			<Column label="Allowances" source="amount" width='40' ikey="allowances" formula = "sum"></Column>			
			<Column label="Type" source="account.name" width='40' ikey="allowances" formula = ""></Column>
			<Column label="Name" source="name" width='40' ikey="allowances" formula = ""></Column>
			<Column label="Amount" source="amount" width='30' ikey="allowances" formula = ""></Column>
			<Column label="Value" source="value" width='20' ikey="allowances" formula = ""></Column>
			<Column label="Base" source="base" width='20' ikey="allowances" formula = ""></Column>									
		</Columns>
	</Grid>
</ContractAllData>`;

export const contract_detail_data = `<?xml version="1.0" encoding="UTF-8"?>
<ContractDetailData>
	<Item text="Contract Name" source="name"></Item>
	<Item text="Start Date" source="dateStart"></Item>
	<Item text="End Date" source="dateEnd"></Item>
	<Item text="Salary" source="salary"></Item>	
	<Item text="Branch" source="branch.name"></Item>
	<Item text="Department" source="department.name"></Item>
	<Item text="Grade" source="grade.name"></Item>
	<Item text="Contract Type" source="account.name"></Item>
	<Item text="Job Title" source="job.title"></Item>
	<Item text="Projects" source="projects" isgroup="true"></Item>
	<Item text="Allowances" source="allowances" isgroup="true"></Item>
</ContractDetailData>`;

export const document_detail_data = `<?xml version="1.0" encoding="UTF-8"?>
<ContractDetailData>
	<Item text="Name" source="document.name"></Item>
	<Item text="Date" source="document.date"></Item>
	<Item text="Sender Name" source="document.sender.name"></Item>
	<Item text="Recipient Name" source="document.recipient.name"></Item>
	<Item text="Company Name" source="document.company.name"></Item>
</ContractDetailData>`;

export const contract_contextmenu = `<?xml version="1.0" encoding="UTF-8"?>
<ContractContextmenu>
	<ContextMenu>
		<Item id='300' text='Contract Type'></Item>
		<Item id='600' text='Place of Work'></Item>
		<Item id='700' text='' type='separator'></Item>
		<Item id='701' text='Organization'></Item>
		<Item id='1000' text='Job'>
			<Item id='__101--NewJob' text='New ...'></Item>
			<Item id='44' text='' type='separator'></Item>
		</Item>
		<Item id='1100' text='Grade'></Item>
		<Item id='1200' text='Scale'></Item>
		<Item id='1300' text='Working Schedule'></Item>		
		<Item id='1400' text='' type='separator'></Item>		
		<Item id='1500' text='Allowances'></Item>
		<Item id='1600' text='' type='separator'></Item>
		<Item id='1700' text='Delete'></Item>
	</ContextMenu>	
</ContractContextmenu>`;

export const sidebar_template_contextmenu = `<?xml version="1.0" encoding="UTF-8"?>
<sidebar_template_contextmenu>
	<ContextMenu>
		<Item id='100' text='Template'>
			<Item id='101' text='Create'></Item>
		</Item>		
		<Item id='200' text='Name Format'>
			<Item id='201' text='Create'></Item>
		</Item>
	</ContextMenu>
</sidebar_template_contextmenu>`;

export const sidebar_contextmenu_for_group = `<?xml version="1.0" encoding="UTF-8"?>
<sidebar_contextmenu_for_group>
	<ContextMenu>
		<Item id='1000' text='Group'>
			<Item id='1001' text='Create'></Item>
		</Item>		
		<Item id='2000' text='Account'>
			<Item id='2001' text='Create'></Item>
		</Item>
	</ContextMenu>
</sidebar_contextmenu_for_group>`;

export const accordion_contextmenu = `<?xml version="1.0" encoding="UTF-8"?>
<AccoridonContextmenu>
	<ContextMenu>
		<Item id='100' text='Document'>
			<Item id='101' text='New ...'></Item>
			<Item id='102' text='' type='separator'></Item>			
			<Item id='103' text='Delete'></Item>		
		</Item>
	</ContextMenu>	
</AccoridonContextmenu>`;

export const composer_contextmenu = `<?xml version="1.0" encoding="UTF-8"?>
<ComposerContextmenu>
	<ContextMenu>
		<Item id='100' text='Document'>
			<Item id='101' text='New ...'></Item>			
			<Item id='102' text='' type='separator'></Item>
		</Item>
		<Item id='300' text='' type='separator'></Item>			
		<Item id='200' text='Delete'></Item>
	</ContextMenu>
</ComposerContextmenu>`;

export const leave_contextmenu = `<?xml version="1.0" encoding="UTF-8"?>
<ComposerContextmenu>
	<ContextMenu>
		<Item id='100' text='Apply'></Item>
		<Item id='300' text='' type='separator'></Item>			
		<Item id='200' text='Delete'></Item>
	</ContextMenu>
</ComposerContextmenu>`;

export const newmenu_groupaccount_contextmenu = `<?xml version="1.0" encoding="UTF-8"?>
<NewMenuGroupAccountContextmenu>
	<ContextMenu>
		<Item id='__41--' text='New'>
			<Item id='__101--' text='Group...'></Item>
			<Item id='__102--' text='Account...'></Item>
		</Item>		
		<Item id='44' text='' type='separator'></Item>			
	</ContextMenu>
</NewMenuGroupAccountContextmenu>`;

export const newmenu_contextmenu = `<?xml version="1.0" encoding="UTF-8"?>
<NewMenuContextmenu>
	<ContextMenu>
		<Item id='__102--102--' text='New ...'></Item>
		<Item id='44' text='' type='separator'></Item>			
	</ContextMenu>
</NewMenuContextmenu>`;
