import * as React from 'react';
import { useState, useEffect } from 'react';
import './GSignup.css';
interface props4 {
  email: string;
  nextStep: () => void;
  prevStep: () => void;
  handleFormData: (
    input: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  values: {
    name: string;
    birthday: string;
    gender: string;
    phone: string;
    company: string;
  };
}

const Message = (props4: any) => {
  const [data, setData] = useState({
    //  id: 1,
    //  name: '',
    //  birthdate: '',
    //  gender: '',
    //  phone: '',
    //  company: '',
  });

  let u_id = Math.floor(Math.random() * 100);

  useEffect(() => {
    setData({
      id: u_id,
      name: props4.values.name,
      email: props4.email,
      birthdate: props4.values.birthday,
      gender: props4.values.gender,
      phone: props4.values.phone,
      company: props4.values.company,
    });
  }, []);

  if (data) console.log(data);

  const handlePrevious = () => {
    props4.prevStep();
  };

  return (
    <>
      <div className='message-center row mx-5 my-5'>
        <div className='col-12'>
          <div className='message-center row'>
            <div className='message-center col-6 fs-6'>
              <p>You have sucessfully registered with</p>
            </div>
          </div>
          <div className='message-center row '>
            <div className='message-center col-6 '>
              <h3>{props4.values.company}</h3>
            </div>
          </div>
          <div className='message-center row'>
            <div className='message-center col-6 fs-6'>
              <p>{props4.values.name}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='row mx-5' style={{ marginTop: '100px' }}>
        <div className='message-btn  col-12'>
          <button className='flat-button fs-6'>Exit</button>
          <button className='flat-button mx-1 fs-6' onClick={handlePrevious}>
            Back
          </button>
        </div>
      </div>
    </>
  );
};
export default Message;
