// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SOURAV - 2023-11-27 - 80 -- Meeting

import React, { Component } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import Common from '../Util/Common';
import './ImageEditor.css';
interface props {
  showImage: any;
  _data: any;
  getFinalImage: any;
  isImageEditor: boolean;
  doubleClickedId: any;
  editImage: any;
}
const { REACT_APP_ENDPOINT_FOXSYSTEMSERVICE } = process.env;

// * ------------------------------------------------------------------------------------------------------------------6.Adapter
// * SOURAV - 2023-11-27 - 400 -- working on resizing of image(if already exist) in image eitor.
class ImageEditor extends Component<props> {
  private _structure: any;
  private user: any;
  constructor(props: props) {
    super(props);
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.user = JSON.parse(Common.getUser() ?? '');
    this.saveImage = this.saveImage.bind(this);
  }
  state = {
    showimage: this.props.showImage,
    imageDivHeight: '0px',
    resizedImage: '',
    rotation: 0,
    imageUrl: '',
    width: 0,
    height: 0,
    isCropping: false,
    cropX: 0,
    cropY: 0,
    crop: {
      size: 240,
      sizeX: 460,
      sizeY: 259,
    },
    isMoving: false,
    offsetX: 0,
    offsetY: 0,
    fImage: '',
    getImage: null,
  };

  componentDidMount() {
    // if (this.props.editImage && this.props.isImageEditor) {
    //   let existImageHeight: any = 240;
    //   let existImageWidth: any = 240;
    //   this.setState({
    //     width: existImageWidth,
    //     height: existImageHeight,
    //   });
    // } else {
    //   let existImageHeight: any = 259;
    //   let existImageWidth: any = 460;
    //   this.setState({
    //     width: existImageWidth,
    //     height: existImageHeight,
    //   });
    // }
    // if (this.props.editImage) {
    //   this.setState({
    //     resizedImage: `data:image/png;base64,${this.props.editImage}`,
    //   });
    // }
    // if (this.props.editImage) {
    //   setTimeout(() => {
    //     let docX: any = document.getElementById('crop-box');
    //     this.setState({
    //       cropX: docX.offsetLeft,
    //       cropY: docX.offsetTop,
    //     });
    //   }, 300);
    // }
    // Common.ApiCallAsync(
    //   'POST',
    //   `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Image/Get`,
    //   {
    //     object: this.props._data.id,
    //     field: this._structure.Field.FileType.Jpg,
    //   },
    //   Common.getToken() || '',
    //   this.user
    // )
    //   .then((resopnse: any) => {
    //     // console.log(resopnse.data);
    //     return resopnse.data;
    //   })
    //   .then((resopnse: any) => {
    //     this.setState({ getImage: resopnse });
    //   })
    //   .catch((error: any) => {})
    //   .finally(() => {});
  }
  handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        let newWidth = null;
        let newHeight = null;
        const container = document.getElementById('image-div');
        if (container === null) {
          return;
        }
        const containerRatio = container.offsetWidth / container.offsetHeight;
        const imageRatio = img.width / img.height;
        if (
          img.width < container.offsetWidth &&
          img.height < container.offsetHeight
        ) {
          newWidth = img.width;
          newHeight = img.height;
        } else if (imageRatio > containerRatio) {
          newWidth = container.offsetWidth;
          newHeight = newWidth / imageRatio;
        } else {
          newHeight = container.offsetHeight;
          newWidth = newHeight * imageRatio;
        }
        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        const resizedImage = canvas.toDataURL('image/jpeg', 1);
        this.setState({
          imageUrl: img.src,
          isCropping: true,
          resizedImage: resizedImage,
          width: newWidth,
          height: newHeight,
        });

        setTimeout(() => {
          let docX: any = document.getElementById('crop-box');
          this.setState({
            cropX: docX.offsetLeft,
            cropY: docX.offsetTop,
          });
        }, 300);
      };
    };
    reader.readAsDataURL(file);
  };
  handleResizePlus = () => {
    const { width, height, imageUrl } = this.state;
    if (!imageUrl) return;
    const img = new Image();

    img.onload = () => {
      const aspectRatio = height / width;
      const canvas = document.createElement('canvas');
      const canvasWidth = width * 1.05;
      const canvasHeight = canvasWidth * aspectRatio;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;
      ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
      const resizedImage = canvas.toDataURL('image/jpeg', 1);
      this.setState({
        width: canvasWidth,
        resizedImage: resizedImage,
        height: canvasHeight,
        //imageUrl: resizedImage,
      });
    };
    img.src = imageUrl;
    // if (!this.props.editImage) {
    //   const { width, height, imageUrl } = this.state;
    //   if (!imageUrl) return;
    //   const img = new Image();

    //   img.onload = () => {
    //     const aspectRatio = height / width;
    //     const canvas = document.createElement('canvas');
    //     const canvasWidth = width * 1.05;
    //     const canvasHeight = canvasWidth * aspectRatio;
    //     canvas.width = canvasWidth;
    //     canvas.height = canvasHeight;
    //     const ctx = canvas.getContext('2d');
    //     if (!ctx) return;
    //     ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
    //     const resizedImage = canvas.toDataURL('image/jpeg', 1);
    //     this.setState({
    //       width: canvasWidth,
    //       resizedImage: resizedImage,
    //       height: canvasHeight,
    //       //imageUrl: resizedImage,
    //     });
    //   };
    //   img.src = imageUrl;
    // } else {
    //   const { width, height, resizedImage } = this.state;
    //   const img = new Image();
    //   img.onload = () => {
    //     const aspectRatio = height / width;
    //     console.log(aspectRatio);
    //     const canvas = document.createElement('canvas');
    //     const canvasWidth = width * 1.1;
    //     const canvasHeight = canvasWidth * aspectRatio;
    //     canvas.width = canvasWidth;
    //     canvas.height = canvasHeight;
    //     const ctx = canvas.getContext('2d');
    //     if (!ctx) return;
    //     ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    //     const resizedImage = canvas.toDataURL('image/jpeg', 1);
    //     this.setState({
    //       width: Math.floor(canvasWidth),
    //       resizedImage,
    //       height: Math.floor(canvasHeight),
    //     });
    //   };
    //   // img.src = `data:image/png;base64,${this.props.editImage}`;
    //   img.src = resizedImage;
    // }
  };

  handleResizeMinus = () => {
    // if (!this.props.editImage) {
    //   const { width, height, imageUrl } = this.state;

    //   if (!imageUrl) return;
    //   const img = new Image();
    //   img.onload = () => {
    //     const aspectRatio = height / width;
    //     const canvas = document.createElement('canvas');
    //     const canvasWidth = width * 0.95;
    //     const canvasHeight = canvasWidth * aspectRatio;
    //     canvas.width = canvasWidth;
    //     canvas.height = canvasHeight;
    //     const ctx = canvas.getContext('2d');
    //     if (!ctx) return;
    //     ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    //     const resizedImage = canvas.toDataURL('image/jpeg', 1);
    //     this.setState({
    //       width: Math.floor(canvasWidth),
    //       resizedImage,
    //       height: Math.floor(canvasHeight),
    //     });
    //   };
    //   img.src = imageUrl;
    // } else {
    //   const { width, height, resizedImage } = this.state;
    //   const img = new Image();
    //   img.onload = () => {
    //     const aspectRatio = height / width;
    //     console.log(aspectRatio);
    //     const canvas = document.createElement('canvas');
    //     const canvasWidth = width * 0.95;
    //     const canvasHeight = canvasWidth * aspectRatio;
    //     canvas.width = canvasWidth;
    //     canvas.height = canvasHeight;
    //     const ctx = canvas.getContext('2d');
    //     if (!ctx) return;
    //     ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    //     const resizedImage = canvas.toDataURL('image/jpeg', 1);
    //     this.setState({
    //       width: Math.floor(canvasWidth),
    //       resizedImage,
    //       height: Math.floor(canvasHeight),
    //     });
    //   };
    //   //img.src = `data:image/png;base64,${this.props.editImage}`;
    //   img.src = resizedImage;
    // }

    const { width, height, imageUrl } = this.state;

    if (!imageUrl) return;
    const img = new Image();
    img.onload = () => {
      const aspectRatio = height / width;
      const canvas = document.createElement('canvas');
      const canvasWidth = width * 0.95;
      const canvasHeight = canvasWidth * aspectRatio;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const resizedImage = canvas.toDataURL('image/jpeg', 1);
      this.setState({
        width: Math.floor(canvasWidth),
        resizedImage,
        height: Math.floor(canvasHeight),
      });
    };
    img.src = imageUrl;
  };
  btnRotate = () => {
    // if (!this.props.editImage) {
    //   const { rotation, resizedImage, imageUrl } = this.state;
    //   const canvas = document.createElement('canvas');
    //   const ctx = canvas.getContext('2d');
    //   const image = new Image();
    //   image.onload = () => {
    //     const { width, height } = image;
    //     const diagonal = Math.sqrt(width * width + height * height);
    //     canvas.width = diagonal;
    //     canvas.height = diagonal;
    //     const centerX = diagonal / 2;
    //     const centerY = diagonal / 2;
    //     if (ctx === null) {
    //       return;
    //     }
    //     ctx.fillStyle = 'white';
    //     ctx.fillRect(0, 0, diagonal, diagonal);
    //     ctx.save();
    //     ctx.translate(centerX, centerY);
    //     ctx.rotate((90 * Math.PI) / 180);
    //     ctx.drawImage(image, -width / 2, -height / 2);
    //     ctx.restore();
    //     const rotatedImage = canvas.toDataURL('image/jpeg', 1);
    //     this.setState({ resizedImage: rotatedImage });
    //   };
    //   image.src = resizedImage;
    // } else {
    //   const { resizedImage } = this.state;
    //   const canvas = document.createElement('canvas');
    //   const ctx = canvas.getContext('2d');
    //   const image = new Image();
    //   image.onload = () => {
    //     const { width, height } = image;
    //     const diagonal = Math.sqrt(width * width + height * height);
    //     canvas.width = diagonal;
    //     canvas.height = diagonal;
    //     const centerX = diagonal / 2;
    //     const centerY = diagonal / 2;
    //     if (ctx === null) {
    //       return;
    //     }
    //     ctx.fillStyle = 'white';
    //     ctx.fillRect(0, 0, diagonal, diagonal);
    //     ctx.save();
    //     ctx.translate(centerX, centerY);
    //     ctx.rotate((90 * Math.PI) / 180);
    //     ctx.drawImage(image, -width / 2, -height / 2);
    //     ctx.restore();
    //     const rotatedImage = canvas.toDataURL('image/jpeg', 1);
    //     this.setState({ resizedImage: rotatedImage });
    //   };
    //   //image.src = `data:image/png;base64,${this.props.editImage}`;
    //   image.src = resizedImage;
    // }
    const { rotation, resizedImage, imageUrl } = this.state;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.onload = () => {
      const { width, height } = image;
      const diagonal = Math.sqrt(width * width + height * height);
      canvas.width = diagonal;
      canvas.height = diagonal;
      const centerX = diagonal / 2;
      const centerY = diagonal / 2;
      if (ctx === null) {
        return;
      }
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, diagonal, diagonal);
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate((90 * Math.PI) / 180);
      ctx.drawImage(image, -width / 2, -height / 2);
      ctx.restore();
      const rotatedImage = canvas.toDataURL('image/jpeg', 1);
      this.setState({ resizedImage: rotatedImage });
    };
    image.src = resizedImage;
  };

  handleMouseCropMove = (event: any) => {
    const { isMoving, offsetX, offsetY } = this.state;
    if (isMoving) {
      const { pageX, pageY } = event;
      const cropX = pageX - offsetX;
      const cropY = pageY - offsetY;
      this.setState({ cropX, cropY });
    }
  };

  handleMouseCropDown = (e: any) => {
    e.preventDefault();
    // if (e.detail === 2) {
    //   this.handleCropApply();
    //   this.handleDocumentCropApply();
    //   return;
    // } else {
    // }

    const { pageX, pageY } = e;
    const { cropX, cropY } = this.state;
    this.setState({
      isMoving: true,
      offsetX: pageX - cropX,
      offsetY: pageY - cropY,
    });
    document.addEventListener('mousemove', this.handleMouseCropMove);
    document.addEventListener('mouseup', this.handleMouseCropUp);

    // if (!this.props.editImage) {
    //   const { pageX, pageY } = e;
    //   const { cropX, cropY } = this.state;
    //   this.setState({
    //     isMoving: true,
    //     offsetX: pageX - cropX,
    //     offsetY: pageY - cropY,
    //   });
    //   document.addEventListener('mousemove', this.handleMouseCropMove);
    //   document.addEventListener('mouseup', this.handleMouseCropUp);
    // } else {
    //   const { pageX, pageY } = e;
    //   const { cropX, cropY } = this.state;
    //   this.setState({
    //     isMoving: true,
    //     offsetX: pageX - cropX,
    //     offsetY: pageY - cropY,
    //   });
    //   document.addEventListener('mousemove', this.handleMouseCropMove);
    //   document.addEventListener('mouseup', this.handleMouseCropUp);
    // }
  };

  handleMouseCropUp = () => {
    this.setState({ isMoving: false });
    document.removeEventListener('mousemove', this.handleMouseCropMove);
    document.removeEventListener('mouseup', this.handleMouseCropUp);
  };

  handleCropApply = () => {
    //if (!this.props.editImage) {
    const { crop, resizedImage, cropX, cropY } = this.state;
    const container = document.getElementById('image-div');
    if (container === null) {
      return;
    }
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    let img = new Image();
    img.onload = () => {
      const { size } = crop;
      const imageX = (containerWidth - img.width) / 2;
      const imageY = (containerHeight - img.height) / 2;
      const adjustedCropX = cropX - imageX;
      const adjustedCropY = cropY - imageY;
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      canvas.width = size;
      canvas.height = size;
      if (ctx === null) {
        return;
      }
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, size, size);
      ctx.drawImage(
        img,
        adjustedCropX,
        adjustedCropY,
        size,
        size,
        0,
        0,
        size,
        size
      );

      let croppedImageData = canvas.toDataURL('image/jpeg', 1);
      this.setState({
        croppedImage: croppedImageData,
        resizedImage: croppedImageData,
        isCropping: false,
        showimage: false,
        fImage: croppedImageData,
      });
      let payload: any = {
        object: this.props._data.id,
        field: this._structure.Field.FileType.Jpg,
        value: croppedImageData.replace('data:', '').replace(/^.+,/, ''),
      };
      this.saveImage(payload);
      this.props.getFinalImage({
        image: croppedImageData.replace('data:', '').replace(/^.+,/, ''),
        isCropping: false,
        showimage: false,
      });
    };
    img.src = resizedImage;
    // } else {
    //   const { crop, resizedImage, cropX, cropY } = this.state;
    //   const container = document.getElementById('image-div');
    //   if (container === null) {
    //     return;
    //   }
    //   const containerWidth = container.clientWidth;
    //   const containerHeight = container.clientHeight;
    //   let img = new Image();
    //   img.onload = () => {
    //     const { size } = crop;
    //     const imageX = (containerWidth - img.width) / 2;
    //     const imageY = (containerHeight - img.height) / 2;
    //     const adjustedCropX = cropX - imageX;
    //     const adjustedCropY = cropY - imageY;
    //     let canvas = document.createElement('canvas');
    //     let ctx = canvas.getContext('2d');
    //     canvas.width = size;
    //     canvas.height = size;
    //     if (ctx === null) {
    //       return;
    //     }
    //     ctx.fillStyle = 'white';
    //     ctx.fillRect(0, 0, size, size);
    //     ctx.drawImage(
    //       img,
    //       adjustedCropX,
    //       adjustedCropY,
    //       size,
    //       size,
    //       0,
    //       0,
    //       size,
    //       size
    //     );

    //     let croppedImageData = canvas.toDataURL('image/jpeg', 1);
    //     this.setState({
    //       croppedImage: croppedImageData,
    //       resizedImage: croppedImageData,
    //       isCropping: false,
    //       showimage: false,
    //       fImage: croppedImageData,
    //     });
    //     let payload: any = {
    //       object: this.props._data.id,
    //       field: this._structure.Field.FileType.Jpg,
    //       value: croppedImageData.replace('data:', '').replace(/^.+,/, ''),
    //     };
    //     this.saveImage(payload);
    //     this.props.getFinalImage({
    //       image: croppedImageData.replace('data:', '').replace(/^.+,/, ''),
    //       isCropping: false,
    //       showimage: false,
    //     });
    //   };
    //   img.src = resizedImage;
    // }
  };

  handleDocumentCropApply = () => {
    //if (!this.props.editImage) {
    const { resizedImage } = this.state;
    const container = document.getElementById('image-div');
    if (container === null) {
      return;
    }
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    let img = new Image();
    img.onload = () => {
      const { cropX, cropY, crop } = this.state;
      const { sizeX, sizeY } = crop;
      const imageX = (containerWidth - img.width) / 2;
      const imageY = (containerHeight - img.height) / 2;
      const adjustedCropX = cropX - imageX;
      const adjustedCropY = cropY - imageY;
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      canvas.width = sizeX;
      canvas.height = sizeY;
      if (ctx === null) {
        return;
      }
      ctx.rotate((this.state.rotation * Math.PI) / 180);
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, sizeX, sizeY);
      ctx.drawImage(
        img,
        adjustedCropX,
        adjustedCropY,
        sizeX,
        sizeY,
        0,
        0,
        sizeX,
        sizeY
      );
      const croppedImageData = canvas.toDataURL('image/jpeg', 1);
      this.setState({
        croppedImage: croppedImageData,
        resizedImage: croppedImageData,
        isCropping: false,
        fImage: croppedImageData,
      });
      let id: number = 0;
      if (this.props._data.document) {
        let fltr: any = this.props._data.document.filter(
          (x: any) => x.id == this.props.doubleClickedId
        );
        if (fltr.length > 0) {
          // id = fltr[0].account.id;
          id = fltr[0].id;
        }
      }
      if (Number(id) > 0) {
        let payload: any = {
          object: Number(id),
          field: this._structure.Field.FileType.Jpg,
          value: croppedImageData.replace('data:', '').replace(/^.+,/, ''),
        };
        this.saveImage(payload);
      }

      this.props.getFinalImage({
        imageId: Number(id),
        image: croppedImageData.replace('data:', '').replace(/^.+,/, ''),
        isCropping: false,
        showimage: false,
      });
    };
    img.src = resizedImage;
    // } else {
    //   const { resizedImage } = this.state;
    //   const container = document.getElementById('image-div');
    //   if (container === null) {
    //     return;
    //   }
    //   const containerWidth = container.clientWidth;
    //   const containerHeight = container.clientHeight;
    //   let img = new Image();
    //   img.onload = () => {
    //     const { cropX, cropY, crop } = this.state;
    //     const { sizeX, sizeY } = crop;
    //     const imageX = (containerWidth - img.width) / 2;
    //     const imageY = (containerHeight - img.height) / 2;
    //     const adjustedCropX = cropX - imageX;
    //     const adjustedCropY = cropY - imageY;
    //     let canvas = document.createElement('canvas');
    //     let ctx = canvas.getContext('2d');
    //     canvas.width = sizeX;
    //     canvas.height = sizeY;
    //     if (ctx === null) {
    //       return;
    //     }
    //     ctx.rotate((this.state.rotation * Math.PI) / 180);
    //     ctx.fillStyle = 'white';
    //     ctx.fillRect(0, 0, sizeX, sizeY);
    //     ctx.drawImage(
    //       img,
    //       adjustedCropX,
    //       adjustedCropY,
    //       sizeX,
    //       sizeY,
    //       0,
    //       0,
    //       sizeX,
    //       sizeY
    //     );
    //     const croppedImageData = canvas.toDataURL('image/jpeg', 1);
    //     this.setState({
    //       croppedImage: croppedImageData,
    //       resizedImage: croppedImageData,
    //       isCropping: false,
    //       fImage: croppedImageData,
    //     });
    //     let id: number = 0;
    //     if (this.props._data.document) {
    //       let fltr: any = this.props._data.document.filter(
    //         (x: any) => x.id == this.props.doubleClickedId
    //       );
    //       if (fltr.length > 0) {
    //         // id = fltr[0].account.id;
    //         id = fltr[0].id;
    //       }
    //     }
    //     if (Number(id) > 0) {
    //       let payload: any = {
    //         object: Number(id),
    //         field: this._structure.Field.FileType.Jpg,
    //         value: croppedImageData.replace('data:', '').replace(/^.+,/, ''),
    //       };
    //       this.saveImage(payload);
    //     }

    //     this.props.getFinalImage({
    //       imageId: Number(id),
    //       image: croppedImageData.replace('data:', '').replace(/^.+,/, ''),
    //       isCropping: false,
    //       showimage: false,
    //     });
    //   };
    //   img.src = resizedImage;
    // }
  };

  handleCancel = (e: any) => {
    this.setState({
      imageUrl: null,
      resizedImage: null,
      name: null,
      croppedImage: null,
      isCropping: false,
      fImage: null,
      showimage: false,
    });
  };
  btnAccept = () => {
    this.props.isImageEditor === true
      ? this.handleCropApply()
      : this.handleDocumentCropApply();

    this.setState({ showimage: false });
  };

  saveImage = (payload: any) => {
    if (payload.value) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Image/Save`,
        payload,
        Common.getToken() || '',
        this.user
      )
        .then((resopnse: any) => {
          return resopnse.data;
        })

        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  };

  newDialogContent() {
    return (
      <>
        <div className='row'>
          <div className='col-md-12'>
            <div
              id='image-div'
              style={{
                height: `${this.state.imageDivHeight}`,
              }}
            >
              {this.state.resizedImage ? (
                <div id='imgdiv'>
                  <img
                    src={this.state.resizedImage}
                    alt='Resized'
                    id='img'
                    style={{
                      transform: `rotate(${this.state.rotation}deg)`,
                    }}
                  />
                </div>
              ) : (
                <>
                  {this.state.imageUrl ? (
                    <div
                      id='imgdiv'
                      style={{
                        width: this.state.width,
                        height: this.state.height,
                      }}
                    >
                      <img
                        src={this.state.imageUrl}
                        // src={`data:image/png;base64,${this.props.editImage}`}
                        alt='Selected'
                        id='img'
                        style={{
                          position: 'absolute',
                          pointerEvents: 'auto',
                          transform: `rotate(${this.state.rotation}deg)`,
                          width: this.state.width,
                          height: this.state.height,
                        }}
                      />
                    </div>
                  ) : this.props.editImage ? (
                    <div
                      id='imgdiv'
                      style={{
                        width: this.state.width,
                        height: this.state.height,
                        position: 'absolute',
                        pointerEvents: 'auto',
                        //transform: `rotate(${this.state.rotation}deg)`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        src={`data:image/png;base64,${this.props.editImage}`}
                        alt='Image'
                        id='img'
                      />
                    </div>
                  ) : null}
                </>
              )}
              {this.state.isCropping &&
                (this.props.isImageEditor ? (
                  <div
                    id='crop-box'
                    style={{
                      top:
                        this.state.cropY === 0 ? undefined : this.state.cropY,
                      left:
                        this.state.cropX === 0 ? undefined : this.state.cropX,
                      marginLeft: 'auto',
                      marginTop: 'auto',
                      width: this.state.crop.size,
                      height: this.state.crop.size,
                    }}
                    onMouseDown={this.handleMouseCropDown.bind(this)}
                  >
                    <img src='./images/profile-pic2.png' id='cropbox-img' />
                  </div>
                ) : (
                  <div
                    id='crop-box'
                    style={{
                      top:
                        this.state.cropY === 0 ? undefined : this.state.cropY,
                      left:
                        this.state.cropX === 0 ? undefined : this.state.cropX,
                      marginLeft: 'auto',
                      marginTop: 'auto',
                      width: this.state.crop.sizeX,
                      height: this.state.crop.sizeY,
                    }}
                    onMouseDown={this.handleMouseCropDown.bind(this)}
                  >
                    <img
                      src='./images/idPicture2.png'
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>
                ))}
              {/* crop box for existing image */}
              {/* {this.props.editImage &&
                (this.props.isImageEditor ? (
                  <div
                    id='crop-box'
                    style={{
                      top:
                        this.state.cropY === 0 ? undefined : this.state.cropY,
                      left:
                        this.state.cropX === 0 ? undefined : this.state.cropX,
                      marginLeft: 'auto',
                      marginTop: 'auto',
                      width: this.state.crop.size,
                      height: this.state.crop.size,
                    }}
                    onMouseDown={this.handleMouseCropDown.bind(this)}
                  >
                    <img src='./images/profile-pic2.png' id='cropbox-img' />
                  </div>
                ) : (
                  <div
                    id='crop-box'
                    style={{
                      top:
                        this.state.cropY === 0 ? undefined : this.state.cropY,
                      left:
                        this.state.cropX === 0 ? undefined : this.state.cropX,
                      marginLeft: 'auto',
                      marginTop: 'auto',
                      width: this.state.crop.sizeX,
                      height: this.state.crop.sizeY,
                    }}
                    onMouseDown={this.handleMouseCropDown.bind(this)}
                  >
                    <img
                      src='./images/idPicture2.png'
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>
                ))} */}
            </div>
          </div>
        </div>
      </>
    );
  }
  newDialogContentFooter() {
    return (
      <>
        <div className='row px-1'>
          <div className='col-md-7' id='footer-div'>
            <span className='mx-2'>
              <label htmlFor='fileupload-icon'>
                <i
                  className='font-icon fa-regular fa-folder-open  fs-6'
                  title='choose file'
                ></i>
                <input
                  type='file'
                  id='fileupload-icon'
                  onChange={this.handleFileChange.bind(this)}
                  accept='image/*'
                />
              </label>
            </span>
            <span className='mx-2'>
              {' '}
              <i
                className='font-icon fa-solid fa-magnifying-glass-minus fs-6'
                onClick={this.handleResizeMinus.bind(this)}
                title='zoom-in'
              ></i>
            </span>
            <span className='mx-2'>
              {' '}
              <i
                className='font-icon fa-solid fa-magnifying-glass-plus fs-6'
                onClick={this.handleResizePlus.bind(this)}
                title='zoom-out'
              ></i>
            </span>
            <span className='mx-2'>
              <i
                id='imgrotate'
                className='font-icon fa-solid fa-rotate fs-6'
                title='Rotate'
                onClick={this.btnRotate.bind(this)}
              ></i>
            </span>
          </div>{' '}
          <div className='col-md-5' id='footer-btn'>
            <span>
              <button
                className='flat-button'
                onClick={this.handleCancel.bind(this)}
              >
                Cancel
              </button>
              <span>
                <button
                  className='flat-button'
                  onClick={this.btnAccept.bind(this)}
                >
                  Accept
                </button>
              </span>
            </span>
          </div>
        </div>
      </>
    );
  }

  newDialogCreated(e: any) {
    let dialog: HTMLElement = document.querySelector(
      `#image-dialog`
    ) as HTMLElement;
    let header: HTMLElement = document.querySelector(
      `#image-dialog .e-dlg-header-content`
    ) as HTMLElement;

    let footer: HTMLElement = document.querySelector(
      `#image-dialog .e-footer-content`
    ) as HTMLElement;

    let imageContent: HTMLElement = document.querySelector(
      `#image-dialog #image-div`
    ) as HTMLElement;
    // imageContent.style.height = `${
    //   dialog.getBoundingClientRect().height -
    //   header.getBoundingClientRect().height -
    //   footer.getBoundingClientRect().height -
    //   50
    // }px`;
    this.setState({
      imageDivHeight: `${
        dialog.getBoundingClientRect().height -
        header.getBoundingClientRect().height -
        footer.getBoundingClientRect().height -
        50
      }px`,
    });
  }
  render() {
    return (
      <>
        {this.state.showimage === true ? (
          <DialogComponent
            id='image-dialog'
            showCloseIcon={true}
            visible={this.state.showimage}
            width={'46vw'}
            height='77vh'
            close={() => {
              this.setState({ showimage: false });
              this.props.getFinalImage({
                image: this.props.editImage
                  ? this.props.editImage
                      .replace('data:', '')
                      .replace(/^.+,/, '')
                  : this.state.fImage.replace('data:', '').replace(/^.+,/, ''),
                isCropping: false,
                showimage: false,
              });
            }}
            content={this.newDialogContent.bind(this)}
            isModal={true}
            statelessTemplates={[]}
            header={this.props._data ? this.props._data['name'] : ''}
            footerTemplate={this.newDialogContentFooter.bind(this)}
            style={{ padding: '22px' }}
            open={this.newDialogCreated.bind(this)}
          ></DialogComponent>
        ) : null}
      </>
    );
  }
}

export default ImageEditor;
