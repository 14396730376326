// * ---------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SUHAIL - 2023-03-22 - 320 - Research and Developement to show tab as per selector click
// * SANDEEP - 2023-04-10 - 420 - Worked on Tab Styles and some changes in the design provided by fabio
// * SUHAIL - 2023-04-10 - 380 - Research and Developement on preventing page refresh on selector click and deployment
// * SANDEEP - 2023-04-10 - 420 - Worked on Tab Styles and some changes in the design provided by fabio
// * SANDEEP - 2023-04-21 - 200 - Worked on changes as per the design and pager control on different project
// * SANDEEP - 2023-05-02 - 120 -- Reading newly made xml file with changes.
// * SANDEEP - 2023-05-03 - 150 -- Removed Parsing Logic from Composer & Moved to Dataviewer
// * SANDEEP - 2023-04-08 - 60 -- Meeting
// * SANDEEP - 2023-04-08 - 120 -- Removing the data repetition and trying to generate with minimum xml model
// * SANDEEP - 2023-05-17 - 180 -- Generate xml for education and experience and displayed the data.
// * SUHAIL - 2023-05-23 - 90 - meeting with fabio on local database connection and multi company login
// * SUHAIL - 2023-05-23 - 390 - Adapt login flow to the new structure 1.5.26
// * SUHAIL - 2023-05-24 - 210 - working on creating sample xml for dossier and contract to display custom columns
// * SUHAIL - 2023-05-24 - 60 - working with avinash to apply holidays when selected year changed
// * SUHAIL - 2023-05-24 - 60 - weekly status meeting
// * SUHAIL - 2023-05-24 - 30 - meeting with fabio on calling foxsystem api by using user object in header for amos project
// * SUHAIL - 2023-05-25 - 50 - sprint planning meeting
// * SUHAIL - 2023-05-25 - 40 - meeting with fabio on create employee and contract xml
// * SUHAIL - 2023-05-25 - 380 - working on creating xml for dossier to display dossier data in employee
// * SUHAIL - 2023-05-26 - 35 - sprint review meeting
// * SUHAIL - 2023-05-26 - 120 - helping sourav on image not showing on employee change in dialog component
// * SUHAIL - 2023-05-26 - 325 - working, building, deploying and testing gntz and cisp company multi user
// * SUHAIL - 2023-06-07 - 150 - meeting with fabio and saurav on task discussion
// * SUHAIL - 2023-06-07 - 50 - weekly status meeting
// * SUHAIL - 2023-06-07 - 80 - guiding sourav and avinash on aruti 2.0 code structure
// * SUHAIL - 2023-06-07 - 200 - Provide grouping column settings in xml to display dossier group by Account

// * ---------------------------------------------------------------------------------------------------------------2.Initialize
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from '@syncfusion/ej2-react-navigations';
import React, { Component } from 'react';
import Composer from '../Composer';
import Login from '../Login/Login';
import bio_data from '../../dummydata/xml_data_bio.js';
import address_data from '../../dummydata/xml_data_address.js';
import addresslist from '../../dummydata/xml_data_addresslist.js';
import learninglist from '../../dummydata/xml_learninginfo.js';
import XCard from '../XCard';
import Common, { JsonTree } from '../../Util/Common';
import DocumentViewer from './DocumentViewer';
import LeaveProcess from '../LeaveProcess';
import {
  dossier_data,
  contract_data,
  contractcard_data,
  loan_data,
  contract_alldata,
  leave_type_data,
} from '../../dummydata/xml_data_employee';
import Attendance from '../NewAttendance';
import Dashboard from './Dashboard';

// * UNCOMMENT IF XML2JS ARE REQUIRED
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

// * ------------------------------------------------------------------------------------------------------------------3.Style

// * -----------------------------------------------------------------------------------------------------------------DOTENV VAR DECLARATION

// * ---------------------------------------------------------------------------------------------------------------2.Initialize
// * SUHAIL - 2023-03-31 - 15
interface tabstructure {
  idx: number;
  object: number;
  name: string;
  label: string;
}
// * ---------------------------------------------------------------------------------------------------------------2.Initialize
// * SANDEEP - 2023-08-10 - 05
interface Props {
  serviceName: string;
  jsonDataViewer: any;
  rootmenu: any;
  selectedmenu: any;
  selectedtab: any;
  selectedtabindex: number;
  //jsonDocument?: any;
  //handleDocumentSave?: any;
  showganttchart: boolean;
  refreshSelector?: any;
  showdashboard?: boolean;
}

// * -------------------------------------------------------------------------------------------------------------------CLASS DECLARATION
class DataViewer extends React.Component<Props> {
  // * ------------------------------------------------------------------------------------------------------------------2.Initialize Component
  // * SUHAIL - 2023-03-31 - 10
  private _structure: any;
  private tabset: any = null;
  private js: any = null;
  private user: any;
  private tabs: tabstructure[] = [];
  private isTabAdded: boolean;
  private controlType: any;
  private child: Composer | any;
  private _fulljson: any;
  private _showcommon_cntxmneu: boolean = false;
  private isTabsRefresh: boolean = false;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this._fulljson = JSON.parse(Common.getFullJson() ?? '');

    if (
      this.props.serviceName.split('/')!.pop()!.toLowerCase() === 'person' ||
      this.props.serviceName.split('/')!.pop()!.toLowerCase() === 'employee'
    ) {
      this._showcommon_cntxmneu = true;
    }
    if (this.props.selectedmenu !== null) {
      this.controlType = Common.FindJsonByRelationFieldId(
        this.props.selectedmenu.children,
        this._structure.UiComponents.ControlType
      );
      this.tabset = Common.FindJsonByRelationFieldId(
        this.props.selectedmenu.children,
        this._structure.Parameters.TabsSet
      ); //9182 = TabSet
    }
    this.isTabAdded = false;

    let tabst: tabstructure;
    if (this.tabset !== null) {
      this.tabset.children.forEach((x: any) => {
        let idx: any = this.tabs.findIndex((p: any) => {
          return p.object === x.object;
        });

        if (idx < 0) {
          idx = this.tabs.length;
          tabst = {
            idx: this.tabs.length,
            object: x.object,
            name: '',
            label: '',
          };
          this.tabs.push(tabst);
        }

        switch (x.field) {
          case this._structure.Field.Identif.Name:
            tabst = { ...this.tabs[idx], name: x.value };
            this.tabs[idx] = tabst;
            break;

          case this._structure.Field.Identif.Label:
          case this.user.language:
            tabst = { ...this.tabs[idx], label: x.value };
            this.tabs[idx] = tabst;
            break;
        }
      });
    }
    this.child = React.createRef();
  }

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    //jsonDataViewer: this.js
    _selectedtab: undefined,
    _menuitems: undefined,
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount() {}

  addnew_click(evt: any) {
    switch (this.props.selectedtabindex) {
      case 0:
        this.child.current.AddAccordion();
        break;
      case 1:
        break;
      default:
        break;
    }
  }

  // * -------------------------------------------------------------------------------------------------------------------4.Api

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter

  private findUpTag(el: any, tag: string) {
    while (el.parentNode) {
      el = el.parentNode;
      if (el.id === tag) return el;
    }
    return null;
  }

  private ParseXML(value: string) {
    var XMLParser = require('react-xml-parser');
    var xml = new XMLParser().parseFromString(value);
    return xml;
  }

  public getSelectedSelector(data: any) {
    this.setState({ jsonDataViewer: data });
  }

  private applyStyle(args: any) {
    for (
      var i = 0;
      i < document.querySelectorAll('.tab-highlighter').length;
      i++
    ) {
      var el: any;
      el = document.querySelectorAll('.tab-highlighter')[i];
      if (el !== null) {
        el.className = 'e-tab-text';
      }
    }

    for (
      var i = 0;
      i < document.querySelectorAll('.e-addicon-hide').length;
      i++
    ) {
      var el: any;
      el = document.querySelectorAll('.e-addicon-hide ')[i];
      if (el !== null) {
        el.className = 'e-addicon';
      }
    }

    var _id: any;
    if (args.selectedItem !== undefined && args.selectedItem.id !== null) {
      _id = args.selectedItem.id;
    } else {
      _id = args.id;
    }
    if (_id !== undefined && _id !== null) {
      var obj: any = document.getElementById(_id);
      if (obj !== null) {
        if (obj.classList.contains('e-active')) {
          for (var i = 0; i < obj.querySelectorAll('.e-tab-text').length; i++) {
            var el: any;
            el = obj.querySelectorAll('.e-tab-text')[i];
            if (el !== null) {
              el.className = 'e-tab-text tab-highlighter';
            }
          }
        }
      }
      if (this.HideButton(obj) == true) {
        for (
          var i = 0;
          i < document.querySelectorAll('.e-addicon').length;
          i++
        ) {
          var el: any;
          el = document.querySelectorAll('.e-addicon')[i];
          if (el !== null) {
            el.className = 'e-addicon-hide';
          }
        }
      }
    }
  }

  private HideButton(ele: any) {
    // let res = Boolean;

    if (
      ele.innerText !== undefined &&
      (ele.innerText as any).toLowerCase() === 'attendance'
    ) {
      return true;
    } else if (
      ele.innerText !== undefined &&
      (ele.innerText as any).toLowerCase() === 'leaves'
    ) {
      return true;
    } else if (
      ele.innerText !== undefined &&
      (ele.innerText as any).toLowerCase() === 'pay slips'
    ) {
      return true;
    }
    // else {
    //   this.props.selectedtab?.toLowerCase() !== 'attendance'{
    //     return false
    //   }
    // }
    // return res;
  }

  // * --------------------------------------------------------------------------------------------------------------------HTML
  // * SANDEEP - 2023-03-23 - 450 -- Implement the changes in the main copy and working on card component under tab
  // * SUHAIL - 2023-03-31 - 120
  // * SUHAIL - 2023-04-03 - 10
  // * SANDEEP - 2023-04-06 - 300 -- Working on CSS for applying dynanically
  // * SANDEEP - 2023-04-07 - 300 -- Working on CSS for applying dynanically as per the design
  // * SUHAIL - 2023-05-04 - 60
  // * SANDEEP - 2023-06-01 - 30 -- Working on callback function to setting tab style as persistance property was removing it
  // * Avinash - 2023-05-26 - 30 -- Working on timeline year
  // * SANDEEP - 2023-08-10 - 20 -- Added Leave Process Component

  render() {
    // this._structure = JSON.parse(Common.getStructure() ?? '');
    // if (this.props.rootmenu !== null) {
    //     this.tabset = Common.FindJsonByRelationFieldId(this.props.rootmenu.children, this._structure.Parameters.TabsSet);//9182 = TabSet
    // }
    // var isTabAdded = false;
    // let tabs: tabstructure[] = [];
    // let tabst: tabstructure;
    // if (this.tabset !== null) {
    //     this.tabset.children.forEach((x: any) => {
    //         let idx: any = tabs.findIndex((p: any) => { return p.object === x.object });

    //         if (idx < 0) {
    //             idx = tabs.length;
    //             tabst = { idx: tabs.length, object: x.object, name: '', label: '' };
    //             tabs.push(tabst);
    //         }

    //         switch (x.field) {

    //             case this._structure.Field.Identif.Name:
    //                 tabst = { ...tabs[idx], name: x.value };
    //                 tabs[idx] = tabst;
    //                 break;

    //             case this._structure.Field.Identif.Label:
    //             case this.user.language:
    //                 tabst = { ...tabs[idx], label: x.value };
    //                 tabs[idx] = tabst;
    //                 break;

    //         }
    //     });

    // }

    const selected = (args: any) => {
      if (this.isTabsRefresh === true) {
        tabInstance.refresh();
        this.isTabsRefresh = false;
      }
      this.props.selectedtab(args.selectedIndex);
      this.applyStyle(args);
      this.setState({ _selectedtab: args.selectedIndex });
    };

    let tabInstance: TabComponent | any;

    const tabCreated = (): void => {
      if (this.props.jsonDataViewer === null) {
        return;
      }

      let itemsData: Object[] = [];
      let tab: string[] = [];

      if (this.tabs.length <= 0) {
        if (
          (this.props.jsonDataViewer &&
            this.props.jsonDataViewer.constructor === {}.constructor) ||
          (Array.isArray(this.props.jsonDataViewer) &&
            this.props.jsonDataViewer.length > 0)
        ) {
          //tab.push(this.props.serviceName);
          //tab.push('Bio');
          if (this.props.serviceName.toLowerCase() == 'person') {
            tab.push('Bio');
          } else {
            tab.push(this.props.serviceName);
          }
        }

        this.props.jsonDataViewer &&
          Object.keys(this.props.jsonDataViewer).forEach((r: any) =>
            (Array.isArray(this.props.jsonDataViewer[r]) &&
              r.toString().toLowerCase() !== 'bio') ||
            this.props.jsonDataViewer[r].constructor === {}.constructor
              ? tab.push(r)
              : null
          );
      } else {
        this.tabs.forEach((x: any) => {
          tab.push(x.label);
        });
        this.isTabAdded = true;
      }
      let headertext: any = [
        { text: 'Twitter', iconCss: 'e-twitter' },
        { text: 'Facebook', iconCss: 'e-facebook' },
        { text: 'WhatsApp', iconCss: 'e-whatsapp' },
      ];

      for (let i: number = 0; i < tab.length; i++) {
        // if (i == 0) {
        //     // let cntnt: any = {};
        //     // Object.keys(this.props.jsonDataViewer).forEach(r => {
        //     //     if (!(Array.isArray(this.props.jsonDataViewer[r]) || this.props.jsonDataViewer[r].constructor === ({}).constructor)) {
        //     //         cntnt[r] = this.props.jsonDataViewer[r]
        //     //     }
        //     // })
        //     let cntnt: any = this.props.jsonDataViewer[tab[i]];
        //     //itemsData.push({ header: { text: tab[i] }, content: JSON.stringify(cntnt) });
        //     itemsData.push({ header: { text: tab[i] }, content: "" });

        // }
        // else {
        //     if (tab[i].toLowerCase() == "bio") {
        //         let cntnt: any =
        //             () => {
        //                 return (<Composer _jObject={this.props.jsonDataViewer} />)
        //             };
        //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
        //     }
        //     else {
        //         let cntnt: any = this.props.jsonDataViewer[tab[i]];
        //         //itemsData.push({ header: { text: tab[i] }, content: JSON.stringify(cntnt) });
        //         itemsData.push({ header: { text: tab[i] }, content: "" });
        //     }
        // }

        var _finalxml: any;
        //     if (tab[i].toLowerCase() == "bio") {
        //         let cntnt: any =
        //             () => {
        //                 // var _idata = Common.FindJsonByFieldId(this.props.selectedmenu.children, this._structure.Field.Identif.Composer);
        //                 // if (_idata !== undefined) {
        //                 //     _finalxml = this.ParseXML(_idata.value);
        //                 //     return (<Composer _jObject={this.props.jsonDataViewer} _xml={_finalxml} selectedtabname={tab[i]} />)
        //                 // }
        //                 // else {
        //                 //     return ''
        //                 // }

        //                 _finalxml = this.ParseXML(bio_data);
        //                 return (<Composer _jObject={this.props.jsonDataViewer} _xml={_finalxml} selectedtabname={tab[i]} />)

        //             };
        //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
        //     } else if (tab[i].toLowerCase() == "contact") {
        //         let cntnt: any =
        //             () => {
        //                 _finalxml = this.ParseXML(addresslist);
        //                 var _emergency = _finalxml.getElementsByTagName('Address').filter((x: any) => x.attributes.type == 'Emergency')[0];
        //                 return (<Composer _jObject={this.props.jsonDataViewer} _xml={_emergency} selectedtabname={tab[i]} />)
        //                 //_finalxml = this.ParseXML(address_data);
        //                 //return (<Composer _jObject={this.props.jsonDataViewer} _xml={_finalxml} />)
        //             };
        //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
        //     }
        //     else if (tab[i].toLowerCase() == "address") {
        //         let cntnt: any =
        //             () => {
        //                 _finalxml = this.ParseXML(addresslist);
        //                 var _personal = _finalxml.getElementsByTagName('Address').filter((x: any) => x.attributes.type == 'Personal')[0];
        //                 if (_personal !== undefined) {
        //                     return (<Composer _jObject={this.props.jsonDataViewer} _xml={_personal} selectedtabname={tab[i]} />)
        //                 }
        //             };
        //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
        //     }
        //     else if (tab[i].toLowerCase() == "reference") {
        //         let cntnt: any =
        //             () => {
        //                 _finalxml = this.ParseXML(addresslist);
        //                 var _reference = _finalxml.getElementsByTagName('Address').filter((x: any) => x.attributes.type == 'Reference')[0];
        //                 if (_reference !== undefined) {
        //                     return (<Composer _jObject={this.props.jsonDataViewer} _xml={_reference} selectedtabname={tab[i]} />)
        //                 }
        //             };
        //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
        //     }
        //     else if (tab[i].toLowerCase() == "education") {
        //         let cntnt: any =
        //             () => {
        //                 _finalxml = this.ParseXML(learninglist);
        //                 var _education = _finalxml.getElementsByTagName('DataValue').filter((x: any) => x.attributes.type == 'Education')[0];
        //                 if (_education !== undefined) {
        //                     return (<Composer _jObject={this.props.jsonDataViewer} _xml={_education} selectedtabname={tab[i]} />)
        //                 }
        //             };
        //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
        //     }
        //     else if (tab[i].toLowerCase() == "experience") {
        //         let cntnt: any =
        //             () => {
        //                 _finalxml = this.ParseXML(learninglist);
        //                 var _experience = _finalxml.getElementsByTagName('DataValue').filter((x: any) => x.attributes.type == 'Experience')[0];
        //                 if (_experience !== undefined) {
        //                     return (<Composer _jObject={this.props.jsonDataViewer} _xml={_experience} selectedtabname={tab[i]} />)
        //                 }
        //             };
        //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
        //     }
        //     else if (tab[i].toLowerCase() == "dossier") {
        //         let cntnt: any =
        //             () => {
        //                 return (<Composer _jObject={this.props.jsonDataViewer} _xml={null} selectedtabname={tab[i]} />)
        //             };
        //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
        //     }
        //     else if (tab[i].toLowerCase() == "contract") {
        //         let cntnt: any =
        //             () => {
        //                 return (<Composer _jObject={this.props.jsonDataViewer} _xml={null} selectedtabname={tab[i]} />)
        //             };
        //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
        //     }
        //     else {
        //         //let cntnt: any = this.props.jsonDataViewer[tab[i].toString()];
        //         //itemsData.push({ header: { text: tab[i] }, content: JSON.stringify(cntnt) });
        //         itemsData.push({ header: { text: tab[i] }, content: '' });
        //     }
        // }

        if (
          this.tabs
            .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
            .name.toLowerCase() === 'bio'
        ) {
          let cntnt: any = () => {
            // var _idata = Common.FindJsonByFieldId(this.props.selectedmenu.children, this._structure.Field.Identif.Composer);
            // if (_idata !== undefined) {
            //     _finalxml = this.ParseXML(_idata.value);
            //     return (<Composer _jObject={this.props.jsonDataViewer} _xml={_finalxml} selectedtabname={tab[i]} />)
            // }
            // else {
            //     return ''
            // }

            _finalxml = this.ParseXML(bio_data);
            return (
              <Composer
                ref={this.child}
                _jObject={this.props.jsonDataViewer}
                _xml={_finalxml}
                selectedtabname={
                  this.tabs.filter(
                    (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                  )[0].name
                }
                showCommonCntxMenu={this._showcommon_cntxmneu}
              />
            );
          };
          itemsData.push({ header: { text: tab[i] }, content: cntnt });
        } else if (
          this.tabs
            .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
            .name.toLowerCase() === 'contact'
        ) {
          let cntnt: any = () => {
            _finalxml = this.ParseXML(addresslist);
            var _emergency = _finalxml
              .getElementsByTagName('Address')
              .filter((x: any) => x.attributes.type == 'Emergency')[0];
            return (
              <Composer
                _jObject={this.props.jsonDataViewer}
                _xml={_emergency}
                selectedtabname={
                  this.tabs.filter(
                    (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                  )[0].name
                }
                showCommonCntxMenu={this._showcommon_cntxmneu}
              />
            );
          };
          itemsData.push({ header: { text: tab[i] }, content: cntnt });
        } else if (
          this.tabs
            .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
            .name.toLowerCase() === 'address'
        ) {
          let cntnt: any = () => {
            _finalxml = this.ParseXML(addresslist);
            var _personal = _finalxml
              .getElementsByTagName('Address')
              .filter((x: any) => x.attributes.type == 'Address')[0];
            if (_personal !== undefined) {
              return (
                <Composer
                  _jObject={this.props.jsonDataViewer}
                  _xml={_personal}
                  selectedtabname={
                    this.tabs.filter(
                      (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                    )[0].name
                  }
                  showCommonCntxMenu={this._showcommon_cntxmneu}
                />
              );
            }
          };
          itemsData.push({ header: { text: tab[i] }, content: cntnt });
        } else if (
          this.tabs
            .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
            .name.toLowerCase() === 'reference'
        ) {
          let cntnt: any = () => {
            _finalxml = this.ParseXML(addresslist);
            var _reference = _finalxml
              .getElementsByTagName('Address')
              .filter((x: any) => x.attributes.type == 'Reference')[0];
            if (_reference !== undefined) {
              return (
                <Composer
                  _jObject={this.props.jsonDataViewer}
                  _xml={_reference}
                  selectedtabname={
                    this.tabs.filter(
                      (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                    )[0].name
                  }
                  showCommonCntxMenu={this._showcommon_cntxmneu}
                />
              );
            }
          };
          itemsData.push({ header: { text: tab[i] }, content: cntnt });
        } else if (
          this.tabs
            .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
            .name.toLowerCase() === 'education'
        ) {
          let cntnt: any = () => {
            _finalxml = this.ParseXML(learninglist);
            var _education = _finalxml
              .getElementsByTagName('DataValue')
              .filter((x: any) => x.attributes.type == 'Education')[0];
            if (_education !== undefined) {
              return (
                <Composer
                  _jObject={this.props.jsonDataViewer}
                  _xml={_education}
                  selectedtabname={
                    this.tabs.filter(
                      (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                    )[0].name
                  }
                  showCommonCntxMenu={this._showcommon_cntxmneu}
                />
              );
            }
          };
          itemsData.push({ header: { text: tab[i] }, content: cntnt });
        } else if (
          this.tabs
            .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
            .name.toLowerCase() === 'experience'
        ) {
          let cntnt: any = () => {
            _finalxml = this.ParseXML(learninglist);
            var _experience = _finalxml
              .getElementsByTagName('DataValue')
              .filter((x: any) => x.attributes.type == 'Experience')[0];
            if (_experience !== undefined) {
              return (
                <Composer
                  _jObject={this.props.jsonDataViewer}
                  _xml={_experience}
                  selectedtabname={
                    this.tabs.filter(
                      (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                    )[0].name
                  }
                  showCommonCntxMenu={this._showcommon_cntxmneu}
                />
              );
            }
          };
          itemsData.push({ header: { text: tab[i] }, content: cntnt });
        } else if (
          this.tabs
            .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
            .name.toLowerCase() === 'dossier'
        ) {
          let cntnt: any = () => {
            var _result: any;
            parser.parseString(dossier_data, function (err: any, result: any) {
              _result = JSON.parse(JSON.stringify(result));
            });
            let jsondviewer: any = this.props.jsonDataViewer;
            // jsondviewer[tab[i].toLowerCase()] = this.props.jsonDataViewer[
            //   tab[i].toLowerCase()
            // ].sort((a: any, b: any) => {
            //   return new Date(b.date).getTime() - new Date(a.date).getTime();
            // });

            return (
              <Composer
                //_jObject={this.props.jsonDataViewer}
                _jObject={jsondviewer}
                _xml={null}
                _json={_result}
                selectedtabname={tab[i]}
                datagridAllowGrouping={true}
                serviceName={this.props.serviceName}
                selectedmenu={this.props.selectedmenu}
                showCommonCntxMenu={this._showcommon_cntxmneu}
              />
            );
          };
          itemsData.push({ header: { text: tab[i] }, content: cntnt });
        } else if (
          this.tabs
            .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
            .name.toLowerCase() === 'contracts'
        ) {
          let cntnt: any = () => {
            var _result: any;
            parser.parseString(contract_data, function (err: any, result: any) {
              _result = JSON.parse(JSON.stringify(result));
            });
            let jsondviewer: any = this.props.jsonDataViewer;
            // if (this.props.jsonDataViewer[tab[i].toLowerCase()]) {
            // 	jsondviewer[tab[i].toLowerCase()] = this.props.jsonDataViewer[
            // 		tab[i].toLowerCase()
            // 	].sort((a: any, b: any) => {
            // 		return (
            // 			new Date(b.startDate).getTime() -
            // 			new Date(a.startDate).getTime()
            // 		);
            // 	});
            // } else {
            // 	jsondviewer['contract'] = this.props.jsonDataViewer[
            // 		'contract'
            // 	].sort((a: any, b: any) => {
            // 		return (
            // 			new Date(b.startDate).getTime() -
            // 			new Date(a.startDate).getTime()
            // 		);
            // 	});
            // }
            //var subcardxml: any = this.ParseXML(contractcard_data);
            var subcardxml: any;
            parser.parseString(
              contract_alldata,
              function (err: any, result: any) {
                subcardxml = JSON.parse(JSON.stringify(result));
              }
            );

            return (
              <Composer
                //_jObject={this.props.jsonDataViewer}
                _jObject={jsondviewer}
                _xml={null}
                _json={_result}
                selectedtabname={'Contract'}
                serviceName={this.props.serviceName}
                selectedmenu={this.props.selectedmenu}
                datagridWidth={_result.Grid.$.width ?? undefined}
                subcardxml={subcardxml}
                showCommonCntxMenu={this._showcommon_cntxmneu}
                refreshTabs={refreshTabs}
              />
            );
          };

          itemsData.push({ header: { text: tab[i] }, content: cntnt });
        } else if (
          this.tabs
            .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
            .name.toLowerCase() === 'payslips'
        ) {
          let cntnt: any = () => {
            var _result: any;
            parser.parseString(contract_data, function (err: any, result: any) {
              _result = JSON.parse(JSON.stringify(result));
            });

            //var subcardxml: any = this.ParseXML(contractcard_data);
            var subcardxml: any;
            parser.parseString(
              contract_alldata,
              function (err: any, result: any) {
                subcardxml = JSON.parse(JSON.stringify(result));
              }
            );

            return (
              <Composer
                _jObject={this.props.jsonDataViewer}
                _xml={null}
                _json={_result}
                selectedtabname={'PaySlips'}
                datagridWidth={_result.Grid.$.width ?? undefined}
                subcardxml={subcardxml}
                showCommonCntxMenu={this._showcommon_cntxmneu}
              />
            );
          };

          itemsData.push({ header: { text: tab[i] }, content: cntnt });
        } else if (
          this.tabs
            .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
            .name.toLowerCase() === 'loans'
        ) {
          let cntnt: any = () => {
            var _result: any;
            parser.parseString(loan_data, function (err: any, result: any) {
              _result = JSON.parse(JSON.stringify(result));
            });

            return (
              <Composer
                _jObject={this.props.jsonDataViewer}
                _xml={null}
                _json={_result}
                selectedtabname={'Loan'}
                showCommonCntxMenu={this._showcommon_cntxmneu}
              />
            );
          };

          itemsData.push({ header: { text: tab[i] }, content: cntnt });
        } else if (
          this.tabs
            .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
            .name.toLowerCase() === 'leaves'
        ) {
          let cntnt: any = () => {
            var _result: any;
            parser.parseString(
              leave_type_data,
              function (err: any, result: any) {
                _result = JSON.parse(JSON.stringify(result));
              }
            );
            return (
              <Composer
                _jObject={this.props.jsonDataViewer}
                _xml={null}
                _json={_result}
                selectedtabname={'Leave'}
                showCommonCntxMenu={this._showcommon_cntxmneu}
              />
            );
          };
          itemsData.push({ header: { text: tab[i] }, content: cntnt });
        }

        // else if (
        //   this.tabs
        //     .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
        //     .name.toLowerCase() === 'attendance'
        // ) {
        //   let cntnt: any = () => {
        //     return (
        //       <Composer
        //         _jObject={this.props.jsonDataViewer}
        //         _xml={null}
        //         _json={null}
        //         selectedtabname={'Attendance'}
        //       />
        //     );
        //   };
        //   itemsData.push({ header: { text: tab[i] }, content: cntnt });
        // }
        else if (
          this.tabs
            .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
            .name.toLowerCase() === 'attendance'
        ) {
          let cntnt: any = () => {
            return (
              <Composer
                _jObject={this.props.jsonDataViewer}
                _xml={null}
                _json={null}
                selectedtabname={'attendance'}
                showCommonCntxMenu={this._showcommon_cntxmneu}
              />
            );
          };
          itemsData.push({ header: { text: tab[i] }, content: cntnt });
        } else if (
          this.tabs
            .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
            .name.toLowerCase() === 'skill'
        ) {
          let cntnt: any = () => {
            return (
              <Composer
                _jObject={this.props.jsonDataViewer}
                _xml={null}
                _json={null}
                selectedtabname={'skill'}
                showCommonCntxMenu={this._showcommon_cntxmneu}
              />
            );
          };
          itemsData.push({ header: { text: tab[i] }, content: cntnt });
        } else {
          itemsData.push({ header: { text: tab[i] }, content: '' });
        }
      }

      var el: any = document.getElementById('tabC');
      if (el !== null) {
        el.className = 'tabline';
      }

      if (tabInstance === undefined) {
        let obj: any = document.getElementById(
          'dataviewer-tabcomponent'
        ) as Element;
        if (obj !== null) {
          tabInstance = obj.ej2_instances[0] as TabComponent;
        }
      }

      if (tabInstance !== undefined && tabInstance !== null) {
        tabInstance.items = itemsData;
        tabInstance.overflowMode = 'Scrollable';
        tabInstance.refresh();

        var tabidx: any = 0;
        // if (localStorage.getItem('tabdataviewer-tabcomponent') !== undefined) {
        //   var idx = Object.keys(
        //     JSON.parse(
        //       localStorage.getItem('tabdataviewer-tabcomponent')!.toString()
        //     )
        //   ).indexOf('selectedItem');
        //   tabidx = Object.values(
        //     JSON.parse(
        //       localStorage.getItem('tabdataviewer-tabcomponent')!.toString()
        //     )
        //   ).at(idx);
        // }
        if (tabidx <= 0) {
          tabidx = this.props.selectedtabindex;
        }

        //this.applyStyle(tabInstance.tbItem[tabidx]);
        this.applyStyle(tabInstance.tbItem[tabidx]);

        //this.applyStyle(tabInstance.tbItem[0])
        // this.applyStyle(
        // 	tabInstance.tbItem[
        // 		this.props.selectedtabindex == undefined
        // 			? 0
        // 			: this.props.selectedtabindex
        // 	]
        // );
      }
    };

    // const handleDocumentSave = (jsnAttach: Object[]) => {
    //   this.props.handleDocumentSave(jsnAttach);
    // };

    //tabCreated();
    let selectedemployeename: string = '';
    if (this.props.jsonDataViewer) {
      switch (this.props.serviceName?.split('/')!.pop()!.toLowerCase()) {
        case 'person':
          selectedemployeename = this.props.jsonDataViewer['name'];
          break;

        case 'employee':
          selectedemployeename =
            this.props.jsonDataViewer.name +
            ' - ' +
            this.props.jsonDataViewer.person.name;
          break;

        default:
          break;
      }
    }

    const refreshTabs = (bln: boolean) => {
      this.isTabsRefresh = bln;
      //this.props.refreshSelector(true);
    };

    return (
      <div className='control-section card-control-section basic_card_layout'>
        <div className='e-card-resize-container'>
          <div className='row mx-0'>
            <div className='row mx-0 card-layout'>
              <div className='col-12'>
                <div
                  className=''
                  id='basic_card'
                  style={{
                    height: 'calc(100vh - 109px)',
                    justifyContent: 'start',
                    marginTop: '20px',
                    marginBottom: '0px',
                    overflow: 'hidden',
                  }}
                >
                  <div className='e-card-header'>
                    <div className='e-card-header-caption'>
                      <div className='row'>
                        {/* <div className='col-2 pt-2' > */}
                        <div className='col-12'>
                          {this.controlType &&
                          Number(this.controlType.value) ===
                            this._structure.UiComponents.Tabs ? (
                            //this.controlType && this.controlType.object === this._structure.UiComponents.Tabs ?
                            <>
                              <div
                                className='e-card-title'
                                style={{
                                  fontWeight: '500',
                                  fontSize: '20px',
                                  float: 'left',
                                }}
                              >
                                {
                                  /* {this.props.jsonDataViewer
                                  ? this.props.jsonDataViewer['name']
                                  : ''} */
                                  selectedemployeename
                                }
                              </div>
                              <div
                                key={'tabC'}
                                id='tabC'
                                className='tab-control-resize-container'
                              >
                                {/* <TabComponent heightAdjustMode='Auto' cssClass='e-accent dataviewer' ref={tab => tabInstance = tab} created={tabCreated} selected={selected} selecting={selected}>
                                                        </TabComponent> */}
                                <TabComponent
                                  id='dataviewer-tabcomponent'
                                  heightAdjustMode='Auto'
                                  cssClass='responsive-mode'
                                  //width={'auto'}
                                  ref={(tab) => (tabInstance = tab)}
                                  created={tabCreated}
                                  selected={selected}
                                  //enablePersistence={true}
                                  selectedItem={this.props.selectedtabindex}
                                  overflowMode='Scrollable'
                                ></TabComponent>
                              </div>
                              <div
                                className='e-addicon'
                                title='New'
                                style={{ display: 'none' }}
                              >
                                <i
                                  className='fa fa-circle-plus'
                                  style={{ fontSize: '50px' }}
                                  onClick={this.addnew_click.bind(this)}
                                ></i>
                              </div>
                            </>
                          ) : this.props.showganttchart == false &&
                            this.props.jsonDataViewer !== null ? (
                            <div id='dataviewerdocumentviewer'>
                              <DocumentViewer
                                //data={JSON.stringify(this.props.jsonDocument)}
                                //data={this.props.jsonDocument}
                                id='dataviewer'
                                jsonDataViewer={this.props.jsonDataViewer}
                                serviceName={this.props.serviceName}
                                //handleDocumentSave={handleDocumentSave}
                                selectedmenu={this.props.selectedmenu}
                                hideTitlebar={true}
                              />
                            </div>
                          ) : (
                            <>
                              {this.props.showganttchart == true ? (
                                <LeaveProcess />
                              ) : (
                                <>
                                  {this.props.showdashboard == true ? (
                                    <Dashboard />
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                        {/* <div className='col-10' >
                                                    {/* <TabComponent heightAdjustMode='Auto' id='defaultTab'>
                                                        <TabItemsDirective>
                                                            <TabItemDirective header={headertext[0]}
                                                                content={() => <div>
                                                                    Twitter
                                                                </div>} />
                                                            <TabItemDirective header={headertext[1]}
                                                                content={() => <div>
                                                                    Twitter
                                                                </div>} />
                                                            <TabItemDirective header={headertext[2]}
                                                                content={() => <div>
                                                                    Twitter
                                                                </div>} />
                                                        </TabItemsDirective>
                                                    </TabComponent> 
                                                    {/* <TabComponent heightAdjustMode='Auto' cssClass='e-accent' ref={tab => tabInstance = tab} created={tabCreated} >
                                                    </TabComponent>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                  <div className='e-card-content'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DataViewer;
