// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege

// * -----------------------------------------------------------------------------------------------------------------2.Initialize

// * ------------------------------------------------------------------------------------------------------------------3.Style

// * -----------------------------------------------------------------------------------------------------------------2.Initialize

export default function AppMobile() {
  // * ------------------------------------------------------------------------------------------------------------------5.State

  // * ------------------------------------------------------------------------------------------------------------------Adapter

  // *  --------------------------------------------------------------------------------------------------------------------HTML
  return (
    <div>
        <h1>MOBILE DEVICE DETECTED</h1>
    </div>
  )
}
