import React from 'react';

import axios from 'axios';
import {
  ContextMenuComponent,
  MenuItemModel,
  MenuEventArgs,
} from '@syncfusion/ej2-react-navigations';
import Common from '../../Util/Common';
import './PeriodSelector.css';
interface props {
  id: string;
  handleInputChange: any;
  _jObject: any;
  showEmployeePeriodsOnly: boolean;
  showLastPeriod: boolean;
}

const { REACT_APP_ENDPOINT_FOXSYSTEMSERVICE } = process.env;

class PeriodSelector extends React.Component<props> {
  private user: any;
  private cMenu: ContextMenuComponent | null = null;
  private menuItem: MenuItemModel[] = [];

  constructor(props: props) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
  }
  state = {
    Periods: [],
    currPeriodId: undefined,
    curr_period: null,
    periodName: '',
    menuItem: [],
  };

  componentDidMount(): void {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Periods`,
      '',
      Common.getToken() || '',
      this.user
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let date = new Date();
        let currPeriod: any = null;
        let empPeriod: any = response;
        if (this.props.showLastPeriod === false) {
          empPeriod = empPeriod.filter(
            (x: any) => new Date(x.dateEnd).getTime() < new Date().getTime()
          );
          // empPeriod.length = empPeriod.length - 1;
        } else {
          empPeriod = empPeriod.filter(
            (x: any) => new Date(x.dateStart).getTime() <= new Date().getTime()
          );
        }

        if (this.props.showEmployeePeriodsOnly) {
          let appointDate = new Date();
          if (
            this.props._jObject.contract &&
            this.props._jObject.contract.length > 0
          ) {
            appointDate = new Date(
              this.props._jObject.contract.sort((a: any, b: any) => {
                return (
                  new Date(a.dateStart).getTime() -
                  new Date(b.dateStart).getTime()
                );
              })[0]['dateStart']
            );
          }

          empPeriod = empPeriod.filter(
            (x: any) => new Date(x.dateStart) >= appointDate
          );
        }
        empPeriod.filter((x: any) => {
          if (date >= new Date(x.dateStart) && date <= new Date(x.dateEnd)) {
            currPeriod = x;
          }
        });

        if (currPeriod === null) {
          currPeriod = empPeriod.slice(-1)[0];
        }

        let lastSelectedPeriod: any =
          localStorage.getItem('lastSelectedPeriod');

        if (lastSelectedPeriod) {
          lastSelectedPeriod = JSON.parse(lastSelectedPeriod);

          if (
            new Date(lastSelectedPeriod.dateStart).getTime() <
              new Date(currPeriod.dateStart).getTime() &&
            new Date(lastSelectedPeriod.dateStart).getTime() >=
              new Date(empPeriod[0].dateStart).getTime()
          ) {
            currPeriod = lastSelectedPeriod;
          }
        }
        let menuItem: MenuItemModel[] = this.getYearData(empPeriod);

        this.setState({
          Periods: empPeriod,
          currPeriodId: currPeriod.id,
          curr_period: currPeriod,
          periodName: currPeriod.serial,
          menuItem: menuItem,
        });
        localStorage.setItem(this.props.id, JSON.stringify(empPeriod));
        this.processPeriodClick(currPeriod.id);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  handleContextMenu = (e: any) => {
    if (this.cMenu) {
      let obj1: HTMLElement = document.getElementById(
        this.props.id
      ) as HTMLElement;

      let c_area: any = obj1.getBoundingClientRect();

      this.cMenu.open(c_area.top + c_area.height, c_area.left + 220);
    }
  };

  private getYearData(Periods: any) {
    let jsnPeriods: MenuItemModel[] = [];

    const years: number[] = Periods.forEach((period: any) => {
      let list: any = jsnPeriods.filter(
        (x: any) => x.text == new Date(period.dateStart).getFullYear()
      );

      if (list.length <= 0) {
        jsnPeriods.unshift({
          text: new Date(period.dateStart).getFullYear().toString(),
          id: period.id,

          items: [
            {
              text: period.name,
              id: period.id,
            },
          ],
        });
      } else {
        list[0].items.push({
          text: period.name,
          id: period.id,
        });
      }
    });

    return jsnPeriods;
  }

  processPeriodChange = (periodData: any) => {
    this.props.handleInputChange(periodData);
  };

  processPeriodClick(id: string) {
    let itms: any = JSON.parse(localStorage.getItem(this.props.id) ?? '');
    let itm: any = itms.filter((x: any) => x.id == id);

    localStorage.setItem('lastSelectedPeriod', JSON.stringify(itm[0]));
    this.setState(
      {
        periodName: itm[0].serial,
        curr_period: itm[0],
      },
      () => {
        this.processPeriodChange(itm[0]);
      }
    );
  }

  handlePeriodClick = (e: any) => {
    this.processPeriodClick(e.element.id);
    e.cancel = true;
  };

  //previous button handler
  handleLastPeriod = (e: any) => {
    let periodData: any[] = this.state.Periods;

    let lastPeriod: any;
    if (periodData) {
      lastPeriod = periodData.slice(0, 1)[0];
    }
    this.setState({
      periodName: lastPeriod.serial,
      curr_period: lastPeriod,
    });
    this.processPeriodClick(lastPeriod.id);
  };

  //handle ascending

  handlePreviousPeriod = () => {
    let cp: any = this.state.curr_period;
    let periodData: any[] = this.state.Periods;
    let newPeriod: any;
    let currMonth: any;
    let ascSort: any = periodData.sort((a: any, b: any) => {
      return new Date(a.dateStart).getTime() - new Date(b.dateStart).getTime();
    });

    currMonth = ascSort.filter((x: any) => x.dateEnd < cp.dateStart);

    if (currMonth.length > 0) {
      newPeriod = currMonth.slice(-1)[0];

      this.setState({
        periodName: newPeriod.serial,
        curr_period: newPeriod,
      });

      this.processPeriodClick(newPeriod.id);
    }
  };

  handleNextPeriod = () => {
    let cp: any = this.state.curr_period;
    let periodData: any[] = this.state.Periods;

    let currMonth: any[];
    let ascSort: any = periodData.sort((a: any, b: any) => {
      return new Date(a.dateStart).getTime() - new Date(b.dateStart).getTime();
    });
    currMonth = ascSort.filter((x: any) => x.dateStart > cp.dateEnd);

    let newPeriod: any;
    if (currMonth.length > 0) {
      newPeriod = currMonth.slice(0, 1)[0];
      this.setState({
        periodName: newPeriod.serial,
        curr_period: newPeriod,
      });

      this.processPeriodClick(newPeriod.id);
    }
  };

  handleFirstPeriod = () => {
    let firstPeriod: any[] = this.state.Periods;

    let fp: any;
    if (firstPeriod) {
      fp = firstPeriod.slice(-1)[0];
    }
    this.setState({
      periodName: fp.serial,
      curr_period: fp,
    });
    this.processPeriodClick(fp.id);
  };
  render() {
    return (
      <>
        {this.state.curr_period && (
          <>
            <div className='period-selector'>
              <i
                className='periodSelector-icons fa-solid fa-backward-step fs-6'
                title='First'
                onClick={this.handleLastPeriod}
              ></i>
              <i
                className='periodSelector-icons fa-solid fa-caret-left fs-6 mx-2'
                title='Previous'
                onClick={this.handlePreviousPeriod}
              ></i>
              <input
                id={this.props.id}
                onClick={this.handleContextMenu}
                value={
                  this.state.curr_period
                    ? `Period ${
                        this.state.periodName
                      } - From ${Common.formatDate(
                        new Date(this.state.curr_period!['dateStart']),
                        'dd MMM yyyy'
                      )} to ${Common.formatDate(
                        new Date(this.state.curr_period!['dateEnd']),
                        'dd MMM yyyy'
                      )}`
                    : ''
                }
                className='periodSelector-input'
                readOnly
              />
              <i
                className='periodSelector-dropdownIcons fa-solid fa-angle-down'
                onClick={this.handleContextMenu}
              ></i>
              <i
                className='periodSelector-icons fa-solid fa-caret-right mx-2 fs-6'
                title='Next'
                onClick={this.handleNextPeriod}
              ></i>

              <i
                className='periodSelector-icons fa-solid fa-forward-step fs-6'
                title='Last'
                onClick={this.handleFirstPeriod}
              ></i>
            </div>
            <div>
              <ContextMenuComponent
                id={this.props.id + 'contextmenu'}
                ref={(scope) => (this.cMenu = scope as ContextMenuComponent)}
                items={this.state.menuItem}
                select={this.handlePeriodClick}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

export default PeriodSelector;
