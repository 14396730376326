import React, { useEffect, useState } from 'react';
import { Stepper } from 'react-form-stepper';
import Identity from './Identity';
import Details from './Details';
import Confirm from './Confirm';
import Message from './Message';

interface props {
  name: string;
  email: string;
}

// interface Props {
//   dialogExit: () => void
// }

function CustomStepper(props: any) {
  return (
    <Stepper
      {...props}
      connectorStateColors={true}
      connectorStyleConfig={{
        completedColor: '#ed7d31',
        activeColor: '#ed7d31',
        disabledColor: '#f4b285',
        size: 7,
        stepSize: '2.5em',
      }}
      styleConfig={{
        activeBgColor: '#ed7d31',
        completedBgColor: '#ed7d31',
        inactiveBgColor: '#f4b285',
        activeTextColor: '#111',
        completedTextColor: '#222',
        inactiveTextColor: '#444',
        //size:'14px'
        size: '2.7em',
        labelFontSize: '1rem',
      }}

      //stepClassName={'stepper__step fa-solid fa-user fs-5'}
    />
  );
}

function Regwizard(props: any) {
  const [activeStep, setActiveStep] = useState(1);

  const [formData, setFormData] = useState({
    email: props.email,
    name: props.name,
    birthday: '',
    gender: '',
    phone: '',
    company: '',
    access_code: '',
  });
  const steps = [
    { label: 'Identity' },
    { label: 'Personal Data' },
    { label: 'Access Code' },
    { label: 'Log in' },
  ];

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleInputData = (input: any) => (e: any) => {
    const { value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  function getSectionComponent() {
    switch (activeStep) {
      case 0:
        return (
          <Identity
            nextStep={nextStep}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      case 1:
        return (
          <Details
            //exitHandler={Props.dialogExit}
            email={props.email}
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      case 2:
        return (
          <Confirm
            email={props.email}
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      case 3:
        return (
          <Message
            email={props.email}
            handleFormData={handleInputData}
            prevStep={prevStep}
            values={formData}
          />
        );
      default:
        return null;
    }
  }

  return (
    <>
      <div className='row mx-2'>
        <div className='col-12 fs-3' style={{ marginLeft: '10%' }}>
          Sign Up
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <CustomStepper steps={steps} activeStep={activeStep} />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>{getSectionComponent()}</div>
      </div>
    </>
  );
}

export default Regwizard;
