// * ---------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SUHAIL - 2023-03-15 - 330 - aknowlege - TreeGrid
// * SUHAIL - 2023-03-21 - 440 - performance issue on person service to fetch all person all information
// * SUHAIL - 2023-03-31 - 160 - research on componentdidmount and componentdidupdate
// * SUHAIL - 2023-05-04 - 95 - research on infinite scroll on selector menu
// * SUHAIL - 2023-05-10 - 180 - weekly status review meeting
// * SUHAIL - 2023-05-10 - 120 - meeting with fabio on updated subscriber service changes in sidebar
// * SUHAIL - 2023-05-10 - 180 - research on add record in person selector
// * SUHAIL - 2023-05-15 - 240 - Adapt Selector with updated selector structure
// * SUHAIL - 2023-05-16 - 120 - New API End Point Set to save person bio information in database
// * SUHAIL - 2023-05-17 - 190 - New API End Point Set to save person bio information in database
// * SUHAIL - 2023-05-18 - 150 - Implement Save bio transaction process in person service
// * SUHAIL - 2023-05-18 - 60 - working with avinash on showing multiple holiday names on tooltip if more than one holiday comes on same day
// * SUHAIL - 2023-05-18 - 150 - meeting with fabio on time schedule and testing frontend with local database at fabio end
// * SUHAIL - 2023-05-19 - 300 - meeting with fabio on testing local environment from fabio machine
// * SUHAIL - 2023-05-19 - 180 - working on updating the all projects with package version 1.5.24
// * SUHAIL - 2023-05-22 - 150 - meeting with fabio on testing new structure on fabio local machine
// * SUHAIL - 2023-05-22 - 20 - weekly status meeting
// * SUHAIL - 2023-05-22 - 310 - Adapt login flow to the new structure 1.5.26
// * SUHAIL - 2023-06-08 - 60 - sprint planning meeting
// * SUHAIL - 2023-06-08 - 100 - meeting with fabio on employee issue for demo nmb
// * SUHAIL - 2023-06-08 - 230 - Provide grouping column settings in xml to display dossier group by Account
// * SUHAIL - 2023-06-09 - 245 - meeting with fabio on image saving issue and error on employee branch filter
// * SUHAIL - 2023-06-09 - 45 - sprint review meeting
// * SUHAIL - 2023-06-09 - 130 - research on request header too large for cisp when company logo is set in user object
// * SUHAIL - 2023-06-09 - 60 - working on error on employee branch click for cisp
// * SUHAIL - 2023-06-12 - 45 - weekly status meeting
// * SUHAIL - 2023-06-12 - 60 - meeting with fabio on data and image issue in backend
// * SUHAIL - 2023-06-12 - 255 - updating the packages 1.5.34 and testing and working adding person name and employee details
// * SUHAIL - 2023-06-14 - 120 - working on Slowness Issue on employee calendar
// * SUHAIL - 2023-06-14 - 50 - weekly status meeting
// * SUHAIL - 2023-06-14 - 150 - hide line between two columns on selector panela and dataviewer
// * SUHAIL - 2023-06-19 - 75 - weekly status review meeting
// * SUHAIL - 2023-06-19 - 255 - working with sourav on image and retrieve from and to from backend api
// * SUHAIL - 2023-06-21 - 60 - weekly status review meeting
// * SUHAIL - 2023-06-21 - 30 - meeting with fabio on updating all database and deploying frontend on digital ocean
// * SUHAIL - 2023-06-22 - 30 - meeting with fabio on attendance api end point
// * SUHAIL - 2023-06-22 - 70 - sprint planning meeting
// * SUHAIL - 2023-06-22 - 200 - research on pdf viewer component and updating packages to 1.5.37
// * SUHAIL - 2023-06-23 - 150 - fixing issue of contex menu sub items not displaying properly
// * SUHAIL - 2023-06-28 - 40 - meeting with fabio on word and pdf document not coming on his machine due to service name port number is different
// * SUHAIL - 2023-06-28 - 60 - weekly status review meeting
// * SUHAIL - 2023-06-28 - 230 - working on displaying document in word/pdf format depending on button click
// * SUHAIL - 2023-06-28 - 150 - working on fixing context menu issue when more than 2 level items
// * SUHAIL - 2023-06-30 - 30 - updating image end points in fox system service to adapt with upgraded structure 1.5.41
// * SUHAIL - 2023-06-30 - 120 - Show document in word format on Word switch button clicked
// * SUHAIL - 2023-06-30 - 330 - Research on open word document from local machine
// * SUHAIL - 2023-09-15 - 30 - meeting with fabio and saurav on showing the issue of transaction not updating in database and payslip slab taxable heads showing
// * SUHAIL - 2023-09-15 - 30 - attended Sprint Review and Retrospective meeting
// * SUHAIL - 2023-09-15 - 420 - working on fixing errors on selecting mail merge fields to insert into document and applying mail merge values
// * SUHAIL - 2023-09-18 - 65 - attended weekly status meeting
// * SUHAIL - 2023-09-18 - 95 - working on recruitment attachment mismatch issue with pinkal
// * SUHAIL - 2023-09-18 - 320 - Show only selected category in selector context menu when selector list is filtered with any sidebar menu sub-category
// * SUHAIL - 2023-09-19 - 120 - Show only selected category in selector context menu when selector list is filtered with any sidebar menu sub-category
// * SUHAIL - 2023-09-19 - 360 - Hide the correspondence category in create correspondence dialog box when clicked on New Item With option from selector context menu
// * SUHAIL - 2023-09-20 - 30 - meeting with fabio and amos for login issue and json id = 0 issue from api and meeting with fabio on reviewing filtered correspondence context menu
// * SUHAIL - 2023-09-20 - 80 - attended weekly status meeting
// * SUHAIL - 2023-09-20 - 370 - Hide the correspondence category in create correspondence dialog box when clicked on New Item With option from selector context menu
// * Sandeep - 2023-09-15 - 300 - Working on adding person by creating popup and api calling method.
// * Sandeep - 2023-09-16 - 420 - Doing amendments on the adding new person and used the dialogform template provided by gridvew
// * Sandeep - 2023-09-18 - 400 - Doing changes in diaglog form template for adding new person.
// * Sandeep - 2023-09-19 - 420 - Worked on css and other changes for person (Chaging the Header/save & Cancel Button css) and doing testing.
// * Sandeep - 2023-09-20 - 430 - Worked on Employee Popup adding person set api and other changes for saving person
// * Sandeep - 2023-09-21 - 430 - Worked on Employee Popup changes for adding new employee and also add person if not availabel (defualt selection not working (tryig to fix))
// * SUHAIL - 2023-09-21 - 25 - meeting with fabio and sourav on reviewing payslip component
// * SUHAIL - 2023-09-21 - 455 - Dropdown Tree option to select either company from company list or person from person list on add new correspondence dialog box
// * SUHAIL - 2023-09-22 - 70 - meeting with fabio and sourav on registration page discussion
// * SUHAIL - 2023-09-22 - 130 - Dropdown Tree option to select either company from company list or person from person list on add new correspondence dialog box
// * SUHAIL - 2023-09-22 - 280 - Option to create document from selector context menu click
// * SUHAIL - 2023-09-25 - 90m - attended weekly status meeting
// * SUHAIL - 2023-09-25 - 390 - Option to create document from selector context menu click
// * SUHAIL - 2023-09-29 - 30 - attended sprint review and retrospective meeting
// * SUHAIL - 2023-09-29 - 50 - meeting with fabio on new menu templates and  adding checkbox to grid context menu
// * SUHAIL - 2023-09-29 - 400 - Issue of paragraph dialog box not coming on top when word document is opened in data viewer word editor as well as in create word template edito

// * ---------------------------------------------------------------------------------------------------------------2.Initialize
// * SUHAIL - 2023-02-27 - 20
// * SUHAIL - 2023-03-02 - 10
// * SUHAIL - 2023-03-28 - 10
import React, { Component } from 'react';
import {
  PaneDirective,
  PanesDirective,
  SplitterComponent,
} from '@syncfusion/ej2-react-layouts';
// import {
//   TreeGridComponent,
//   ColumnsDirective,
//   ColumnDirective,
//   Filter,
//   Sort,
//   Reorder,
//   Inject,
//   ITreeData,
//   ContextMenu,
//   Edit,
//   extendArray,
//   InfiniteScroll,
// } from '@syncfusion/ej2-react-treegrid'
import DataViwer from './DataViewer';
import SelectorSearch from './SelectorSearch';
import Common, {
  GridColumnStructure,
  JsonTree,
  SaveDto,
} from '../../Util/Common';
import {
  ContextMenuItemModel,
  DialogEditEventArgs,
  GridComponent,
  QueryCellInfoEventArgs,
  SearchSettingsModel,
} from '@syncfusion/ej2-react-grids';
import { BeforeOpenCloseEventArgs } from '@syncfusion/ej2-react-inputs';
import { createElement, enableRipple, closest } from '@syncfusion/ej2-base';
import DataGrid from '../DataGrid';
import DialogFormTemplate from '../DialogFormTemplate';
import { Dialog, DialogUtility } from '@syncfusion/ej2-react-popups';
import { DateTime } from '@syncfusion/ej2-react-charts';
import { createCheckBox } from '@syncfusion/ej2-react-buttons';
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
//import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
//import Layout from '../../Pages/Layout';

const {
  REACT_APP_ENDPOINT_API,
  REACT_APP_ENDPOINT_SUBSCRIBER,
  REACT_APP_ENDPOINT_SUBSCRIBERPATH,
  REACT_APP_ENDPOINT_XMLPATH,
  REACT_APP_ENDPOINT_SIDEBARPATH,
  REACT_APP_ENDPOINT_FULLJSONPATH,
} = process.env;

// const countries = [
//   {
//     name: 'TEST1',
//     states: [],
//   },
//   {
//     name: 'TEST2',
//     states: [],
//   },
//   {
//     name: 'TEST3',
//     states: [],
//   },
// ];
//let data: any = [];

const data = [
  {
    employee: {
      id: 100011,
      name: 'E000001',
    },
    date: '1984-10-09T00:00:00',
    age: 39,
    id: 100012,
    name: 'Kari TK Taylor',
  },
];

/*
UNCOMMENT IF XML2JS ARE REQUIRED
*/
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

// * ------------------------------------------------------------------------------------------------------------------3.Style

// * ------------------------------------------------------------------------------------------------------------------4.Api

// * SUHAIL - 2023-03-28 - 10
// * SUHAIL - 2023-03-29 - 10
// * SUHAIL - 2023-04-05 - 10
// interface columnstructure {
//   idx: number
//   object: number
//   label: string
//   source: string
//   width: number | undefined
// }
// * SUHAIL - 2023-04-06 - 10
interface Props {
  isSelectorLoading: boolean;
  serviceName: string;
  jsonSelector: any;
  rootmenu: any;
  currmenu: any;
  selectedAllocations: JsonTree[];
  selectorRowSelected: any;
}
// * ------------------------------------------------------------------------------------------------------------------7.Adapter
// * SUHAIL - 2023-02-27 - 10
// * SUHAIL - 2023-04-04 - 20
// * SUHAIL - 2023-04-06 - 10
class SelectorPanel extends React.Component<Props> {
  private _structure: any;
  private columnSet: any = null;
  private user: any;
  private fulljson: any;
  private selectorSize: number = 0;
  private onnodemenu: any = null;
  private onleafmenu: any = null;
  private onexpandmenu: any = null;
  private selectedmenu: any = null;
  private contextMenuItems: ContextMenuItemModel[] = [];
  private jsonSelectorFiltered: JsonTree[] = [];
  //private childDataViewer: any = null;
  private selectedRowIdx: number = -1;
  private dlgcntxmenuitems: any;
  private dlgaccountid: number;
  private dlgaccountname: string;
  private arrIdFilter: string[] = [];
  private GridInstance: GridComponent | any;
  private objFilter: any;
  private objFilterActive: any;
  private objFilterTerminated: any;
  private objMenuNew: any;
  private objMenuNewWith: any;
  private objMenuSeparator: any;

  constructor(props: any) {
    super(props);
    //this.childDataViewer = React.createRef();
    this.handleSelectorRowClick = this.handleSelectorRowClick.bind(this);

    // ------------------------------------------------------------EVENT BINDING

    // SAMPLE ON EVENT BINDING
    // this.onInputFocus = this.onInputFocus.bind(this);
    //this.Selector = this.Selector.bind(this);
    Common.ClearStatusBar();
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.user = JSON.parse(Common.getUser() ?? '');
    this.fulljson = JSON.parse(Common.getFullJson() ?? '');
    this.dlgcntxmenuitems = null;
    this.dlgaccountid = 0;
    this.dlgaccountname = '';
    //this.InitializeObjects(props);

    let flter: any = Common.FindJsonByObjectValue(
      this.fulljson,
      '_filter_',
      null
    );
    if (flter) {
      flter = Common.FindJsonByObjectId(this.fulljson, flter.object, this.user);
      if (flter) {
        this.objFilter = flter.children;
      }
    }

    this.objFilterActive = Common.FindJsonByObjectValue(
      flter ? flter.children : this.fulljson,
      '_active_',
      null
    );
    if (!this.objFilterActive) {
      this.objFilterActive = {};
      this.objFilterActive['object'] = 85013;
    }

    this.objFilterTerminated = Common.FindJsonByObjectValue(
      flter ? flter.children : this.fulljson,
      '_terminated_',
      null
    );
    if (!this.objFilterTerminated) {
      this.objFilterTerminated = {};
      this.objFilterTerminated['object'] = 85014;
    }

    this.objMenuNew = Common.FindJsonByObjectValue(
      this.fulljson,
      '_new_',
      null
    );
    if (!this.objMenuNew) {
      this.objMenuNew = {};
      this.objMenuNew['object'] = 85001;
    }

    this.objMenuNewWith = Common.FindJsonByObjectValue(
      this.fulljson,
      '_newwith_',
      null
    );
    if (!this.objMenuNewWith) {
      this.objMenuNewWith = {};
      this.objMenuNewWith['object'] = 84016;
    }

    this.objMenuSeparator = Common.FindParentJsonByIdKeyChildren(
      this.fulljson,
      this._structure.MenuElements.CntxtMenuElements.CntxMenuSeparator,
      'object',
      'children'
    );
    if (!this.objMenuSeparator) {
      this.objMenuSeparator = {};
      this.objMenuSeparator['object'] = 85010;
    }

    if (props.serviceName.split('/')!.pop()!.toLowerCase() === 'employee') {
      if (this.objFilterActive) {
        this.arrIdFilter = [this.objFilterActive.object.toString()];
      }
    } else {
      this.arrIdFilter = [];
    }
  }

  // * ------------------------------------------------------------------------------------------------------------------5.State
  // * SUHAIL - 2023-03-29 - 10
  // * SUHAIL - 2023-04-05 - 10
  state = {
    isLoading: false,
    //jsonSelector: [],
    //jsonDataViewer: [],
    //serviceName: '',
    // columnSet: {},
  };

  // * ---------------------------------------------------------------------------------------------------------------2.Initialize
  // * SUHAIL - 2023-04-05 - 80
  // * SUHAIL - 2023-04-06 - 120
  // * SUHAIL - 2023-04-07 - 120
  // * SUHAIL - 2023-05-05 - 120
  private InitializeObjects(props: any): any {
    if (this.props.rootmenu !== null) {
      if (this.props.rootmenu.children.length > 0) {
        this.onnodemenu = Common.FindJsonByRelationFieldId(
          this.props.rootmenu.children,
          this._structure.SideBarActions.OnNode
        );
        this.onleafmenu = Common.FindJsonByRelationFieldId(
          this.props.rootmenu.children,
          this._structure.SideBarActions.OnLeaf
        );
        this.onexpandmenu = Common.FindJsonByRelationFieldId(
          this.props.rootmenu.children,
          this._structure.SideBarActions.OnExpand
        );
        this.selectedmenu =
          this.props.currmenu.rFieldId === this._structure.Element.Child
            ? this.onleafmenu
            : this.onnodemenu;

        let colset: any = Common.FindJsonByRelationFieldId(
          this.selectedmenu.children,
          this._structure.Parameters.ColumnsSet
        ); //9180 = Column Set
        if (colset !== null) {
          //this.setState({ columnset: colset });
          this.columnSet = colset;
        } else {
          // this.setState({ columnset: {} });
          this.columnSet = null;
        }

        let sizeElemntId: any = Common.FindJsonByRelationFieldId(
          this.selectedmenu.children,
          this._structure.Parameters.Properties
        ); //9094 = Properties
        if (sizeElemntId !== null) {
          let sizeElemnt: any = Common.FindJsonByObjectId(
            this.selectedmenu.children,
            sizeElemntId.object,
            this.user,
            this._structure.Properties.Width
          );
          if (sizeElemnt !== null) {
            this.selectorSize = sizeElemnt.value;
          }
        }

        let cntxmenu = Common.FindJsonByRelationFieldId(
          this.selectedmenu.children,
          this._structure.SideBarComponents.CntxMenu
        ); //9150 = CntxMenu
        if (cntxmenu !== null) {
          let cntxmenuitems: ContextMenuItemModel[] | undefined =
            Common.GetContextMenu(
              cntxmenu.children,
              this._structure,
              this.user
            );
          let selectedcntxmenu: any = Common.FindJsonByIdKeyChildren(
            cntxmenuitems,
            this.props.currmenu.object,
            'id',
            'items'
          );
          if (selectedcntxmenu) {
            let selecedparentsids: number[] = [selectedcntxmenu.id];
            if (selectedcntxmenu.items.length > 0) {
              let delnew: any = cntxmenuitems?.filter(
                (x: any) => x.id == this.objMenuNew.object
              );
              if (delnew.length > 0) {
                cntxmenuitems?.splice(cntxmenuitems.indexOf(delnew[0]), 1);
              }

              let delsepr: any = cntxmenuitems?.filter(
                (x: any) => x.id == this.objMenuSeparator.object
              );
              if (delsepr.length > 0) {
                cntxmenuitems?.splice(cntxmenuitems.indexOf(delsepr[0]), 1);
              }
            }

            let selectedparentsid: any = Common.FindParentJsonByIdKeyChildren(
              cntxmenuitems,
              selectedcntxmenu.id,
              'id',
              'items',
              null
            );

            if (selectedparentsid) {
              selectedparentsid = selectedparentsid.id;
              do {
                selecedparentsids.push(selectedparentsid);

                selectedparentsid = Common.FindParentJsonByIdKeyChildren(
                  cntxmenuitems,
                  selectedparentsid,
                  'id',
                  'items',
                  null
                );
                if (selectedparentsid) {
                  selectedparentsid = selectedparentsid.id;
                } else {
                  selectedparentsid = 0;
                }
              } while (Number(selectedparentsid) > 0);
              //selecedparentsids.pop();
            }

            selecedparentsids.forEach((x: any, idx: number) => {
              if (idx === selecedparentsids.length - 1) {
                let p: any = Common.FindJsonByIdKeyChildren(
                  cntxmenuitems,
                  Number(x),
                  'id',
                  'items'
                );
                if (p) {
                  if (selectedcntxmenu.items.length > 0) {
                    p.items = selectedcntxmenu.items;
                  } else {
                    cntxmenuitems?.splice(cntxmenuitems.indexOf(p), 1);
                  }
                }
              } else {
                let r: any = Common.DeleteRestJsonById(
                  cntxmenuitems,
                  x,
                  'id',
                  'items',
                  0
                );
              }
            });
          } else {
            if (
              this.props.serviceName.split('/')!.pop()!.toLowerCase() ===
              'correspondence'
            ) {
              let delnew: any = cntxmenuitems?.filter(
                (x: any) => x.id == this.objMenuNew.object
              );
              if (delnew.length > 0) {
                cntxmenuitems?.splice(cntxmenuitems.indexOf(delnew[0]), 1);
              }

              let delsepr: any = cntxmenuitems?.filter(
                (x: any) => x.id == this.objMenuSeparator.object
              );
              if (delsepr.length > 0) {
                cntxmenuitems?.splice(cntxmenuitems.indexOf(delsepr[0]), 1);
              }
            }
          }
          if (cntxmenuitems) this.contextMenuItems = cntxmenuitems;

          //   cntxmenu.children
          //     .filter(
          //       (x: any) =>
          //         x.rFieldId === this._structure.Element.Child ||
          //         x.rFieldId === this._structure.Element.Group
          //     )
          //     .forEach((x: any) => {
          //       if (
          //         this.contextMenuItems.filter(
          //           (xx: any) => xx.id === x.object.toString()
          //         ).length <= 0
          //       ) {
          //         let sprtr: boolean =
          //           x.value.toLowerCase() === 'separator' ? true : false;
          //         if (x.rFieldId === this._structure.Element.Child) {
          //           if (
          //             cntxmenu.children.filter(
          //               (xx: any) => xx.object === x.object
          //             ).length > 1
          //           ) {
          //             let itm: any = Common.FindJsonByObjectId(
          //               cntxmenu.children,
          //               x.object,
          //               this.user
          //             );
          //             if (itm !== null) {
          //               this.contextMenuItems.push({
          //                 text: sprtr ? '' : itm.value,
          //                 id: itm.object.toString(),
          //                 separator: sprtr,
          //               });
          //             }
          //           } else {
          //             this.contextMenuItems.push({
          //               text: sprtr ? '' : x.value,
          //               id: x.object.toString(),
          //               separator: sprtr,
          //             });
          //           }
          //         } else if (x.rFieldId === this._structure.Element.Group) {
          //           let subcontextMenuItems: ContextMenuItemModel[] = [];
          //           x.children
          //             .filter(
          //               (xx: any) =>
          //                 xx.rFieldId === this._structure.Element.Child ||
          //                 xx.rFieldId === this._structure.Element.Group
          //             )
          //             .forEach((x: any) => {
          //               let sprtr: boolean =
          //                 x.value.toLowerCase() === 'separator' ? true : false;
          //               if (x.rFieldId === this._structure.Element.Child) {
          //                 if (
          //                   cntxmenu.children.filter(
          //                     (xx: any) => xx.object === x.object
          //                   ).length > 1
          //                 ) {
          //                   let itm: any = Common.FindJsonByObjectId(
          //                     cntxmenu.children,
          //                     x.object,
          //                     this.user
          //                   );
          //                   if (itm !== null) {
          //                     subcontextMenuItems.push({
          //                       text: sprtr ? '' : itm.value,
          //                       id: itm.object.toString(),
          //                       separator: sprtr,
          //                     });
          //                   }
          //                 } else {
          //                   subcontextMenuItems.push({
          //                     text: sprtr ? '' : x.value,
          //                     id: x.object.toString(),
          //                     separator: sprtr,
          //                   });
          //                 }
          //               }
          //             });
          //           this.contextMenuItems.push({
          //             text: sprtr ? '' : x.value,
          //             id: x.object.toString(),
          //             separator: sprtr,
          //             items: subcontextMenuItems,
          //           });
          //         }
          //       }
          //     });
        }

        if (
          this.onexpandmenu !== null &&
          this.props.jsonSelector !== null &&
          this.props.jsonSelector.length > 0 &&
          this.props.selectedAllocations.length > 0 &&
          this.props.isSelectorLoading === false
        ) {
          let elementlink: any = Common.FindJsonByRelationFieldId(
            this.onexpandmenu.children,
            this._structure.SideBarComponents.Key
          );
          let elementlinkname: any = Common.FindJsonByObjectId(
            this.fulljson,
            Number(elementlink.value),
            this.user
          );
          //this.jsonSelectorFiltered = this.props.jsonSelector.filter((x: any) => x.contract.some((xx: any) => this.props.selectedAllocations.some((t) => t.object === xx[Common.toCamelCase(elementlink.value)].id)));
          if (
            elementlinkname.object === this._structure.Field.Identif.Branch ||
            elementlinkname.object === this._structure.Field.Identif.Country
          ) {
            this.jsonSelectorFiltered = this.props.jsonSelector.filter(
              (x: any) =>
                this.props.selectedAllocations.some((t) => {
                  //  t.object ===
                  //    x.contract.sort((a: any, b: any) => {
                  //      return (
                  //        new Date(b.startDate).getTime() -
                  //        new Date(a.startDate).getTime()
                  //      );
                  //    })[0][Common.toCamelCase(elementlinkname.value)].id;
                  const cntrct: any = x.contract.sort((a: any, b: any) => {
                    return (
                      new Date(b.dateStart).getTime() -
                      new Date(a.dateEnd).getTime()
                    );
                  })[0];

                  return cntrct[Common.toCamelCase(elementlinkname.value)]
                    ? cntrct[Common.toCamelCase(elementlinkname.value)].id ===
                        t.object
                    : false;
                })
            );
          } else if (
            elementlinkname.object === this._structure.Field.Identif.Project
          ) {
            this.jsonSelectorFiltered = this.props.jsonSelector.filter(
              (x: any) =>
                this.props.selectedAllocations.some((t) => {
                  const cntrct: any = x.contract.sort((a: any, b: any) => {
                    return (
                      new Date(b.dateStart).getTime() -
                      new Date(a.dateEnd).getTime()
                    );
                  })[0];

                  return cntrct[Common.toCamelCase(elementlinkname.value)]
                    ? cntrct[Common.toCamelCase(elementlinkname.value)].some(
                        (x: any) => x.id === t.object
                      )
                    : false;
                })
            );
          } else {
            this.jsonSelectorFiltered = this.props.jsonSelector.filter(
              (x: any) =>
                // this.props.selectedAllocations.some((t) => {
                //   if (t.children!.length > 0) {
                //     return t.children!.some(
                //       (c: any) =>
                //         c.object ===
                //         x[Common.toCamelCase(elementlinkname.value)].id
                //     );
                //   } else {
                //     return (
                //       t.object ===
                //       x[Common.toCamelCase(elementlinkname.value)].id
                //     );
                //   }
                // })
                {
                  return this.somefilter(
                    this.props.selectedAllocations,
                    x,
                    elementlinkname
                  );
                }
            );
          }
        }
      }
    }
  }

  public somefilter(arr: any, x: any, elementlinkname: any) {
    return arr.some((t: any) => {
      if (
        t.children?.length > 0 &&
        t.children?.filter(
          (x: any) =>
            x.rFieldId === this._structure.Element.Group ||
            x.rFieldId === this._structure.Element.Child
        ).length > 0
      ) {
        return this.somefilter(
          t.children?.filter(
            (x: any) =>
              x.rFieldId === this._structure.Element.Group ||
              x.rFieldId === this._structure.Element.Child
          ),
          x,
          elementlinkname
        );
      } else {
        if (x[Common.toCamelCase(elementlinkname.value)]) {
          return t.object === x[Common.toCamelCase(elementlinkname.value)].id;
        } else {
          console.error(
            `Category ${
              elementlinkname.value
            }.id does not exist in the Selector list API ${
              process.env.REACT_APP_ENDPOINT_API + this.props.serviceName
            }/Get`
          );
          return false;
        }
      }
    });
  }

  // public GetContextMenu(cntxmenu: any) {
  //   let cntxMenuItems: ContextMenuItemModel[] | undefined = [];
  //   let cntmenu: any = cntxmenu.filter(
  //     (x: any) =>
  //       x.rFieldId === this._structure.Element.Child ||
  //       x.rFieldId === this._structure.Element.Group ||
  //       x.value.toLowerCase() === 'group' ||
  //       x.rFieldId ===
  //         this._structure.MenuElements.CntxtMenuElements.CntxMenuObject
  //   );
  //   if (cntmenu.length > 0) {
  //     cntmenu.forEach((x: any) => {
  //       if (
  //         cntxMenuItems!.filter((xx: any) => xx.id === x.object.toString())
  //           .length <= 0
  //       ) {
  //         let sprtr: boolean =
  //           x.value.toLowerCase() === 'separator' ? true : false;

  //         //if (x.rFieldId === this._structure.Element.Child) {
  //         if (cntxmenu.filter((xx: any) => xx.object === x.object).length > 1) {
  //           let itm: any = Common.FindJsonByObjectId(
  //             cntxmenu,
  //             x.object,
  //             this.user
  //           );
  //           if (itm !== null) {
  //             if (
  //               itm.value.toLowerCase() !== 'group' &&
  //               x.rFieldId !==
  //                 this._structure.MenuElements.CntxtMenuElements.CntxMenuObject
  //             ) {
  //               cntxMenuItems!.push({
  //                 text: sprtr ? '' : itm.value,
  //                 id: itm.object.toString(),
  //                 separator: sprtr,
  //                 items:
  //                   x.rFieldId === this._structure.Element.Group ||
  //                   x.value.toLowerCase() === 'group'
  //                     ? this.GetContextMenu(itm.children)
  //                     : [],
  //               });
  //             } else {
  //               cntxMenuItems = this.GetContextMenu(itm.children);
  //             }
  //           }
  //         } else {
  //           if (
  //             x.value.toLowerCase() !== 'group' &&
  //             x.rFieldId !==
  //               this._structure.MenuElements.CntxtMenuElements.CntxMenuObject
  //           ) {
  //             cntxMenuItems!.push({
  //               text: sprtr ? '' : x.value,
  //               id: x.object.toString(),
  //               separator: sprtr,
  //               iconCss:
  //                 x.children.filter(
  //                   (x: any) =>
  //                     x.rFieldId ===
  //                       this._structure.MenuElements.CntxtMenuElements
  //                         .CntxMenuItemType &&
  //                     x.value.toLowerCase() === 'checkbox'
  //                 ).length > 0
  //                   ? 'checkbox'
  //                   : '',
  //               items:
  //                 x.rFieldId === this._structure.Element.Group ||
  //                 x.value.toLowerCase() === 'group'
  //                   ? this.GetContextMenu(x.children)
  //                   : [],
  //             });
  //           } else {
  //             cntxMenuItems = this.GetContextMenu(x.children);
  //           }
  //         }
  //         // } else if (x.rFieldId === this._structure.Element.Group) {
  //         //   cntxMenuItems.push({
  //         //     text: sprtr ? '' : x.value,
  //         //     id: x.object.toString(),
  //         //     separator: sprtr,
  //         //     items: this.GetContextMenu(x.children),
  //         //   });
  //         // }
  //       }
  //     });

  //     return cntxMenuItems;
  //   } else {
  //     return [];
  //   }
  // }
  // -------------------------------------------------------Event Handler
  // * SUHAIL - 2023-03-28 - 10
  // * SUHAIL - 2023-03-29 - 20
  // * SUHAIL - 2023-03-30 - 60
  // * SUHAIL - 2023-04-03 - 80
  // * SUHAIL - 2023-04-04 - 340
  // * SUHAIL - 2023-04-05 - 20
  componentDidMount() {
    // TODO ACTIONS
    localStorage.removeItem('treegridtreegrid-selector');
    window.addEventListener('resize', this.updateDimensions);

    if (
      this.props.serviceName.split('/')!.pop()!.toLowerCase() === 'employee'
    ) {
      let tgs: GridComponent | null;
      let obj: any = document.getElementById('treegrid-selector') as Element;

      if (obj !== null) {
        tgs = obj.ej2_instances[0] as GridComponent;
        let dt: object[] =
          this.props.selectedAllocations.length > 0
            ? this.jsonSelectorFiltered
            : this.props.jsonSelector;

        if (dt) {
          if (
            this.arrIdFilter.includes(this.objFilterActive.object.toString()) &&
            this.arrIdFilter.includes(
              this.objFilterTerminated.object.toString()
            )
          ) {
          } else if (
            this.arrIdFilter.includes(this.objFilterActive.object.toString())
          ) {
            dt = dt.filter((x: any) => x.active === true);
          } else if (
            this.arrIdFilter.includes(
              this.objFilterTerminated.object.toString()
            )
          ) {
            dt = dt.filter((x: any) => x.active === false);
          }
          tgs!.dataSource = dt;
        }
      }
    }
    // if (document.getElementById('root') !== undefined && document.getElementById('root') !== null) {
    //   // let spinner: any = document.getElementById('root')?.querySelector('#root > .e-spin-hide');
    //   // if (spinner === null) {
    //   createSpinner({
    //     target: document.getElementById('root')!,
    //   });
    //   // }
    // }
    // this._structure = JSON.parse(Common.getStructure() ?? '');
    // this.user = JSON.parse(Common.getUser() ?? '');
    //if (this.props.jsonSelector.length <= 0 && this.props.rootmenu !== undefined) {
    // if (this.props.rootmenu !== undefined) {
    //   if (this.props.rootmenu.children.length > 0) {

    //     this.onnodemenu = Common.FindJsonByRelationFieldId(this.props.rootmenu.children, this._structure.SideBarElements.OnNode);
    //     this.onleafmenu = Common.FindJsonByRelationFieldId(this.props.rootmenu.children, this._structure.SideBarElements.OnLeaf);
    //     this.onexpandmenu = Common.FindJsonByRelationFieldId(this.props.rootmenu.children, this._structure.SideBarElements.OnExpand);
    //     this.selectedmenu = (this.props.currmenu.rFieldId === this._structure.Element.Child) ? this.onleafmenu : this.onnodemenu;

    //     // let elmnt: any = Common.FindJsonByRelationFieldId(this.selectedmenu['children'], this._structure.Field.Identif.Collection);//699 = Collection
    //     // if (elmnt !== null) {
    //     //   let payLoad: any = Common.FindJsonByFieldId(this.selectedmenu['children'], this._structure.Field.Desc.Json);//700 = Payload
    //     //   if (payLoad !== null) {
    //     //     payLoad = JSON.parse(payLoad.value);
    //     //   }
    //     //   else {
    //     //     payLoad = '';
    //     //   }
    //     //   this.GetSelector(elmnt.value, payLoad);
    //     // }

    //     let colset: any = Common.FindJsonByRelationFieldId(this.selectedmenu.children, this._structure.Parameters.ColumnsSet);//9180 = Column Set
    //     if (colset !== null) {
    //       //this.setState({ columnset: colset });
    //       this.columnSet = colset;
    //     }
    //     else {
    //       // this.setState({ columnset: {} });
    //       this.columnSet = null;
    //     }

    //     let sizeElemntId: any = Common.FindJsonByRelationFieldId(this.selectedmenu.children, this._structure.Parameters.Properties); //9094 = Properties
    //     if (sizeElemntId !== null) {
    //       let sizeElemnt: any = Common.FindJsonByObjectId(this.selectedmenu.children, sizeElemntId.object, this.user, this._structure.Properties.Width);
    //       if (sizeElemnt !== null) {
    //         this.selectorSize = sizeElemnt.value;
    //       }
    //     }

    //     let cntxmenu = Common.FindJsonByRelationFieldId(this.selectedmenu.children, this._structure.SideBarComponents.CntxMenu); //9150 = CntxMenu
    //     if (cntxmenu !== null) {
    //       cntxmenu.children.filter((x: any) => x.rFieldId === this._structure.Element.Child).forEach((x: any) => {
    //         if (this.contextMenuItems.filter((xx: any) => xx.id === x.object.toString()).length <= 0) {
    //           let sprtr: boolean = (x.value.toLowerCase() === 'separator') ? true : false;
    //           if (cntxmenu.children.filter((xx: any) => xx.object === x.object).length > 1) {
    //             let itm: any = Common.FindJsonByObjectId(cntxmenu.children, x.object, this.user);
    //             if (itm !== null) {
    //               this.contextMenuItems.push({ text: sprtr ? '' : itm.value, target: '.e-content', id: itm.object.toString(), separator: sprtr });
    //             }
    //           }
    //           else {
    //             this.contextMenuItems.push({ text: sprtr ? '' : x.value, target: '.e-content', id: x.object.toString(), separator: sprtr });
    //           }
    //         }
    //       });
    //     }

    //     // let selectedAllocations: any = null;
    //     // if (this.props.rootmenu !== this.props.currmenu) {
    //     //   if (this.props.currmenu.rFieldId === this._structure.Element.Child) {
    //     //     selectedAllocations = this.props.currmenu
    //     //   }
    //     //   else {
    //     //     selectedAllocations = Common.GetJsonByRelationFieldId(this.props.currmenu.children, this._structure.Element.Child);
    //     //     if (selectedAllocations === null || selectedAllocations.length <= 0) {
    //     //       selectedAllocations = { object: -999, field: -1, fieldValue: '', value: '', rFieldId: -1, rFieldValue: '', idx: 0, parentidx: 0 };
    //     //     }
    //     //   }
    //     //   console.log(selectedAllocations)
    //     // }

    //   }
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  // -------------------------------------------------------Event Handler
  // * SUHAIL - 2023-03-29 - 20
  // * SUHAIL - 2023-03-30 - 60
  componentDidUpdate(prevProps: any, prevState: any) {
    //     console.log('Prev state', prevState); // Before update
    //     console.log('New state', this.state); // After update
    // if ( && prevState.jsonSidebar !== this.state.jsonSidebar) {
    // }
    // if (this.props.rootmenu !== prevProps.rootmenu) {
    //   if (this.props.rootmenu.children.length > 0) {
    //     let elmnt: any = Common.FindJsonByRelationFieldId(this.props.rootmenu['children'], this._structure.Parameters.Collection);//9110 = Collection
    //     if (elmnt !== null) {
    //       this.GetSelector(elmnt.value);
    //     }
    //     let colset: any = Common.FindJsonByRelationFieldId(this.props.rootmenu.children, this._structure.Parameters.ColumnsSet);//9180 = Column Set
    //     if (colset !== null) {
    //       //this.setState({ columnset: colset });
    //       this.columnSet = colset;
    //     }
    //     else {
    //       //this.setState({ columnset: {} });
    //       this.columnSet = colset;
    //     }
    //     let sizeElemntId: any = Common.FindJsonByRelationFieldId(this.props.rootmenu.children, this._structure.Parameters.Properties); //9094 = Properties
    //     if (sizeElemntId !== null) {
    //       let sizeElemnt: any = Common.FindJsonByObjectId(this.props.rootmenu.children, sizeElemntId.object, this.user, this._structure.Properties.Width);
    //       if (sizeElemnt !== null) {
    //         this.selectorSize = sizeElemnt.value;
    //       }
    //     }
    //   }
    // }
  }

  public updateDimensions = () => {
    // let header: HTMLElement = document.querySelector('#treegrid-selector')?.querySelector('.e-gridheader') as HTMLElement;
    // let body: HTMLElement = document.querySelector('#innerSplitterMain') as HTMLElement;
    // let footer: HTMLElement = document.querySelector('#selectorsearchmain') as HTMLElement;
    // let content: HTMLElement = document.querySelector('#treegrid-selector')?.querySelector('.e-content') as HTMLElement;
    // if (content) {
    //   content.style.height = (body.offsetHeight - header.offsetHeight - footer.offsetHeight - 2) + 'px';
    // }
  };

  // *  ------------------------------------------------------------------------------------------------------------------API
  // * SUHAIL - 2023-03-29 - 60
  // * SUHAIL - 2023-04-03 - 40
  public GetSelector(servicename: string, payLoad: any) {
    this.setState({ isLoading: true });
    //this.props.onServiceLoadingChange(true);
    //let payLoad: any;
    // switch (servicename) {

    //   case 'Person':
    //     payLoad = {
    //       'personid': 0,
    //       'employeeid': 0,
    //       'personinfo': 127
    //     };
    //     break;

    //   case 'Employee':
    //     payLoad = {
    //       'companyid': 100010,
    //       'employeeid': 0,
    //       'info': 2,
    //       'Redis': 0,
    //       'person': {
    //         'personInfo': 0
    //       },
    //       'contract': {
    //         'contractInfo': 31,
    //         'Redis': 0
    //       },
    //       'dossier': {
    //         'Documentinfo': 0,
    //         'account': null,
    //         'Redis': 0
    //       },
    //       'loan': {
    //         'loanid': 0,
    //         'loanInfo': 1,
    //         'Redis': 0
    //       },
    //       'membership': {
    //         'employeeid': 1,
    //         'id': 0
    //       },
    //       'arrears': {
    //         'employeeid': 0,
    //         'id': 0
    //       }
    //     };
    //     break;

    // }

    Common.ApiCallAsync(
      'POST',
      `${servicename}/Get`,
      payLoad,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        return resopnse.data;
      })
      .then((resopnse: any) => {
        this.setState({ serviceName: servicename, jsonSelector: resopnse });
        //this.setState({ jsonSelector: resopnse });
        //this.props.onServiceNameChange(servicename);
      })
      .catch((error: any) => {
        console.error(error);
        this.setState({ isLoading: false });
      })
      .finally(() => {
        this.setState({ isLoading: false });
        //this.props.onServiceLoadingChange(false);
      });

    return null;
  }

  // *  ------------------------------------------------------------------------------------------------------------------API
  public SetSelector(servicename: string, payLoad: any) {
    this.setState({ isLoading: true });

    Common.ApiCallAsync(
      'POST',
      `${servicename}/Set`,
      payLoad,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        this.setState({ isLoading: false });
        this.SaveSelector(resopnse.data);
        return resopnse.data;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });

    return null;
  }

  refreshSelector = (e: any) => {
    this.GridInstance.refreshData();
  };

  // * SUHAIL - 2023-04-04 - 30
  private contextMenuOpen(args: any): void {
    let elem: Element = args.event.target as Element;
    args.items.forEach((x: any) => {
      if (x.iconCss === 'checkbox') {
        let itm: HTMLElement = document.getElementById(x.id) as HTMLElement;
        const check = createCheckBox(createElement, false, {
          checked: false,
          label: x.text,
        });
        itm.innerHTML = '';
        itm.appendChild(check);
      }
    });

    //args.cancel = true;
    // let row: Element = elem.closest('.e-row');
    // let uid: string = row && row.getAttribute('data-uid');
    // let items: NodeListOf<Element> = document.querySelectorAll('.e-menu-item');
    // for (let i: number = 0; i < items.length; i++) {
    //   items.item(i).setAttribute('style', 'display: none;');
    // }
    // if (elem.closest('.e-row')) {
    //   if (isNullOrUndefined(uid) || isNullOrUndefined(getValue('hasChildRecords', this.treegridObj.grid.getRowObjectFromUID(uid).data))) {
    //     args.cancel = true;
    //   } else {
    //     let flag: boolean = getValue('expanded', this.treegridObj.grid.getRowObjectFromUID(uid).data);
    //     let val: string = flag ? 'none' : 'block';
    //     document.querySelectorAll('li#expandrow')[0].setAttribute('style', 'display: ' + val + ';');
    //     val = !flag ? 'none' : 'block';
    //     document.querySelectorAll('li#collapserow')[0].setAttribute('style', 'display: ' + val + ';');
    //   }
    // } else {
    //   let len = this.treegridObj.element.querySelectorAll('.e-treegridexpand').length;
    //   if (len !== 0) {
    //     document.querySelectorAll('li#collapseall')[0].setAttribute('style', 'display: block;');
    //   } else {
    //     document.querySelectorAll('li#expandall')[0].setAttribute('style', 'display: block;');
    //   }
    // }
  }

  /*
       ------------------------------------------------------------------------------------------------------------------6.Adapter
    */
  // * SUHAIL - 2023-02-27 - 120
  // * SUHAIL - 2023-03-16 - 30
  // * SUHAIL - 2023-03-21 - 90
  // * SUHAIL - 2023-03-28 - 120
  // * SUHAIL - 2023-03-31 - 120
  // * SUHAIL - 2023-04-04 - 30
  // * SUHAIL - 2023-04-04 - 30
  // * SUHAIL - 2023-04-05 - 30
  // * SUHAIL - 2023-04-06 - 300
  // * SUHAIL - 2023-04-07 - 120
  // * SUHAIL - 2023-04-11 - 150
  // * SUHAIL - 2023-04-24 - 30
  // * SUHAIL - 2023-04-28 - 60
  // * SUHAIL - 2023-05-05 - 60
  public Selector(): JSX.Element {
    let cols: GridColumnStructure[] = [];
    let col: GridColumnStructure;
    let toolbarOptions: any = ['Search'];

    this.InitializeObjects(this.props);

    if (this.columnSet !== null) {
      let orderobjectid: number = 0;
      let orderobject: any = this.columnSet.children.filter(
        (x: any) => x.rFieldId === this._structure.Element.OrderList
      );
      if (orderobject.length > 0) {
        orderobjectid = orderobject[0].object;
      }
      this.columnSet.children
        .filter((x: any) => x.object !== orderobjectid)
        .forEach((x: any) => {
          let idx: any = cols.findIndex((p: any) => {
            return p.object === x.object;
          });
          // if (idx >= 0) {
          //   switch (x.field) {

          //     case this._structure.ColumnProperties.Source:
          //       col = { ...cols[idx], source: x.value };
          //       cols[idx] = col;
          //       break;

          //     case this._structure.ColumnProperties.Label:
          //       col = { ...cols[idx], label: x.value };
          //       cols[idx] = col;
          //       break;

          //     case this._structure.Properties.Width:
          //       col = { ...cols[idx], width: x.value };
          //       cols[idx] = col;
          //       break;

          //   }
          // }
          // else {
          //   switch (x.field) {

          //     case this._structure.ColumnProperties.Source:
          //       col = { idx: cols.length, object: x.object, source: x.value, label: '', width: undefined };
          //       cols.push(col);
          //       break;

          //     case this._structure.ColumnProperties.Label:
          //       col = { idx: cols.length, object: x.object, source: '', label: x.value, width: undefined };
          //       cols.push(col);
          //       break;

          //     case this._structure.Properties.Width:
          //       col = { idx: cols.length, object: x.object, source: '', label: '', width: x.value };
          //       cols.push(col);
          //       break;

          //   }

          // }
          if (idx < 0) {
            idx = cols.length;
            col = {
              idx: cols.length,
              object: x.object,
              source: '',
              label: '',
              width: undefined,
            };
            cols.push(col);
          }

          switch (x.field) {
            case this._structure.ColumnProperties.Source:
            case this._structure.Field.Identif.Name:
              col = { ...cols[idx], source: x.value };
              cols[idx] = col;
              break;

            case this._structure.Field.Identif.Label:
            case this.user.language:
              col = { ...cols[idx], label: x.value };
              cols[idx] = col;
              break;

            case this._structure.Properties.Width:
              col = { ...cols[idx], width: x.value };
              cols[idx] = col;
              break;
          }
        });
    }

    //let TreeGridSelector: TreeGridComponent | null;
    let TreeGridSelector: GridComponent | null;
    const tooltip = (e: any) => {
      if (!TreeGridSelector || TreeGridSelector.dataSource == null) return;
      //e.target.title = `${TreeGridSelector!.element.querySelectorAll('.e-row').length} records`;
      e.target.title = `Total records : ${
        (TreeGridSelector!.dataSource as []).length
      }`;
    };

    const contextMenuClick = (e: any) => {
      if (!this.selectedmenu) return;

      let itm: any = Common.FindJsonByObjectId(
        this.selectedmenu.children,
        Number(e.item.id),
        this.user
      );
      if (itm !== null) {
        let actn: any = Common.FindJsonByRelationFieldId(
          itm.children,
          this._structure.MenuElements.CntxtMenuElements.CntxMenuAction
        );
        if (actn !== null) {
          switch (actn.object) {
            case this._structure.MenuElements.CntxtMenuActions.Create:
              if (
                this.props.serviceName.split('/')!.pop()!.toLowerCase() ===
                'correspondence'
              ) {
                // let newwithmenu: any = this.contextMenuItems.filter(
                //   (x: any) => x.id == 84016
                // );
                //this.dlgcntxmenuitems = newwithmenu[0].items;
                this.dlgaccountid = this.props.currmenu.object;
                this.dlgaccountname = this.props.currmenu.value;
              }
              //TreeGridSelector?.addRecord({ id: 42433333, name: 'AAAA' });
              TreeGridSelector?.addRecord();
              // const dataSource: object = extendArray(TreeGridSelector?.dataSource as object[]);
              // (dataSource as object[]).push({ id: 4243333, name: 'AAAA' });
              // TreeGridSelector!.dataSource = dataSource;
              break;

            case this._structure.MenuElements.CntxtMenuActions.Delete:
              //TreeGridSelector?.deleteRecord();
              const selectedRow = TreeGridSelector!.getSelectedRowIndexes()[0];
              const dataSource: object =
                TreeGridSelector?.dataSource as object[];
              //if (TreeGridSelector!.getSelectedRecords().length > 0) {
              (dataSource as object[]).splice(selectedRow, 1);
              TreeGridSelector!.dataSource = dataSource;
              TreeGridSelector?.refresh();
              //}
              break;

            case this._structure.MenuElements.CntxtMenuActions.Filter:
              let dt: object[] =
                this.props.selectedAllocations.length > 0
                  ? this.jsonSelectorFiltered
                  : this.props.jsonSelector;

              let o: HTMLElement = e.event.target as HTMLElement;
              if (o.classList.contains('e-label')) {
                o = o.parentElement!.querySelector('span.e-frame')!;
              }
              switch (o.nodeName) {
                case 'LI':
                  if (o.id === this.objFilterActive.object.toString()) {
                    // TreeGridSelector!.searchSettings = {
                    //   fields: ['active'],
                    // };
                    // TreeGridSelector?.search('true');
                    // TreeGridSelector!.searchSettings = { fields: [] };
                    //let dt: object[] = TreeGridSelector?.dataSource as object[];
                    dt = dt.filter((x: any) => x.active === true);
                    TreeGridSelector!.dataSource = dt;
                  } else if (
                    o.id === this.objFilterTerminated.object.toString()
                  ) {
                    // TreeGridSelector!.searchSettings = {
                    //   fields: ['active'],
                    // };
                    // TreeGridSelector?.search('false');
                    // TreeGridSelector!.searchSettings = { fields: [] };
                    //let dt: object[] = TreeGridSelector?.dataSource as object[];
                    dt = dt.filter((x: any) => x.active === false);
                    TreeGridSelector!.dataSource = dt;
                  }
                  break;

                case 'SPAN':
                  //this.arrIdFilter = [];
                  let u: any = o
                    .closest('ul')!
                    .querySelectorAll('li:has(span.e-check');
                  if (u.length > 0) {
                    let m: any = u.forEach((x: any) => {
                      if (!this.arrIdFilter.includes(x.id))
                        this.arrIdFilter.push(x.id);
                    });
                  }
                  if (o.classList.contains('e-check')) {
                    this.arrIdFilter.splice(
                      this.arrIdFilter.indexOf(o.closest('li')!.id),
                      1
                    );
                  } else {
                    if (!this.arrIdFilter.includes(o.closest('li')!.id))
                      this.arrIdFilter.push(o.closest('li')!.id);
                  }
                  if (
                    this.arrIdFilter.includes(
                      this.objFilterActive.object.toString()
                    ) &&
                    this.arrIdFilter.includes(
                      this.objFilterTerminated.object.toString()
                    )
                  ) {
                  } else if (
                    this.arrIdFilter.includes(
                      this.objFilterActive.object.toString()
                    )
                  ) {
                    dt = dt.filter((x: any) => x.active === true);
                  } else if (
                    this.arrIdFilter.includes(
                      this.objFilterTerminated.object.toString()
                    )
                  ) {
                    dt = dt.filter((x: any) => x.active === false);
                  }
                  TreeGridSelector!.dataSource = dt;
                  break;

                default:
                  break;
              }
              //TreeGridSelector?.filterModule
              break;
          }
        } else {
          if (
            this.props.serviceName.split('/')!.pop()!.toLowerCase() ===
            'correspondence'
          ) {
            let parentmeuid = Common.FindParentJsonByIdKeyChildren(
              this.contextMenuItems,
              Number(e.item.id),
              'id',
              'items',
              null
            );

            let rootparentid: number = 0; //Number(parentmeuid);

            if (parentmeuid) {
              parentmeuid = parentmeuid.id;
              rootparentid = Number(parentmeuid);
              do {
                parentmeuid = Common.FindParentJsonByIdKeyChildren(
                  this.contextMenuItems,
                  parentmeuid,
                  'id',
                  'items',
                  null
                );
                if (parentmeuid) {
                  parentmeuid = parentmeuid.id;
                  rootparentid = Number(parentmeuid);
                } else {
                  parentmeuid = 0;
                }
              } while (Number(parentmeuid) > 0);
            }
            if (rootparentid == this.objMenuNewWith.object) {
              //this.dlgcntxmenuitems = [e.item.properties];
              this.dlgaccountid = e.item.id;
              this.dlgaccountname = e.item.text;
              TreeGridSelector?.addRecord();
            }
          }
        }
      }
    };

    const dtbound = () => {
      let obj: any = document.getElementById('treegrid-selector') as Element;
      if (obj !== null) {
        //Common.SetStatusBar(`${(obj.ej2_instances[0] as TreeGridComponent)!.element.querySelectorAll('.e-row').length} records`);
        TreeGridSelector = obj.ej2_instances[0] as GridComponent;
        if (TreeGridSelector !== null) {
          let obj2: any = TreeGridSelector.dataSource as [];
          if (obj2 !== null) {
            obj.title = `Total records : ${obj2.length}`;
            Common.SetStatusBar(`Total records : ${obj2.length}`);
            // if (this.selectedRowIdx >= 0 && obj2.length >= 1) {
            //   TreeGridSelector.selectRow(1);
            //   TreeGridSelector.selectRow(0);
            //   this.selectedRowIdx = -1;
            // }
            if (this.selectedRowIdx >= 0) {
              TreeGridSelector.selectRow(0);
              this.selectedRowIdx = -1;
            }
          }
        }
      }
    };

    const dialogTemplate = (props: any) => {
      switch (this.props.serviceName.split('/')!.pop()!.toLowerCase()) {
        case '5005':
        case 'person':
          return (
            <>
              <DialogFormTemplate
                {...props}
                servicename={this.props.serviceName}
              />
            </>
          );
          break;

        case 'employee':
        case '5006':
          return (
            <>
              {
                <DialogFormTemplate
                  {...props}
                  servicename={this.props.serviceName}
                  personname=''
                />
              }
            </>
          );
          break;

        case 'correspondence':
          return (
            <>
              {
                <DialogFormTemplate
                  {...props}
                  servicename={this.props.serviceName}
                  //menuItem={this.dlgcntxmenuitems}
                  accountid={this.dlgaccountid}
                  accountname={this.dlgaccountname}
                  correspondencedate={new Date()}
                />
              }
            </>
          );
          break;

        default:
          break;
      }
    };
    let cmenuInstance: ContextMenuComponent;

    const beforecmenurender = (e: any) => {
      if (e.item.iconCss === 'checkbox') {
        const check = createCheckBox(createElement, false, {
          checked: this.arrIdFilter.includes(e.item.id),
          label: e.item.text,
        });
        e.element.innerHTML = '';
        e.element.appendChild(check);
      }
    };
    const beforecmenuclose = (e: any) => {
      if (closest(e.event.target as HTMLElement, '.e-menu-item')) {
        const selectedElem = e.element.querySelectorAll('.e-selected');
        for (const elem of selectedElem as any) {
          const ele = elem as HTMLElement;
          ele.classList.remove('e-selected');
        }
        const checkbox = closest(
          e.event.target as Element,
          '.e-checkbox-wrapper'
        ) as HTMLElement;
        if (checkbox) {
          e.cancel = true;
          const frame = checkbox.querySelector('.e-frame');
          if (checkbox && frame?.classList.contains('e-check')) {
            frame.classList.remove('e-check');
          } else if (checkbox) {
            frame?.classList.add('e-check');
          }
        }
      }
    };
    // const getGridInstance = (id: string) => {
    //   if (!TreeGridSelector) {
    //     let obj: any = document.getElementById(id) as Element;
    //     if (obj !== null) {
    //       TreeGridSelector = obj.ej2_instances[0] as GridComponent;
    //     }
    //   }
    //   return TreeGridSelector;
    // }
    return this.props.isSelectorLoading || this.state.isLoading ? (
      <>
        {Common.SetStatusBar('Loading.....')}
        {Common.ShowSpinner()}
      </>
    ) : (
      <>
        {Common.HideSpinner()}
        {/* <div className='loader center1' style={{ position: 'fixed', left: '50%', top: '50%', height: '25%', width: '25%', transform: 'translate(-50%, -50%)', textAlign: 'center', fontSize: '100px' }}><i className="fa-solid fa-circle-notch fa-spin">A</i></div> */}
        {/* < div className="control-pane">
            <div className="control-section" >
              <div onMouseOver={tooltip}>
                <TreeGridComponent
                  height='calc(100vh - 175px)'
                  dataSource={this.props.selectedAllocations.length > 0 ? this.jsonSelectorFiltered : this.props.jsonSelector}
                  // childMapping="name"
                  childMapping=''
                  //height="580px"
                  //allowReordering={true}
                  // allowFiltering={true}
                  allowSorting={true}
                  // filterSettings={{ type: 'Menu', hierarchyMode: 'Parent' }}
                  //onClick={(e) => this.handleSelectorClick(e)}
                  rowSelected={this.handleSelectorRowClick}
                  contextMenuItems={this.contextMenuItems}
                  contextMenuOpen={this.contextMenuOpen}
                  contextMenuClick={contextMenuClick}
                  editSettings={{ allowAdding: true, allowDeleting: true, mode: 'Row', newRowPosition: 'Bottom' }}
                  enablePersistence={true}
                  id='treegrid-selector'
                  ref={(obj) => { TreeGridSelector = obj }}
                  dataBound={dtbound}
                  actionBegin={(e) => this.cellSave(e)}
                  pageSettings={{ pageSize: 50 }}
                  enableInfiniteScrolling={true}
                >
                  <ColumnsDirective>

                    {(this.columnSet === null ?
                      <ColumnDirective key='id' field='name' headerText='Name' ></ColumnDirective>
                      :
                      cols.map((x: any) => (
                        <ColumnDirective key='id' field={x.source.toString().toLowerCase()} headerText={x.label} width={`${x.width}%`} type={x.source.toString().toLowerCase() === 'date' ? 'datetime' : 'string'} format={x.source.toString().toLowerCase() === 'date' ? this.user.settings[0].formats.DateShort : ''} ></ColumnDirective>
                      ))

                    )}

                  </ColumnsDirective>
                  <Inject services={[Filter, Edit, Sort, Reorder, ContextMenu, InfiniteScroll]} />
                </TreeGridComponent>
              </div>
              <SelectorSearch grid_id='treegrid-selector' />
            </div>
          </div > */}
        <DataGrid
          id='treegrid-selector'
          dataSource={
            this.props.selectedAllocations.length > 0
              ? this.jsonSelectorFiltered
              : this.props.jsonSelector
          }
          height='calc(100vh - 175px)'
          columns={this.columnSet ? cols : []}
          rowSelected={this.handleSelectorRowClick}
          //contextMenuItems={this.contextMenuItems}
          //contextMenuOpen={this.contextMenuOpen}
          //contextMenuClick={contextMenuClick}
          actionBegin={this.cellSave.bind(this)}
          actionComplete={this.actionComplete.bind(this)}
          dataBound={dtbound}
          ref={(obj: any) => (this.GridInstance = obj)}
          //onMouseOver={tooltip}
          showSearchBar={true}
          editSettings={{
            allowAdding: true,
            allowDeleting: true,
            newRowPosition: 'Top',
            mode: 'Dialog',
            template: dialogTemplate,
          }}
          pageSettings={{ pageSize: 50 }}
          enableInfiniteScrolling={true}
        />
        <ContextMenuComponent
          id='contentmenugrid'
          target='#treegrid-selector'
          items={this.contextMenuItems}
          beforeItemRender={beforecmenurender}
          beforeClose={beforecmenuclose}
          onClick={contextMenuClick}
          select={contextMenuClick}
          ref={(gridcmenu) =>
            (cmenuInstance = gridcmenu as ContextMenuComponent)
          }
        />
      </>
    );
  }

  /*
       ------------------------------------------------------------------------------------------------------------------6.Adapter
  */
  // * SUHAIL - 2023-03-21 - 150
  // * SUHAIL - 2023-04-11 - 60
  // private handleSelectorClick(e: any) {
  //   if ((e.target as HTMLElement).closest('.e-row') == undefined)
  //     return;

  //   let id: number = Number((e.target as HTMLElement).closest('.e-row')!.querySelector('.e-hide')!.querySelector('.e-treecell')?.innerHTML);
  //   //let id: number = e.data.id;
  //   let data: any = this.props.jsonSelector.filter((x: any) => x.id === id);
  //   if (data.length <= 0) {
  //     let obj: any = document.getElementById('treegrid-selector') as Element;
  //     if (obj !== null) {
  //       const dataSource: object = extendArray((obj.ej2_instances[0] as TreeGridComponent).dataSource as object[]);
  //       data = (dataSource as object[]).filter((x: any) => x.id === id);
  //     }
  //   }

  //   if (data.length > 0) {
  //     //this.setState({ jsonDataViewer: data[0] })
  //     this.props.selectorRowSelected(data[0]);
  //     //this.childDataViewer.getSelectedSelector(data[0]);
  //     //this.childDataViewer.getSelectedSelector(data[0]);
  //   }
  //   else {
  //     //this.setState({ jsonDataViewer: [] })
  //     this.props.selectorRowSelected([]);
  //     //this.childDataViewer.getSelectedSelector([]);
  //     //this.childDataViewer.getSelectedSelector([]);
  //   }

  // }

  private handleSelectorRowClick(e: any) {
    // if ((e.target as HTMLElement).closest('.e-row') == undefined)
    //   return;
    if (
      // (e.previousRowIndex === undefined && e.isInteracted === false) ||
      e.previousRowIndex !== undefined &&
      e.rowIndex === e.previousRowIndex
    )
      return;

    //let id: number = Number((e.target as HTMLElement).closest('.e-row')!.querySelector('.e-hide')!.querySelector('.e-treecell')?.innerHTML);
    let id: number = e.data.id;
    let data: any = this.props.jsonSelector.filter((x: any) => x.id === id);
    // if (data.length <= 0) {
    //   let obj: any = document.getElementById('treegrid-selector') as Element;
    //   if (obj !== null) {
    //     const dataSource: object = extendArray((obj.ej2_instances[0] as TreeGridComponent).dataSource as object[]);
    //     data = (dataSource as object[]).filter((x: any) => x.id === id);
    //   }
    // }

    if (data.length > 0) {
      //this.setState({ jsonDataViewer: data[0] })
      this.props.selectorRowSelected(data[0]);
      //this.childDataViewer.getSelectedSelector(data[0]);
      //this.childDataViewer.getSelectedSelector(data[0]);
    } else {
      //this.setState({ jsonDataViewer: [] })
      this.props.selectorRowSelected([]);
      //this.childDataViewer.getSelectedSelector([]);
      //this.childDataViewer.getSelectedSelector([]);
    }
  }

  private SetDialogCSS(dialog: Dialog) {
    for (let i = 0; i < dialog.buttons.length; i++) {
      let _primary = dialog.buttons[i].buttonModel?.isPrimary;

      let _model = {
        content: Common.toTitleCase(
          dialog.buttons[i].buttonModel?.content?.toLowerCase() == 'save'
            ? 'Accept'
            : `${dialog.buttons[i].buttonModel?.content}`
        ),
        cssClass: 'flat-button',
        isPrimary: _primary,
      };
      dialog.buttons[i].buttonModel = _model;
    }
    dialog.buttons.reverse();
  }

  private actionComplete = (args: DialogEditEventArgs): void => {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      const dialog: Dialog = args.dialog as Dialog;
      switch (this.props.serviceName.split('/')!.pop()!.toLowerCase()) {
        case '5005':
        case 'person':
          dialog.header = 'New Person';
          //this.SetDialogCSS(dialog);
          break;

        case 'employee':
        case '5006':
          dialog.header = 'New Employee';
          //this.SetDialogCSS(dialog);
          break;

        case 'correspondence':
          dialog.header = 'New Correspondence';
          break;

        default:
          break;
      }
      this.SetDialogCSS(dialog);
    }
  };

  // * SUHAIL - 2023-04-11 - 120
  private cellSave(e: any) {
    if (e.requestType === 'save') {
      console.log('save');
      if (e.data.id === undefined) {
        //e.data.id = 98989898998;
        let obj: any = document.getElementById('treegrid-selector') as Element;
        if (obj !== null) {
          //(obj.ej2_instances[0] as TreeGridComponent).endEdit();
          // e.cancel = true;
          // e.type = 'actionComplete';
          // e.requestType = '';

          // let saveDtos: SaveDto[] = [];
          // saveDtos.push({
          //   object: this._structure.Structure.Identity,
          //   field: this._structure.Cluster.Identity.Person,
          //   value: '404130',
          //   table: '',
          //   unique: true,
          // });

          // saveDtos.push({
          //   object: 404130,
          //   field: this._structure.Field.Identif.Name,
          //   value: e.data.name,
          //   table: '',
          //   unique: false,
          // });
          switch (this.props.serviceName.split('/')!.pop()!.toLowerCase()) {
            case '5005':
            case 'person':
              if (!e.data.name) {
                e.requestType = 'add';
                e.cancel = true;
                DialogUtility.alert('Please enter name');
                return;
              } else if (!e.data.date || !new Date(e.data.date).getDate()) {
                e.requestType = 'add';
                e.cancel = true;
                DialogUtility.alert('Please select date');
                return;
              }

              e.cancel = true;
              e.type = 'actionComplete';
              e.requestType = '';

              let personSetDto: any = {
                name: e.data.name,
                date: e.data.date,
              };
              this.SetSelector(this.props.serviceName, personSetDto);
              break;

            case 'employee':
            case '5006':
              if (!e.data.personname) {
                e.requestType = 'add';
                e.cancel = true;
                DialogUtility.alert('Please select person');
                return;
              } else if (
                !e.data.appointmentdate ||
                !new Date(e.data.appointmentdate).getDate()
              ) {
                e.requestType = 'add';
                e.cancel = true;
                DialogUtility.alert('Please select appointment date');
                return;
              }
              if (!e.data.contractid) {
                e.requestType = 'add';
                e.cancel = true;
                DialogUtility.alert('Please select contract');
                return;
              }

              e.cancel = true;
              e.type = 'actionComplete';
              e.requestType = '';

              let employeeSetDto: any = {
                //companyid: { id: this.user.company.id },
                person: {
                  id: e.data.personid,
                  name: e.data.personname,
                  //date: e.data.birthdate,
                },
                //account: { id: e.data.contractid },
                correspondence: { account: { StringId: '_contract_' } },
                contract: {
                  //account: { StringId: '_contract_' },
                  account: { id: e.data.contractid },
                  dateStart: e.data.appointmentdate,
                },
              };
              this.SetSelector(this.props.serviceName, employeeSetDto);
              break;

            case 'correspondence':
              if (!e.data.correspondence.sender.id) {
                e.requestType = 'add';
                e.cancel = true;
                DialogUtility.alert('Please select sender');
                return;
              } else if (!e.data.correspondence.recipient.id) {
                e.requestType = 'add';
                e.cancel = true;
                DialogUtility.alert('Please select recipient');
                return;
              } else if (!e.data.correspondence.subject) {
                e.requestType = 'add';
                e.cancel = true;
                DialogUtility.alert('Please enter subject');
                return;
              } else if (!e.data.correspondence.accountid) {
                e.requestType = 'add';
                e.cancel = true;
                DialogUtility.alert('Please enter account id');
                return;
              } else if (
                !e.data.correspondence.date ||
                !new Date(e.data.correspondence.date).getDate()
              ) {
                e.requestType = 'add';
                e.cancel = true;
                DialogUtility.alert('Please select date');
                return;
              }

              e.cancel = true;
              e.type = 'actionComplete';
              e.requestType = '';

              let correspondenceSetDto: any = {
                account: { id: e.data.correspondence.accountid },
                sender: e.data.correspondence.sender.id,
                recipient: e.data.correspondence.recipient.id,
                date: Common.RemoveTimezoneOffsetFromDate(
                  e.data.correspondence.date
                ),
                subject: e.data.correspondence.subject,
                protocol: e.data.correspondence.protocol,
              };
              this.SetSelector(this.props.serviceName, correspondenceSetDto);

              break;

            default:
              break;
          }

          // if (this.newperson !== null) {
          //   let obj2: GridComponent | any = obj
          //     .ej2_instances[0] as GridComponent;
          //   // //const dataSource: object = extendArray((obj.ej2_instances[0] as TreeGridComponent).dataSource as object[]);
          //   const dataSource: object = obj2.dataSource as object[];
          //   (dataSource as object[]).push(e.data);
          //   (obj.ej2_instances[0] as GridComponent).dataSource = dataSource;
          // }
        }
      }
    }
  }

  public SaveSelector(newperson: any) {
    if (newperson !== null) {
      switch (this.props.serviceName.split('/')!.pop()!.toLowerCase()) {
        case 'correspondence':
          break;

        default:
          break;
      }
      let obj: any = document.getElementById('treegrid-selector') as Element;
      let obj2: GridComponent = obj.ej2_instances[0] as GridComponent;
      // //const dataSource: object = extendArray((obj.ej2_instances[0] as TreeGridComponent).dataSource as object[]);
      // const dataSource: object = obj2.dataSource as object[];
      // (dataSource as object[]).unshift(newperson);
      // (obj.ej2_instances[0] as GridComponent).dataSource = dataSource;
      this.props.jsonSelector.unshift(newperson);
      if (this.props.selectedAllocations.length > 0) {
        this.jsonSelectorFiltered.unshift(newperson);
      }
      this.selectedRowIdx = 0;
    }
  }

  // public Selector2(): JSX.Element {
  //   return (
  //     <>
  //       <div style={{ height: '100px', overflow: 'auto', border: '1px solid' }}>
  //         <table>
  //           <thead>
  //             <tr>
  //               <th>name</th>
  //             </tr>
  //           </thead>

  //           <tbody>

  //             {this.props.selectedAllocations.length > 0 ? this.jsonSelectorFiltered : this.props.jsonSelector.map((x: any) => <tr><td onClick={this.handleSelectorClick.bind(this)}>{x.name}</td></tr>)}
  //           </tbody>
  //         </table>
  //       </div></>
  //   )
  // }

  // * --------------------------------------------------------------------------------------------------------------------HTML
  // * SUHAIL - 2023-02-27 - 40
  // * SUHAIL - 2023-02-28 - 30
  // * SUHAIL - 2023-03-31 - 30
  // * SUHAIL - 2023-04-05 - 180
  render() {
    let stringa =
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ornare, dui vitae consectetur bibendum, turpis tellus facilisis ex, et blandit nisi diam at mi. Ut hendrerit ultrices sapien in pretium. Etiam maximus felis lacinia ultrices blandit.';

    let nuovastringa = '';
    for (var i = 0; i < 1; i++) nuovastringa += stringa;

    //return nuovastringa;
    //queryCellInfo={this.queryCellinfo.bind(this)}
    //template={this.flagtemplate} filter={this.Filter}
    //let pd: JSX.Element = <PaneDirective size="80%" min="40%" content = "" />;
    var oPD = [];
    let _MAX = 3;
    let _SPC = 100 / _MAX;
    let _SSPC = _SPC.toString() + '%';
    // for (let i = 0; i < _MAX; i++) {
    //   //let temp = <PaneDirective size="50%" min="50%" content = {this.fSelector} />
    //   //let content = "panel auto "+i;
    //   //let temp = <PaneDirective  size={_SSPC} min={_SSPC} content = {<Selector type="treeview" index={i} />}  />

    //   let content
    //   if (i == 0) content = this.Selector

    //   let temp = (
    //     <PaneDirective key={i} size={_SSPC} min={_SSPC} content={content} />
    //   )
    //   oPD.push(temp)
    // }

    // if (this.props.rootmenu !== undefined) {
    //   if (this.props.rootmenu.children.length > 0) {

    //     this.onnodemenu = Common.FindJsonByRelationFieldId(this.props.rootmenu.children, this._structure.SideBarElements.OnNode);
    //     this.onleafmenu = Common.FindJsonByRelationFieldId(this.props.rootmenu.children, this._structure.SideBarElements.OnLeaf);
    //     this.onexpandmenu = Common.FindJsonByRelationFieldId(this.props.rootmenu.children, this._structure.SideBarElements.OnExpand);
    //     this.selectedmenu = (this.props.currmenu.rFieldId === this._structure.Element.Child) ? this.onleafmenu : this.onnodemenu;

    //     let colset: any = Common.FindJsonByRelationFieldId(this.selectedmenu.children, this._structure.Parameters.ColumnsSet);//9180 = Column Set
    //     if (colset !== null) {
    //       //this.setState({ columnset: colset });
    //       this.columnSet = colset;
    //     }
    //     else {
    //       // this.setState({ columnset: {} });
    //       this.columnSet = null;
    //     }

    //     let sizeElemntId: any = Common.FindJsonByRelationFieldId(this.selectedmenu.children, this._structure.Parameters.Properties); //9094 = Properties
    //     if (sizeElemntId !== null) {
    //       let sizeElemnt: any = Common.FindJsonByObjectId(this.selectedmenu.children, sizeElemntId.object, this.user, this._structure.Properties.Width);
    //       if (sizeElemnt !== null) {
    //         this.selectorSize = sizeElemnt.value;
    //       }
    //     }

    //     let cntxmenu = Common.FindJsonByRelationFieldId(this.selectedmenu.children, this._structure.SideBarComponents.CntxMenu); //9150 = CntxMenu
    //     if (cntxmenu !== null) {
    //       cntxmenu.children.filter((x: any) => x.rFieldId === this._structure.Element.Child).forEach((x: any) => {
    //         if (this.contextMenuItems.filter((xx: any) => xx.id === x.object.toString()).length <= 0) {
    //           let sprtr: boolean = (x.value.toLowerCase() === 'separator') ? true : false;
    //           if (cntxmenu.children.filter((xx: any) => xx.object === x.object).length > 1) {
    //             let itm: any = Common.FindJsonByObjectId(cntxmenu.children, x.object, this.user);
    //             if (itm !== null) {
    //               this.contextMenuItems.push({ text: sprtr ? '' : itm.value, target: '.e-content', id: itm.object.toString(), separator: sprtr });
    //             }
    //           }
    //           else {
    //             this.contextMenuItems.push({ text: sprtr ? '' : x.value, target: '.e-content', id: x.object.toString(), separator: sprtr });
    //           }
    //         }
    //       });
    //     }

    //   }
    // }

    return (
      <>
        {/* <div style={{ width: '100%' }}> {this.Selector2()}</div> */}
        <div style={{ width: '100%' }}> {this.Selector()}</div>
        {/* <SplitterComponent
          id="selectorPanel"
          height="calc(100vh - 104px)"
          width="100%"
          separatorSize={0}
          cssClass='border-0'
        >
          
          <PanesDirective>
            {this.props.jsonSelector.length > 0 || this.props.rootmenu ?
              <PaneDirective size={this.selectorSize > 0 ? this.selectorSize.toString() + '%' : '20%'} min="15%" content={this.Selector.bind(this)} collapsible={true} />
              : null
            }

            {/* <PaneDirective min="15%" content={() => <DataViwer ref={(obj) => { this.childDataViewer = obj }} serviceName={this.props.serviceName} jsonDataViewer={this.state.jsonDataViewer} rootmenu={this.props.rootmenu} />} /> */}
        {/* <PaneDirective size="10%" min="10%" /> */}
        {/*    </PanesDirective>
        </SplitterComponent> */}
      </>
    );
  }
}

export default SelectorPanel;
