// * -----------------------------------------------------------------------------------------------------------------1.Meeting
// * SOURAV - 2023-07-26 - 60 -- meeting.
// * SOURAV - 2023-07-31 - 90 -- meeting.
// * SOURAV - 2023-08-02 - 40 -- meeting.
// * SOURAV - 2023-08-03 - 60 -- meeting.
// * SOURAV - 2023-08-04 - 45 -- meeting.
// * SOURAV - 2023-08-07 - 60 -- meeting.
// * SOURAV - 2023-08-08 - 110 -- personal meeting with fabio and team regarding payslip.
// * SOURAV - 2023-08-11 - 20 -- payslip review meeting with fabio and team.
// * SOURAV - 2023-08-14 - 70 --  meeting.
// * SOURAV - 2023-08-16 - 40 --  meeting.
// * SOURAV - 2023-08-17 - 60 --  meeting.
// * SOURAV - 2023-08-18 - 55 --  meeting.
// * SOURAV - 2023-08-21 - 75 --  meeting.
// * SOURAV - 2023-08-23 - 60 --  meeting.
// * SOURAV - 2023-08-28 - 40 --  meeting.
// * SOURAV - 2023-08-31 - 25 --  meeting with fabio and team.
// * SOURAV - 2023-08-31 - 35 --  meeting.
// * SOURAV - 2023-08-01 - 30 --  meeting.
// * SOURAV - 2023-09-04 - 95 --  meeting.
// * SOURAV - 2023-09-06 - 400 -- PI meeting.
// * SOURAV - 2023-09-11 - 75 --  meeting.
// * SOURAV - 2023-09-13 - 80 --  meeting.
// * SOURAV - 2023-09-14 - 90 --  meeting.
// * SOURAV - 2023-09-15 - 45 --  meeting.
// * SOURAV - 2023-09-18 - 65 --  meeting.
// * SOURAV - 2023-09-20 - 60 --  meeting.
// * SOURAV - 2023-09-21 - 25 --  meeting.
// * SOURAV - 2023-09-25 - 90 --  meeting.
// * SOURAV - 2023-09-27 - 100 --  meeting.

import React, { Component } from 'react';
import Common from '../Util/Common';
import PeriodSelector from './PeriodSelector/PeriodSelector';

import {
  TreeGridComponent,
  ColumnsDirective,
  ColumnDirective,
} from '@syncfusion/ej2-react-treegrid';
import './PaySlip.css';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Inject, Toolbar, ToolbarItems } from '@syncfusion/ej2-react-treegrid';

interface Props {
  selectorData: any;
}
const { REACT_APP_ENDPOINT_FOXSYSTEMSERVICE } = process.env;

// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SOURAV - 2023-07-25 - 460 -- working on payslips layout and able to fetch it's data in browser console.
// * SOURAV - 2023-07-26 - 400 -- working on dynamic rendering of payslip(as per data provided by api).
// * SOURAV - 2023-07-28 - 450 -- working on period selector (to make it separate component).
// * SOURAV - 2023-07-31 - 370 -- working on period selector(multiple rendering issues) and also working on changed json database.
// * SOURAV - 2023-08-01 - 460 -- able to get welfare data on ui,also working on period selector to change payslip data dynamically and on it's rendering isssues.
// * SOURAV - 2023-08-02 - 440 -- rectify re-rendering issues of period selector and also working on to get items data from it's menuitem.
// * SOURAV - 2023-08-03 - 400 -- able to get items data from it's menuitem and also able to get data as per period selector ,also working on payslip layout as per ppt.
// * SOURAV - 2023-08-04 - 410 -- working on payslip layout as per ppt.
// * SOURAV - 2023-08-07 - 400 -- implemented thousand separator,decimal formatter and also applied conditional rendering if their is no data present.
// * SOURAV - 2023-08-08 - 350 -- doing research on treegrid component for payslip .
// * SOURAV - 2023-08-09 - 470 -- working on payslip json as required in treegrid .
// * SOURAV - 2023-08-10 - 300 -- working on payslip json as required in treegrid and able to complete it.
// * SOURAV - 2023-08-10 - 160 -- working on payslip to render it's data inside treegrid component.
// * SOURAV - 2023-08-10 - 450 -- working on grouping total of respective payslip data in treegrid comp.,formatting it's amount,and also working on it's layout.
// * SOURAV - 2023-08-14 - 400 -- working on payslip to implement changes (like make an extra column,cond. rendering of contribution data,respective contract show) as said by fabio sir in meeting.
// * SOURAV - 2023-08-16 - 70 -- working on period selector arrow key functionality.
// * SOURAV - 2023-08-16 - 350 -- working on to add employer's cost data in treegrid component of payslip.
// * SOURAV - 2023-08-17 - 350 -- working on to set previous period on previous navigation button click and also worked on to resolve some issue in period selector.
// * SOURAV - 2023-08-18 - 280 -- working on to set next period on next navigation button click  and also worked on next button in period selector.
// * SOURAV - 2023-08-18 - 120 -- working on to show period list as per employee tenure in period selector (completed).
// * SOURAV - 2023-08-21 - 385 -- working on payslip and periodselector component (implementing changes as per shared corrections ppt by fabio sir).
// * SOURAV - 2023-08-22 - 460 -- working on payslip json for repayments data, additions data, deduction data also working on it's layout.
// * SOURAV - 2023-08-23 - 400 -- working on payslip json for deduction data also working on it's styling (like make group of payslip data bold,fontsize,layout).
// * SOURAV - 2023-08-24 - 460 -- working on payslip to show payee slab in dialog box for contributions data and also working on styling of total and netpay amount.
// * SOURAV - 2023-08-25 - 450 -- working on payslip to show contributions data and welfare data in dialog box and also working on to hide the add new button.
// * SOURAV - 2023-08-28 - 400 -- working on to set new json data for payslip (as payslip API is updated) and also working on image editor component to fix width resizing issue.
// * SOURAV - 2023-08-29 - 460 -- working on to set new json data for payslip (as payslip API is updated) and also doing some research related to it.
// * SOURAV - 2023-08-31 - 400 -- working on to set new json data of payslip for treegrid component(as payslip API is updated).
// * SOURAV - 2023-08-01 - 380 -- working on to generate payslip dynamically(as per udated api ) in treegrid component.
// * SOURAV - 2023-09-04 - 120 -- working on  styling of payslip component.
// * SOURAV - 2023-09-11 - 390 -- working on to fix issue in payslip component and period selector component (as per shared document by fabio) .
// * SOURAV - 2023-09-13 - 260 -- working on to fix issue in payslip component and added bank payment details in payslip .
// * SOURAV - 2023-09-13 - 120 -- working on to show paye payslabs data in dialogbox.
// * SOURAV - 2023-09-14 - 370 --  working on to show paye payslabs data in dialogbox.
// * SOURAV - 2023-09-15 - 410 --  working on to show allowances details,payslab details in dialogbox and also working on styling of payslip component .
// * SOURAV - 2023-09-18 - 400 --  working on to show payeslab details (included taxable data)in dialogbox and also working on styling of payslip component .
// * SOURAV - 2023-09-19 - 300 --  working on to show payeslab included taxable data(completed it) and also working on to show rest contribution data in dialogbox.
// * SOURAV - 2023-09-19 - 160 --  working on to fix issues (as per updated api) of payslip component.
// * SOURAV - 2023-09-20 - 410 --  working on to fix issues (as per updated api) of payslip component.
// * SOURAV - 2023-09-21 - 120 --  working on to show welfare details (if exists) of payslip in dialogbox.
// * SOURAV - 2023-09-21 - 325 --  working on to fix issues (as per updated api) of payslip component and also started working on identity page of registration.
// * SOURAV - 2023-09-25 - 370 --  working on to fix issues (as per shared correction pdf by fabio sir) payslip component and period selector component.
// * SOURAV - 2023-09-26 - 460 --  working on period selector component (to make it persistance and also apply setting ),payslip component (resolving it's issue as per pdf).
// * SOURAV - 2023-09-27 - 360 --  working on to fix period selector issue,payslip component (resolving it's issue as per pdf).

class Payslip extends Component<Props> {
  private user: any;
  private idx: number;
  private employerCostData: any;
  private employerTotalCost: number;
  constructor(props: Props) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this.idx = 1;
    this.employerCostData = [];
    this.employerTotalCost = 0;
  }
  state = {
    isDataNotFound: false,
    isDialog: false,
    employerCostPayslipData: [],
    bankAccountDetails: [],
    newPayslipData: [],
    isPayslipTaxData: false,
    isPayslipContractData: false,
    payslabsJson: [],
    payslipDialogJson: [],
    isSlabLayout: false,
    responseSummaryData: [],
  };
  handleInputChange = (e: any) => {
    var payLoad: any = {
      id: 0,
      employee: this.props.selectorData,
      period: {
        id: e.id,
      },
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Payslip`,
      payLoad,
      Common.getToken() || '',
      this.user
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let payslipemp: any = this.newPayslip(response);
        let employerRes: any = this.employerCostData;
        let bankDetailsRes: any = this.bankDetailsJson(response.employee);
        let taxableData: any = this.payeeSlabTaxableJson(response.summary);
        this.setState({
          isDataNotFound: true,
          newPayslipData: payslipemp,
          employerCostPayslipData: employerRes,
          bankAccountDetails: bankDetailsRes,
          responseSummaryData: taxableData,
        });
      })
      .catch((error: any) => {
        this.setState({
          isDataNotFound: false,
          newPayslipData: null,
          employerCostPayslipData: null,
          bankAccountDetails: null,
          responseSummaryData: null,
        });

        console.error(error);
      })
      .finally(() => {});
  };

  handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  newPayslip = (payslipdata: any) => {
    let employeePayData: any[] = [];
    let pay_data: any;
    let amount1Total: number = 0;
    let amount2Total: number = 0;
    this.employerCostData = [];
    this.employerTotalCost = 0;
    this.employerTotalCost =
      this.employerTotalCost + Number(payslipdata.summary.earnings);
    this.employerCostData.push({
      indexId: this.idx++,
      name: 'Salary',
      amount: payslipdata.summary
        ? Common.FormatNumber(
            payslipdata.summary.earnings,
            this.user.settings.formats.Decimal
          )
        : null,
    });

    Object.keys(payslipdata.paydata).forEach((level1: any) => {
      amount1Total = 0;
      amount2Total = 0;
      employeePayData.push({
        indexId: this.idx++,
        name: level1,
        amount1: 0,
        subtasks: [],
      });

      Object.keys(payslipdata.paydata[level1]).forEach((level2: any) => {
        amount2Total = 0;
        if (payslipdata.paydata[level1][level2].length > 0) {
          let pay_data2: any = employeePayData.filter(
            (x: any) => x.name.toLowerCase() === level1.toLowerCase()
          );
          if (pay_data2.length > 0) {
            pay_data2[0].subtasks.push({
              indexId: this.idx++,
              name: level2,
              amount2: 0,
              subtasks: [],
            });

            payslipdata.paydata[level1][level2]
              .sort((a: any, b: any) => {
                return a.name > b.name ? 1 : -1;
              })
              .forEach((level3: any) => {
                let pay_data3: any = pay_data2[0].subtasks.filter(
                  (x: any) => x.name.toLowerCase() === level2.toLowerCase()
                );

                if (pay_data3.length > 0 && Number(level3.amount) > 0) {
                  if (
                    !level3.payee ||
                    level3.payee.toLowerCase() !== 'employer'
                  ) {
                    pay_data3[0].subtasks.push({
                      indexId: this.idx++,
                      name: level3.name,
                      amount3: Common.FormatNumber(
                        level3.amount,
                        this.user.settings.formats.Decimal
                      ),
                      rate: level3.rate,
                      slabs: level3.slabs,
                      value: level3.value,
                      base: level3.base,
                    });
                    amount2Total += Number(level3.amount);
                    amount1Total += Number(level3.amount);
                  } else if (
                    level3.payee &&
                    level3.payee.toLowerCase() === 'employer'
                  ) {
                    this.employerTotalCost =
                      this.employerTotalCost + Number(level3.amount);
                    this.employerCostData.push({
                      indexId: this.idx++,
                      name: level3.name,
                      amount: Common.FormatNumber(
                        level3.amount,
                        this.user.settings.formats.Decimal
                      ),
                      base: level3.base,
                      value: level3.value,
                      rate: level3.rate,
                      slabs: level3.slabs,
                      subtasks: [],
                    });
                  }
                }
              });
          }

          pay_data = pay_data2[0].subtasks.filter(
            (x: any) => x.name.toLowerCase() === level2.toLowerCase()
          );
          if (pay_data.length > 0) {
            if (pay_data[0].subtasks.length > 0) {
              pay_data[0].amount2 = Common.FormatNumber(
                amount2Total,
                this.user.settings.formats.Decimal
              );
            } else {
              pay_data2[0].subtasks.splice(
                pay_data2[0].subtasks.indexOf(pay_data[0]),
                1
              );
            }
          }
        }
      });

      pay_data = employeePayData.filter(
        (x: any) => x.name.toLowerCase() === level1.toLowerCase()
      );
      if (pay_data.length > 0) {
        pay_data[0].amount1 = Common.FormatNumber(
          amount1Total,
          this.user.settings.formats.Decimal
        );
      }
    });
    employeePayData.push({
      indexId: this.idx++,
      name: 'Net Pay',
      amount1: payslipdata.summary
        ? Common.FormatNumber(
            payslipdata.summary.netPay,
            this.user.settings.formats.Decimal
          )
        : null,
    });
    this.employerCostData.push({
      indexId: this.idx++,
      name: 'Total',
      amount: Common.FormatNumber(
        this.employerTotalCost,
        this.user.settings.formats.Decimal
      ),
    });
    return employeePayData;
  };

  bankDetailsJson = (bankdetails: any) => {
    let bankDetailsData: any[] = [];
    bankDetailsData.push({
      name: 'Bank',
      value:
        bankdetails.bankAccount !== undefined
          ? bankdetails.bankAccount.bank.name
          : null,
    });
    bankDetailsData.push({
      name: 'Account',
      value:
        bankdetails.bankAccount !== undefined
          ? bankdetails.bankAccount.name
          : null,
    });
    return bankDetailsData;
  };

  onNetPayInfo(args: any) {
    if (args.data && args.data.name.toLowerCase() === 'earnings') {
      args.cell.style.fontWeight = '500';
      args.cell.style.fontSize = '14px';
    }
    if (args.data && args.data.name.toLowerCase() === 'deductions') {
      args.cell.style.fontWeight = '500';
      args.cell.style.fontSize = '14px';
    }
    if (args.data && args.data.name === 'Net Pay') {
      args.cell.style.fontWeight = '500';
      args.cell.style.fontSize = '14px';
    }

    if (
      args.data.rate >= 0 &&
      args.data.value &&
      args.data.slabs &&
      args.data.base === undefined
    ) {
      args.cell.classList.add('touch-cursor');
    }
    if (
      args.data.rate >= 0 &&
      args.data.base &&
      args.data.value &&
      !args.data.slabs
    ) {
      args.cell.classList.add('touch-cursor');
    }
  }
  onTotalInfo(args: any) {
    if (args.data && args.data.name === 'Total') {
      args.cell.style.fontWeight = '500';
      args.cell.style.fontSize = '14px';
      args.cell.classList.add('border-top');
    }
  }
  payslabsAmountTotal(args: any) {
    if (args.data && args.data.payslabTotalAmount === 'Total') {
      args.cell.style.fontWeight = '600';
      args.cell.style.fontSize = '15px';
    }
    if (args.data && args.data.taxTotal === 'Total') {
      args.cell.style.fontWeight = '600';
      args.cell.style.fontSize = '15px';
    }
  }

  payeeSlabTaxableJson = (taxdetails: any) => {
    let taxableArray: any[] = [];

    let taxableData: any = taxdetails;
    taxableArray.push({
      name: 'Basic Salary',
      amount: Common.FormatNumber(
        taxableData.earnings,
        this.user.settings.formats.Decimal
      ),
    });
    taxableData.taxableDetails.forEach((el: any) => {
      taxableArray.push({
        name: el.name,
        amount: Common.FormatNumber(
          el.value,
          this.user.settings.formats.Decimal
        ),
      });
    });
    taxableArray.push({
      taxTotal: 'Total',
      amount: Common.FormatNumber(
        taxableData.taxable,
        this.user.settings.formats.Decimal
      ),
    });
    return taxableArray;
  };

  payeeSlabsDataJson = (payslabs: any) => {
    let payeSlabsArray: any[] = [];
    payslabs.forEach((el: any) => {
      if (el.rate === 0) {
        payeSlabsArray.push({
          from: '0.00',
          to: Common.FormatNumber(
            Number(el.value),
            this.user.settings.formats.Decimal
          ),
          rate: `${(el.rate * 100).toFixed(2)}%`,
          amount: el.amount.toFixed(2),
        });
      } else {
        payeSlabsArray.push({
          from: Common.FormatNumber(
            Number(
              Number(
                payeSlabsArray[payeSlabsArray.length - 1].to.replace(',', '')
              ) + 1
            ),
            this.user.settings.formats.Decimal
          ),
          to: Common.FormatNumber(
            Number(el.value),
            this.user.settings.formats.Decimal
          ),
          rate: `${(el.rate * 100).toFixed(2)}%`,
          amount: Common.FormatNumber(
            el.amount,
            this.user.settings.formats.Decimal
          ),
        });
      }
    });
    let payslabAmountTotal: any = payslabs.reduce((total: any, el: any) => {
      return total + el.amount;
    }, 0);
    payeSlabsArray.push({
      amount: Common.FormatNumber(
        payslabAmountTotal,
        this.user.settings.formats.Decimal
      ),
      payslabAmountTotal,
      payslabTotalAmount: 'Total',
    });
    return payeSlabsArray;
  };

  contributionDataJson = (m: any, n: any, o: any, p: any) => {
    let contributionArray: any[] = [];
    contributionArray.push({
      base: m,
      value: Common.FormatNumber(n, this.user.settings.formats.Decimal),
      rate: `${(o * 100).toFixed(2)}%`,
      amount: p,
    });
    return contributionArray;
  };

  handlePayslipDialog = (e: any) => {
    if (e.rowData) {
      if (
        e.rowData.rate &&
        !e.rowData.slabs &&
        !e.rowData.value &&
        !e.rowData.base
      ) {
        this.setState({ isDialog: false });
      } else if (
        e.rowData.rate >= 0 &&
        e.rowData.base &&
        e.rowData.value &&
        !e.rowData.slabs
      ) {
        let housingData: any = this.contributionDataJson(
          e.rowData.base,
          e.rowData.value,
          e.rowData.rate,
          e.rowData.amount3
        );
        this.setState({
          isDialog: true,
          isPayslipTaxData: false,
          payslipDialogJson: housingData,
          isSlabLayout: false,
        });
      } else if (
        e.rowData.rate >= 0 &&
        e.rowData.value &&
        e.rowData.slabs &&
        e.rowData.base === undefined
      ) {
        let payslabData: any = this.payeeSlabsDataJson(e.rowData.slabs);
        this.setState({
          isDialog: true,
          isPayslipTaxData: true,
          payslabsJson: payslabData,
          isSlabLayout: true,
        });
      } else {
        this.setState({ isDialog: false });
      }
    }
  };

  payeeSlabJson() {
    return (
      <>
        {this.state.isPayslipTaxData ? (
          <div className='row  mb-3 mx-3'>
            <div className='col-12'>
              <div className='row my-3'>
                <div className='col-12'>
                  <div className='payeHeader-style'>THE FINANCE ACT, YYYY</div>
                  <div className='payeHeader-style'>
                    PART VIII, (CAP.322), Section 25
                  </div>
                  <div className='payeHeader-style'>Enforcement yyyy-mm-dd</div>
                </div>
              </div>
              <div className='row my-3'>
                <div className='payeeName col-12'>Taxable Amount</div>
              </div>
              <div className='row my-2'>
                <div className='payslab-style col-12'>
                  <TreeGridComponent
                    dataSource={this.state.responseSummaryData}
                    treeColumnIndex={1}
                    childMapping='subtasks'
                    height='auto'
                    queryCellInfo={this.payslabsAmountTotal.bind(this)}
                  >
                    <ColumnsDirective>
                      <ColumnDirective
                        field='name'
                        headerText='From'
                        width='100'
                        //textAlign='Right'
                      ></ColumnDirective>

                      <ColumnDirective
                        field='amount'
                        headerText='Amount'
                        width='160'
                        textAlign='Right'
                      ></ColumnDirective>
                    </ColumnsDirective>
                  </TreeGridComponent>
                </div>
              </div>
              <div className='row mt-4 '>
                <div className='payeeName col-12'>Payee</div>
              </div>

              <div className='row my-2'>
                <div className='payslab-style col-12'>
                  <TreeGridComponent
                    dataSource={this.state.payslabsJson}
                    treeColumnIndex={1}
                    childMapping='subtasks'
                    height='auto'
                    queryCellInfo={this.payslabsAmountTotal.bind(this)}
                  >
                    <ColumnsDirective>
                      <ColumnDirective
                        field='from'
                        headerText='From'
                        width='90'
                        textAlign='Right'
                      ></ColumnDirective>

                      <ColumnDirective
                        field='to'
                        headerText='To'
                        width='80'
                        textAlign='Right'
                      ></ColumnDirective>
                      <ColumnDirective
                        field='rate'
                        headerText='Rate'
                        width='60'
                        textAlign='Right'
                      ></ColumnDirective>
                      <ColumnDirective
                        field='amount'
                        headerText='Amount'
                        width='80'
                        textAlign='Right'
                      ></ColumnDirective>
                    </ColumnsDirective>
                  </TreeGridComponent>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='row'>
            <div className='payslab-style col-12'>
              <TreeGridComponent
                dataSource={this.state.payslipDialogJson}
                treeColumnIndex={1}
                childMapping='subtasks'
                height='auto'
                queryCellInfo={this.payslabsAmountTotal.bind(this)}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field='base'
                    headerText='Base'
                    width='90'
                    //textAlign='Right'
                  ></ColumnDirective>

                  <ColumnDirective
                    field='value'
                    headerText='Value'
                    width='80'
                    textAlign='Right'
                  ></ColumnDirective>
                  <ColumnDirective
                    field='rate'
                    headerText='Rate'
                    width='60'
                    textAlign='Right'
                  ></ColumnDirective>
                  <ColumnDirective
                    field='amount'
                    headerText='Amount'
                    width='80'
                    textAlign='Right'
                  ></ColumnDirective>
                </ColumnsDirective>
              </TreeGridComponent>
            </div>
          </div>
        )}
      </>
    );
  }

  render() {
    return (
      <>
        <div className='row mx-3 mt-3' onContextMenu={this.handleContextMenu}>
          <div className='col-12'>
            <PeriodSelector
              id='payslip-period'
              handleInputChange={this.handleInputChange}
              _jObject={this.props.selectorData}
              showEmployeePeriodsOnly={true}
              showLastPeriod={false}
            />
          </div>
        </div>
        {this.state.isDataNotFound && (
          <div
            className='row mx-2'
            id='payslip-style'
            onContextMenu={this.handleContextMenu}
          >
            <div className='control-pane col-12 h-100'>
              <div className='control-section row h-100'>
                <div className='employee-payslip col-md-8'>
                  <div className='row'>
                    <div className='payslip-container col-12'>
                      {this.state.newPayslipData && (
                        <TreeGridComponent
                          dataSource={this.state.newPayslipData}
                          treeColumnIndex={1}
                          childMapping='subtasks'
                          selectionSettings={{
                            type: 'Multiple',
                            mode: 'Both',
                          }}
                          queryCellInfo={this.onNetPayInfo.bind(this)}
                          recordDoubleClick={this.handlePayslipDialog.bind(
                            this
                          )}
                        >
                          <ColumnsDirective>
                            <ColumnDirective
                              field='indexId'
                              headerText=''
                              width='0'
                              textAlign='Left'
                            ></ColumnDirective>
                            <ColumnDirective
                              field='name'
                              headerText=''
                              width='40%'
                            ></ColumnDirective>
                            <ColumnDirective
                              field='amount3'
                              headerText=''
                              width='20%'
                              textAlign='Right'
                            />
                            <ColumnDirective
                              field='amount2'
                              headerText=''
                              width='20%'
                              textAlign='Right'
                            />
                            <ColumnDirective
                              field='amount1'
                              headerText=''
                              width='20%'
                              textAlign='Right'
                            />
                          </ColumnsDirective>
                        </TreeGridComponent>
                      )}
                    </div>
                  </div>
                </div>
                <div className='employer-payslip col-md-4 '>
                  <div className='row'>
                    <div className='payslip-container col-12'>
                      {this.state.employerCostPayslipData && (
                        <TreeGridComponent
                          dataSource={this.state.employerCostPayslipData}
                          height='auto'
                          queryCellInfo={this.onTotalInfo.bind(this)}
                        >
                          <ColumnsDirective>
                            <ColumnDirective
                              field='indexId'
                              headerText=''
                              width='0'
                              textAlign='Left'
                            ></ColumnDirective>
                            <ColumnDirective
                              field='name'
                              headerText='Employer Cost'
                              width='40%'
                            ></ColumnDirective>
                            <ColumnDirective
                              field='amount'
                              headerText=''
                              width='60%'
                              textAlign='Right'
                            ></ColumnDirective>
                          </ColumnsDirective>
                        </TreeGridComponent>
                      )}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='payslip-container col-12'>
                      {this.state.bankAccountDetails ? (
                        <TreeGridComponent
                          dataSource={this.state.bankAccountDetails}
                          height='auto'
                        >
                          <ColumnsDirective>
                            <ColumnDirective
                              field='indexId'
                              headerText=''
                              width='0'
                              textAlign='Left'
                            ></ColumnDirective>
                            <ColumnDirective
                              field='name'
                              headerText='Payments'
                              width='30%'
                            ></ColumnDirective>

                            <ColumnDirective
                              field='value'
                              headerText=''
                              width='70%'
                              textAlign='Right'
                            ></ColumnDirective>
                          </ColumnsDirective>
                        </TreeGridComponent>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          {this.state.isDialog ? (
            <DialogComponent
              id='contract-dialog'
              showCloseIcon={true}
              visible={this.state.isDialog}
              width={'25vw'}
              height='auto'
              close={() => this.setState({ isDialog: false })}
              isModal={true}
              content={this.payeeSlabJson.bind(this)}
              statelessTemplates={[]}
            ></DialogComponent>
          ) : null}
        </div>
      </>
    );
  }
}

export default Payslip;
