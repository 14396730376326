interface props1 {
  nextStep: () => void;

  //   handleFormData: (
  //     input: string
  //   ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  //   values: {
  //     firstName: string;
  //     lastName: string;
  //     age: string;
  //     email: string;
  //   };
}

const Identity = (props1: any) => {
  const submitFormData = (e: any) => {
    e.preventDefault();
    props1.nextStep();
  };

  return (
    <>
      <div className='row ' id='Identity'>
        <div className='col-12'>
          <form onSubmit={submitFormData}>
            <div className='row my-2 mx-5' style={{ height: '257px' }}>
              <div className='col-6'>
                <div className='my-3'>
                  <input
                    type='text'
                    className='form-control'
                    id='username'
                    placeholder='Username'
                    style={{
                      border: 'none',
                      borderBottom: '1px solid rgb(237,125,49)',
                    }}
                  />
                </div>
                <div className='my-3'>
                  <input
                    type='password'
                    className='form-control'
                    id='password'
                    placeholder='Password'
                    style={{
                      border: 'none',
                      borderBottom: '1px solid rgb(237,125,49)',
                    }}
                  />
                </div>
                <div className='my-3'>
                  <input
                    type='password'
                    className='form-control'
                    id='cfm_password'
                    placeholder='Verify Password'
                    style={{
                      border: 'none',
                      borderBottom: '1px solid rgb(237,125,49)',
                      borderRadius: 'none',
                    }}
                  />
                </div>
                <div className='my-4'>
                  <button
                    type='submit'
                    className='btn'
                    style={{
                      backgroundColor: 'rgb(237,125,49)',
                      color: '#fff',
                    }}
                  >
                    Signup with Google
                  </button>
                </div>
              </div>
              <div
                className='col-4 mx-5'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p>
                  By Entering your Data or 'Continue with Google', you agree to
                  the <a href='#'>Aruti TOS</a> and
                  <a href='#'>Privacy Policy</a>.
                </p>
              </div>
            </div>

            <div className='row'>
              <div
                className='col-12'
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'center',
                }}
              >
                <button className='flat-button fs-6'>Exit</button>

                <button type='submit' className='flat-button mx-1 fs-6'>
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Identity;
