const learninglist = `<?xml version="1.0" encoding="UTF-8"?>
<Learning>
	<DataValue type="Education">
		<Grid rows="1" class="classOne classTwo classThree">
			<Data source="education">
				<Cards>
					<Card col="5" rows="2" flow="left" margin="20,0,0,40">
						<Grid col="12" rows="2">
							<Text row="1" cols="12">
								<Properties alignment="right" padding="5" margin="1" spacing="1" lineheight="1"/>
								<Value source="date" type="date"/>
								<Font size="13"  />
							</Text>
							<Label row="2" cols="3">
                                <Font family="calibri" size="15" color="black" style="1" />
								<Value>Level</Value>
							</Label>
							<Text row="2" cols="9">
								<Value source="level"/>
							</Text>
							<Label row="3" cols="3">
                                <Font family="calibri" size="15" color="black" style="1" />
								<Value>Qualification</Value>
							</Label>
							<Text row="3" cols="9">
								<Value source="qualification"/>
							</Text>
							<Label row="4" cols="3">
                                <Font family="calibri" size="15" color="black" style="1" />
								<Value>Institute</Value>
							</Label>
							<Text row="4" cols="9">
								<Value source="institute"/>
							</Text>
							<Label row="5" cols="12">
								<Properties alignment="right" padding="1" margin="1" spacing="1" lineheight="1" />
								<Font size="20" color="black" icon="fa-edit" show="false" />
							</Label>
						</Grid>
					</Card>
				</Cards>
			</Data>
		</Grid>
	</DataValue>
	<DataValue type="Experience">
		<Grid rows="1" class="classOne classTwo classThree">
			<Data source="experience">
				<Cards>
					<Card col="5" rows="2" flow="left" margin="20,0,0,40">
						<Grid col="12" rows="2">
							<Text row="1" cols="9">
								<Properties alignment="right"/>
								<Value source="dateStart" type="date"/>
								<Font size="13"  />
							</Text>
                            <Text row="1" cols="3">
								<Properties alignment="left"/>
								<Value source="dateEnd" type="date"/>
								<Font size="13"  />
							</Text>
							<Label row="2" cols="3">
                                <Font family="calibri" size="15" color="black" style="1" />
								<Value>Company</Value>
							</Label>
							<Text row="2" cols="9">
								<Value  source="company"/>
							</Text>
							<Label row="3" cols="3">
                                <Font family="calibri" size="15" color="black" style="1" />
								<Value>Title</Value>
							</Label>
							<Text row="3" cols="9">
								<Value  source="title"/>
							</Text>
							<Label row="4" cols="12">
								<Properties alignment="right" padding="1" margin="1" spacing="1" lineheight="1" />
								<Font size="20" color="black" icon="fa-edit" show="false" />
							</Label>					
						</Grid>
					</Card>
				</Cards>
			</Data>
		</Grid>
	</DataValue>
</Learning>`;

export default learninglist;
