// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SANDEEP - 2023-07-31 - 60 - Status Review Meeting.
// * SANDEEP - 2023-08-02 - 120 - Checking syncfusion gantt chart and getting accoquinted with it
// * Sandeep - 2023-08-24 - 300 -- Working on Changes given for fixing in ppt by fabio (Latency In progress)
// * -----------------------------------------------------------------------------------------------------------------2.Initialize

import React, { ReactNode, startTransition, useEffect } from 'react';
import Common from '../../Util/Common';
import './YearlyCalendar.css';
import {
  ContextMenuComponent,
  MenuItemModel,
  MenuEventArgs,
} from '@syncfusion/ej2-react-navigations';
import { leave_contextmenu } from '../../dummydata/xml_data_employee';

import { start } from 'repl';
import { count, time } from 'console';

const {
  REACT_APP_ENDPOINT_HOLIDAYPATH,
  REACT_APP_ENDPOINT_EMPLOYEESERVICE,
  REACT_APP_ENDPOINT_FOXSYSTEMSERVICE,
} = process.env;

interface _timeline {
  _years: number[];
  // _employeeId: number;
  _employee: any;
  _firstDayOfWeek: number;
  _accountid?: number;
  _showWeekSeparators: boolean;
  _forceFullWeeks: boolean;
  _cmenuitems: any;
  _reversemonthorder?: boolean;
  _absences: any;
  _minYear: number;
  _issue_calender_days: boolean;
}
// * ------------------------------------------------------------------------------------------------------------------3.Style

/*
UNCOMMENT IF XML2JS ARE REQUIRED
*/
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

class YearlyCalanderView extends React.Component<_timeline> {
  // * ----------------------------------------------------------------------------------------------------------------2.Initialize Component
  //private holiday: any[] = [];
  private holiday: { [key: number]: any } = {};
  private absences: any = undefined;
  private user: any;
  private _structure: any;
  private _fulljson: any;

  private dragevt: any = false;
  private cMenu: ContextMenuComponent | null = null;
  private menuItem: MenuItemModel[] = [];
  private contextmenu: any = undefined;
  private cntxitems: MenuItemModel[] = [];
  private _objectId: any;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this._fulljson = JSON.parse(Common.getFullJson() ?? '');

    var _result: any;
    parser.parseString(leave_contextmenu, function (err: any, result: any) {
      _result = JSON.parse(JSON.stringify(result));
    });
    this.cntxitems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item
    ) as MenuItemModel[];
  }

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    selectedDay: new Date(),
    selectedRange: [,],
    selectRange: false,
    selectingRange: [,],
    _holiday: {},
    _absences: undefined,
    _menuitems: undefined,
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount() {
    if (this.isEmpty(this.state._holiday) == true) {
      if (
        this.props._years !== undefined &&
        this.props._years.length > 0 &&
        this.props._years[0] !== undefined
      ) {
        this.props._years.map((_year: any) => {
          this.GetHoliday(
            _year.toString() + '-01-01',
            _year.toString() + '-12-31',
            _year
          );
        });
      }
    }
    // if (
    //   this.state._absences == undefined &&
    //   this.props._latestyear == Math.max(...this.props._years)
    // ) {
    //   var minYear = Math.min(...this.props._years);
    //   var maxYear = Math.max(...this.props._years);
    //   this.GetAbsences(
    //     minYear.toString() + '-01-01',
    //     maxYear.toString() + '-12-31'
    //   );
    // }
    if (this.contextmenu == undefined) {
      this.GetContextMenu();
    }
  }

  onUp(day: any, classes: any, timestamp: any, _dragevt: boolean) {
    if (day.nativeEvent.button == 0) {
      this.dragevt = _dragevt;
      // this.dayClicked1(day, classes, timestamp);
      if (_dragevt == false) {
        const { selectingRange } = this.state;

        if (selectingRange) {
          // selectingRange[1] = hoveredDay;
          selectingRange[1] = timestamp;
          this.setState({
            selectingRange,
          });
        }
      }
    } else if (day.nativeEvent.button == 2) {
      day.preventDefault();
    }
  }

  onClick(day: any, classes: any, timestamp: any, _dragevt: boolean) {
    if (day.nativeEvent.button == 0) {
      this.dragevt = _dragevt;
      this.dayClicked1(day, classes, timestamp);
    }
  }

  onHover(day: any, timestamp: any) {
    this.dayHovered(day, timestamp);
  }

  handleMenuClick = (e: any) => {
    if (this.isEmpty(this.state.selectedRange) === false) {
      let start = (this.state.selectingRange || this.state.selectedRange)[0];
      let end = (this.state.selectingRange || this.state.selectedRange)[1];
      var _start = start !== undefined ? start : '';
      var _end = end !== undefined ? end : '';
      if (_start !== '' && _end !== '') {
        this.AddLeave(_start, _end, e.element.id);
      }
    }
  };

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SANDEEP - 2023-07-17 - 420 - Create Component & Methods.
  // * SANDEEP - 2023-07-18 - 460 - Called Methods and CSS Application.
  // * SANDEEP - 2023-07-20 - 300 - Created the room for Fetching holidays and working on rendetiing it.
  // * SANDEEP - 2023-07-21 - 400 - Working on Holiday Rendering based on state and some css changes.
  // * SANDEEP - 2023-07-24 - 460 - Completed Holiday Rendering based on state and css changes for display of full monthname and holiday name on hover.
  // * SANDEEP - 2023-07-25 - 460 - Working on Selection and Holiday Rendering with css changes.
  // * SANDEEP - 2023-07-26 - 300 - Working on Selection on click and on hover along with circle on today's date.
  // * SANDEEP - 2023-07-27 - 460 - Working on Absense API For getting Data and rendring absense.
  // * SANDEEP - 2023-07-28 - 300 - Working on Absense API For getting Data and rendring absense and trying to display name and getting counts.
  // * SANDEEP - 2023-07-31 - 420 - Working Logic to display Absense name and days along with the captions for absense.
  // * SANDEEP - 2023-08-01 - 420 - Working Logic to display Absense name and days along with the captions for absense.
  // * SANDEEP - 2023-08-02 - 300 - Working on selection on dates for range and data display for selected day range in css.
  // * SANDEEP - 2023-08-03 - 420 - Working on selection on dates for range and data display for selected day range in css.
  // * SANDEEP - 2023-09-14 - 120 - Worked on adding leave application from context menu.
  // * Sandeep - 2023-10-03 - 240 - Solving issue with changes sent and doing code modifications
  // * Sandeep - 2023-10-04 - 240 - Solving issue with changes sent and doing code modifications
  // * Sandeep - 2023-10-05 - 120 - Displaying the menu and applying the leave.

  private addDays(_date: Date, _days: number) {
    var date: Date = _date;
    date.setDate(date.getDate() + _days);
    return date;
  }

  private setContextMenuItems() {
    // for (let i = 0; i < this.props._cmenuitems.length; i++) {
    // 	var mnuitem = this.props._cmenuitems[i];
    // 	this.menuItem.push({ text: mnuitem.$.text });
    // }
    // if (this.state._menuitems !== undefined) {
    //   let cntxmenu = Common.FindJsonByRelationFieldId(
    //     this.contextmenu.children,
    //     this._structure.MenuElements.CntxtMenuElements.CntxMenuObject,
    //     this.user
    //   ); //9150 = CntxMenu
    //   if (cntxmenu !== null) {
    //     let cntxmenuitems: MenuItemModel[] | undefined = Common.GetContextMenu(
    //       cntxmenu.children,
    //       this._structure,
    //       this.user
    //     );
    //     cntxmenuitems = cntxmenuitems.filter(
    //       (x: any) => !x.text.includes('Re-Entry')
    //     );
    //     if (cntxmenuitems) this.menuItem = cntxmenuitems;
    //   }
    // }
    let _objectId = Common.FindJsonByObjectValue(
      this._fulljson,
      'LeaveCalendarContxMenu',
      this.user
    );

    if (_objectId !== null) {
      let menu = Common.FindJsonByObjectId(
        this._fulljson,
        _objectId.object,
        this.user
      );
      this.contextmenu = menu;

      let cntxmenu = Common.FindJsonByRelationFieldId(
        this.contextmenu.children,
        this._structure.MenuElements.CntxtMenuElements.CntxMenuObject,
        this.user
      ); //9150 = CntxMenu
      if (cntxmenu !== null) {
        let cntxmenuitems: MenuItemModel[] | undefined = Common.GetContextMenu(
          cntxmenu.children,
          this._structure,
          this.user
        );
        cntxmenuitems = cntxmenuitems.filter(
          (x: any) => !x.text.includes('Re-Entry')
        );
        // let menu = Common.FindJsonByObjectId(
        //   this._fulljson,
        //   _objectId.object,
        //   this.user
        // );

        // if (menu !== undefined) {
        //   this.contextmenu = menu;
        // }

        // let cntxmenuitems: MenuItemModel[] | undefined = Common.GetContextMenu(
        //   (this.contextmenu as any).children,
        //   this._structure.MenuElements.CntxtMenuElements.CntxMenuObject,
        //   this.user
        // );

        for (let i = 0; i < cntxmenuitems.length; i++) {
          this.cntxitems[0].items?.splice(1, 0, cntxmenuitems[i]);
        }
        this.menuItem = this.cntxitems;
      } else {
        this.menuItem = this.cntxitems;
      }
    } else {
      this.menuItem = this.cntxitems;
    }
    this.menuItem.splice(
      this.menuItem.indexOf(
        this.menuItem.filter((x: any) => x.text == 'Delete')[0]
      ) - 1,
      this.menuItem.indexOf(
        this.menuItem.filter((x: any) => x.text == 'Delete')[0]
      )
    );
  }

  private range(start: any, stop: any, step?: any) {
    if (stop == null) {
      stop = start || 0;
      start = 0;
    }
    step = step || 1;

    const length = Math.max(Math.ceil((stop - start) / step), 0);
    const range = Array(length);

    for (let idx = 0; idx < length; idx++, start += step) {
      range[idx] = start;
    }

    return range;
  }

  private async GetContextMenu() {
    /* THIS IS TEMP CODE TO BE REVISED ONCE WE HAVE THE MENU IN PLACE - START*/
    let x = Common.FindJsonByObjectValue(
      this._fulljson,
      'LeaveCalendarContxMenu',
      this.user
    );
    let objectid = 0;
    if (x !== undefined) {
      objectid = x.object;
    }

    let menu = Common.FindJsonByObjectId(this._fulljson, objectid, this.user);

    this._objectId = objectid;
    this.contextmenu = menu;
    this.setState({ _menuitems: this.contextmenu });
    /* THIS IS TEMP CODE TO BE REVISED ONCE WE HAVE THE MENU IN PLACE - END*/

    // Common.ApiCallAsync(
    //   'GET',
    //   `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/JsonTree?objectid=${objectid}`,
    //   null,
    //   Common.getToken() || '',
    //   this.user
    // )
    //   .then((resopnse: any) => {
    //     if (this.isEmpty(resopnse.data) == false) {
    //       this._objectId = resopnse.data[0].object;
    //       this.contextmenu = resopnse.data[1];
    //       this.setState({ _menuitems: this.contextmenu });
    //     }
    //   })
    //   .catch((error: any) => {
    //     console.error(error);
    //   })
    //   .finally(() => {});
  }

  private async GetAbsences(_employee?: any) {
    var payLoad: any;
    if (_employee! == undefined) {
      payLoad = { employee: this.props._employee };
    } else {
      payLoad = { employee: _employee };
    }
    // var payLoad: any = {
    //   employee: this.props._employee,
    //   // period: {
    //   //   datestart: dateStart,
    //   //   dateend: dateEnd,
    //   // },
    // };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/Absences`,
      payLoad,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        this.absences = resopnse.data;
        this.setState({
          _absences: this.absences,
          selectedRange: [,],
          selectingRange: [,],
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private async AddLeave(
    dateStart: string,
    dateEnd: string,
    leaveAccountId: number
  ) {
    var payLoad: any = {
      //Employeeid: this.props._employeeId,
      employee: this.props._employee,
      accountid: leaveAccountId,
      period: {
        datestart: Common.formatDate(new Date(dateStart), 'yyyy-MM-dd'),
        dateend: Common.formatDate(new Date(dateEnd), 'yyyy-MM-dd'),
      },
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Dossier/Absences/Set`,
      payLoad,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        // var minYear = Math.min(...this.props._years);
        // var maxYear = Math.max(...this.props._years);
        // this.GetAbsences(
        //   minYear.toString() + '-01-01',
        //   maxYear.toString() + '-12-31'
        // );
        this.GetAbsences(resopnse.data);
        // this.absences = resopnse.data;
        // this.setState({ _absences: this.absences });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private async GetHoliday(dateStart: string, dateEnd: string, _year: number) {
    var payLoad: any = {
      country: { id: this.user.company.registration.country.id },
      language: this.user.language,
      period: {
        dateStart: dateStart,
        dateEnd: dateEnd,
      },
      redis: 1,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_HOLIDAYPATH}`,
      payLoad,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        this.holiday[_year] = resopnse;
        if (Math.min(...this.props._years) === _year) {
          this.setState({ _holiday: this.holiday });
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private processCaptions(_year: number) {
    var _caption: any = [];

    var _absent: any;
    _absent = this.state._absences;
    if (_absent == undefined) {
      _absent = this.props._absences;
    }

    if (_absent !== undefined) {
      var _accounts: any = [];
      // _accounts = Object.values(this.state._absences).filter((x: any) =>
      //   x.Dates.find((y: any) => new Date(y).getFullYear() == _year)
      // );
      _accounts = Object.values(_absent).filter(
        (x: any) => new Date(x.Date).getFullYear() == _year
      );
      _accounts.sort((x: any, y: any) => x.Account.id - y.Account.id);

      var resArr: any = [];
      _accounts.filter(function (item: any) {
        var i = resArr.findIndex(
          (x: any) => x.Account.Name == item.Account.Name
        );
        if (i <= -1) {
          resArr.push(item);
        }
        return [];
      });

      var _name: any = '';
      var _totaldays: number = 0;

      if (resArr !== undefined && resArr.length > 0) {
        for (let i = 0; i < resArr.length; i++) {
          var _len = Object.values(_accounts).filter(
            (x: any) => x.Account.Name == resArr[i].Account.Name
          ).length;

          if (_len == undefined) {
            _len = 0;
          }
          if (_name !== resArr[i].Account.Name) {
            _caption.push(resArr[i].Account.Name + ' : ' + _len.toString());
            _name = resArr[i].Account.Name;
          } else {
            var _item = _caption.filter(
              (x: any) => x.split(':')[0].trim() == resArr[i].Account.Name
            );
            if (_item !== undefined && _item.length > 0) {
              var _index = _caption.indexOf(_item[0]);
              _caption.splice(_index, 1);
              _totaldays = parseInt(_item[0].split(':')[1]);
              _totaldays = _totaldays + _len;
              _caption.push(
                _item[0].split(':')[0].trim() + ' : ' + _totaldays.toString()
              );
            }
          }
        }
      }
    }
    return _caption.join('\n');
  }

  private isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  private DaysOfWeek(
    firstDayOfWeek: number,
    forceFullWeeks: boolean,
    showWeekSeparators: boolean,
    _year: number
  ) {
    var cday = firstDayOfWeek;
    const dates = [
      new Date(2023, 6, 2),
      new Date(2023, 6, 3),
      new Date(2023, 6, 4),
      new Date(2023, 6, 5),
      new Date(2023, 6, 6),
      new Date(2023, 6, 7),
      new Date(2023, 6, 8),
    ];
    var fday = dates.length - 1;
    const totalDays = forceFullWeeks ? 42 : 37;
    const days: any = [];
    this.range(firstDayOfWeek, totalDays + firstDayOfWeek).forEach((i) => {
      if (cday > fday) {
        cday = 0;
      }
      const day = Common.formatDate(new Date(dates[cday]), 'EEE').charAt(0);
      cday++;
      if (showWeekSeparators) {
        if (i % 7 === firstDayOfWeek && days.length) {
          // push week separator
          days.push(<th className='week-separator' key={`seperator-${i}`} />);
        }
      }
      days.push(<th key={`weekday-${i}`}>{day}</th>);
    });
    return (
      <tr>
        <th style={{ textAlign: 'right' }}>
          <span style={{ fontSize: '13pt' }}>{_year}</span>
        </th>
        {days}
      </tr>
    );
  }

  // private setAbsense(idate: any, classes: any) {
  //   var _cvalue: any;
  //   if (this.state._absences !== undefined) {
  //     var _accounts: any = [];
  //     _accounts = Object.values(this.state._absences).filter((x: any) =>
  //       x.Dates.find((y: any) => new Date(y).getTime() == idate.getTime())
  //     );
  //     if (_accounts !== undefined && _accounts.length > 0) {
  //       if (
  //         (classes.includes('next-month') || classes.includes('prev-month')) ==
  //         false
  //       ) {
  //         _cvalue =
  //           _accounts[0].Account.Color + '|' + _accounts[0].Account.Name;
  //       }
  //     }
  //   }
  //   return _cvalue;
  // }

  private setAbsense(idate: any, classes: any) {
    var _absent: any;
    _absent = this.state._absences;
    if (_absent == undefined) {
      _absent = this.props._absences;
    }
    var _cvalue: any;
    if (_absent !== undefined) {
      var _accounts: any = [];
      _accounts = Object.values(_absent).filter(
        (x: any) => new Date(x.Date).getTime() == idate.getTime()
      );

      if (_accounts == undefined || _accounts.length <= 0) {
        if (this.state._absences !== undefined) {
          _accounts = Object.values(_absent).filter(
            (x: any) => new Date(x.Date).getTime() == idate.getTime()
          );
          if (_accounts !== undefined && _accounts.length > 0) {
          }
        }
      }

      if (_accounts !== undefined && _accounts.length > 0) {
        if (
          (classes.includes('next-month') || classes.includes('prev-month')) ==
          false
        ) {
          _cvalue =
            _accounts[0].Account.Color + '|' + _accounts[0].Account.Name;
        }
      }
    }
    return _cvalue;
  }

  private setHoliday(idate: any, year: any) {
    var _cvalue = '';
    if (this.isEmpty(this.state._holiday) == false) {
      var idx: number = Object.keys(this.state._holiday).indexOf(
        year.toString()
      );
      var _holidays: any;
      _holidays = Object.values(this.state._holiday).at(idx);
      if (_holidays !== undefined) {
        var _holiday: any = Object.entries(
          _holidays[Object.keys(_holidays)[0]]
        )[0][1];
        Object.values(_holiday).map((val: any) => {
          let date = new Date(val.toString());
          if (idate.getTime() == date.getTime()) {
            var _name: any = Object.keys(_holiday)
              .filter((key) => _holiday[key] === val)
              .join('\n');
            _cvalue = _name;
          }
        });
      }
    }

    return _cvalue;
  }

  private MonthDays(
    year: number,
    month: number,
    firstDayOfWeek: number,
    forceFullWeeks: boolean,
    showWeekSeparators: boolean,
    holidayrange: any[]
  ) {
    const monthStart = new Date(year, month, 1); // current day
    let prevMonthDaysCount = this.weekdaynumber(monthStart);
    while (prevMonthDaysCount < firstDayOfWeek) {
      prevMonthDaysCount += 7;
    }
    const numberOfDays = this.daysInMonth(year, month);
    const totalDays = forceFullWeeks ? 42 : 37;

    // day-generating loop
    const days: any = [];
    this.range(firstDayOfWeek + 1, totalDays + firstDayOfWeek + 1).forEach(
      (i) => {
        var day = new Date(year, month, i - prevMonthDaysCount);

        // pick appropriate classes
        const classes: any = [];
        const title = day ? day : undefined;

        if (day.getTime() < new Date().getTime()) {
          //classes.push('prev-day');
        }

        if ((i - 7) % 7 === 0) {
          // Saturday
          classes.push('saturdaycolor');
        }

        if ((i - 1) % 7 === 0) {
          // sunday
          classes.push('weekendcolor');
        }

        if (i <= prevMonthDaysCount) {
          classes.push('prev-month');
        } else if (i > numberOfDays + prevMonthDaysCount) {
          classes.push('next-month');
        } else {
          if (this.isEmpty(this.state.selectedRange) === false) {
            if (
              this.state.selectedRange[0] !== undefined &&
              day.getFullYear() ===
                new Date(this.state.selectedRange[0]).getFullYear()
            ) {
              // selectingRange is used while user is selecting a range
              // (has clicked on start day, and is hovering end day - but not yet clicked)
              let start = (this.state.selectingRange ||
                this.state.selectedRange)[0];
              let end = (this.state.selectingRange ||
                this.state.selectedRange)[1];

              var _start = start !== undefined ? new Date(start) : undefined;
              var _end = end !== undefined ? new Date(end) : undefined;

              // validate range
              if (_end !== undefined && _start !== undefined && _end < _start) {
                [end, start] =
                  this.state.selectingRange || this.state.selectedRange;
              }

              if (
                _end !== undefined &&
                _start !== undefined &&
                day.getTime() >= new Date(_start).getTime() &&
                day.getTime() <= new Date(_end).getTime()
              )
                if (
                  this.props._issue_calender_days == false &&
                  (classes.includes('saturdaycolor') ||
                    classes.includes('weekendcolor')) == false
                ) {
                  classes.push('range');
                } else if (this.props._issue_calender_days == true) {
                  classes.push('range');
                }

              // if (
              //   _end !== undefined &&
              //   _start !== undefined &&
              //   day.getTime() >= new Date(_start).getTime() &&
              //   day.getTime() <= new Date(_end).getTime()
              // )
              //   if (
              //     (classes.includes('prev-day') ||
              //       classes.includes('saturdaycolor') ||
              //       classes.includes('weekendcolor')) == false
              //   ) {
              //     classes.push('range');
              //   }
            }
          }

          // if (day.isSame(start, "day")) {
          if (_start !== undefined && day == _start) {
            classes.push('range-left');
          }

          // if (day.isSame(end, "day")) {
          if (_end !== undefined && day == _end) {
            classes.push('range-right');
          }
        }

        if (year === new Date().getFullYear()) {
          if (
            Common.formatDate(day, 'yyyyMMdd') ===
            Common.formatDate(new Date(), 'yyyyMMdd')
          ) {
            if (
              (classes.includes('next-month') ||
                classes.includes('prev-month')) == false
            ) {
              classes.push('selected');
            }
          }
        }

        if (day.getTime() > new Date().getTime()) {
          classes.push('gray-day');
        }

        if (showWeekSeparators) {
          if ((i - 1) % 7 === firstDayOfWeek && days.length) {
            // push week separator
            days.push(<td className='week-separator' key={`seperator-${i}`} />);
          }
        }

        var _jsxElement: any[] = [];
        var _absent = this.setAbsense(day, classes);
        var _holiday = this.setHoliday(day, year);

        let _eleid = '';

        if (
          (classes.includes('next-month') || classes.includes('prev-month')) ==
          false
        ) {
          _eleid = day.getTime().toString();
        }

        if (
          _absent !== undefined &&
          _absent !== '' &&
          _holiday !== undefined &&
          _holiday !== ''
        ) {
          _jsxElement.push(
            <>
              <td
                style={{ background: _absent.split('|')[0] }}
                className={classes.join(' ')}
                title={_absent.split('|')[1] + '\n' + _holiday}
                id={_eleid}>
                {classes.includes('next-month') ||
                classes.includes('prev-month') == true ? (
                  <span></span>
                ) : (
                  <span
                    className='holiday-number'
                    style={{
                      fontWeight: 'bold',
                      color: 'red',
                    }}>
                    {day === null ? '' : day.getDate()}
                  </span>
                )}
              </td>
            </>
          );
        }
        if (
          _absent !== undefined &&
          _absent !== '' &&
          (_holiday == undefined || _holiday == '')
        ) {
          _jsxElement.push(
            <>
              <td
                style={{ background: _absent.split('|')[0] }}
                className={classes.join(' ')}
                title={_absent.split('|')[1]}
                id={_eleid}>
                {classes.includes('next-month') ||
                classes.includes('prev-month') == true ? (
                  <span></span>
                ) : (
                  <span>{day === null ? '' : day.getDate()}</span>
                )}
              </td>
            </>
          );
        }

        if (
          _holiday !== undefined &&
          _holiday !== '' &&
          (_absent == undefined || _absent == '')
        ) {
          _jsxElement.push(
            <>
              <td className={classes.join(' ')} title={_holiday} id={_eleid}>
                {classes.includes('next-month') ||
                classes.includes('prev-month') == true ? (
                  <span></span>
                ) : (
                  <span
                    className='holiday-number'
                    style={{
                      fontWeight: 'bold',
                      color: 'red',
                    }}>
                    {day === null ? '' : day.getDate()}
                  </span>
                )}
              </td>
            </>
          );
        }
        if (
          (_absent == undefined || _absent == '') &&
          (_holiday == undefined || _holiday == '')
        ) {
          _jsxElement.push(
            <>
              <td
                onMouseDown={(d) =>
                  this.onClick(d, classes.join(' '), day.getTime(), true)
                }
                onMouseMove={(d) => this.onHover(d, day.getTime())}
                onMouseUp={(d) =>
                  this.onUp(d, classes.join(' '), day.getTime(), false)
                }
                onContextMenu={(e) =>
                  this.rightClick(e, day.getTime().toString())
                }
                className={classes.join(' ')}
                style={{ width: '2%' }}
                id={_eleid}>
                {classes.includes('next-month') ||
                classes.includes('prev-month') == true ? (
                  <span></span>
                ) : (
                  <span className='day-number'>
                    {day === null ? '' : day.getDate()}
                  </span>
                )}
              </td>
            </>
          );
        }
        days.push(_jsxElement[0]);
      }
    );

    return days;
  }

  private daysInMonth(year: number, month: number) {
    if (isNaN(year) || isNaN(month)) {
      return NaN;
    }
    var modMonth: number = this.mod(month, 12);
    year += (month - modMonth) / 12;
    return modMonth === 1
      ? this.isLeapYear(year)
        ? 29
        : 28
      : 31 - ((modMonth % 7) % 2);
  }

  private mod(num1: number, num2: number) {
    return ((num1 % num2) + num2) % num2;
  }

  private isLeapYear(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  private daysInYear(year: number) {
    return this.isLeapYear(year) ? 366 : 365;
  }

  private weekdaynumber(date: Date) {
    return date.getDay();
  }

  private processMonthDays(
    year: number,
    firstDayOfWeek: number,
    forceFullWeeks: boolean,
    showWeekSeparators: boolean
  ) {
    var _holiday: any; //this.getYearlyHoliday(year);
    var _months = this.range(0, 12);
    if (
      this.props._reversemonthorder !== undefined &&
      this.props._reversemonthorder === true
    ) {
      _months.reverse();
    }
    var _jsxElement: any[] = [];
    for (var i = 0; i < _months.length; i++) {
      _jsxElement.push(
        <>
          <tr>
            <td className='month-name'>
              {Common.formatDate(new Date(year, _months[i], 1), 'MMMM')}
            </td>
            {this.MonthDays(
              year,
              _months[i],
              firstDayOfWeek,
              forceFullWeeks,
              showWeekSeparators,
              _holiday
            )}
          </tr>
        </>
      );
    }
    return _jsxElement;
  }

  private datePicked(date: any, classes: any) {
    this.setState({
      selectedDay: date,
      // selectedRange: [date, new Date(date.setDate(15))],
      selectedRange: [date, date],
    });
  }

  private rangePicked(start: any, end: any) {
    this.setState({
      selectedRange: [start, end],
      selectedDay: start,
    });
  }

  private rightClick(evt: any, id: string) {
    if (evt.nativeEvent.button == 2) {
      evt.preventDefault();

      if (this.cMenu) {
        let obj1: HTMLElement = document.getElementById(id) as HTMLElement;
        if (obj1.classList.contains('range') == true) {
          let c_area: any = obj1.getBoundingClientRect();
          this.cMenu.open(c_area.top + c_area.height, c_area.left);
        }
      }
    }
  }

  private dayClicked1(date: any, classes: any, timestamp: any) {
    if (date.nativeEvent.button == 0) {
      if (this.dragevt == true) {
        this.datePicked(new Date(timestamp), classes);

        let { selectingRange } = this.state;

        if (selectingRange !== undefined) {
          selectingRange = [,];
          var _end: Date = new Date(timestamp);
          selectingRange = [timestamp, _end.getTime()];
        }

        this.setState({
          selectingRange,
        });
      } else if (this.dragevt == false) {
        let { selectingRange } = this.state;
        if ((selectingRange as any)[0] == undefined) {
          (selectingRange as any)[0] = timestamp;
        }
        if ((selectingRange as any)[0] >= timestamp) {
          this.rangePicked(timestamp, (selectingRange as any)[0]);
        } else {
          this.rangePicked((selectingRange as any)[0], timestamp);
        }
      }
    } else if (date.nativeEvent.button == 2) {
      date.preventDefault();
    }
  }

  private dayClicked(date: any, classes: any, timestamp: any) {
    if (!date) {
      // clicked on prev or next month
      return;
    }

    let { selectingRange } = this.state;

    if (!this.state.selectRange) {
      this.datePicked(new Date(timestamp), classes);
      //return;
    }

    if (!selectingRange) {
      var _end: Date = new Date(timestamp);
      selectingRange = [timestamp, _end.getTime()];
    } else {
      if ((selectingRange as any)[0] == undefined) {
        (selectingRange as any)[0] = timestamp;
      }
      if ((selectingRange as any)[0] >= timestamp) {
        this.rangePicked(timestamp, (selectingRange as any)[0]);
      } else {
        this.rangePicked((selectingRange as any)[0], timestamp);
      }
      //selectingRange = [,];
    }

    this.setState({
      selectingRange,
    });
  }

  private dayHovered(hoveredDay: any, timestamp: any) {
    if (this.dragevt == true) {
      if (!hoveredDay) {
        // clicked on prev or next month
        return;
      }

      const { selectingRange } = this.state;

      if (selectingRange) {
        // selectingRange[1] = hoveredDay;
        selectingRange[1] = timestamp;
        this.setState({
          selectingRange,
        });
      }
    } else {
    }
  }

  // * --------------------------------------------------------------------------------------------------------------------HTML
  render() {
    // if (this.state._menuitems !== undefined && this.menuItem.length <= 0) {
    //  this.setContextMenuItems();
    //}

    if (this.menuItem.length <= 0) {
      this.setContextMenuItems();
    }

    return (
      <>
        {this.isEmpty(this.state._holiday) == false &&
        this.props._absences !== undefined ? (
          <>
            <div>
              <table className='calendar'>
                {this.props._years
                  ? this.props._years.map((_year) => (
                      <>
                        <thead className='day-headers'>
                          {this.DaysOfWeek(
                            this.props._firstDayOfWeek,
                            this.props._forceFullWeeks,
                            this.props._showWeekSeparators,
                            _year
                          )}
                        </thead>
                        <tbody>
                          {this.processMonthDays(
                            _year,
                            this.props._firstDayOfWeek,
                            this.props._forceFullWeeks,
                            this.props._showWeekSeparators
                          )}
                          <tr
                            style={{
                              height: '40px',
                              fontWeight: 'bold',
                            }}>
                            <td
                              colSpan={this.props._forceFullWeeks ? 42 : 37}
                              style={{ textAlign: 'left' }}>
                              {this.processCaptions(_year)}
                            </td>
                          </tr>
                        </tbody>
                      </>
                    ))
                  : ''}
              </table>
            </div>
            <div>
              <ContextMenuComponent
                id={Math.random().toString() + 'contextmenu'}
                ref={(scope) => (this.cMenu = scope as ContextMenuComponent)}
                items={this.menuItem}
                select={this.handleMenuClick}
              />
            </div>
          </>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default YearlyCalanderView;
