// * -----------------------------------------------------------------------------------------------------------------1.Meeting
//// * SOURAV - 2023-09-08 - 60 -- meeting.
// * SUHAIL - 2023-10-03 - 30 - meeting with fabio and saurav on showing employee filter with active and terminated and new task to display contract from xml and enable editing
// * SUHAIL - 2023-10-03 - 120 - Selector : Context Menu - Employee - Add checkbox options for Active and Terminated options in Filter context menu
// * SUHAIL - 2023-10-03 - 240 - Selector : Context Menu - Employee - Filter employee list on Active or Terminated filter options clicked
// * SUHAIL - 2023-10-03 - 90 - Employee - Contract - Create an xml to show items as contract details in given order
// * SUHAIL - 2023-10-04 - 90 - attended weekly status review meeting
// * SUHAIL - 2023-10-04 - 210 - Employee - Contract - Create an xml to show items as contract details in given order
// * SUHAIL - 2023-10-04 - 180 - Employee - Contract - Display contract info as per xml in contract tab
// * SUHAIL - 2023-10-05 - 480 - Employee - Contract - Allow to to edit contract details in employee contract tab
// * SUHAIL - 2023-10-06 - 30 - meeting with fabio and saurav regarding payslip punctuality data and image editor component
// * SUHAIL - 2023-10-06 - 450 - Employee - Contract - Create context menu with New, Branch, Department and Delete options on contract info right click
// * SUHAIL - 2023-10-09 - 60 - attended weekly status review meeting
// * SUHAIL - 2023-10-09 - 420 - Employee - Contract - Show allowance list with levels and amount on context menu in employee contract tab
// * SUHAIL - 2023-10-10 - 30 - meeting with fabio on allowance set api and contract save api
// * SUHAIL - 2023-10-10 - 200 - Allow to to edit contract details in employee contract tab
// * SUHAIL - 2023-10-10 - 250 - Add allowance in contract when allowance is selected from context menu in employee contract tab
// * SUHAIL - 2023-10-11 - 15 - attended weekly status review meeting
// * SUHAIL - 2023-10-11 - 465 - Save newly added allowance from contract tab into database
// * SUHAIL - 2023-10-12 - 20 - Attended Sprint Planning Meeting
// * SUHAIL - 2023-10-12 - 60 - Save newly added allowance from contract tab into database
// * SUHAIL - 2023-10-12 - 400 - Employee - Contract - Save edited allowance from contract tab into database
// * SUHAIL - 2023-10-13 - 45 - attended Sprint Review and Retrospective meeting
// * SUHAIL - 2023-10-13 - 435 - Contract - Save edited contract info from contract tab into database
// * SUHAIL - 2023-10-16 - 30 - attended weekly status review meeting
// * SUHAIL - 2023-10-16 - 120 - Contract - Save edited contract info from contract tab into database
// * SUHAIL - 2023-10-16 - 240 - Contract - Read - Display Branch list in the context menu from the back-end API
// * SUHAIL - 2023-10-16 - 90 - Contract - Read - Display Job list in the context menu from the back-end API
// * SUHAIL - 2023-10-17 - 240 - Contract - Read - Display Job Title in employee contract tab
// * SUHAIL - 2023-10-17 - 240 - Contract - Read - Display Job list in the context menu from the back-end API
// * SUHAIL - 2023-10-18 - 60 - attended weekly status review meeting
// * SUHAIL - 2023-10-18 - 20 - meeting with fabio regarding image document editor
// * SUHAIL - 2023-10-18 - 200 - Contract - Create - Add / Edit job title from context menu job list
// * SUHAIL - 2023-10-18 - 200 - Contract - Create - Add / Edit branch from context menu branch list
// * SUHAIL - 2023-10-19 - 120 - Contract - Delete - Delete selected contract item when click on delete option from context menu
// * SUHAIL - 2023-10-19 - 360 - Contract - Show Job description in word document editor in employee contract tab
// * SUHAIL - 2023-10-20 - 150 - Contract - Show Job description in word document editor in employee contract tab
// * SUHAIL - 2023-10-20 - 150 - Contract - Read - Display Contract Type list in the context menu from the back-end API
// * SUHAIL - 2023-10-20 - 50 - meeting with fabio on work schedule and merged document filed list
// * SUHAIL - 2023-10-20 - 130 - Contract - Delete - Delete selected Contract Type when clicked on delete option from context menu
// * SUHAIL - 2023-10-23 - 70 - attended weekly status review meeting.
// * SUHAIL - 2023-10-24 - 480 - Word - Show contract details with all contract information in word editor
// * SUHAIL - 2023-10-25 - 35 - attended daily status review meeting
// * SUHAIL - 2023-10-25 - 265 - Word - Show document details used in mail merged document in word editor show fields panel
// * SUHAIL - 2023-10-25 - 180 - Word - Display only respective context menu item when right clicked on show fields panel in word document
// * SUHAIL - 2023-10-26 - 30 - attended sprint planning meeting
// * SUHAIL - 2023-10-26 - 450 - Word - Display job document in existing word template if job description field is used in mail merge fields
// * SUHAIL - 2023-10-27 - 35 - attended sprint review meeting
// * SUHAIL - 2023-10-27 - 445 - getting acquainted with react native for mobile development
// * SUHAIL - 2023-10-31 - 50 - attended sprint review meeting
// * SUHAIL - 2023-10-31 - 200 - Display only respective context menu item when right clicked on contract item in employee contract
// * SUHAIL - 2023-10-31 - 230 - getting acquainted with react native for mobile development
// * SUHAIL - 2023-11-06 - 50 - attended weekly status meeting
// * SUHAIL - 2023-11-06 - 430 - working on changes in contract as per fabio’s feedback
// * SUHAIL - 2023-11-07 - 30 - meeting with fabio on issue on adding new contract using given end point
// * SUHAIL - 2023-11-07 - 450 - Allow to add a new contract from the context menu "New" option in employee contract tab
// * SUHAIL - 2023-11-08 - 90 - attended weekly status meeting
// * SUHAIL - 2023-11-08 - 190 - Allow to add a new contract from the context menu "New" option in employee contract tab
// * SUHAIL - 2023-11-08 - 120 - Add Project in Contract Context menu and allow to add / edit Project in employee contract
// * SUHAIL - 2023-11-08 - 80 - Display Department list in the context menu from the back-end API
// * SUHAIL - 2023-11-09 - 30 - attended sprint planning
// * SUHAIL - 2023-11-09 - 30 - meeting with fabio on issues in creating new contract
// * SUHAIL - 2023-11-09 - 120 - Add Project in Contract Context menu and allow to add / edit Project in employee contract
// * SUHAIL - 2023-11-09 - 300 - Allow to add a new contract from the context menu "New" option in employee contract tab
// * SUHAIL - 2023-11-10 - 35 - Attended Sprint Review and Retrospective
// * SUHAIL - 2023-11-10 - 55 - meeting with fabio on creating new branch , project in employee contract
// * SUHAIL - 2023-11-10 - 400 - Contract - Add New... option on context menu items for Project list  to create new project in employee contract

import React, { Component } from 'react';

import {
  TreeGridComponent,
  ColumnsDirective,
  ColumnDirective,
  EditSettingsModel,
  Edit,
  Inject,
  Toolbar,
  ToolbarItems,
  Column,
  ColumnModel,
  ContextMenu,
} from '@syncfusion/ej2-react-treegrid';
import Common from '../Util/Common';
import {
  contract_detail_data,
  document_detail_data,
  contract_contextmenu,
  newmenu_groupaccount_contextmenu,
  newmenu_contextmenu,
} from '../dummydata/xml_data_employee';
import PeriodSelector from './PeriodSelector/PeriodSelector';
import {
  Dialog,
  DialogComponent,
  DialogUtility,
} from '@syncfusion/ej2-react-popups';
import './Contract.css';
import { ContextMenuItemModel, IEditCell } from '@syncfusion/ej2-react-grids';
import { AutoComplete } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import {
  ContextMenuComponent,
  MenuItem,
  MenuItemModel,
} from '@syncfusion/ej2-react-navigations';
import DocumentViewer from './Layout/DocumentViewer';

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

interface props {
  id: string;
  selectorData: any;
  contractData: any;
  inoffcanvas?: boolean;
  showallfields?: boolean;
  mandatoryfields?: string[];
  refreshContract?: any;
  contractTitle: string;
  /**
   * expected value : 'contract' | 'document'
   *
   * @default 'contract'
   */
  showDataFrom: string;
}
const { REACT_APP_ENDPOINT_FOXSYSTEMSERVICE } = process.env;

// * -----------------------------------------------------------------------------------------------------------------1.Acknowledge
// * SOURAV - 2023-09-04 - 270 -- working on to show contracts in treegrid component.
// * SOURAV - 2023-09-06 - 80 --  working on to show contract data (props) in treegrid component .
// * SOURAV - 2023-09-07 - 460 --  working on to show contracts  in treegrid component .
// * SOURAV - 2023-09-08 - 400 --  working on to show contracts layout in treegrid component .

class Contracts extends React.Component<props> {
  private user: any;
  private _structure: any;
  private fulljson: any;
  private idx: number;
  private fieldlist: any;
  private xmlContract: any;
  private allowanceDataJson: any;
  private contractFlatJson: any;
  private contractXMLFlatJson: any;
  private cntxitems: MenuItemModel[] = [];
  private origcntxitems: MenuItemModel[] = [];
  private defaultPreMenuItems: MenuItemModel[] = [];
  private defaultPreMenuJobItems: MenuItemModel[] = [];
  private treegridObj: TreeGridComponent = new TreeGridComponent(null);
  private dt: DatePickerComponent = new DatePickerComponent(null);
  private jsonAllowances: any = [];
  private jsonOrg: any = [];
  private jsonDept: any;
  //private arrayOrgGrooupIDs: number[] = [];
  // private jsonProject: any = [];
  // private jsonBranch: any = [];
  // private jsonDepartment: any = [];
  private jsonJob: any = [];
  private jsonContractType: any = [];
  private rightClickedRowElement: any;
  private cmenuInstance: any;
  private readonlyfieldnames: string[] = [
    'document.name',
    'name',
    'allowances',
    'branch.name',
    'grade.name',
    'projects',
    'department.name',
    'account.name',
    'dateend',
  ];
  private nonvoidablefieldnames: string[] = [
    'name',
    'datestart',
    'allowances',
    'projects',
  ];
  private editSettings: EditSettingsModel = {
    allowEditing: true,
    mode: 'Cell',
  };
  private elem: HTMLInputElement | any;
  private autoCompleteobj: AutoComplete = new AutoComplete();
  private editTemplate: IEditCell = {
    create: (e: any) => {
      switch (e.data.type) {
        case 'number':
          this.elem = document.createElement('input');
          this.elem.setAttribute('type', 'number');
          this.elem.setAttribute('step', 'any');
          this.elem.setAttribute('data-type', 'number');
          // if (e.data.subtasks.length > 0) {
          //   this.elem.readOnly = true;
          // }
          break;

        case 'date':
          this.dt.element.classList.remove('e-field');
          this.dt.element.classList.remove('e-ralign');
          this.dt.element.classList.remove('e-autocomplete');
          this.dt.element.classList.remove('e-focus');
          this.dt.value = new Date(e.data.originalvalue);
          if (
            this.readonlyfieldnames.includes(e.data.fieldname.toLowerCase()) ===
            false
          ) {
            this.elem = this.dt.element;
          } else {
            this.elem = document.createElement('input');
          }
          this.elem.setAttribute('data-type', 'date');
          // dt.value = new Date();
          // this.elem = dt;

          // e.data.value = Common.formatDate(
          //   new Date(e.data.originalvalue),
          //   this.user.settings.formats.DateShort
          // );
          //this.elem.setAttribute('type', 'date');
          break;
        default:
          this.elem = document.createElement('input');
          break;
      }
      this.elem.setAttribute('data-name', e.data.name);

      if (e.data.parentItem) {
        this.elem.setAttribute('data-subtaskname', e.data.parentItem.name);
      }

      if (
        (e.data.parentItem &&
          e.data.parentItem.name.toLowerCase() !== 'projects') ||
        this.readonlyfieldnames.includes(e.data.fieldname.toLowerCase())
      ) {
        this.elem.setAttribute('class', 'border-0 text-end');
      }
      return this.elem;
    },
    read: (e: any) => {
      try {
        let val: any = this.autoCompleteobj.value;
        if (e.getAttribute('data-type') === 'number') {
          val = Common.FormatNumber(
            Number(val.replaceAll(',', '')),
            this.user.settings.formats.Decimal
          );
        } else if (e.getAttribute('data-type') === 'date') {
        }
        if (!e.getAttribute('data-subtaskname')) {
          let flter: any = this.contractXMLFlatJson.filter(
            (x: any) => x.name === e.getAttribute('data-name')
          );
          if (flter.length > 0) {
            flter[0].value = val;
            if (e.getAttribute('data-type') === 'number') {
              flter[0]['originalvalue'] = Number(
                Common.FormatNumber(
                  Number(val.replaceAll(',', '')),
                  this.user.settings.formats.Decimal
                ).replaceAll(',', '')
              );
            } else if (e.getAttribute('data-type') === 'date') {
              flter[0]['originalvalue'] = Common.formatDate(
                new Date(val),
                'yyyy-MM-ddThh:mm:ss'
              );
              e.value = val;
              this.dt.value = new Date(val);
            } else {
              flter[0]['originalvalue'] = val;
            }
          }
          let allwflter: any = this.contractXMLFlatJson.filter(
            (x: any) => (x: any) =>
              x.name === e.getAttribute('data-subtaskname')
            // x.name.toLowerCase() === 'allowances' ||
            // x.name.toLowerCase() === 'projects'
          );
          if (allwflter.length > 0) {
            setTimeout(() => {
              allwflter[0]['value'] = Common.FormatNumber(
                allwflter[0]['originalvalue'],
                this.user.settings.formats.Decimal
              );
            }, 1000);
          }
          //this.contractFlatJson[e.getAttribute('data-name')] = val;
        } else {
          let flter: any = this.contractXMLFlatJson.filter(
            (x: any) => x.name === e.getAttribute('data-subtaskname')
          );
          if (flter.length > 0) {
            let subflter: any = flter[0].subtasks.filter(
              (x: any) => x.name === e.getAttribute('data-name')
            );
            if (subflter.length > 0) {
              if (e.getAttribute('data-type') === 'number') {
                subflter[0]['originalvalue'] = Common.FormatNumber(
                  Number(val.replaceAll(',', '')),
                  this.user.settings.formats.Decimal
                ).replaceAll(',', '');
              } else if (e.getAttribute('data-type') === 'date') {
                subflter[0]['originalvalue'] = Common.formatDate(
                  new Date(val),
                  'yyyy-MM-ddThh:mm:ss'
                );
                e.value = val;
                this.dt.value = new Date(val);
              } else {
                subflter[0]['originalvalue'] = val;
              }
            }
          }
          let allwflter: any = this.contractXMLFlatJson.filter(
            (x: any) => x.name === e.getAttribute('data-subtaskname')
            // x.name.toLowerCase() === 'allowances' ||
            // x.name.toLowerCase() === 'projects'
          );
          if (allwflter.length > 0) {
            setTimeout(() => {
              allwflter[0]['value'] = Common.FormatNumber(
                allwflter[0]['originalvalue'],
                this.user.settings.formats.Decimal
              );
            }, 1000);
          }
        }

        return val;
      } catch (error: any) {
        console.error(error);
      }
    },
    destroy: () => {
      this.autoCompleteobj.destroy();
    },
    write: (args: any) => {
      try {
        let val: any = args.rowData[args.column.field];
        if (args.rowData.type === 'number') {
          val = val.replaceAll(',', '');
        } else if (args.rowData.type === 'date') {
          this.dt.value = new Date(val);
          if (
            this.readonlyfieldnames.includes(
              args.rowData.fieldname.toLowerCase()
            ) === false
          ) {
            this.dt.show();
          }
        }
        this.autoCompleteobj = new AutoComplete({
          dataSource: this.treegridObj.grid.dataSource as {
            key: string;
            value: any;
          }[],
          fields: { value: 'value' },
          value: val,
        });
        this.autoCompleteobj.noRecordsTemplate = '';
        this.autoCompleteobj.popupHeight = '0px';
        if (
          (args.rowData.parentItem &&
            args.rowData.parentItem.name.toLowerCase() !== 'projects') ||
          this.readonlyfieldnames.includes(args.rowData.fieldname.toLowerCase())
        ) {
          this.autoCompleteobj.readonly = true;
          this.autoCompleteobj.cssClass = 'border-0 text-end ';
        }
        this.autoCompleteobj.appendTo(this.elem);
      } catch (error: any) {
        console.error(error);
      }
    },
  };

  constructor(props: props) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.fulljson = JSON.parse(Common.getFullJson() ?? '');
    this.idx = 1;
    this.fieldlist = Common.FindJsonByObjectId(
      this.fulljson,
      this._structure.Element.Field,
      this.user
    );
    var xmlContract;
    switch (this.props.showDataFrom) {
      case 'contract':
        parser.parseString(
          contract_detail_data,
          function (err: any, result: any) {
            xmlContract = JSON.parse(JSON.stringify(result));
          }
        );
        break;

      case 'document':
        parser.parseString(
          document_detail_data,
          function (err: any, result: any) {
            xmlContract = JSON.parse(JSON.stringify(result));
          }
        );
        break;

      default:
        break;
    }

    this.xmlContract = xmlContract;

    let org: any = Common.FindJsonByObjectValue(
      this.fulljson,
      '_organization_',
      null
    );

    if (org) {
      org = Common.FindJsonByObjectId(this.fulljson, org.object, this.user);
      this.jsonOrg = org.children;
    }

    let dept: any = Common.FindJsonByObjectValue(
      this.fulljson,
      '_department_',
      null
    );

    if (dept) {
      this.jsonDept = Common.FindJsonByObjectId(
        this.fulljson,
        dept.object,
        this.user
      );
    }

    this.InitializeData();

    // let branches: any = Common.FindJsonByObjectValue(
    //   this.fulljson,
    //   'EmployeeBranchGroup',
    //   this.user
    // );

    // if (branches.children.length > 0) {
    //   let flter: any = branches.children.filter((x: any) => x.field === 101);
    //   if (flter.length > 0) {
    //     if (branches.children.length > 1) {
    //       branches = Common.FindJsonByObjectId(
    //         branches.children,
    //         branches.children[0].object,
    //         this.user
    //       );
    //       branches = branches.children;
    //     } else {
    //       branches = branches.children[0].children;
    //     }
    //     switch (flter[0].value.toLowerCase()) {
    //       case 'project':
    //         this.jsonProject = branches;
    //         break;

    //       case 'branch':
    //         this.jsonBranch = branches;
    //         break;

    //       case 'department':
    //         this.jsonDepartment = branches;
    //         break;

    //       default:
    //         break;
    //     }
    //   }
    // }

    let contractstype = Common.FindJsonByObjectId(
      this.fulljson,
      this._structure.Constants.Contract,
      this.user
    );
    this.jsonContractType = contractstype.children;

    var _result: any;
    parser.parseString(
      newmenu_groupaccount_contextmenu,
      function (err: any, result: any) {
        _result = JSON.parse(JSON.stringify(result));
      }
    );
    this.defaultPreMenuItems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item,
      0
    ) as MenuItemModel[];

    _result = null;
    parser.parseString(newmenu_contextmenu, function (err: any, result: any) {
      _result = JSON.parse(JSON.stringify(result));
    });
    this.defaultPreMenuJobItems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item,
      0
    ) as MenuItemModel[];

    _result = null;
    parser.parseString(contract_contextmenu, function (err: any, result: any) {
      _result = JSON.parse(JSON.stringify(result));
    });
    this.cntxitems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item
    ) as MenuItemModel[];
    this.origcntxitems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item
    ) as MenuItemModel[];

    this.ContextMenuOrganization();
    // this.ContextMenuProject();
    // this.ContextMenuBranch();
    // this.ContextMenuDepartment();
    this.ContextMenuJob();
    this.ContextMenuContractType();
  }

  static defaultProps = {
    inoffcanvas: false,
    mandatoryfields: [],
    contractTitle: 'Contracts Details',
    showDataFrom: 'contract',
  };

  state = {
    cntxitems: [],
    contractXMLFlatJson: [],
    allowancefetched: false,
    jobfetched: false,
    showjobdoc:
      this.props.contractData.job && this.props.contractData.job.id > 0
        ? true
        : false,
    showdialog: false,
    dlgcontent: '',
    groupname: '',
    nextidx: 99990,
    rootmenu: {
      id: 0,
      text: '',
      iconCss: '',
      separator: undefined,
      items: undefined,
    },
  };

  componentDidMount() {
    this.jsonAllowances = [];
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Allowances/Get`,
      {},
      Common.getToken() || '',
      this.user
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.jsonAllowances = response;
        this.ContextMenuAllowance();
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ allowancefetched: true });
      });

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Job/Get`,
      {},
      Common.getToken() || '',
      this.user
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.jsonJob = response;
        this.ContextMenuJob();
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ jobfetched: true });
      });

    /* Open contextmenu on three vertical dots */
    // document
    //   .querySelector('#moreoptions')
    //   ?.closest('div')
    //   ?.addEventListener('click', (e: any) =>
    //     this.cmenuInstance.open(e.y, e.x)
    //   );
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      this.state.allowancefetched === true &&
      this.state.jobfetched === true &&
      prevState.cntxitems.length <= 0
    ) {
      this.setState({ cntxitems: this.cntxitems });
    }
  }

  public InitializeData() {
    this.allowanceDataJson = this.GetAllowanceData(this.props.contractData);
    this.contractFlatJson = this.GetContractData(
      Common.flattenJSON(this.props.contractData)
    );

    this.contractXMLFlatJson = this.GetContractDataXMLOrder(
      this.contractFlatJson,
      this.xmlContract
    );
    let allw: any = this.cntxitems.filter(
      (x: any) => x.text.toLowerCase() === 'allowances'
    );
    if (allw.length > 0) {
      allw[0].items = [];
      this.ContextMenuAllowance();
    }

    let org: any = this.cntxitems.filter(
      (x: any) => x.text.toLowerCase() === 'organization'
    );
    if (org.length > 0) {
      //org[0].items.splice(2, org[0].items.length - 2);
      org[0].items = [];
      this.ContextMenuOrganization();
    }

    // let branch: any = this.cntxitems.filter(
    //   (x: any) => x.text.toLowerCase() === 'branch'
    // );
    // if (branch.length > 0) {
    //   branch[0].items.splice(2, branch[0].items.length - 2);
    //   this.ContextMenuBranch();
    // }

    // let project: any = this.cntxitems.filter(
    //   (x: any) => x.text.toLowerCase() === 'project'
    // );
    // if (project.length > 0) {
    //   //project[0].items = [];
    //   project[0].items.splice(2, project[0].items.length - 2);
    //   this.ContextMenuProject();
    // }

    // let department: any = this.cntxitems.filter(
    //   (x: any) => x.text.toLowerCase() === 'department'
    // );
    // if (department.length > 0) {
    //   //department[0].items = [];
    //   department[0].items.splice(2, department[0].items.length - 2);
    //   this.ContextMenuDepartment();
    // }

    let job: any = this.cntxitems.filter(
      (x: any) => x.text.toLowerCase() === 'job'
    );
    if (job.length > 0) {
      //job[0].items = [];
      job[0].items.splice(2, job[0].items.length - 2);
      this.ContextMenuJob();
    }

    let cntrcttype: any = this.cntxitems.filter(
      (x: any) => x.text.toLowerCase() === 'contract type'
    );
    if (cntrcttype.length > 0) {
      cntrcttype[0].items = [];
      this.ContextMenuContractType();
    }
  }

  //----------------------------------new contract json -----------------------------
  public GetContractData = (contractflatdata: any) => {
    let contractData: any[] = [];
    let allowancesSubtasks: any;
    let projectsSubtasks: any;
    let alw = { name: '', value: '', id: 0, accountid: 0 };
    let prj = { name: '', quota: 0, id: 0, accountid: 0 };
    let allowancesTotal: number = 0;
    let projectsTotal: number = 0;
    if (this.props.contractData.allowances) {
      allowancesTotal = this.props.contractData.allowances.reduce(
        (total: any, el: any) => {
          return total + Number(el.amount);
        },
        0
      );
    }
    if (this.props.contractData.projects) {
      projectsTotal = this.props.contractData.projects.reduce(
        (total: any, el: any) => {
          return total + Number(el.quota);
        },
        0
      );
    }
    Object.entries(contractflatdata).forEach((el: any) => {
      if (
        !el[0].includes('employee.') &&
        !el[0].includes('allowances.') &&
        !el[0].includes('projects.')
      ) {
        if (typeof el[1] === 'number') {
          contractData.push({
            name: el[0],
            value: Common.FormatNumber(
              el[1],
              this.user.settings.formats.Decimal
            ),
            originalvalue: el[1],
            type: 'number',
          });
        }

        if (el[0].includes('name') || el[0].toLowerCase() === 'job.title') {
          contractData.push({
            name: el[0],
            value: el[1],
            originalvalue: el[1],
            type: 'text',
          });
        }
        if (el[0].includes('dateStart')) {
          contractData.push({
            name: el[0],
            value: Common.formatDate(
              new Date(el[1]),
              this.user.settings.formats.DateShort
            ),
            originalvalue: el[1],
            type: 'date',
          });
        } else if (el[0].includes('dateEnd')) {
          //if (new Date(el[1]).getTime() > -62135618008000) {
          if (el[1] !== null || this.props.mandatoryfields?.includes(el[0])) {
            contractData.push({
              name: el[0],
              // value:
              //   new Date(el[1]).getTime() > -62135618008000
              //     ? Common.formatDate(
              //         new Date(el[1]),
              //         this.user.settings.formats.DateShort
              //       )
              //     : null,
              value:
                el[1] !== null
                  ? Common.formatDate(
                      new Date(el[1]),
                      this.user.settings.formats.DateShort
                    )
                  : null,
              originalvalue: el[1],
              type: 'date',
            });
          }
        } else if (el[0].includes('document.date')) {
          if (el[1] !== null || this.props.mandatoryfields?.includes(el[0])) {
            contractData.push({
              name: el[0],
              value:
                el[1] !== null
                  ? Common.formatDate(
                      new Date(el[1]),
                      this.user.settings.formats.DateShort
                    )
                  : null,
              originalvalue: el[1],
              type: 'date',
            });
          }
        }
      }
      if (el[0].includes('allowances.')) {
        let fldname: string = el[0]
          .split('.')
          .slice(
            el[0]
              .split('.')
              .map((x: any) => {
                if (isNaN(x) === false) {
                  return 0;
                } else {
                  return -1;
                }
              })
              .indexOf(0) + 1
          )
          .join('.');
        if (fldname == 'name') {
          alw.name = el[1];
        } else if (fldname == 'amount') {
          alw.value = el[1];
        } else if (fldname == 'id') {
          alw.id = el[1];
        } else if (fldname == 'account.id') {
          alw.accountid = el[1];
        }

        if (
          alw.name !== '' &&
          alw.value !== '' &&
          alw.id > 0 &&
          alw.accountid > 0
        ) {
          allowancesSubtasks = contractData.filter(
            (x: any) => x.name === 'Allowances'
          );
          if (allowancesSubtasks <= 0) {
            contractData.push({
              name: 'Allowances',
              value: Common.FormatNumber(
                allowancesTotal,
                this.user.settings.formats.Decimal
              ),
              originalvalue: allowancesTotal,
              type: 'number',
              subtasks: [
                {
                  name: alw.name,
                  value: Common.FormatNumber(
                    Number(alw.value),
                    this.user.settings.formats.Decimal
                  ),
                  originalvalue: alw.value,
                  fieldname: 'Welfare',
                  id: alw.id,
                  accountid: alw.accountid,
                  type: 'number',
                },
              ],
            });
          } else {
            allowancesSubtasks[0].subtasks.push({
              name: alw.name,
              value: Common.FormatNumber(
                Number(alw.value),
                this.user.settings.formats.Decimal
              ),
              originalvalue: alw.value,
              fieldname: 'Welfare',
              id: alw.id,
              accountid: alw.accountid,
              type: 'number',
            });
          }

          alw.name = '';
          alw.value = '';
          alw.id = 0;
          alw.accountid = 0;
        }
      } else if (el[0].includes('projects.')) {
        let fldname: string = el[0]
          .split('.')
          .slice(
            el[0]
              .split('.')
              .map((x: any) => {
                if (isNaN(x) === false) {
                  return 0;
                } else {
                  return -1;
                }
              })
              .indexOf(0) + 1
          )
          .join('.');

        if (fldname == 'name') {
          prj.name = el[1];
        } else if (fldname == 'quota') {
          prj.quota = el[1];
        } else if (fldname == 'id') {
          prj.id = el[1];
        } else if (fldname == 'account.id') {
          prj.accountid = el[1];
        }

        let prjIdx: number = Number(
          el[0].split('.')[el[0].split('.').indexOf('projects') + 1]
        );
        let quotaexist: boolean = false;
        if (this.props.contractData.projects[prjIdx].quota) {
          quotaexist = true;
        }

        if (
          prj.id > 0 &&
          prj.name !== '' &&
          (quotaexist === false || Number(prj.quota)) !== 0
        ) {
          projectsSubtasks = contractData.filter(
            (x: any) => x.name === 'Projects'
          );
          if (projectsSubtasks <= 0) {
            contractData.push({
              name: 'Projects',
              value: Common.FormatNumber(
                projectsTotal,
                this.user.settings.formats.Decimal
              ),
              originalvalue: projectsTotal,
              type: 'number',
              subtasks: [
                {
                  name: prj.name,
                  value: Common.FormatNumber(
                    Number(prj.quota),
                    this.user.settings.formats.Decimal
                  ),
                  originalvalue: prj.quota,
                  fieldname: 'Quota',
                  id: prj.id,
                  accountid: prj.accountid,
                  type: 'number',
                },
              ],
            });
          } else {
            projectsSubtasks[0].subtasks.push({
              name: prj.name,
              value: Common.FormatNumber(
                Number(prj.quota),
                this.user.settings.formats.Decimal
              ),
              originalvalue: prj.quota,
              fieldname: 'Quota',
              id: prj.id,
              accountid: prj.accountid,
              type: 'number',
            });
          }

          prj.name = '';
          prj.quota = 0;
          prj.id = 0;
          prj.accountid = 0;
        }
        // else if (fldname == 'quota' && Number(prj.quota) !== 0) {
        //   projectsSubtasks = contractData.filter(
        //     (x: any) => x.name === 'Projects'
        //   );
        //   if (projectsSubtasks.length > 0) {
        //     if (this.props.contractData.projects[prjIdx].quota)
        //       projectsSubtasks[0].subtasks[prjIdx]['value'] = prj.quota;
        //     projectsSubtasks[0].subtasks[prjIdx]['originalvalue'] = Number(
        //       prj.quota.toString().replaceAll(',', '')
        //     );
        //     prj.quota = 0;
        //   }
        // }
      } else {
        return;
      }
    });

    return contractData;
  };
  //----------------------------------------//---end---//-----------------------------
  // Allowances Json
  public GetAllowanceData = (contractdata: any) => {
    let allowanceData: any[] = [];
    let contract: any;
    let allowancesTotal: number = 0;
    if (contractdata.allowances) {
      allowancesTotal = contractdata.allowances.reduce(
        (total: any, el: any) => {
          return total + Number(el.amount);
        },
        0
      );

      contractdata.allowances.forEach((el: any) => {
        contract = allowanceData.filter((x: any) => x.name === 'Allowances');
        if (contract.length <= 0) {
          allowanceData.push({
            indexId: this.idx++,
            name: 'Allowances',
            amount: Common.FormatNumber(
              allowancesTotal,
              this.user.settings.formats.Decimal
            ),
            subtasks: [
              {
                indexId: this.idx++,
                name: el.name,
                amount: Common.FormatNumber(
                  el.amount,
                  this.user.settings.formats.Decimal
                ),
              },
            ],
          });
        } else {
          contract[0].subtasks.push({
            indexId: this.idx++,
            name: el.name,
            amount: Common.FormatNumber(
              el.amount,
              this.user.settings.formats.Decimal
            ),
          });
        }
      });
    }

    return allowanceData;
  };

  public GetContractDataXMLOrder = (contractdata: any, xmldata: any) => {
    let contractXMLData: any[] = [];
    let cntrctid: number = 0;
    let branchtid: number = 0;
    let idfltr: any = contractdata.filter(
      (f: any) => f.name.toLowerCase() === 'id'
    );
    if (idfltr.length > 0) {
      cntrctid = idfltr[0].originalvalue;
    }
    idfltr = contractdata.filter(
      (f: any) => f.name.toLowerCase() === 'branch.id'
    );
    if (idfltr.length > 0) {
      branchtid = idfltr[0].originalvalue;
    }
    xmldata.Item.forEach((x: any) => {
      let flter: any = contractdata.filter(
        (f: any) => f.name.toLowerCase() === x.$.source.toLowerCase()
      );
      if (
        (this.props.mandatoryfields!.length > 0 &&
          this.props.mandatoryfields?.includes(x.$.source)) ||
        (this.props.mandatoryfields!.length <= 0 && flter.length > 0)
      ) {
        if (flter.length > 0) {
          contractXMLData.push({
            ...flter[0],
            name: x.$.text,
            fieldname: flter[0].name,
            id: cntrctid,
            branchid: branchtid,
            subtasks:
              x.$.isgroup && x.$.isgroup !== undefined ? flter[0].subtasks : [],
          });
        } else {
          contractXMLData.push({
            name: x.$.text,
            fieldname: x.$.source,
            id: cntrctid,
            branchid: branchtid,
            subtasks: x.$.isgroup && x.$.isgroup !== undefined ? [] : [],
          });
        }
      }
    });

    return contractXMLData;
  };

  public ContextMenuAllowance() {
    let allw: any = this.cntxitems.filter(
      (x: any) => x.text.toLowerCase() === 'allowances'
    );
    if (allw.length > 0) {
      let fltr: any =
        this.contractXMLFlatJson &&
        this.contractXMLFlatJson.filter(
          (a: any) => a.name.toLowerCase() === 'allowances'
        );
      if (this.contractXMLFlatJson && fltr.length <= 0) {
        // this.props.contractData['allowances'] = [];
        // this.contractXMLFlatJson.push({
        //   name: 'Allowances',
        //   value: '0',
        //   originalvalue: 0,
        //   fieldname: 'Allowances',
        //   type: 'number',
        //   subtasks: [],
        // });
        // fltr = this.contractXMLFlatJson.filter(
        //   (a: any) => a.name.toLowerCase() === 'allowances'
        // );
      }
      this.jsonAllowances.forEach((x: any) => {
        let sub: any = [];
        let origsub: any = [];
        x.scale.forEach((y: any) => {
          if (fltr.length > 0) {
            let sflter: any = fltr[0].subtasks.filter(
              (s: any) =>
                s.name.split('-')[0].trim() === x.name.trim() &&
                s.name.split('-')[1].trim() === y.name.trim()
            );
            if (sflter.length <= 0) {
              sub.push({
                id: y.id.toString(),
                text: `${y.name} - ${Common.FormatNumber(
                  y.amount,
                  this.user.settings.formats.Decimal
                )}`,
                iconCss: 'noicon',
              });
            }
            origsub.push({
              id: y.id.toString(),
              text: `${y.name} - ${Common.FormatNumber(
                y.amount,
                this.user.settings.formats.Decimal
              )}`,
              iconCss: 'noicon',
            });
          } else {
            sub.push({
              id: y.id.toString(),
              text: `${y.name} - ${Common.FormatNumber(
                y.amount,
                this.user.settings.formats.Decimal
              )}`,
              iconCss: 'noicon',
            });
            origsub.push({
              id: y.id.toString(),
              text: `${y.name} - ${Common.FormatNumber(
                y.amount,
                this.user.settings.formats.Decimal
              )}`,
              iconCss: 'noicon',
            });
          }
        });
        allw[0].items.push({
          id: x.id.toString(),
          text: x.name,
          items: sub,
          iconCss: 'noicon',
        });
      });
    }
  }

  public ContextMenuJob() {
    let job: any = this.cntxitems.filter(
      (x: any) => x.text.toLowerCase() === 'job'
    );

    if (job.length > 0) {
      this.jsonJob
        .sort((a: any, b: any) => {
          return a.description < b.description ? 1 : -1;
        })
        .sort((a: any, b: any) => {
          return a.account.name > b.account.name ? 1 : -1;
        })
        .forEach((x: any) => {
          let subitem: any = [];
          if (x.id.toString() !== '') {
            subitem = [
              {
                id: x.id.toString(),
                text: x.description,
                items: [],
                iconCss: 'noicon',
              },
            ];
          }

          let jobcategory: any = job[0].items.filter(
            (j: any) => j.id == x.account.id
          );
          if (this.props.contractData.job) {
            if (jobcategory.length <= 0) {
              if (this.props.contractData.job.id != x.id) {
                let tmpitm: any = this.defaultPreMenuJobItems.map((xx: any) => {
                  return {
                    ...xx,
                    id: xx.id.toString().startsWith('__')
                      ? xx.id.toString() + x.account.id + '--' + x.account.name
                      : xx.id.toString(),
                  };
                });
                if (subitem.length > 0) {
                  job[0].items.push({
                    id: x.account.id.toString(),
                    text: x.account.name,
                    items: [...tmpitm, ...subitem],
                    iconCss: 'noicon',
                  });
                } else {
                  job[0].items.push({
                    id: x.account.id.toString(),
                    text: x.account.name,
                    items: [...tmpitm],
                    iconCss: 'noicon',
                  });
                }
              }
            } else {
              jobcategory[0].items.push(...subitem);
            }
          } else {
            if (jobcategory.length <= 0) {
              let tmpitm: any = this.defaultPreMenuJobItems.map((xx: any) => {
                return {
                  ...xx,
                  id: xx.id.toString().startsWith('__')
                    ? xx.id.toString() + x.account.id + '--' + x.account.name
                    : xx.id.toString(),
                };
              });
              if (subitem.length > 0) {
                job[0].items.push({
                  id: x.account.id.toString(),
                  text: x.account.name,
                  items: [...tmpitm, ...subitem],
                  iconCss: 'noicon',
                });
              } else {
                job[0].items.push({
                  id: x.account.id.toString(),
                  text: x.account.name,
                  items: [...tmpitm],
                  iconCss: 'noicon',
                });
              }
            } else {
              jobcategory[0].items.push(...subitem);
            }
          }
        });
    }
  }

  public ContextMenuOrganization() {
    let org: any = this.cntxitems.filter(
      (x: any) => x.text.toLowerCase() === 'organization'
    );

    if (org.length > 0) {
      let existingIds: string[] = [];
      if (this.props.contractData.branch) {
        existingIds.push(this.props.contractData.branch.id.toString());
      }
      if (this.props.contractData.department) {
        existingIds.push(this.props.contractData.department.id.toString());
      }
      if (
        this.props.contractData.projects &&
        this.props.contractData.projects.length > 0
      ) {
        existingIds.push(
          ...this.props.contractData.projects.map((x: any) => x.id.toString())
        );
      }

      let orgcntxmenu: any = Common.GetContextMenu(
        this.jsonOrg,
        this._structure,
        this.user,
        existingIds,
        false,
        this.defaultPreMenuItems,
        0,
        0,
        'rFieldId'
      );

      if (orgcntxmenu.length > 0) {
        //this.arrayOrgGrooupIDs = orgcntxmenu.map((x: any) => Number(x.id));
        //orgcntxmenu.map((x: any) => Number(x.id));
        org[0].items = [];
        org[0].items.push(...orgcntxmenu);
      }
    }
  }

  // public ContextMenuBranch() {
  //   // let origbranch: any = this.origcntxitems.filter(
  //   //   (x: any) => x.text.toLowerCase() === 'branch'
  //   // );
  //   let branch: any = this.cntxitems.filter(
  //     (x: any) => x.text.toLowerCase() === 'branch'
  //   );

  //   if (branch.length > 0) {
  //     let fltr: any =
  //       this.contractXMLFlatJson &&
  //       this.contractXMLFlatJson.filter(
  //         (a: any) => a.fieldname.toLowerCase() === 'branch.name'
  //       );

  //     let branchcntxmenu: any = Common.GetContextMenu(
  //       this.jsonBranch,
  //       this._structure,
  //       this.user,
  //       this.props.contractData.branch ? this.props.contractData.branch.id : 0,
  //       true
  //     );

  //     if (branchcntxmenu.length > 0) {
  //       branch[0].items.push(...branchcntxmenu);
  //     }
  //   }
  // }

  // public ContextMenuProject() {
  //   let project: any = this.cntxitems.filter(
  //     (x: any) => x.text.toLowerCase() === 'project'
  //   );

  //   if (project.length > 0) {
  //     let fltr: any =
  //       this.contractXMLFlatJson &&
  //       this.contractXMLFlatJson.filter(
  //         (a: any) => a.fieldname.toLowerCase() === 'project.name'
  //       );

  //     let projectcntxmenu: any = Common.GetContextMenu(
  //       this.jsonProject,
  //       this._structure,
  //       this.user,
  //       this.props.contractData.project
  //         ? this.props.contractData.project.id
  //         : 0,
  //       true
  //     );

  //     if (projectcntxmenu.length > 0) {
  //       project[0].items.push(...projectcntxmenu);
  //     }
  //   }
  // }

  // public ContextMenuDepartment() {
  //   let department: any = this.cntxitems.filter(
  //     (x: any) => x.text.toLowerCase() === 'department'
  //   );

  //   if (department.length > 0) {
  //     let fltr: any =
  //       this.contractXMLFlatJson &&
  //       this.contractXMLFlatJson.filter(
  //         (a: any) => a.fieldname.toLowerCase() === 'department.name'
  //       );

  //     let departmentcntxmenu: any = Common.GetContextMenu(
  //       this.jsonDepartment,
  //       this._structure,
  //       this.user,
  //       this.props.contractData.department
  //         ? this.props.contractData.department.id
  //         : 0,
  //       true
  //     );

  //     if (departmentcntxmenu.length > 0) {
  //       department[0].items.push(...departmentcntxmenu);
  //     }
  //   }
  // }

  public ContextMenuContractType() {
    let ctype: any = this.cntxitems.filter(
      (x: any) => x.text.toLowerCase() === 'contract type'
    );

    if (ctype.length > 0) {
      let ctypecntxmenu: any = Common.GetContextMenu(
        this.jsonContractType,
        this._structure,
        this.user,
        this.props.contractData.account
          ? [this.props.contractData.account.id.toString()]
          : [],
        true
      );
      if (ctypecntxmenu) {
        ctype[0].items = ctypecntxmenu;
      }
    }
  }

  render() {
    let cnfDlg: Dialog;

    const dtopen = (e: any) => {
      setTimeout(() => {
        let a: any = document.querySelectorAll('.e-popup-open');
        if (a.length > 0) {
          let obj: any = this.elem.getBoundingClientRect();
          a[0].style.top = `${obj.bottom.toString()}px`;
          a[0].style.left = `${obj.left.toString()}px`;
        }
      }, 100);
    };

    const contextMenuClick = (e: any) => {
      let rootMenu: any = Common.GetSelectedContextMenuRootMenu(e.item, 0);

      if (e.item.id.toString().startsWith('__')) {
        let id: string = e.item.id.slice(2, e.item.id.indexOf('--'));

        if (id === '101') {
          //NEW GROUP
          this.setState({
            showdialog: true,
            groupname: '',
            dlgcontent: 'group',
            rootmenu: rootMenu,
          });
        } else if (id === '102') {
          //NEW ACCUNT
          this.setState({
            showdialog: true,
            groupname: '',
            dlgcontent: 'account',
            rootmenu: rootMenu,
          });
        }
      } else if (
        rootMenu.text.toLowerCase() === 'organization' ||
        rootMenu.text.toLowerCase() === 'job' ||
        rootMenu.text.toLowerCase() === 'contract type'
      ) {
        if (e.item.items.length > 0) {
          e.cancel = true;
          return;
        }
        if (rootMenu.text.toLowerCase() === 'organization') {
          rootMenu = Common.GetSelectedContextMenuRootMenu(e.item, 9999);
        }

        let fldname: string = rootMenu.text.toLowerCase();
        if (rootMenu.text.toLowerCase() === 'contract type') {
          fldname = 'account';
        }

        if (this.fieldlist) {
          let fieldfltr: any = Common.FindJsonByObjectValue(
            this.fieldlist.children,
            fldname,
            this.user,
            0,
            0,
            true
          );
          if (fieldfltr) {
            if (rootMenu.text.toLowerCase() === 'project') {
              SaveProject({
                employee: this.props.selectorData,
                contractid: this.props.contractData['id'],
                project: {
                  id: e.item.id.toString().split('-')[0],
                  quota: 0,
                  //account: { id: fieldfltr.object },
                },
              });
            } else {
              SaveValue([
                {
                  object: this.props.contractData.id,
                  field: fieldfltr.object,
                  value: e.item.id.toString().split('-')[0],
                  unique: true,
                },
              ]).then((response: any) => {
                if (rootMenu.text.toLowerCase() === 'job') {
                  this.props.contractData[fldname] = {
                    id: Number(e.item.id.toString().split('-')[0]),
                    title: e.item.text,
                    department: e.item.parentObj.text,
                  };

                  this.setState({
                    cntxitems: [],
                    allowancefetched: false,
                    jobfetched: false,
                    showjobdoc: false,
                  });
                  this.InitializeData();
                  this.setState({
                    allowancefetched: true,
                    jobfetched: true,
                    showjobdoc: true,
                  });
                  if (this.props.selectorData) {
                    this.props.refreshContract(
                      this.props.selectorData['contract']
                    );
                  }
                } else {
                  this.props.contractData[fldname] = {
                    id: Number(e.item.id.toString().split('-')[0]),
                    name: e.item.text,
                  };

                  this.setState({
                    cntxitems: [],
                    allowancefetched: false,
                    jobfetched: false,
                  });
                  this.InitializeData();
                  this.setState({
                    allowancefetched: true,
                    jobfetched: true,
                  });
                  if (this.props.selectorData) {
                    this.props.refreshContract(
                      this.props.selectorData['contract']
                    );
                  }
                }
              });
            }
          }
        }
      } else if (rootMenu.text.toLowerCase() === 'allowances') {
        // if (
        //   e.item.parentObj.parentObj.text.toLowerCase().trim() === 'allowances'
        // ) {
        let grp: any = this.contractXMLFlatJson.filter(
          (x: any) =>
            x.name.toLowerCase() ===
            e.item.parentObj.parentObj.text.toLowerCase()
        );
        if (grp.length <= 0) {
          this.props.contractData['allowances'] = [];
          this.contractXMLFlatJson.push({
            name: 'Allowances',
            value: '0',
            originalvalue: 0,
            fieldname: 'Allowances',
            type: 'number',
            subtasks: [],
          });
          grp = this.contractXMLFlatJson.filter(
            (x: any) =>
              x.name.toLowerCase() ===
              e.item.parentObj.parentObj.text.toLowerCase()
          );
        }
        if (grp.length > 0) {
          SaveAllowance({
            contract: this.props.contractData,
            welfare: Number(e.item.parentObj.id),
            scale: Number(e.item.id),
          });
        }
        // } else if (
        //   e.item.parentObj.parentObj.text.toLowerCase().trim() === 'branch'
        // ) {
        //   SaveValue([
        //     {
        //       object: this.props.contractData.id,
        //       field: this._structure.Field.Identif.Branch,
        //       value: e.item.id.toString(),
        //       unique: true,
        //     },
        //   ]).then((response: any) => {
        //     this.props.contractData['branch'] = {
        //       id: Number(e.item.id),
        //       name: e.item.text,
        //     };

        //     this.setState({
        //       cntxitems: [],
        //       allowancefetched: false,
        //       jobfetched: false,
        //     });
        //     this.InitializeData();
        //     this.setState({
        //       allowancefetched: true,
        //       jobfetched: true,
        //     });

        //     if (this.props.selectorData) {
        //       this.props.refreshContract(this.props.selectorData['contract']);
        //     }
        //   });
        // } else if (
        //   e.item.parentObj.parentObj.text.toLowerCase().trim() === 'project'
        // ) {
        //   SaveValue([
        //     {
        //       object: this.props.contractData.id,
        //       field: this._structure.Field.Identif.Project,
        //       value: e.item.id.toString(),
        //       unique: true,
        //     },
        //   ]).then((response: any) => {
        //     this.props.contractData['project'] = {
        //       id: Number(e.item.id),
        //       name: e.item.text,
        //     };

        //     this.setState({
        //       cntxitems: [],
        //       allowancefetched: false,
        //       jobfetched: false,
        //     });
        //     this.InitializeData();
        //     this.setState({
        //       allowancefetched: true,
        //       jobfetched: true,
        //     });

        //     if (this.props.selectorData) {
        //       this.props.refreshContract(this.props.selectorData['contract']);
        //     }
        //   });
        // } else if (
        //   e.item.parentObj.parentObj.text.toLowerCase().trim() === 'job'
        // ) {
        //   SaveValue([
        //     {
        //       object: this.props.contractData.id,
        //       field: this._structure.Field.Employment.Job,
        //       value: e.item.id.toString(),
        //       unique: true,
        //     },
        //   ]).then((response: any) => {
        //     this.props.contractData['job'] = {
        //       id: Number(e.item.id),
        //       title: e.item.text,
        //       department: e.item.parentObj.text,
        //     };

        //     this.setState({
        //       cntxitems: [],
        //       allowancefetched: false,
        //       jobfetched: false,
        //       showjobdoc: false,
        //     });
        //     this.InitializeData();
        //     this.setState({
        //       allowancefetched: true,
        //       jobfetched: true,
        //       showjobdoc: true,
        //     });
        //     this.props.refreshContract(this.props.selectorData['contract']);
        //   });
        // }
      }
      // else if (
      //   e.item.parentObj &&
      //   e.item.parentObj.controlParent &&
      //   e.item.parentObj.text.toLowerCase().trim() === 'contract type'
      // ) {
      //   SaveValue([
      //     {
      //       object: this.props.contractData.id,
      //       field: this._structure.Field.Identif.Account,
      //       value: e.item.id.toString(),
      //       unique: true,
      //     },
      //   ]).then((response: any) => {
      //     this.props.contractData['account'] = {
      //       id: Number(e.item.id),
      //       name: e.item.text,
      //     };

      //     this.setState({
      //       cntxitems: [],
      //       allowancefetched: false,
      //       jobfetched: false,
      //     });
      //     this.InitializeData();
      //     this.setState({
      //       allowancefetched: true,
      //       jobfetched: true,
      //     });
      //     if (this.props.selectorData) {
      //       this.props.refreshContract(this.props.selectorData['contract']);
      //     }
      //   });
      // } else if (
      //   e.item.parentObj &&
      //   e.item.parentObj.controlParent &&
      //   e.item.parentObj.text.toLowerCase().trim() === 'department'
      // ) {
      //   SaveValue([
      //     {
      //       object: this.props.contractData.id,
      //       field: this._structure.Field.Identif.Department,
      //       value: e.item.id.toString(),
      //       unique: true,
      //     },
      //   ]).then((response: any) => {
      //     this.props.contractData['department'] = {
      //       id: Number(e.item.id),
      //       name: e.item.text,
      //     };

      //     this.setState({
      //       cntxitems: [],
      //       allowancefetched: false,
      //       jobfetched: false,
      //     });
      //     this.InitializeData();
      //     this.setState({
      //       allowancefetched: true,
      //       jobfetched: true,
      //     });

      //     if (this.props.selectorData) {
      //       this.props.refreshContract(this.props.selectorData['contract']);
      //     }
      //   });
      // }
      else if (e.item.text.toLowerCase() === 'delete') {
        if (
          !this.rightClickedRowElement.rowInfo.rowData ||
          this.nonvoidablefieldnames.includes(
            this.rightClickedRowElement.rowInfo.rowData.fieldname.toLowerCase()
          )
        ) {
          return;
        }

        // let fieldlist: any = Common.FindJsonByObjectId(
        //   this.fulljson,
        //   this._structure.Element.Field,
        //   this.user
        // );
        if (this.fieldlist) {
          let fldname: string =
            this.rightClickedRowElement.rowInfo.rowData.fieldname;
          switch (fldname.toString().toLowerCase()) {
            case 'branch.name':
              fldname = 'Branch';
              break;

            case 'project.name':
              fldname = 'Project';
              break;

            case 'department.name':
              fldname = 'Department';
              break;

            case 'account.name':
              fldname = 'Account';
              break;

            case 'job.title':
              fldname = 'Job';
              break;

            case 'quota':
              fldname = 'Percentage';
              break;

            case 'document.sender.name':
              fldname = 'Sender';
              break;

            case 'document.name':
              fldname = 'Name';
              break;

            case 'document.recipient.name':
              fldname = 'Recipient';
              break;

            case 'document.date':
              fldname = 'Date';
              break;

            default:
              break;
          }
          let fieldfltr: any = Common.FindJsonByObjectValue(
            this.fieldlist.children,
            fldname,
            this.user,
            0,
            0,
            true
          );
          if (fieldfltr) {
            cnfDlg = DialogUtility.confirm({
              animationSettings: { effect: 'SlideTop' },
              showCloseIcon: true,
              closeOnEscape: true,
              cssClass: 'deletecontractinfo',
              title: ` Do you want to delete the ${this.rightClickedRowElement.rowInfo.rowData.name}? `,
              content: '',
              cancelButton: {
                text: 'No',
              },
              okButton: {
                text: 'Yes',
                click: confirmYesClick,
              },
            });
          }
        }
      }
    };

    const confirmYesClick = (e: any) => {
      // let fieldlist: any = Common.FindJsonByObjectId(
      //   this.fulljson,
      //   this._structure.Element.Field,
      //   this.user
      // );
      if (this.fieldlist) {
        let fldname: string =
          this.rightClickedRowElement.rowInfo.rowData.fieldname;
        let pval: string = '';

        switch (fldname.toLowerCase().toString().toLowerCase()) {
          case 'branch.name':
            fldname = 'Branch';
            break;

          case 'project.name':
            fldname = 'Project';
            break;

          case 'department.name':
            fldname = 'Department';
            break;

          case 'account.name':
            fldname = 'Account';
            break;

          case 'job.title':
            fldname = 'Job';
            break;

          case 'quota':
            fldname = 'Percentage';
            pval = this.rightClickedRowElement.rowInfo.rowData.id.toString();
            break;

          case 'welfare':
            pval = this.rightClickedRowElement.rowInfo.rowData.id.toString();
            break;

          default:
            break;
        }
        let fieldfltr: any = Common.FindJsonByObjectValue(
          this.fieldlist.children,
          fldname,
          this.user,
          0,
          0,
          true
        );
        if (fieldfltr) {
          let payload: any = {
            object: this.props.contractData.id,
            field: fieldfltr.object,
            value: pval,
          };
          DeleteValue(payload).then((response: any) => {
            if (!this.rightClickedRowElement.rowInfo.rowData.parentItem) {
              delete this.props.contractData[
                this.rightClickedRowElement.rowInfo.rowData.fieldname.split(
                  '.'
                )[0]
              ];
            } else if (
              this.rightClickedRowElement.rowInfo.rowData.parentItem.name.toLowerCase() ===
              'allowances'
            ) {
              let flter: any = this.props.contractData['allowances'].filter(
                (x: any) =>
                  x.id == this.rightClickedRowElement.rowInfo.rowData.id
              );
              if (flter) {
                this.props.contractData['allowances'].splice(
                  this.props.contractData['allowances'].indexOf(flter[0]),
                  1
                );
              }
            } else if (
              this.rightClickedRowElement.rowInfo.rowData.parentItem.name.toLowerCase() ===
              'projects'
            ) {
              let flter: any = this.props.contractData['projects'].filter(
                (x: any) =>
                  x.id == this.rightClickedRowElement.rowInfo.rowData.id
              );
              if (flter) {
                this.props.contractData['projects'].splice(
                  this.props.contractData['projects'].indexOf(flter[0]),
                  1
                );
              }
            }

            this.setState({
              cntxitems: [],
              allowancefetched: false,
              jobfetched: false,
            });
            this.InitializeData();
            this.setState({
              allowancefetched: true,
              jobfetched: true,
            });
          });
        }
      }

      return true;
    };

    const SaveAllowance = (payload: any) => {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Contract/Allowance/Set`,
        payload,
        Common.getToken() || '',
        this.user
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          let cntrct: any = response;
          this.props.contractData['allowances'] = cntrct['allowances'];

          this.setState({
            cntxitems: [],
            allowancefetched: false,
            jobfetched: false,
          });
          this.InitializeData();
          this.setState({
            allowancefetched: true,
            jobfetched: true,
          });
          if (this.props.selectorData) {
            this.props.refreshContract(this.props.selectorData['contract']);
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    const SaveProject = (payload: any) => {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Contract/Project/Set`,
        payload,
        Common.getToken() || '',
        this.user
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          let cntrct: any = response;
          this.props.contractData['projects'] = cntrct['projects'];

          this.setState({
            cntxitems: [],
            allowancefetched: false,
            jobfetched: false,
          });
          this.InitializeData();
          this.setState({
            allowancefetched: true,
            jobfetched: true,
          });
          if (this.props.selectorData) {
            this.props.refreshContract(this.props.selectorData['contract']);
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    const SaveValue = async (payload: any) => {
      await Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Save`,
        payload,
        Common.getToken() || '',
        this.user
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          let obj: any = response;
          return response;
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    const DeleteValue = async (payload: any) => {
      await Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Delete`,
        payload,
        Common.getToken() || '',
        this.user
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          let obj: any = response;
          cnfDlg.hide();
          return response;
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    const actionbegin = (e: any) => {
      if (e.type === 'edit') {
        switch (e.rowData.fieldname) {
          case 'name':
            e.cancel = true;
            e.type = '';
            break;
          default:
            break;
        }
      } else if (e.type === 'save') {
        if (
          !(
            (e.rowData.parentItem &&
              e.rowData.parentItem.name.toLowerCase() !== 'projects') ||
            this.readonlyfieldnames.includes(e.rowData.fieldname.toLowerCase())
          )
        ) {
          // let fieldlist: any = Common.FindJsonByObjectId(
          //   this.fulljson,
          //   this._structure.Element.Field,
          //   this.user
          // );
          if (this.fieldlist) {
            let fldname: string = e.rowData.fieldname;
            if (e.rowData.fieldname.toLowerCase() === 'quota') {
              fldname = 'Percentage';
            }
            let fieldfltr: any = Common.FindJsonByObjectValue(
              this.fieldlist.children,
              fldname,
              this.user,
              0,
              0,
              true
            );
            if (fieldfltr) {
              let objectid: number = e.rowData.id;
              let fieldid: number = fieldfltr.object;
              let value: string = e.value;
              let flter: any;
              if (e.rowData.parentItem) {
                flter = this.contractXMLFlatJson.filter(
                  (x: any) =>
                    x.fieldname.toLowerCase() ===
                    e.rowData.parentItem.name.toLowerCase()
                );
                if (flter.length > 0) {
                  flter = flter[0].subtasks.filter(
                    (x: any) =>
                      x.id.toString().toLowerCase() ===
                      e.rowData.id.toString().toLowerCase()
                  );
                }
              } else {
                flter = this.contractXMLFlatJson.filter(
                  (x: any) =>
                    x.fieldname.toLowerCase() ===
                    e.rowData.fieldname.toLowerCase()
                );
              }
              if (flter.length > 0) {
                if (e.rowData.type === 'number') {
                  value = flter[0]['originalvalue'].toString();
                  if (
                    fldname.toLowerCase() === 'percentage' &&
                    e.rowData.parentItem
                  ) {
                    let tflter = this.contractXMLFlatJson.filter(
                      (x: any) =>
                        x.fieldname.toLowerCase() ===
                        e.rowData.parentItem.name.toLowerCase()
                    );
                    if (tflter.length > 0) {
                      let percTotal: number = tflter[0].subtasks.reduce(
                        (total: any, x: any) => {
                          return total + Number(x.originalvalue);
                        },
                        0
                      );
                      if (percTotal > 100) {
                        DialogUtility.alert({
                          title: 'Invalid Percentage',
                          content:
                            'Sorry, Percentage should not be greater than 100!',
                        });

                        e.cancel = true;
                        e.type = 'actionComplete';
                        e.requestType = '';
                        return;
                      }
                    }
                  }
                } else if (e.rowData.type === 'date') {
                  value = flter[0]['originalvalue'];
                }

                if (e.value !== e.previousValue) {
                  SaveValue([
                    { object: objectid, field: fieldid, value: value },
                  ]).then((response: any) => {
                    if (e.rowData.parentItem) {
                      let sflter = this.props.contractData[
                        e.rowData.parentItem.name.toLowerCase()
                      ].filter((x: any) => x.id == e.rowData.id);
                      if (sflter.length > 0) {
                        sflter[0]['quota'] = flter[0]['originalvalue'];
                      }
                    } else {
                      this.props.contractData[e.rowData.fieldname] =
                        flter[0]['originalvalue'];
                    }

                    this.setState({
                      cntxitems: [],
                      allowancefetched: false,
                      jobfetched: false,
                    });
                    this.InitializeData();
                    this.setState({
                      allowancefetched: true,
                      jobfetched: true,
                    });
                    if (this.props.selectorData) {
                      this.props.refreshContract(
                        this.props.selectorData['contract']
                      );
                    }
                  });
                }
              }
            } else {
              console.log(
                'field not found in structure field list',
                e.rowData.fieldname
              );
            }
          }
        }
      }
    };

    const dialogContent = () => {
      if (
        this.state.dlgcontent === 'group' ||
        this.state.dlgcontent === 'account'
      ) {
        return (
          <div>
            <div className='form-row'>
              <div className='form-group col-md-12'>
                <div className='e-float-input e-control-wrapper'>
                  <input
                    id='groupname'
                    name='groupname'
                    type='text'
                    spellCheck={false}
                    autoComplete='off'
                    onChange={onGroupNameChange}
                    value={this.state.groupname}
                    required={true}
                    autoFocus
                  />
                  <span className='e-float-line' />
                  <label
                    className='e-float-text e-label-top'
                    htmlFor='groupname'
                  >
                    Name
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.dlgcontent === 'job') {
        return (
          <DocumentViewer
            id={'wordeditor-contract'}
            indialogbox={true}
            jsonDataViewer={{
              id: this.props.contractData.job.id,
              account: { id: 0 },
            }}
            selectedmenu={{ children: [] }}
            hidetoolbar={true}
            hideFooterToolbar={true}
            restrictEditing={true}
          />
        );
      }
    };

    const onGroupNameChange = (e: any) => {
      this.setState({
        groupname: e.target.value,
      });
    };

    const saveGroup = (e: any) => {
      if (this.state.groupname.trim().length <= 0) {
        DialogUtility.alert('Please enter name.');
        e.cancel = true;
        return;
      }

      let payload: any = null;
      let parentid: number = 0;
      let parentobj: any = null;

      if (
        this.state.dlgcontent === 'group' ||
        this.state.dlgcontent === 'account'
      ) {
        parentobj = this.cmenuInstance.lItem;
        if (this.state.rootmenu.text.toLowerCase() === 'job') {
          if (this.state.dlgcontent === 'group') {
            if (this.jsonDept) {
              parentid = this.jsonDept.object;
            }
          } else if (this.state.dlgcontent === 'account') {
            parentid = Number(parentobj.id);

            DialogUtility.alert('Task is pending. Please try after some time.');
            e.cancel = true;
            return;
          }
        } else {
          //parentid = Number(parentobj.id.split('-')[0]);
          parentid = Number(
            parentobj.id.slice(
              parentobj.id.indexOf('--') + 2,
              parentobj.id.indexOf('--', parentobj.id.indexOf('--') + 1)
            )
          );
        }

        if (parentid > 0) {
          payload = {
            name: this.state.groupname,
            ischild: this.state.dlgcontent === 'group' ? false : true,
            parentid: parentid,
          };
        } else {
          DialogUtility.alert('Parent object not found.');
          e.cancel = true;
          return;
        }
      }

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/General/Group/Set`,
        payload,
        Common.getToken() || '',
        this.user
      )
        .then((response: any) => {
          dialogInstance.hide();

          let jsn: any = Common.FindJsonByObjectId(
            this.jsonOrg,
            parentid,
            this.user
          );

          if (jsn) {
            let newitem: any = {
              object: Number(response.data.id),
              field: this.user.language,
              fieldValue: '',
              value: payload.name,
              rFieldId:
                this.state.dlgcontent === 'group'
                  ? this.state.rootmenu.text.toLowerCase() === 'job'
                    ? this._structure.Element.Child
                    : this._structure.Element.Group
                  : this._structure.Element.Child,
              idx: this.state.nextidx,
              parentidx: jsn.idx,
              rFieldValue: '',
              children: [],
            };
            jsn.children.push(newitem);

            Common.setItem('fulljson', this.fulljson);

            if (
              this.state.dlgcontent === 'group' &&
              this.state.rootmenu.text.toLowerCase() === 'job'
            ) {
              let newItem: any = {
                id: '',
                name: '',
                description: '',
                account: { id: Number(response.data.id), name: payload.name },
              };
              let flter: any = this.jsonJob.push(newItem);
            }
            this.setState({
              cntxitems: [],
              allowancefetched: false,
              jobfetched: false,
            });
            this.InitializeData();
            this.setState({
              allowancefetched: true,
              jobfetched: true,
              nextidx: Number(this.state.nextidx) + 1,
            });
          }

          return response.data;
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
      return undefined;
    };

    const footerContent = () => {
      return (
        <>
          <button
            className='flat-button'
            type='button'
            onClick={() => dialogInstance.hide()}
          >
            Cancel
          </button>
          <button className='flat-button' type='button' onClick={saveGroup}>
            Accept
          </button>
        </>
      );
    };

    const handleOpenjobdocClick = (e: any) => {
      this.setState({ showdialog: true, dlgcontent: 'job' });
    };

    const handleoverlayClick = (e: any) => {
      if (this.state.dlgcontent === 'job') {
        dialogInstance.hide();
      }
    };
    var dialogInstance: DialogComponent | any;
    return (
      <>
        <div className='row mx-4 my-2' id='contract-header'>
          <div
            className='col-12 d-flex align-items-center'
            style={{ height: '27px' }}
          >
            <div>{this.props.contractTitle}</div>
          </div>
        </div>
        <div className='contract-body row mx-1'>
          <div className='col-12 h-100'>
            <div className='row contract-content my-3'>
              <div
                id='treegridcontractjson'
                className={`contract-json px-0 ${
                  this.props.inoffcanvas === true ? 'col-12' : 'col-md-7'
                }`}
              >
                <div className='control-pane h-100'>
                  <div className='control-section h-100'>
                    <div className='h-100'>
                      <TreeGridComponent
                        id={`TreeGridContract-${this.props.id}`}
                        dataSource={this.contractXMLFlatJson}
                        height='100%'
                        //rowTemplate={this.contractDataTemplate.bind(this)}
                        treeColumnIndex={0}
                        childMapping='subtasks'
                        contextMenuItems={[]}
                        editSettings={this.editSettings}
                        ref={(treegrid: any) => (this.treegridObj = treegrid)}
                        //allowTextWrap={true}
                        //cellEdit={beginedit}
                        actionBegin={actionbegin}
                        enableHover={true}
                        contextMenuOpen={(e: any) => {
                          if (e.event.target.classList.contains('e-content')) {
                            this.rightClickedRowElement = null;
                          } else {
                            this.rightClickedRowElement = e;
                          }
                          this.rightClickedRowElement = e;
                        }}
                        // toolbar={[
                        //   {
                        //     text: '',
                        //     tooltipText: 'More Options',
                        //     prefixIcon: 'e-columnmenu',
                        //     id: 'moreoptions',
                        //     align: 'Right',
                        //   },
                        // ]}
                      >
                        <ColumnsDirective>
                          <ColumnDirective
                            field='name'
                            headerText=''
                            textAlign='Left'
                            width={'60%'}
                            allowEditing={false}
                          ></ColumnDirective>
                          <ColumnDirective
                            field='value'
                            headerText=''
                            textAlign='Left'
                            width={'40%'}
                            edit={this.editTemplate}
                          ></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Edit, ContextMenu, Toolbar]} />
                      </TreeGridComponent>
                      <ContextMenuComponent
                        id={`contentmenucontractgrid--${this.props.id}`}
                        target={`#TreeGridContract-${this.props.id}`}
                        items={this.state.cntxitems}
                        onClick={contextMenuClick}
                        select={contextMenuClick}
                        cssClass='contract-custom-contextmenu'
                        beforeOpen={(e: any) => {
                          let arr: string[] = [];
                          //if (this.jsonProject.length <= 0) arr.push('Project');
                          //if (this.jsonBranch.length <= 0) arr.push('Branch');
                          // if (this.jsonDepartment.length <= 0) arr.push('Department');
                          //if (this.jsonJob.length <= 0) arr.push('Job');
                          this.cmenuInstance.enableItems(
                            [
                              ...arr,
                              'Place of Work',
                              'Grade',
                              'Scale',
                              'Working Schedule',
                            ],
                            false
                          );
                          if (this.rightClickedRowElement.rowInfo.rowData) {
                            let itms: any = e.items.map((x: any) => x.text);
                            if (!e.parentItem) {
                              this.cmenuInstance.hideItems(itms);
                              if (this.props.inoffcanvas == false) {
                                if (
                                  this.nonvoidablefieldnames.includes(
                                    this.rightClickedRowElement.rowInfo.rowData.fieldname.toLowerCase()
                                  ) === false
                                ) {
                                  this.cmenuInstance.showItems(['Delete']);
                                }
                              }
                              // itms
                              //   .filter((x: any) => x === '')
                              //   .forEach((itm: any) =>
                              //     //this.cmenuInstance.removeItems([''])
                              //   );
                              let sitems: any = e.element.querySelectorAll(
                                'li.e-separator:not(.e-menu-hide)'
                              );
                              if (sitems.length > 0) {
                                sitems.forEach((x: any) =>
                                  x.classList.add('e-menu-hide')
                                );
                              }

                              if (this.rightClickedRowElement) {
                                switch (
                                  this.rightClickedRowElement.rowInfo.rowData
                                    .fieldname
                                ) {
                                  case 'branch.name':
                                    //this.cmenuInstance.showItems(['Branch']);
                                    this.cmenuInstance.showItems([
                                      'Organization',
                                    ]);
                                    break;

                                  case 'projects':
                                    //this.cmenuInstance.showItems(['Project']);
                                    this.cmenuInstance.showItems([
                                      'Organization',
                                    ]);
                                    break;

                                  case 'department.name':
                                    // this.cmenuInstance.showItems([
                                    //   'Department',
                                    // ]);
                                    this.cmenuInstance.showItems([
                                      'Organization',
                                    ]);
                                    break;

                                  case 'job.title':
                                    this.cmenuInstance.showItems(['Job']);
                                    break;

                                  case 'Allowances':
                                    this.cmenuInstance.showItems([
                                      'Allowances',
                                    ]);
                                    break;

                                  case 'account.name':
                                    this.cmenuInstance.showItems([
                                      'Contract Type',
                                    ]);
                                    break;

                                  default:
                                    break;
                                }
                              }
                            }
                          } else {
                            let itms: any = e.items.map((x: any) => x.text);
                            if (!e.parentItem) {
                              this.cmenuInstance.showItems(itms);
                            }
                          }
                        }}
                        ref={(gridcmenu) =>
                          (this.cmenuInstance =
                            gridcmenu as ContextMenuComponent)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-none'>
                <DatePickerComponent
                  id='pdate'
                  format={this.user.settings.formats.DateShort}
                  value={new Date()}
                  //onChange={this.onpersonemp_datechange.bind(this)}
                  showClearButton={false}
                  cssClass='e-hidedate-icon'
                  openOnFocus={true}
                  showTodayButton={false}
                  //placeholder={this.user.settings.formats.DateShort}
                  placeholder='Select Date'
                  style={{ fontSize: '15px' }}
                  ref={(dt: any) => (this.dt = dt)}
                  open={dtopen}
                />
              </div>
              <div
                className={`h-100 ${
                  this.props.inoffcanvas === true ? 'col-12' : 'col-md-5'
                }`}
              >
                <div className='contract-job'>
                  <div className='control-pane h-100'>
                    <div className='control-section h-100'>
                      {/* <div className='row'>
                        <div
                          className={` ${
                            this.props.inoffcanvas === true
                              ? 'col-7'
                              : 'col-md-2'
                          }`}
                          style={{
                            paddingLeft: `${
                              this.props.inoffcanvas === true
                                ? '30px'
                                : undefined
                            }`,
                          }}
                        >
                          <label>
                            {this.props.contractData.job &&
                            this.props.inoffcanvas === false
                              ? 'Job Title'
                              : ''}
                          </label>
                        </div>
                        <div
                          className={` ${
                            this.props.inoffcanvas === true
                              ? 'col-5 text-end'
                              : 'col-md-10'
                          }`}
                        >
                          <label className='font-14'>
                            {this.props.contractData.job &&
                            this.props.inoffcanvas === false
                              ? this.props.contractData.job.title
                              : ''}
                          </label>
                        </div>
                      </div> */}
                      <div className='row'>
                        <div className='col-md-12 text-end'>
                          <label className='font-13'>
                            {this.props.inoffcanvas === false &&
                            this.props.contractData.job
                              ? 'Job Description : '
                              : ''}
                            {this.props.inoffcanvas === false &&
                              this.props.contractData.job && (
                                <div
                                  id='openjobdoc'
                                  title='Show Job Description'
                                  className='fa-solid fa-up-right-from-square cursor-pointer'
                                  onClick={handleOpenjobdocClick}
                                ></div>
                              )}
                          </label>
                        </div>
                      </div>
                      <div className='row job-doc'>
                        <div className='col-md-12 h-100'>
                          {this.props.contractData.job &&
                          this.props.inoffcanvas === false &&
                          this.state.showjobdoc === true ? (
                            // <DocumentViewer
                            //   id={'contract'}
                            //   indialogbox={false}
                            //   jsonDataViewer={{
                            //     id: this.props.contractData.job.id,
                            //     account: { id: 0 },
                            //   }}
                            //   selectedmenu={{ children: [] }}
                            //   hidetoolbar={true}
                            // />
                            <></>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showdialog === true ? (
          <DialogComponent
            id='defaultDialog-dossier'
            showCloseIcon={true}
            visible={this.state.showdialog}
            width={
              this.state.dlgcontent === 'group' ||
              this.state.dlgcontent === 'account'
                ? '380px'
                : '40%'
            }
            height={
              this.state.dlgcontent === 'group' ||
              this.state.dlgcontent === 'account'
                ? '180px'
                : '90%'
            }
            style={{ overflow: 'auto' }}
            target={'#root'}
            ref={(dialog) => (dialogInstance = dialog)}
            close={() => this.setState({ showdialog: false })}
            content={dialogContent}
            footerTemplate={this.state.showjobdoc ? null : footerContent}
            position={
              this.state.dlgcontent === 'group' ||
              this.state.dlgcontent === 'account'
                ? { X: 'center', Y: 'center' }
                : { X: 'right', Y: 'center' }
            }
            animationSettings={{
              effect:
                this.state.dlgcontent === 'group' ||
                this.state.dlgcontent === 'account'
                  ? 'Zoom'
                  : 'SlideRight',
            }}
            isModal={this.state.dlgcontent === 'job' ? true : true}
            statelessTemplates={[]}
            header={
              this.state.dlgcontent === 'group'
                ? 'Create Group'
                : this.state.dlgcontent === 'account'
                ? 'Create Account'
                : this.state.dlgcontent === 'job'
                ? this.props.contractData.job.title
                : 'Create'
            }
            overlayClick={handleoverlayClick}
          ></DialogComponent>
        ) : null}
      </>
    );
  }
}

export default Contracts;
