// * ---------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SUHAIL - 2023-03-03 - 370 - research on collapse and expandable tree view / sidebar
// * SUHAIL - 2023-03-09 - 90 - research - sidebar api in json structure with id, field and value
// * SUHAIL - 2023-03-10 - 270 - research - sidebar api in json structure with id, field and value
// * SUHAIL - 2023-03-17 - 30 - reasearch on getting id and value of collection on person click in sidebar
// * SUHAIL - 2023-03-20 - 390 - research on preventing re - render sidebar on person click
// * SUHAIL - 2023-03-27 - 340 - research on fetching employee side list as not working after package update.
// * SUHAIL - 2023-05-12 - 30 - meeting with fabio on new fetching holiday list from new subscriber service
// * SUHAIL - 2023-05-12 - 60 - sprint review meeting
// * SUHAIL - 2023-05-12 - 330 - research on adapting new selector structure with subscriber service
// * SUHAIL - 2023-06-06 - 80 - meeting with fabio on new package update
// * SUHAIL - 2023-06-06 - 120 - research on custom formatting number for employee salary
// * SUHAIL - 2023-06-06 - 180 - merging and testing sourav and avinash changes in main branch
// * SUHAIL - 2023-06-06 - 100 - working on creating context menu from xml on employee dossier
// * SANDEEP - 2023-08-09 - 120 - Understanding the flow for sidebar and understanding it.
// * SUHAIL - 2023-08-25 - 390 - Enable context menu on Sidebar to allow to create word template in dialog box
// * SUHAIL - 2023-08-28 - 40 - attended weekly status review meeting
// * SUHAIL - 2023-08-28 - 60 - meeting with fabio on creating context menu and template from side bar
// * SUHAIL - 2023-08-28 - 380 - Fixing some issues as per Fabio's comment and Enable context menu on Sidebar to allow to create word template in dialog box
// * SUHAIL - 2023-08-29 - 480 - Working on creating dialog box on sidebar context menu click and show word document editor to create word template
// * SUHAIL - 2023-08-31 - 25 - attended sprint planning meeting.
// * SUHAIL - 2023-08-31 - 45 - attended weekly status review meeting
// * SUHAIL - 2023-08-31 - 410 - working on creating dialog box on sidebar context menu click and research on getting list of fields from c# class object
// * SUHAIL - 2023-09-01 - 30 - attended sprint review and retrospective meeting.
// * SUHAIL - 2023-09-01 - 90 - attended meeting for preparation for the upcoming pi meeting
// * SUHAIL - 2023-09-01 - 15 - meeting with fabio on saving template with new account
// * SUHAIL - 2023-09-01 - 345 - Save word template in database for respective correspondence menu sub items
// * SUHAIL - 2023-09-12 - 90 - meeting with fabio on correspondence template create name format and template and issues after updating package
// * SUHAIL - 2023-09-12 - 90 - Display respective word template from the database in document viewer when employee is selected from selector
// * SUHAIL - 2023-09-12 - 100 - Fixing issue of word template not opening on dossier double click for saved template from sidebar context menu
// * SUHAIL - 2023-09-12 - 200 - Working on issue of Json?id=0 end point giving error on Amos Felician request
// * SUHAIL - 2023-09-13 - 80  - attended weekly status review meeting
// * SUHAIL - 2023-09-13 - 400 - Applying contract info in mail merge fields from newly updated package contract endpoint and applying date format in both document and contract merge fields
// * SUHAIL - 2023-09-14 - 95 - attended sprint planning meeting
// * SUHAIL - 2023-09-14 - 385 - Name Format - Dialog box from sidebar context menu click to set Name Format for Correspondence Name
// * SUHAIL - 2023-09-26 - 480 - Option to Add new company for sender / recipient on Create Correspondence dialog box
// * SUHAIL - 2023-11-16 - 30 - meeting with fabio on context menu organization position group in employee contract
// * SUHAIL - 2023-11-16 - 450 - Create new Group under sidebar menu when clicked on Group item from sidebar context menu
// * SUHAIL - 2023-11-17 - 15 - meeting with fabio on list of parent for Project in contract after package update
// * SUHAIL - 2023-11-17 - 465 - Create new Account under sidebar menu when clicked on Account item from sidebar context menu
// * SUHAIL - 2023-11-20 - 30 - attended weekly status meeting
// * SUHAIL - 2023-11-20 - 450 - Add New... option on context menu items for Department list  to create new Department in employee contract
// * SUHAIL - 2023-11-21 - 30 - meeting with fabio on issues in saving project in employee contract
// * SUHAIL - 2023-11-21 - 450 - Add New... option on context menu items for Project list  to create new project in employee contract
// * SUHAIL - 2023-11-22 - 80 - attended weekly status review meeting
// * SUHAIL - 2023-11-22 - 60 - meeting with fabio on issue on saving project in employee contract
// * SUHAIL - 2023-11-22 - 340 - Contract - Allow to add multiple Projects in employee contract
// * SUHAIL - 2023-11-23 - 60 - attended sprint planning meeting
// * SUHAIL - 2023-11-23 - 60 - meeting with fabio on issue on saving project in employee contract
// * SUHAIL - 2023-11-23 - 180 - Contract - Show multiple Projects list in employee contract
// * SUHAIL - 2023-11-23 - 180 - Contract - Add New... option in context menu items for Job list  to create new Job Group in employee contract
// * SUHAIL - 2023-11-24 - 60 - meeting with fabio on project save and contract save issue
// * SUHAIL - 2023-11-24 - 30 - attended sprint review and retrospective meeting
// * SUHAIL - 2023-11-24 - 390 - Contract - Hide Project items and department items from context menu if project or department is already assigned in employee contract
// * SUHAIL - 2023-11-27 - 55 - attended weekly status review meeting
// * SUHAIL - 2023-11-27 - 65 - Contract - Hide Project items and department items from context menu if project or department is already assigned in employee contract
// * SUHAIL - 2023-11-27 - 180 - Add loader/splash screen after login
// * SUHAIL - 2023-11-27 - 180 - Login : Show validation message in username and password text as place holder
// * SUHAIL - 2023-11-27 - 60 - meeting with fabio on login page issues and splash screen changes
// * SUHAIL - 2023-11-29 - 240 - Force client browser to refresh the page when frontend project new version is deployed on cloud
// * SUHAIL - 2023-11-29 - 60 - Word - Hide Title bar in word document editor
// * SUHAIL - 2023-11-29 - 90 - Contract - Allow to Add / Change percentage in Projects in employee contract
// * SUHAIL - 2023-11-29 - 90 - Contract - Allow to remove Projects in employee contract
// * SUHAIL - 2023-11-30 - 30 - meeting with fabio sir regarding login registration page.
// * SUHAIL - 2023-11-30 - 450 - Contract - Show job document in overlay panel on expand click in employee contract
// * SUHAIL - 2023-12-01 - 100 - meeting with fabio on discussion on changes requested by him
// * SUHAIL - 2023-12-01 - 380 - Employee - Contract - change aruti logo and title, set new correspondence subject by default account name and other small changes
// * SUHAIL - 2023-12-07 - 80 - Set bootstrap modal dialog box for login registration wizard
// * SUHAIL - 2023-12-07 - 400 - Fixing issues on registration access code page
// * SUHAIL - 2023-12-11 - 80 - Contract - Allow to Edit Project Percentage in employee contract
// * SUHAIL - 2023-12-11 - 200 - Dossier - Show merged document if template is created when double click on dossier in employee dossier list
// * SUHAIL - 2023-12-11 - 200 - Contract - Show contract document when double click on contract in employee contract list
// * SUHAIL - 2023-12-12 - 480 - working on layout fix on sidebar
/*
    ---------------------------------------------------------------------------------------------------------------2.Initialize
*/
// * SUHAIL - 2023-02-24 - 10
import React, { Component } from 'react';
import Common, { JsonTree } from '../../Util/Common';
import {
  AppBarColor,
  TreeViewComponent,
  SidebarComponent,
  ContextMenuComponent,
  BeforeOpenCloseMenuEventArgs,
  MenuItemModel,
} from '@syncfusion/ej2-react-navigations';
import { enableRipple } from '@syncfusion/ej2-base';
import {
  PaneDirective,
  PanesDirective,
  SplitterComponent,
} from '@syncfusion/ej2-react-layouts';
import SelectorPanel from './SelectorPanel';
import { JsxElement } from 'typescript';
import {
  sidebar_template_contextmenu,
  sidebar_contextmenu_for_group,
  newmenu_groupaccount_contextmenu,
} from '../../dummydata/xml_data_employee';
import { ContextMenuItemModel } from '@syncfusion/ej2-react-grids';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-react-popups';
import WordTemplate from '../Template/WordTemplate';
//import Layout from '../../Pages/Layout';
//import { createSpinner } from '@syncfusion/ej2-popups';

// const {
//     REACT_APP_ENDPOINT_API,
//     REACT_APP_ENDPOINT_SUBSCRIBER,
//     REACT_APP_ENDPOINT_SUBSCRIBERPATH,
//     REACT_APP_ENDPOINT_XMLPATH,
//     REACT_APP_ENDPOINT_SIDEBARPATH,
//     REACT_APP_ENDPOINT_FULLJSONPATH,
// } = process.env

/*
UNCOMMENT IF XML2JS ARE REQUIRED
*/
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

/*
  ------------------------------------------------------------------------------------------------------------------3.Style
*/

/*
-------------------------------------------------------------------------------------------------------------------------------------------------DOTENV VAR DECLARATION
*/
const { REACT_APP_ENVIRONMENT, REACT_APP_ENDPOINT_FOXSYSTEMSERVICE } =
  process.env;

// * ---------------------------------------------------------------------------------------------------------------2.Initialize
interface Props {
  // onServiceLoadingChange: any
  // onServiceNameChange: any
  sidebarClick: any;
  selectorLoading: any;
  jsonSidebar: any;
  noSelector: any;
}

/*
-----------------------------------------------------------------------------------------------------------------------4.Api
*/

/*
-------------------------------------------------------------------------------------------------------------------------------------------------CLASS DECLARATION
// * SUHAIL - 2023-02-24 - 30
*/
class Sidebar extends React.Component<Props> {
  /*
             ------------------------------------------------------------------------------------------------------------------2.Initialize Component
         */
  // * SUHAIL - 2023-02-24 - 10
  // * SUHAIL - 2023-03-07 - 10
  // * SUHAIL - 2023-03-13 - 10
  // * SUHAIL - 2023-03-16 - 10
  // * SUHAIL - 2023-03-28 - 10
  // * SUHAIL - 2023-03-29 - 10
  // * SUHAIL - 2023-04-05 - 10
  private idx = 0;
  private icons: any = [];
  private user: any;
  private _structure: any;
  private fulljson: any;
  //private columnset: any;
  private onnodemenu: any = null;
  private onleafmenu: any = null;
  private onexpandmenu: any = null;
  private selectedmenu: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.fulljson = JSON.parse(Common.getFullJson() ?? '');
    /*
          -------------------------------------------------------------------------------------------------------------------------------------------------EVENT BINDING
        */
    // SAMPLE ON EVENT BINDING
    // this.onInputFocus = this.onInputFocus.bind(this);
  }

  /*
      ------------------------------------------------------------------------------------------------------------------5.State
     */
  // * SUHAIL - 2023-02-24 - 20
  // * SUHAIL - 2023-03-13 - 10
  // * SUHAIL - 2023-03-29 - 10
  // * SUHAIL - 2023-04-04 - 10
  // * SUHAIL - 2023-04-05 - 10
  // * SUHAIL - 2023-04-06 - 10
  state = {
    isLoading: this.props.jsonSidebar !== null ? false : true,
    jsonSidebar: this.props.jsonSidebar,
    isSelectorLoading: false,
    serviceName: '',
    jsonSelector: [],
    // columnset: null,
    rootmenu: undefined,
    currmenu: undefined,
    selectedAllocations: [],
    showdialog: false,
    accountid: 0,
    accountname: '',
    jsonTemplate: null,
    dlgcontent: '',
    selectednameformat: null,
    groupname: '',
    nextidx: 99990,
  };

  /*
     ------------------------------------------------------------------------------------------------------------------Event Handler
   */
  // * SUHAIL - 2023-02-24 - 20
  // * SUHAIL - 2023-03-13 - 10
  // * SUHAIL - 2023-03-16 - 20
  // * SUHAIL - 2023-03-28 - 10
  componentDidMount() {
    // TODO ACTIONS
    localStorage.removeItem('treeviewmain-treeview');
    //if (this.state.jsonSidebar !== null) {
    if (this.state.jsonSidebar.length <= 0) {
      this.GetSidebar();
      // this.user = JSON.parse(Common.getUser() ?? '');
      // this._structure = JSON.parse(Common.getStructure() ?? '');
    }
  }

  // shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<{}>, nextContext: any): boolean {
  //   // alert(this.props.refesh);
  //   if (this.props.refesh === nextProps.refesh) {
  //     return false;
  //   }
  //   else
  //     return true;
  // }
  /*
     ------------------------------------------------------------------------------------------------------------------6.Adapter
   */
  // * SUHAIL - 2023-02-24 - 150
  public GetSidebar() {
    this.setState({ isLoading: true });
    // Common.ApiCallAsync(
    //   'GET',
    //   `${REACT_APP_ENDPOINT_SIDEBARPATH}`,
    //   '',
    //   Common.getToken() || '',
    // )
    //   .then((resopnse: any) => {
    //     return resopnse.data
    //   })
    //   .then((resopnse: any) => {
    //     this.setState({ jsonSidebar: resopnse })
    //   })
    //   .catch((error: any) => {
    //     console.error(error)
    //   })
    //   .finally(() => {
    //     this.setState({ isLoading: false });
    //   })
    let sidebar: any = Common.FindJsonByObjectId(
      this.fulljson,
      this.user.sidebar,
      this.user
    );
    this.setState({ jsonSidebar: [sidebar], isLoading: false });

    return null;
  }

  /*
     ------------------------------------------------------------------------------------------------------------------6.Adapter
   */
  // * SUHAIL - 2023-03-20 - 0
  // * SUHAIL - 2023-03-28 - 60
  // * SUHAIL - 2023-03-29 - 40
  // * SUHAIL - 2023-04-05 - 60
  public GetSelector(
    servicename: string,
    payLoad: any,
    rootmenu: any,
    currmenu: any,
    selectedAllocations: any,
    selectorSize: number
  ) {
    //servicename = `5221/${servicename}`;
    if (REACT_APP_ENVIRONMENT === 'dev') {
      servicename = `5221/${servicename}`;
    } else {
      servicename = `dataprovider/${servicename}`;
    }
    //this.setState({ isSelectorLoading: true });
    this.props.selectorLoading(
      true,
      rootmenu,
      currmenu,
      this.selectedmenu,
      selectedAllocations,
      servicename,
      this.state.jsonSidebar,
      selectorSize
    );
    //this.props.onServiceLoadingChange(true);
    //let payLoad: any;
    // switch (servicename) {

    //   case 'Person':
    //     payLoad = {
    //       'personid': 0,
    //       'employeeid': 0,
    //       'personinfo': 127
    //     };
    //     break;

    //   case 'Employee':
    //     payLoad = {
    //       'companyid': 100010,
    //       'employeeid': 0,
    //       'info': 2,
    //       'Redis': 0,
    //       'person': {
    //         'personInfo': 0
    //       },
    //       'contract': {
    //         'contractInfo': 31,
    //         'Redis': 0
    //       },
    //       'dossier': {
    //         'Documentinfo': 0,
    //         'account': null,
    //         'Redis': 0
    //       },
    //       'loan': {
    //         'loanid': 0,
    //         'loanInfo': 1,
    //         'Redis': 0
    //       },
    //       'membership': {
    //         'employeeid': 1,
    //         'id': 0
    //       },
    //       'arrears': {
    //         'employeeid': 0,
    //         'id': 0
    //       }
    //     };
    //     break;

    // }

    Common.ApiCallAsync(
      'POST',
      `${servicename}/Get`,
      payLoad,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        return resopnse.data;
      })
      .then((resopnse: any) => {
        //this.setState({ serviceName: servicename, jsonSelector: resopnse });
        //this.setState({ jsonSelector: resopnse });
        //this.props.onServiceNameChange(servicename);
        this.props.sidebarClick(resopnse);
      })
      .catch((error: any) => {
        console.error(error);
        //this.setState({ isSelectorLoading: false });
        this.props.selectorLoading(
          false,
          rootmenu,
          currmenu,
          this.selectedmenu,
          selectedAllocations,
          servicename,
          this.state.jsonSidebar,
          selectorSize
        );
      })
      .finally(() => {
        //Layout.HideSpinner();
        //this.setState({ isSelectorLoading: false });
        this.props.selectorLoading(
          false,
          rootmenu,
          currmenu,
          this.selectedmenu,
          selectedAllocations,
          servicename,
          this.state.jsonSidebar,
          selectorSize
        );
        //this.props.onServiceLoadingChange(false);
      });

    return null;
  }

  private handleSelectorRowSelected(data: any) {
    this.setState({ jsonDataViewer: data });
    //this.props.selectorRowSelected(data);
  }

  /*
     ------------------------------------------------------------------------------------------------------------------6.Adapter
   */
  // * SUHAIL - 2023-02-24 - 10
  // * SUHAIL - 2023-03-07 - 20
  private fRecursiveElement(e: any, force: boolean): Object[] {
    if (e.OnExpand != null || force) {
      var aReturnElems: Object[] = [];
      let o = e;
      if (!force) o = e.OnExpand;
      //console.log("recursive", o);
      if (typeof o === 'undefined') return [{}];

      Object.entries(o).forEach(([key, value], index) => {
        var subElems = this.fRecursiveElement(value, true);

        let temp = {
          id: ++this.idx, //key, //Math.floor(Math.random() * index * 50), //index, // probabilmente va cambiato per ogni elemento!
          text: key,
          child: subElems,
          iconCss: 'fa fa-user-circle-o',
        };
        aReturnElems.push(temp);
      });
      return aReturnElems;
    } else return [{}];
  }

  /*
   ------------------------------------------------------------------------------------------------------------------6.Adapter
 */
  // * SUHAIL - 2023-03-10 - 180
  // * SUHAIL - 2023-03-13 - 150
  // * SUHAIL - 2023-03-16 - 120
  private getTreeItemsFromData(
    treeItems: any,
    currItem: any,
    finalelems: any,
    ci: any
  ): any {
    //var aReturnElems: Object[] = [];
    // if (treeItems.rFieldId === 9030) {
    treeItems.forEach((treeItemData: any) => {
      let obj: any = {
        object: treeItemData.object,
        idx: treeItemData.idx,
        parentidx: treeItemData.parentidx,
        id: `${treeItemData.object}-${treeItemData.rFieldId}-${treeItemData.field}`,
        text:
          treeItemData.object > 10000 &&
          (treeItemData.rFieldId === this._structure.Element.Group ||
            treeItemData.rFieldId === this._structure.Element.Child ||
            treeItemData.rFieldId === this._structure.SideBarComponents.Group ||
            treeItemData.rFieldId === this._structure.Parameters.Tab)
            ? treeItemData.value
            : treeItemData.rFieldValue,
        children: [],
        iconCss: 'no-icon',
      };
      //let user: any = JSON.parse(Common.getUser() ?? '');

      if (treeItemData.field === this._structure.Field.Visual.Icon) {
        this.icons.push(obj);
      }

      let ids: any = [this._structure.Field.Identif.Name, this.user.language];
      let notids: any = [
        this._structure.SideBarActions.OnNode,
        this._structure.SideBarActions.OnLeaf,
        this._structure.Element.OrderList,
        this._structure.SideBarComponents.Key,
        this._structure.Field.Identif.Template,
      ];
      let blnSkipped: boolean = false;
      if (
        treeItemData.object != this.user.sidebar &&
        ids.includes(treeItemData.field) &&
        notids.includes(treeItemData.rFieldId) === false &&
        treeItemData.rFieldId !== this._structure.SideBarActions.OnExpand &&
        treeItemData.rFieldId !== this._structure.SideBarComponents.Group
      ) {
        if (
          finalelems.length <= 0 ||
          obj.parentidx === this.state.jsonSidebar[0].idx
        ) {
          finalelems.push(obj);
        } else {
          if (
            Common.FindJsonByIndex(
              this.state.jsonSidebar,
              treeItemData.parentidx
            ).rFieldId !== this._structure.SideBarComponents.Group
          ) {
            if (currItem.idx === obj.parentidx) {
              currItem.children.push(obj);
            }
            //else if (ci.idx === obj.parentidx) {
            else {
              //ci.children.push(obj);
              let citm: any = Common.FindJsonByIndex(finalelems, ci.idx);
              if (citm !== null) {
                citm.children.push(obj);
              } else {
                console.log('not found');
              }
            }
            // else {
            //   currItem.children.push(obj);
            // }
          } else {
            blnSkipped = true;
          }
        }
        if (
          treeItemData.children &&
          treeItemData.children.length > 0 &&
          blnSkipped === false
        ) {
          currItem = obj;
        }
      } else {
        blnSkipped = true;
      }

      if (notids.includes(treeItemData.rFieldId) === false) {
        let children;
        if (treeItemData.children && treeItemData.children.length > 0) {
          children = this.getTreeItemsFromData(
            treeItemData.children,
            currItem,
            finalelems,
            blnSkipped ? currItem : treeItemData
          );
        }
      }
    });

    return finalelems;
    //-------------------------------
    // let obj: any = {
    //   id: treeItems.object,
    //   text: treeItems.value,
    //   child: [],
    //   iconCss: '',
    // }

    // let children;
    // if (treeItems.children && treeItems.children.length > 0) {
    //   children = this.getTreeItemsFromData(treeItems.children);
    // }

    //return obj;
    //-------------------------------
  }

  /*
   ------------------------------------------------------------------------------------------------------------------6.Adapter
  */
  // * SUHAIL - 2023-03-13 - 60
  // * SUHAIL - 2023-03-29 - 20
  // private FindJsonByIndex(jsons: any, idx: number): any {

  //   let js: any = jsons.find((x: any) => x.idx === idx);
  //   if (js !== undefined) {
  //     return js;
  //   }

  //   if (jsons.length === 0) {
  //     return null;
  //   }

  //   let jsnfinal: any = null;
  //   jsons.forEach((jsn: any) => {
  //     let js1: any = this.FindJsonByIndex(jsn.children, idx);
  //     if (js1 !== null) {
  //       jsnfinal = js1;
  //     }
  //   })
  //   return jsnfinal;
  // }

  /*
   ------------------------------------------------------------------------------------------------------------------6.Adapter
  */
  // * SUHAIL - 2023-03-13 - 60
  // * SUHAIL - 2023-03-16 - 60
  // * SUHAIL - 2023-03-29 - 20
  // private FindJsonByObjectId(jsons: any, id: number): any {

  //   let js: any = jsons.filter((x: any) => x.object === id);
  //   // if (js !== undefined) {
  //   //   return js;
  //   // }
  //   if (js.length > 0 && (this.user == null || js.length == 1)) {
  //     return js[0];
  //   }
  //   else if (js.length > 0 && (this.user != null && js.length > 1)) {
  //     let js2: any = js.filter((x: any) => x.field === this.user.language);
  //     if (js2.length > 0) {
  //       return js2[0];
  //     }

  //     js2 = js.filter((x: any) => x.field === 101);
  //     if (js2.length > 0) {
  //       return js2[0];
  //     }

  //   }

  //   if (jsons.length === 0) {
  //     return null;
  //   }

  //   let jsnfinal: any = null;
  //   jsons.forEach((jsn: any) => {
  //     let js1: any = this.FindJsonByObjectId(jsn.children, id);
  //     if (js1 !== null) {
  //       jsnfinal = js1;
  //     }
  //   })
  //   return jsnfinal;
  // }
  /*
   ------------------------------------------------------------------------------------------------------------------6.Adapter
  */
  // * SUHAIL - 2023-03-16 - 120
  // * SUHAIL - 2023-03-29 - 20
  // private FindJsonByRelationFieldId(jsons: any, id: number): any {

  //   let js: any = jsons.find((x: any) => x.rFieldId === id);
  //   if (js !== undefined) {
  //     return js;
  //   }

  //   if (jsons.length === 0) {
  //     return null;
  //   }

  //   let jsnfinal: any = null;
  //   jsons.forEach((jsn: any) => {
  //     let js1: any = this.FindJsonByRelationFieldId(jsn.children, id);
  //     if (js1 !== null) {
  //       jsnfinal = js1;
  //     }
  //   })
  //   return jsnfinal;
  // }

  /*
      --------------------------------------------------------------------------------------------------------------------HTML
    */
  // * SUHAIL - 2023-02-24 - 60
  // * SUHAIL - 2023-03-06 - 160
  // * SUHAIL - 2023-03-07 - 360
  // * SUHAIL - 2023-03-13 - 60
  // * SUHAIL - 2023-03-14 - 30
  // * SUHAIL - 2023-03-16 - 60
  // * SUHAIL - 2023-03-28 - 120
  // * SUHAIL - 2023-03-29 - 120
  // * SUHAIL - 2023-04-03 - 50
  // * SUHAIL - 2023-04-04 - 20
  // * SUHAIL - 2023-04-05 - 30
  // * SUHAIL - 2023-04-06 - 30
  // * SUHAIL - 2023-04-11 - 30
  // * SUHAIL - 2023-05-09 - 60
  // * SANDEEP - 23-08-10 - 20
  render() {
    let cmenuInstance: ContextMenuComponent;
    let aElems: any = [];
    this.idx = 0;
    if (this.state.jsonSidebar.length > 0) {
      let treeitem: any = this.getTreeItemsFromData(
        this.state.jsonSidebar,
        null,
        aElems,
        null
      );
      aElems = treeitem;

      if (this.icons.length > 0) {
        this.icons.forEach((jsn: any) => {
          let js: any = Common.FindJsonByObjectId(
            aElems,
            jsn.object,
            this.user
          );
          if (js !== null) {
            js.iconCss = jsn.text;
          }
        });
      }
    }

    // Object.entries(this.state.jsonSidebar).forEach(([key, value], index) => {
    //   //console.log(key, value, index);
    //   let rtemp = this.fRecursiveElement(value, false)
    //   var temp: object
    //   //console.log(key, rtemp.length, rtemp);
    //   if (Object.keys(rtemp[0]).length != 0)
    //     temp = {
    //       id: ++this.idx,//key, //index,
    //       text: key,
    //       child: rtemp,
    //       iconCss: 'fa fa-user'
    //     }
    //   else
    //     temp = {
    //       id: ++this.idx,//key, //index,
    //       text: key,
    //       child: [],
    //       iconCss: 'fa fa-address-book'
    //     }

    //   aElems.push(temp)
    //   //console.log(this.fRecursiveElement(value, false));
    // })
    //aElems = [{ id: '0', text: 'ROOT', child: aElems, expanded: true }]
    aElems = aElems;
    let oElems = { localData: aElems };

    const fields: object = {
      dataSource: oElems.localData, // data,
      id: 'id',
      text: 'text',
      child: 'children',
      iconCss: 'iconCss',
      imageUrl: 'image',
    };

    let sidebarobj: SidebarComponent;
    let treeviewobj: TreeViewComponent;
    let width: string = '100%';
    let target: string = '.main-content';
    let mediaQuery: string = '(min-width: 600px)';
    let dockSize: string = '44px';

    const onResize = (e: Event) => {
      if (sidebarobj !== null) {
        var x = window.matchMedia('(max-width: 800px)');
        if (x.matches) {
          sidebarobj.type = 'Over';
        } else {
          sidebarobj.type = 'Auto';
        }
      }
    };
    window.onresize = onResize;

    function onCreate(): void {
      if (sidebarobj !== null) {
        sidebarobj.element.style.visibility = '';
        sidebarobj.element
          .closest('#sidebarroot')
          ?.parentElement?.classList.add('w100');
        sidebarobj.element
          .closest('#sidebarroot')
          ?.parentElement?.classList.add('h-100');
        sidebarobj.element
          .closest('#sidebarroot')
          ?.parentElement?.parentElement?.classList.add('h-100');

        let selectedNodes: string[] = [];
        if (oElems.localData.length > 0) {
          if (oElems.localData[0].iconCss.includes('chart-line')) {
            selectedNodes = [oElems.localData[0].id];
            treeviewobj.selectedNodes = selectedNodes;
          }
        }
      }
    }
    function onClose(): void {
      if (sidebarobj !== null) {
        treeviewobj.collapseAll();
      }
    }
    function toggleClick(): void {
      if (sidebarobj.isOpen) {
        sidebarobj.hide();
        //treeviewobj.collapseAll();
        sidebarobj.element.classList.remove('w100');
        sidebarobj.element
          .closest('.e-resizable')
          ?.classList.add('flex-basis-3');
        sidebarobj.element.classList.remove('overflow-auto');
        sidebarobj.element.classList.add('overflow-hidden');
        sidebarobj.element.classList.add('sidebar-width');
        sidebarobj.element.classList.remove('sidebar-zindex');
        document
          .getElementById('layout-content-main')
          ?.classList.add('layout-content-main');
        sidebarobj.element
          .closest('.e-resizable')
          ?.classList.remove('layout-body-left-minwidth');
      } else {
        sidebarobj.show();
        //treeviewobj.expandAll();
        sidebarobj.element.classList.add('w100');
        sidebarobj.element.classList.remove('sidebar-width');
        sidebarobj.element.classList.add('sidebar-zindex');
        sidebarobj.element
          .closest('.e-resizable')
          ?.classList.remove('flex-basis-3');
        sidebarobj.element.classList.remove('overflow-hidden');
        sidebarobj.element.classList.add('overflow-auto');
        document
          .getElementById('layout-content-main')
          ?.classList.remove('layout-content-main');
        sidebarobj.element
          .closest('.e-resizable')
          ?.classList.add('layout-body-left-minwidth');
      }
    }
    const menuClick = (e: any) => {
      if (e.target.classList.contains('e-fullrow')) {
        //let elmnt: any = this.FindJsonByObjectId(this.state.jsonSidebar[0]['children'], Number(e.target.parentElement.attributes.getNamedItem('data-uid').value.split('-')[0]));
        let rootmenu: any;
        if (
          e.target.parentElement.attributes.getNamedItem('aria-level').value ===
          '1'
        ) {
          rootmenu = Common.FindJsonByObjectId(
            this.state.jsonSidebar[0]['children'],
            Number(
              e.target.parentElement.attributes
                .getNamedItem('data-uid')
                .value.split('-')[0]
            ),
            this.user
          );

          // if (rootmenu !== null) {
          //   let elmnt: any = this.FindJsonByRelationFieldId(rootmenu['children'], this._structure.Parameters.Collection);//9110 = Collection
          //   if (elmnt !== null) {
          //     this.GetSelector(elmnt.value);
          //   }
          // }
        } else {
          rootmenu = Common.FindJsonByObjectId(
            this.state.jsonSidebar[0]['children'],
            Number(
              e.target
                .closest('.e-level-1')
                .attributes.getNamedItem('data-uid')
                .value.split('-')[0]
            ),
            this.user
          );

          // if ((rootmenu !== null && this.state.jsonSidebar.length <= 0) || this.state.rootmenu !== rootmenu) {
          //   let elmnt: any = this.FindJsonByRelationFieldId(rootmenu['children'], this._structure.Parameters.Collection);//9110 = Collection
          //   if (elmnt !== null) {
          //     this.GetSelector(elmnt.value);
          //   }
          // }
        }

        let currmenu = Common.FindJsonByObjectId(
          this.state.jsonSidebar[0]['children'],
          Number(
            e.target.parentElement.attributes
              .getNamedItem('data-uid')
              .value.split('-')[0]
          ),
          this.user,
          0,
          Number(
            e.target.parentElement.attributes
              .getNamedItem('data-uid')
              .value.split('-')[1]
          )
        );
        let selectedAllocations: JsonTree[] = [];
        if (rootmenu !== currmenu) {
          if (currmenu.rFieldId === this._structure.Element.Child) {
            selectedAllocations.push(currmenu);
          } else {
            let allocations: any = Common.GetJsonByRelationFieldId(
              currmenu.children,
              this._structure.Element.Child
            );
            if (allocations === null || allocations.length <= 0) {
              selectedAllocations.push({
                object: -999,
                field: -1,
                fieldValue: '',
                value: '',
                rFieldId: -1,
                rFieldValue: '',
                idx: 0,
                parentidx: 0,
              });
            } else {
              selectedAllocations = allocations;
            }
          }
        }

        if (this.state.rootmenu !== rootmenu) {
          this.onnodemenu = Common.FindJsonByRelationFieldId(
            rootmenu.children,
            this._structure.SideBarActions.OnNode
          );
          this.onleafmenu = Common.FindJsonByRelationFieldId(
            rootmenu.children,
            this._structure.SideBarActions.OnLeaf
          );
          this.onexpandmenu = Common.FindJsonByRelationFieldId(
            rootmenu.children,
            this._structure.SideBarActions.OnExpand
          );
          this.selectedmenu =
            currmenu.rFieldId === this._structure.Element.Child
              ? this.onleafmenu
              : this.onnodemenu;

          if (this.selectedmenu === null) {
            this.selectedmenu = rootmenu;
          }

          let isShowSelector: boolean = false;
          let objSelectorid: any = Common.FindJsonByRelationFieldId(
            this.selectedmenu['children'],
            this._structure.Panels.Selector
          );
          if (objSelectorid !== null) {
            isShowSelector = true;

            let selectorSize: number = 20;
            let sizeElemntId: any = Common.FindJsonByRelationFieldId(
              this.selectedmenu.children,
              this._structure.Parameters.Properties
            ); //9094 = Properties
            if (sizeElemntId !== null) {
              let sizeElemnt: any = Common.FindJsonByObjectId(
                this.selectedmenu.children,
                sizeElemntId.object,
                this.user,
                this._structure.Properties.Width
              );
              if (sizeElemnt !== null) {
                selectorSize = sizeElemnt.value;
              }
            }

            let elmnt: any = Common.FindJsonByRelationFieldId(
              this.selectedmenu['children'],
              this._structure.Parameters.Collection
            ); //699 = Collection
            if (elmnt !== null) {
              let srvcname: any = Common.FindJsonByObjectId(
                this.fulljson,
                Number(elmnt.value),
                this.user
              );
              let payLoad: any = Common.FindJsonByFieldId(
                this.selectedmenu['children'],
                this._structure.Field.FileType.Json
              ); //700 = Payload
              if (payLoad !== null) {
                payLoad = JSON.parse(payLoad.value);
              } else {
                payLoad = '';
              }
              //this.GetSelector(elmnt.value, payLoad, rootmenu, currmenu, selectedAllocations, selectorSize);
              this.GetSelector(
                srvcname.value,
                payLoad,
                rootmenu,
                currmenu,
                selectedAllocations,
                selectorSize
              );
            }
          } else {
            let elmnt: any = Common.FindJsonByRelationFieldId(
              this.selectedmenu['children'],
              this._structure.Parameters.Components
            );
            if (elmnt !== null) {
              // let srvcname: any = Common.FindJsonByObjectId(
              // 	this.fulljson,
              // 	Number(elmnt.value),
              // 	this.user
              // );
              // let payLoad: any = Common.FindJsonByFieldId(
              // 	this.selectedmenu['children'],
              // 	this._structure.Field.FileType.Json
              // );
              // if (payLoad !== null) {
              // 	payLoad = JSON.parse(payLoad.value);
              // } else {
              // 	payLoad = '';
              // }

              this.props.selectorLoading(
                false,
                rootmenu,
                currmenu,
                this.selectedmenu,
                selectedAllocations,
                null,
                this.state.jsonSidebar,
                null,
                true
              );
              //this.GetSelector(elmnt.value, payLoad, rootmenu, currmenu, selectedAllocations, selectorSize);
            } else {
              this.props.selectorLoading(
                false,
                rootmenu,
                currmenu,
                this.selectedmenu,
                selectedAllocations,
                null,
                this.state.jsonSidebar,
                null,
                false,
                true
              );
            }
          }
        }

        //if (rootmenu.children.length <= 0) {
        if (
          rootmenu.children.filter(
            (x: any) =>
              x.rFieldId === this._structure.SideBarActions.OnNode ||
              x.rFieldId === this._structure.SideBarActions.OnLeaf ||
              x.rFieldId === this._structure.SideBarActions.OnExpand
          ).length <= 0
        ) {
          this.setState({
            rootmenu: rootmenu,
            currmenu: currmenu,
            selectedAllocations: selectedAllocations,
          });
          this.props.noSelector();
          return;
        }
        if (
          this.state.rootmenu === rootmenu &&
          this.state.currmenu !== currmenu
        ) {
          this.props.selectorLoading(
            false,
            rootmenu,
            currmenu,
            this.selectedmenu,
            selectedAllocations,
            null,
            this.state.jsonSidebar,
            null
          );
        }

        if (
          this.state.rootmenu !== rootmenu ||
          this.state.currmenu !== currmenu
        ) {
          this.setState({
            rootmenu: rootmenu,
            currmenu: currmenu,
            selectedAllocations: selectedAllocations,
          });
          // this.props.sidebarClick(rootmenu, currmenu, this.selectedmenu, this.state.jsonSelector, selectedAllocations, this.state.serviceName);
        }
        // if (rootmenu.children.length > 0) {
        //   let colset: any = this.FindJsonByRelationFieldId(rootmenu.children, this._structure.Parameters.ColumnsSet);//9180 = Column Set
        //   if (colset !== null) {
        //     this.setState({ columnset: colset });
        //   }
        //   else {
        //     this.setState({ columnset: null });
        //   }
        // }
      }
    };

    const nodeselected = (e: any) => {
      let target: any = e.node;
      let rootmenu: any;
      if (target.ariaLevel === '1') {
        rootmenu = Common.FindJsonByObjectId(
          this.state.jsonSidebar[0]['children'],
          Number(target.dataset.uid.split('-')[0]),
          this.user
        );
      } else {
        rootmenu = Common.FindJsonByObjectId(
          this.state.jsonSidebar[0]['children'],
          Number(
            target
              .closest('.e-level-1')
              .attributes.getNamedItem('data-uid')
              .value.split('-')[0]
          ),
          this.user
        );
      }

      let currmenu = Common.FindJsonByObjectId(
        this.state.jsonSidebar[0]['children'],
        Number(target.dataset.uid.split('-')[0]),
        this.user,
        0,
        Number(target.dataset.uid.split('-')[1])
      );
      let selectedAllocations: JsonTree[] = [];
      if (rootmenu !== currmenu) {
        if (currmenu.rFieldId === this._structure.Element.Child) {
          selectedAllocations.push(currmenu);
        } else {
          let allocations: any = Common.GetJsonByRelationFieldId(
            currmenu.children,
            this._structure.Element.Child
          );
          if (allocations === null || allocations.length <= 0) {
            selectedAllocations.push({
              object: -999,
              field: -1,
              fieldValue: '',
              value: '',
              rFieldId: -1,
              rFieldValue: '',
              idx: 0,
              parentidx: 0,
            });
          } else {
            selectedAllocations = allocations;
          }
        }
      }

      if (this.state.rootmenu !== rootmenu) {
        this.onnodemenu = Common.FindJsonByRelationFieldId(
          rootmenu.children,
          this._structure.SideBarActions.OnNode
        );
        this.onleafmenu = Common.FindJsonByRelationFieldId(
          rootmenu.children,
          this._structure.SideBarActions.OnLeaf
        );
        this.onexpandmenu = Common.FindJsonByRelationFieldId(
          rootmenu.children,
          this._structure.SideBarActions.OnExpand
        );
        this.selectedmenu =
          currmenu.rFieldId === this._structure.Element.Child
            ? this.onleafmenu
            : this.onnodemenu;

        if (this.selectedmenu === null) {
          this.selectedmenu = rootmenu;
        }

        let isShowSelector: boolean = false;
        let objSelectorid: any = Common.FindJsonByRelationFieldId(
          this.selectedmenu['children'],
          this._structure.Panels.Selector
        );
        if (objSelectorid !== null) {
          isShowSelector = true;

          let selectorSize: number = 20;
          let sizeElemntId: any = Common.FindJsonByRelationFieldId(
            this.selectedmenu.children,
            this._structure.Parameters.Properties
          ); //9094 = Properties
          if (sizeElemntId !== null) {
            let sizeElemnt: any = Common.FindJsonByObjectId(
              this.selectedmenu.children,
              sizeElemntId.object,
              this.user,
              this._structure.Properties.Width
            );
            if (sizeElemnt !== null) {
              selectorSize = sizeElemnt.value;
            }
          }

          let elmnt: any = Common.FindJsonByRelationFieldId(
            this.selectedmenu['children'],
            this._structure.Parameters.Collection
          ); //699 = Collection
          if (elmnt !== null) {
            let srvcname: any = Common.FindJsonByObjectId(
              this.fulljson,
              Number(elmnt.value),
              this.user
            );
            let payLoad: any = Common.FindJsonByFieldId(
              this.selectedmenu['children'],
              this._structure.Field.FileType.Json
            ); //700 = Payload
            if (payLoad !== null) {
              payLoad = JSON.parse(payLoad.value);
            } else {
              payLoad = '';
            }
            //this.GetSelector(elmnt.value, payLoad, rootmenu, currmenu, selectedAllocations, selectorSize);
            this.GetSelector(
              srvcname.value,
              payLoad,
              rootmenu,
              currmenu,
              selectedAllocations,
              selectorSize
            );
          }
        } else {
          let elmnt: any = Common.FindJsonByRelationFieldId(
            this.selectedmenu['children'],
            this._structure.Parameters.Components
          );
          if (elmnt !== null) {
            this.props.selectorLoading(
              false,
              rootmenu,
              currmenu,
              this.selectedmenu,
              selectedAllocations,
              null,
              this.state.jsonSidebar,
              null,
              true
            );
            //this.GetSelector(elmnt.value, payLoad, rootmenu, currmenu, selectedAllocations, selectorSize);
          } else {
            this.props.selectorLoading(
              false,
              rootmenu,
              currmenu,
              this.selectedmenu,
              selectedAllocations,
              null,
              this.state.jsonSidebar,
              null,
              false,
              true
            );
          }
        }
      }

      //if (rootmenu.children.length <= 0) {
      if (
        rootmenu.children.filter(
          (x: any) =>
            x.rFieldId === this._structure.SideBarActions.OnNode ||
            x.rFieldId === this._structure.SideBarActions.OnLeaf ||
            x.rFieldId === this._structure.SideBarActions.OnExpand
        ).length <= 0
      ) {
        this.setState({
          rootmenu: rootmenu,
          currmenu: currmenu,
          selectedAllocations: selectedAllocations,
        });
        this.props.noSelector();
        return;
      }
      if (
        this.state.rootmenu === rootmenu &&
        this.state.currmenu !== currmenu
      ) {
        this.props.selectorLoading(
          false,
          rootmenu,
          currmenu,
          this.selectedmenu,
          selectedAllocations,
          null,
          this.state.jsonSidebar,
          null
        );
      }

      if (
        this.state.rootmenu !== rootmenu ||
        this.state.currmenu !== currmenu
      ) {
        this.setState({
          rootmenu: rootmenu,
          currmenu: currmenu,
          selectedAllocations: selectedAllocations,
        });
        // this.props.sidebarClick(rootmenu, currmenu, this.selectedmenu, this.state.jsonSelector, selectedAllocations, this.state.serviceName);
      }
    };

    // const Sidebar = () => {

    //   return (
    //     this.state.isLoading ?
    //       <div>Loading.....</div>
    //       :
    //       <div id='sidebarroot' style={{ width: '100%' }}>
    //         <div className="control-section">
    //           <div id="wrapper">
    //             <title>Essential JS 2 for React - Sidebar {'>'} Sidebar with ListView </title>
    //             <div className="">
    //               <div className='main-header' id='header-section'>
    //                 <ul className='header-list'>
    //                   <li className='float-left header-style fa fa-bars' id='hamburger' onClick={toggleClick} ref={sidebar => (sidbarToggle as any) = sidebar}></li>

    //                   {/* <li className='header-style float-right support border-left'><b>Support</b></li> */}
    //                 </ul>
    //               </div>
    //               <SidebarComponent id="sidebar-treeview" ref={Sidebar => sidebarobj = Sidebar as SidebarComponent} width={width} target={target} mediaQuery={mediaQuery} className="w100" style={{ visibility: "hidden", height: '100%', width: '100%!important' }} created={onCreate} close={onClose} dockSize={dockSize} enableDock={true}>
    //                 <div className='main-menu'>
    //                   <div>
    //                     {/* <TreeViewComponent id='main-treeview' ref={Treeview => treeviewobj = Treeview as TreeViewComponent} fields={fields} expandOn='Click' /> */}
    //                     <TreeViewComponent id='main-treeview' ref={Treeview => treeviewobj = Treeview as TreeViewComponent} fields={fields} onClick={menuClick} enablePersistence={true} />
    //                     {/* {<LayoutBodyLeft />} */}
    //                   </div>
    //                 </div>
    //               </SidebarComponent>

    //             </div>
    //             <div className="col-lg-12 col-sm-12 col-md-12" style={{ height: 'calc(100vh - 158px)', overflow: 'auto' }}>
    //               <div className="main-content" id="main-text" style={{ display: 'list-item', height: '100%' }}>
    //                 <div className='sidebar-content d-none'>
    //                   <h2 className='sidebar-heading'></h2>
    //                   <p className='paragraph-content'> </p>

    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>

    //       </div>
    //   )
    // }

    let sidbarToggle: any = null;

    var cntxitems: ContextMenuItemModel[] = [];
    var _result: any;
    parser.parseString(
      sidebar_template_contextmenu,
      function (err: any, result: any) {
        _result = JSON.parse(JSON.stringify(result));
      }
    );
    cntxitems = Common.GetContextMenuFromXML(_result.ContextMenu.Item);

    var _resultGroup: any;
    parser.parseString(
      newmenu_groupaccount_contextmenu,
      function (err: any, result: any) {
        _resultGroup = JSON.parse(JSON.stringify(result));
      }
    );
    var cntxgroup: ContextMenuItemModel[] = Common.GetContextMenuFromXML(
      _resultGroup.ContextMenu.Item
    );
    const beforeopen = (e: BeforeOpenCloseMenuEventArgs) => {
      let cancel: boolean = true;

      if (e.left! > 0 && e.event.target) {
        let he: HTMLElement = e.event.target as HTMLElement;

        let currmenu = Common.FindJsonByObjectId(
          this.state.jsonSidebar[0]['children'],
          Number(
            he!
              ?.closest('.e-level-1')!
              .attributes.getNamedItem('data-uid')!
              .value.split('-')[0]
          ),
          this.user,
          0,
          Number(
            he!
              .closest('.e-level-1')!
              .attributes.getNamedItem('data-uid')!
              .value.split('-')[1]
          )
        );

        let selectedmenu = Common.FindJsonByObjectId(
          this.state.jsonSidebar[0]['children'],
          Number(
            he
              .parentElement!.attributes!.getNamedItem('data-uid')!
              .value.split('-')[0]
          ),
          this.user,
          0,
          Number(
            he
              .parentElement!.attributes!.getNamedItem('data-uid')!
              .value.split('-')[1]
          )
        );

        let elmnt: any = Common.FindJsonByRelationFieldId(
          currmenu['children'],
          this._structure.Parameters.Collection
        );

        let isChildMenu: boolean = false;
        if (
          elmnt &&
          Number(elmnt.value) === this._structure.Structure.Correspondence
        ) {
          if (selectedmenu.rFieldId === this._structure.Element.Child) {
            cancel = false;
            isChildMenu = true;
          } else if (currmenu.object === selectedmenu.object) {
            cancel = true;
          } else {
            cancel = false;
          }
        }
        e.cancel = cancel;
        if (cancel) return;

        let s: string[] = cmenuInstance.items.map((x: any) => {
          return x.text;
        });
        if (s.length > 1) {
          s = s.slice(1);
          cmenuInstance.removeItems(s);
        }
        cmenuInstance.items[0].text = 'tempitem1';
        if (isChildMenu) {
          cmenuInstance.insertBefore(cntxitems as MenuItemModel[], 'tempitem1');
          cmenuInstance.removeItems(['tempitem1']);
        } else {
          cmenuInstance.insertBefore(cntxgroup as MenuItemModel[], 'tempitem1');
          cmenuInstance.removeItems(['tempitem1']);
        }

        let selectednode = (this.onnodemenu = Common.FindJsonByRelationFieldId(
          currmenu['children'],
          this._structure.SideBarActions.OnExpand
        ));

        let nameformat: any = Common.FindJsonByObjectId(
          selectednode['children'],
          Number(
            he
              .parentElement!.attributes!.getNamedItem('data-uid')!
              .value.split('-')[0]
          ),
          this.user,
          this._structure.Field.Desc.Format
        );

        if (isChildMenu) {
          let flter: any = selectedmenu.children.filter(
            (x: any) => x.field === this._structure.Field.FileType.Word
          );

          if (flter.length > 0) {
            cmenuInstance.items[0].items![0].text = 'Edit';
            // let f = cmenuInstance.items.filter((x: any) =>
            //   x.items.some((y: any) => y.id === '101')
            // );
            // if (f.length > 0) {
            //   f[0].items![0].text = 'Edit';
            // }
          } else {
            cmenuInstance.items[0].items![0].text = 'Create';
          }
          if (nameformat) {
            cmenuInstance.items[1].items![0].text = 'Edit';
            this.setState({ selectednameformat: nameformat });
          } else {
            cmenuInstance.items[1].items![0].text = 'Create';
            let obj: JsonTree = {
              object: Number(
                he
                  .parentElement!.attributes!.getNamedItem('data-uid')!
                  .value.split('-')[0]
              ),
              field: this._structure.Field.Desc.Format,
              value: '',
              fieldValue: 'Format',
              rFieldId: -1,
              rFieldValue: '',
              idx: 0,
              parentidx: 0,
            };
            this.setState({ selectednameformat: obj });
          }

          this.setState({
            accountid: flter.length > 0 ? flter[0].object : selectedmenu.object,
            accountname: selectedmenu.value,
            jsonTemplate: selectedmenu,
          });
        } else {
          this.setState({
            accountid: selectedmenu.object,
            accountname: selectedmenu.value,
            jsonTemplate: selectedmenu,
            selectednameformat: null,
          });
        }
      }
    };

    const cntxmenuclick = (e: any) => {
      if (!e.element.classList.contains('e-menu-caret-icon')) {
        if (
          // e.item.text.toLowerCase() === 'create' ||
          // e.item.text.toLowerCase() === 'edit'
          e.item.id === '101'
        ) {
          this.setState({
            showdialog: true,
            dlgcontent: 'word',
            selectednameformat: null,
          });
        } else if (e.item.id === '201') {
          this.setState({ showdialog: true, dlgcontent: 'nameformat' });
        } else if (e.item.id === '__101--') {
          this.setState({
            showdialog: true,
            dlgcontent: 'group',
          });
        } else if (e.item.id === '__102--') {
          this.setState({
            showdialog: true,
            dlgcontent: 'account',
          });
        }
      }
    };

    const dialogContent = () => {
      if (this.state.dlgcontent === 'word') {
        return (
          <WordTemplate
            id='wordtemplate-sidebar'
            accountid={this.state.accountid}
            name={this.state.accountname}
            showindialogbox={true}
            jsonTemplate={this.state.jsonTemplate}
          />
        );
      } else if (this.state.dlgcontent === 'nameformat') {
        // dialogInstance.width = '20%';
        // dialogInstance.height = '40%';

        return (
          <div>
            <div className='form-row'>
              <div className='form-group col-md-12'>
                <div className='e-float-input e-control-wrapper'>
                  <input
                    id='nameformatvalue'
                    name='nameformatvalue'
                    type='text'
                    value={
                      this.state.selectednameformat
                        ? this.state.selectednameformat['value']
                        : ''
                    }
                    spellCheck={false}
                    autoComplete='off'
                    onChange={onChange}
                    autoFocus
                  />
                  <span className='e-float-line' />
                  <label
                    className='e-float-text e-label-top'
                    htmlFor='nameformatvalue'
                  >
                    Name Format
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (
        this.state.dlgcontent === 'group' ||
        this.state.dlgcontent === 'account'
      ) {
        return (
          <div>
            <div className='form-row'>
              <div className='form-group col-md-12'>
                <div className='e-float-input e-control-wrapper'>
                  <input
                    id='groupname'
                    name='groupname'
                    type='text'
                    spellCheck={false}
                    autoComplete='off'
                    onChange={onGroupNameChange}
                    required={true}
                    autoFocus
                  />
                  <span className='e-float-line' />
                  <label
                    className='e-float-text e-label-top'
                    htmlFor='groupname'
                  >
                    Name
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      }
    };

    const onGroupNameChange = (e: any) => {
      this.setState({
        groupname: e.target.value,
      });
    };

    const saveGroup = (e: any) => {
      if (this.state.groupname.trim().length <= 0) {
        DialogUtility.alert('Please enter name.');
        e.cancel = true;
        return;
      }

      let payload: any = null;
      let parentObj: any = null;
      let parentJsonObj: any = null;
      let parentid: number = 0;
      if (
        this.state.dlgcontent === 'group' ||
        this.state.dlgcontent === 'account'
      ) {
        parentid = Number(
          cmenuInstance['currentTarget'].parentElement.attributes[
            'data-uid'
          ].value.split('-')[0]
        );

        payload = {
          name: this.state.groupname,
          ischild: this.state.dlgcontent === 'group' ? false : true,
          parentid: parentid,
        };

        parentObj = Common.FindJsonByObjectId(
          treeviewobj.fields.dataSource,
          parentid,
          this.user
        );

        parentJsonObj = Common.FindJsonByObjectId(
          this.state.jsonSidebar[0].children,
          parentid,
          this.user
        );
      }

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/General/Group/Set`,
        payload,
        Common.getToken() || '',
        this.user
      )
        .then((response: any) => {
          dialogInstance.hide();
          let newItem: any = {
            object: Number(response.data.id),
            idx: this.state.nextidx,
            parentidx: parentObj.idx,
            id: `${response.data.id}-${
              payload.isChild
                ? this._structure.Element.Child
                : this._structure.Element.Group
            }-${this.user.language}`,
            text: payload.name,
            iconCss: 'no-icon',
            children: [],
          };
          //parentObj.children.push(newItem);

          newItem = {
            object: Number(response.data.id),
            field: this.user.language,
            fieldValue: '',
            value: payload.name,
            rFieldId:
              this.state.dlgcontent === 'group'
                ? this._structure.Element.Group
                : this._structure.Element.Child,
            idx: this.state.nextidx,
            parentidx: parentObj.idx,
            rFieldValue: '',
            children: [],
          };
          let arr: string[] = treeviewobj.expandedNodes;
          parentJsonObj.children.push(newItem);

          setTimeout(
            (tobj: any) => {
              tobj.expandAll(arr);
            },
            500,
            treeviewobj
          );

          this.setState({ nextidx: Number(this.state.nextidx) + 1 });

          return response.data;
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
      return undefined;
    };

    const onChange = (e: any) => {
      let nmformat: any = this.state.selectednameformat;
      this.setState({
        selectednameformat: { ...nmformat, value: e.target.value },
      });
    };

    const saveNameFormat = (e: any) => {
      if (this.state.selectednameformat) {
        if (String(this.state.selectednameformat['value']).trim().length <= 0) {
          DialogUtility.alert('Please enter name format.');
          e.cancel = true;
          return;
        }

        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Save`,
          [
            {
              object: Number(this.state.selectednameformat['object']),
              field: Number(this.state.selectednameformat['field']),
              value: this.state.selectednameformat['value'],
            },
          ],
          Common.getToken() || '',
          this.user
        )
          .then((response: any) => {
            dialogInstance.hide();
            let nmelemnt: any = Common.FindJsonByObjectId(
              this.state.jsonSidebar[0]['children'],
              Number(this.state.selectednameformat!['object']),
              this.user
            );
            if (nmelemnt) {
              let nmelemnt1: any = Common.FindJsonByIndex(
                this.state.jsonSidebar[0]['children'],
                nmelemnt.parentidx
              );
              if (nmelemnt1) {
                let fltr: any = nmelemnt1.children.filter(
                  (x: any) =>
                    x.object ===
                      Number(this.state.selectednameformat!['object']) &&
                    x.field === this._structure.Field.Desc.Format
                );

                if (fltr.length > 0) {
                  fltr[0].value = this.state.selectednameformat!['value'];
                } else {
                  nmelemnt1.children.push({
                    object: Number(this.state.selectednameformat!['object']),
                    field: this._structure.Field.Desc.Format,
                    value: this.state.selectednameformat!['value'],
                    fieldValue: 'Format',
                    rFieldId: this._structure.Element.Child,
                    rFieldValue: 'Child',
                    idx: 9999999999,
                    parentidx: nmelemnt.parentidx,
                  });
                }
              }
            }
            return response.data;
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {});
      }
    };

    const footerContent = () => {
      return (
        <>
          <button
            className='flat-button'
            type='button'
            onClick={() => dialogInstance.hide()}
          >
            Cancel
          </button>
          <button
            className='flat-button'
            type='button'
            onClick={
              this.state.dlgcontent === 'group' ||
              this.state.dlgcontent === 'account'
                ? saveGroup
                : saveNameFormat
            }
          >
            Accept
          </button>
        </>
      );
    };

    var dialogInstance: DialogComponent | any;

    return (
      // <SplitterComponent
      //   id="innerSplitter"
      //   orientation="Horizontal"
      //   height="calc(100vh - 104px)"
      //   width="100%"
      //   separatorSize={0}
      //   cssClass="border-0"
      // >
      //   <PanesDirective>
      //     <PaneDirective min="10%" size='15%' content={Sidebar.bind(this)} cssClass="layout-body-left" />
      //     {/* <PaneDirective min="10%" content={() => <SelectorPanel rootmenu={this.state.rootmenu} currmenu={this.state.currmenu} isSelectorLoading={this.state.isSelectorLoading} serviceName={this.state.serviceName} jsonSelector={this.state.jsonSelector} selectedAllocations={this.state.selectedAllocations} />} cssClass="layout-body-left" /> */}
      //     {/* <PaneDirective
      //       size="15%">
      //       content={() => this.SS.bind(this)}
      //       cssClass="layout-body-left"
      //     </PaneDirective> */}
      //     {/* <PaneDirective min="10%" content={() => <SelectorPanel isSelectorLoading={this.state.isSelectorLoading} />} /> */}

      //   </PanesDirective>
      // </SplitterComponent>

      this.state.isLoading ? (
        <>
          {Common.SetStatusBar('Loading.....')}
          {Common.ShowSpinner()}
        </>
      ) : (
        <>
          {Common.HideSpinner()}
          <div id='sidebarroot' style={{ width: '100%', height: '100%' }}>
            <div className='control-section h-100'>
              <div id='wrapper' className='h-100'>
                <title>
                  Essential JS 2 for React - Sidebar {'>'} Sidebar with ListView{' '}
                </title>
                <div className=''>
                  <div className='main-header' id='header-section'>
                    <ul className='header-list'>
                      <li
                        className='float-left header-style fa fa-bars'
                        id='hamburger'
                        onClick={toggleClick}
                        ref={(sidebar) => ((sidbarToggle as any) = sidebar)}
                      ></li>

                      {/* <li className='header-style float-right support border-left'><b>Support</b></li> */}
                    </ul>
                  </div>
                  <SidebarComponent
                    id='sidebar-treeview'
                    ref={(Sidebar) =>
                      (sidebarobj = Sidebar as SidebarComponent)
                    }
                    width={width}
                    target={target}
                    mediaQuery={mediaQuery}
                    className='w100'
                    style={{
                      visibility: 'hidden',
                      height: '100%',
                      width: '100%!important',
                    }}
                    created={onCreate}
                    close={onClose}
                    dockSize={dockSize}
                    enableDock={true}
                    enableGestures={false}
                    enablePersistence={true}
                  >
                    <div className='main-menu'>
                      <div>
                        {/* <TreeViewComponent id='main-treeview' ref={Treeview => treeviewobj = Treeview as TreeViewComponent} fields={fields} expandOn='Click' /> */}
                        <TreeViewComponent
                          id='main-treeview'
                          ref={(Treeview) =>
                            (treeviewobj = Treeview as TreeViewComponent)
                          }
                          fields={fields}
                          //onClick={menuClick}
                          nodeSelected={nodeselected}
                          enablePersistence={true}
                        />
                        {/* {<LayoutBodyLeft />} */}
                        <ContextMenuComponent
                          id='contentmenutree'
                          target='#main-treeview'
                          items={cntxitems}
                          beforeOpen={beforeopen}
                          onClick={cntxmenuclick}
                          select={cntxmenuclick}
                          ref={(cmenu) =>
                            (cmenuInstance = cmenu as ContextMenuComponent)
                          }
                        />
                      </div>
                    </div>
                  </SidebarComponent>
                </div>
                <div
                  className='col-lg-12 col-sm-12 col-md-12'
                  style={{ height: 'calc(100% - 35px)', overflow: 'auto' }}
                >
                  <div
                    className='main-content'
                    id='main-text'
                    style={{ display: 'list-item', height: '100%' }}
                  >
                    <div className='sidebar-content d-none'>
                      <h2 className='sidebar-heading'></h2>
                      <p className='paragraph-content'> </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.showdialog === true ? (
            <DialogComponent
              id='defaultDialog-dossier'
              showCloseIcon={true}
              visible={this.state.showdialog}
              width={
                this.state.dlgcontent === 'nameformat'
                  ? '380px'
                  : this.state.dlgcontent === 'group' ||
                    this.state.dlgcontent === 'account'
                  ? '380px'
                  : '70%'
              }
              height={
                this.state.dlgcontent === 'nameformat'
                  ? '180px'
                  : this.state.dlgcontent === 'group' ||
                    this.state.dlgcontent === 'account'
                  ? '180px'
                  : '90%'
              }
              style={{ overflow: 'auto' }}
              target={'#root'}
              ref={(dialog) => (dialogInstance = dialog)}
              close={() => this.setState({ showdialog: false })}
              content={dialogContent}
              footerTemplate={
                this.state.dlgcontent === 'nameformat' ||
                this.state.dlgcontent === 'group' ||
                this.state.dlgcontent === 'account'
                  ? footerContent
                  : null
              }
              isModal={true}
              statelessTemplates={
                this.state.selectednameformat ? [] : undefined
              }
              header={
                this.state.dlgcontent === 'nameformat'
                  ? 'Create Name Format'
                  : this.state.dlgcontent === 'group'
                  ? 'Create Group'
                  : this.state.dlgcontent === 'account'
                  ? 'Create Account'
                  : 'Create Template'
              }
            ></DialogComponent>
          ) : null}
        </>
      )
    );
  }
}

export default Sidebar;
