// * ---------------------------------------------------------------------------------------------------------------1.Aknowlege
// * Sandeep - 2023-09-25 - 430 - Worked on the document fixes and employee dialog box fixes and trying to add employee.
// * Sandeep - 2023-09-26 - 120 - Worked on the alignment fixes and some css for color.

// * ---------------------------------------------------------------------------------------------------------------2.Initialize
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DateTime } from '@syncfusion/ej2-react-charts';
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import {
  ChangeEventArgs,
  DropDownTree,
  DropDownListComponent,
  DropDownTreeComponent,
  FilteringEventArgs,
  TreeSettingsModel,
} from '@syncfusion/ej2-react-dropdowns';
import React, { Component } from 'react';
import Common from '../Util/Common';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { DialogUtility, DialogComponent } from '@syncfusion/ej2-react-popups';

interface personbiomodel {
  isAdd?: boolean;
  servicename?: string;
  name?: string;
  date?: Date;
}

interface employeebiomodel {
  isAdd?: boolean;
  servicename?: string;
  personname?: string;
  personid?: number;
  jsonperson?: any;
  showDialog?: boolean;
  appointmentdate?: DateTime;
  contractid?: number;
  // companyid?: number;
  birthdate?: DateTime;
}

interface correspondencebiomodel {
  isAdd?: boolean;
  servicename?: string;
  name?: string;
  accountid?: string;
  accountname?: string;
  correspondencedate?: Date;
  senderid?: string;
  sendername?: string;
  recipientid?: string;
  recipientname?: string;
  'correspondence.subject'?: string;
  protocol?: string;
  jsonpersoncompany?: { [key: string]: Object }[];
  menuItem?: any;
  showDialog?: boolean;
  showDialogCompany?: boolean;
  company_name?: string;
}

const {
  REACT_APP_ENDPOINT_FOXSYSTEMSERVICE,
  REACT_APP_ENDPOINT_SUBSCRIBERSERVICE,
} = process.env;

class DialogFormTemplate extends React.Component<{}, {}> {
  private user: any;
  private _structure: any;
  private _fulljson: any;
  private cMenu: ContextMenuComponent | null = null;
  private srvcname: string = '';
  private fields: object = { text: 'name', value: 'id' };
  private cfields: object = { value: 'object', text: 'value' };
  private listObj: DropDownListComponent | any;
  private listContract: DropDownListComponent | any;
  private jsonpersonobj: any;
  private intNewPersonId: any = 0;
  private emp_personname: string = '';
  private emp_personbirthdate?: DateTime;
  private drpSender: DropDownTreeComponent | any = undefined;
  private drpRecipient: DropDownTreeComponent | any = undefined;
  private contract_type: any;
  public extradialogheader = 'New Person';
  public extradialogbuttons: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        switch (this.srvcname?.split('/')!.pop()!.toLowerCase()) {
          case 'employee':
            this.setState({ showDialog: false });
            break;

          case 'correspondence':
            let datacorresp: correspondencebiomodel = this.state;
            if (datacorresp.showDialogCompany === true) {
              if (this.drpSender.value[0] === '-888') this.drpSender.clear();
              if (this.drpRecipient.value[0] === '-888')
                this.drpRecipient.clear();
            } else {
              if (this.drpSender.value[0] === '-999') this.drpSender.clear();
              if (this.drpRecipient.value[0] === '-999')
                this.drpRecipient.clear();
            }

            this.setState({ showDialog: false, showDialogCompany: false });
            break;

          default:
            break;
        }
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        //this.personsave();
        switch (this.srvcname?.split('/')!.pop()!.toLowerCase()) {
          case 'employee':
            this.personsave();
            break;

          case 'correspondence':
            let datacorresp: correspondencebiomodel = this.state;
            if (datacorresp.showDialogCompany === true) {
              this.CompanySave();
            } else {
              this.personsave();
            }
            break;

          default:
            break;
        }
      },
    },
  ];

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this._fulljson = JSON.parse(Common.getFullJson() ?? '');

    this.state = Object.assign({}, props);
    this.srvcname = props.servicename;
  }

  componentDidMount() {
    switch (this.srvcname?.split('/')!.pop()!.toLowerCase()) {
      case 'employee':
      case 'correspondence':
        this.GetPerson();
        break;

      default:
        break;
    }
  }

  public GetPerson() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/person/Get`,
      { personid: 0, redis: 2 },
      Common.getToken() || '',
      this.user
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        switch (this.srvcname?.split('/')!.pop()!.toLowerCase()) {
          case 'employee':
            // let _object = { id: -999, name: 'New' };
            // data.push(_object);
            // data.sort((a: any, b: any) => a.id - b.id);
            data.sort((a: any, b: any) => {
              return a.name > b.name ? 1 : -1;
            });
            data.splice(0, 0, { id: -999, name: 'New ...' });
            let onlyperson = data.filter((x: any) => x.employee == null);
            this.jsonpersonobj = onlyperson;
            this.setState({ jsonperson: onlyperson });
            break;

          case 'correspondence':
            this.GetCompany(data);
            break;

          default:
            break;
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetContractType() {
    let _contractstype = Common.FindJsonByObjectId(
      this._fulljson,
      this._structure.Constants.Contract,
      this.user
    );
    return _contractstype.children;
  }

  public GetCompany(persondata: any = null) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Company/Get`,
      { id: 0 },
      Common.getToken() || '',
      this.user
    )
      .then((response: any) => {
        return response.data;
      })
      .then((companydata: any) => {
        companydata
          .sort((a: any, b: any) => {
            return a.name > b.name ? 1 : -1;
          })
          .forEach((x: any) => {
            x['parentid'] = -1;
          });
        companydata.splice(0, 0, { id: -888, name: 'New ...', parentid: -1 });
        persondata
          .sort((a: any, b: any) => {
            return a.name > b.name ? 1 : -1;
          })
          .forEach((x: any) => {
            x['parentid'] = -2;
          });
        persondata.splice(0, 0, { id: -999, name: 'New ...', parentid: -2 });
        persondata.splice(1, 0, { id: -1, name: 'Company', hasChild: true });
        persondata.splice(2, 0, { id: -2, name: 'Person', hasChild: true });

        persondata = persondata.concat(
          companydata.map((x: any) => ({
            id: x.id,
            name: x.name,
            parentid: x.parentid,
          }))
        );
        this.setState({ jsonpersoncompany: persondata });
        return companydata;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public SetPerson() {
    if (
      this.emp_personname !== '' &&
      (this.emp_personbirthdate !== undefined ||
        this.emp_personbirthdate !== null)
    ) {
      let personSetDto: any = {
        name: this.emp_personname,
        date: this.emp_personbirthdate,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/person/Set`,
        personSetDto,
        Common.getToken() || '',
        this.user
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          switch (this.srvcname?.split('/')!.pop()!.toLowerCase()) {
            case 'employee':
              this.jsonpersonobj.push(data);
              this.jsonpersonobj.sort((a: any, b: any) => a.id - b.id);
              this.intNewPersonId = data.id;
              //this.jsonpersonobj = data;

              this.setState({
                jsonperson: this.jsonpersonobj,
                showDialog: false,
              });
              this.listObj.refresh();
              this.listObj.value = data.id;
              this.listObj.text = data.name;
              this.listObj.ensureVisible(data.id);

              break;

            case 'correspondence':
              let datacorresp: correspondencebiomodel = this.state;
              let pcdata: any = datacorresp.jsonpersoncompany;
              let flter: any = pcdata.filter((x: any) => x.parentid === -2);
              if (flter.length > 0) {
                pcdata.splice(3, 0, {
                  id: data.id,
                  name: data.name,
                  parentid: -2,
                });
              }
              let s1: string[] =
                this.drpSender.value.length > 0 &&
                this.drpSender.value[0] === '-999'
                  ? [data.id.toString()]
                  : this.drpSender.value;
              let s2: string[] =
                this.drpRecipient.value.length > 0 &&
                this.drpRecipient.value[0] === '-999'
                  ? [data.id.toString()]
                  : this.drpRecipient.value;
              let t1: string[] =
                this.drpSender.value.length > 0 &&
                this.drpSender.value[0] === '-999'
                  ? data.name.toString()
                  : [this.drpSender.text];
              let t2: string[] =
                this.drpRecipient.value.length > 0 &&
                this.drpRecipient.value[0] === '-999'
                  ? data.name.toString()
                  : [this.drpRecipient.text];

              this.setState({
                jsonpersoncompany: pcdata,
                showDialog: false,
              });
              this.drpSender.refresh();
              this.drpRecipient.refresh();
              this.drpSender.value = s1;
              this.drpSender.text = t1;
              this.drpRecipient.value = s2;
              this.drpRecipient.text = t2;
              this.drpSender.ensureVisible(s1[0]);
              this.drpRecipient.ensureVisible(s2[0]);
              break;

            default:
              break;
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  }

  onpersonemp_namechange = (e: any) => {
    this.emp_personname = e.target.value;
  };

  onpersonemp_datechange = (e: any) => {
    this.emp_personbirthdate = e.target.value;
  };

  onChangeCompany = (e: any) => {
    this.setState({
      [(e.target as HTMLInputElement).name]: e.target.value,
    });
  };

  public dialogContent() {
    var _content: any;
    _content = (
      <>
        <div className='row align-items-center'>
          <div className='col-3 py-2'>
            <label style={{ fontSize: '13px' }}>Name:</label>
          </div>
          <div className='col-9 py-2'>
            <input
              className='input-text-border'
              id='pname'
              name='pname'
              type='text'
              placeholder='Enter Name'
              autoComplete='off'
              onChange={this.onpersonemp_namechange.bind(this)}
              style={{ fontSize: '15px' }}
              required={true}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-3  py-2'>
            <label style={{ fontSize: '13px' }}>Birthdate:</label>
          </div>
          <div className='col-9  py-2'>
            <DatePickerComponent
              id='pdate'
              format={this.user.settings.formats.DateShort}
              //value={data.date!}
              onChange={this.onpersonemp_datechange.bind(this)}
              showClearButton={false}
              cssClass='e-hidedate-icon'
              openOnFocus={true}
              showTodayButton={false}
              //placeholder={this.user.settings.formats.DateShort}
              placeholder='Select Date'
              style={{ fontSize: '15px' }}
            />
          </div>
        </div>
      </>
    );
    return <>{_content}</>;
  }

  public onFiltering = (e: FilteringEventArgs) => {
    let query: Query = new Query();
    let predicate: Predicate = new Predicate('name', 'contains', e.text).or(
      'id',
      'lessthanorequal',
      0
    );
    // query =
    //   e.text !== '' ? query.where('name', 'contains', e.text, true) : query;
    query =
      e.text !== ''
        ? query.where(predicate, undefined, undefined, true)
        : query;
  };

  private personsave() {
    if (this.emp_personname == '') {
      DialogUtility.alert('Sorry, Person name is mandatory information.');
      return;
    }
    if (
      this.emp_personbirthdate == undefined ||
      this.emp_personbirthdate == null
    ) {
      DialogUtility.alert('Sorry, Birthdate is mandatory information.');
      return;
    }
    this.SetPerson();
  }

  private CompanySave() {
    let data: correspondencebiomodel = this.state;
    if (!data.company_name) {
      DialogUtility.alert('Please enter company name.');
      return;
    }
    this.SetCompany();
  }

  public SetCompany() {
    let data: correspondencebiomodel = this.state;
    if (data.company_name) {
      let companySetDto: any = {
        name: data.company_name,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/company/Set`,
        companySetDto,
        Common.getToken() || '',
        this.user
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          switch (this.srvcname?.split('/')!.pop()!.toLowerCase()) {
            case 'correspondence':
              let datacorresp: correspondencebiomodel = this.state;
              let pcdata: any = datacorresp.jsonpersoncompany;
              let flter: any = pcdata.filter((x: any) => x.parentid === -1);
              if (flter.length > 0) {
                pcdata.splice(1, 0, {
                  id: data.id,
                  name: data.name,
                  parentid: -1,
                });
              }
              let s1: string[] =
                this.drpSender.value.length > 0 &&
                this.drpSender.value[0] === '-888'
                  ? [data.id.toString()]
                  : this.drpSender.value;
              let s2: string[] =
                this.drpRecipient.value.length > 0 &&
                this.drpRecipient.value[0] === '-888'
                  ? [data.id.toString()]
                  : this.drpRecipient.value;
              this.setState({
                jsonpersoncompany: pcdata,
                showDialog: false,
                showDialogCompany: false,
              });
              this.drpSender.refresh();
              this.drpRecipient.refresh();
              this.drpSender.value = s1;
              this.drpRecipient.value = s2;
              this.drpSender.ensureVisible(s1[0]);
              this.drpRecipient.ensureVisible(s2[0]);
              break;

            default:
              break;
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  }

  public DropDownListChange(): void {
    if (this.listObj === null) {
      return;
    }
    if (parseInt(this.listObj.value.toString()) == -999) {
      this.setState({ showDialog: true });
    } else {
      this.setState({
        personname: this.listObj.text,
        personid: this.listObj.value,
        birthdate: this.jsonpersonobj.filter(
          (x: any) => x.id == this.listObj.value
        )[0].date,
      });
      if (document.getElementById('personname') !== undefined) {
        let _pname = document.getElementById('personname');
        (_pname as any).value = this.listObj.text;
      }
      if (document.getElementById('birthdate') !== undefined) {
        let _birthdate = document.getElementById('birthdate');
        (_birthdate as any).value = this.jsonpersonobj.filter(
          (x: any) => x.id == this.listObj.value
        )[0].date;
      }
    }
  }

  public dialogContentCompany() {
    let datacorresp: correspondencebiomodel = this.state;
    return (
      <>
        <div className='row align-items-center' style={{ padding: '7px' }}>
          <div className='col-3 py-2'>
            <label style={{ fontSize: '13px' }}>Name:</label>
          </div>
          <div className='col-9 py-2'>
            <input
              className='input-text-border'
              id='compayname'
              name='company_name'
              type='text'
              placeholder='Enter Company Name'
              value={datacorresp.company_name}
              autoComplete='off'
              onChange={this.onChangeCompany.bind(this)}
              style={{ fontSize: '15px' }}
              required={true}
              autoFocus={true}
            />
          </div>
        </div>
      </>
    );
  }

  public dialogTemplate(): JSX.Element {
    const onChange = (args: any) => {
      this.setState({
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    };
    const handleTextAccountClick = (e: any) => {
      if (this.cMenu) {
        let obj1: HTMLElement = document.getElementById(
          'account.name'
        ) as HTMLElement;

        let c_area: any = obj1.getBoundingClientRect();

        this.cMenu.open(c_area.top + c_area.height, c_area.left);
      }
    };
    const handleCntxMenuClick = (e: any) => {
      this.setState({
        accountid: e.item.properties.id,
        accountname: e.item.properties.text,
      });
    };

    const dialogTemplatePerson = (data: personbiomodel): JSX.Element => {
      return (
        <>
          <div style={{ width: '20vw', height: '100%', padding: '7px' }}>
            <div className='row align-items-center'>
              <div className='col-3 py-2'>
                <label style={{ fontSize: '13px' }}>Name:</label>
              </div>
              <div className='col-9 py-2'>
                <input
                  className='input-text-border'
                  id='name'
                  name='name'
                  type='text'
                  disabled={!data.isAdd}
                  value={data.name!}
                  onChange={onChange}
                  placeholder='Enter Name'
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  required={true}
                />
              </div>
            </div>
            <div className='row align-items-center'>
              {/* <div className='col-12' style={{ display: 'flex' }}> */}
              <div className='col-3  py-2'>
                <label style={{ fontSize: '13px' }}>Birthdate:</label>
              </div>
              <div className='col-9  py-2'>
                <DatePickerComponent
                  id='date'
                  format={this.user.settings.formats.DateShort}
                  //value={data.date!}
                  showClearButton={false}
                  cssClass='e-hidedate-icon'
                  openOnFocus={true}
                  showTodayButton={false}
                  onChange={onChange}
                  //placeholder={this.user.settings.formats.DateShort}
                  placeholder='Select Date'
                  style={{ fontSize: '15px' }}
                />
              </div>
              {/* </div> */}
            </div>
          </div>
        </>
      );
    };

    const dialogTemplateEmployee = (data: employeebiomodel): JSX.Element => {
      this.contract_type = this.GetContractType();
      const handleContractChange = (e: any) => {
        if (this.listContract === null) {
          return;
        }
        this.setState({
          contractid: this.listContract.value,
        });
      };
      return (
        <>
          <div style={{ width: '28vw', height: '100%', padding: '7px' }}>
            {/* <div className='row align-items-center'>
              <div className='col-3 py-2'>
                <label style={{ fontSize: '13px' }}>Name :</label>
              </div>
              <div className='col-9 py-2'>'Employee Code Value'</div>
            </div> */}
            <div className='row align-items-center'>
              <div className='col-3 py-2'>
                <label style={{ fontSize: '13px' }}>Person :</label>
              </div>
              <div className='col-9 py-2'>
                <input
                  id='personname'
                  name='personname'
                  type='hidden'
                  autoComplete='off'
                  onChange={onChange}
                />
                <input
                  id='birthdate'
                  name='birthdate'
                  type='hidden'
                  autoComplete='off'
                  onChange={onChange}
                />
                <DropDownListComponent
                  id='personid'
                  dataSource={this.jsonpersonobj}
                  ref={(dropdownlist) => {
                    this.listObj = dropdownlist;
                  }}
                  //filtering={this.onFiltering.bind(this)}
                  filterBarPlaceholder='Search a Person'
                  allowFiltering={true}
                  filterType='Contains'
                  fields={this.fields}
                  change={this.DropDownListChange.bind(this)}
                  placeholder='Select Person'
                  popupHeight='220px'
                  style={{ fontSize: '15px' }}
                ></DropDownListComponent>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-3 py-2'>
                <label style={{ fontSize: '13px' }}>Appointment Date :</label>
              </div>
              <div className='col-9 py-2'>
                <DatePickerComponent
                  id='appointmentdate'
                  format={this.user.settings.formats.DateShort}
                  //value={data.date!}
                  onChange={onChange}
                  showClearButton={false}
                  cssClass='e-hidedate-icon'
                  openOnFocus={true}
                  showTodayButton={false}
                  //placeholder={this.user.settings.formats.DateShort}
                  placeholder='Select Date'
                  style={{ fontSize: '15px' }}
                />
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-3 py-2'>
                <label style={{ fontSize: '13px' }}>Type Of Contract :</label>
              </div>
              <div className='col-9 py-2'>
                <DropDownListComponent
                  id='contractid'
                  dataSource={this.contract_type}
                  ref={(scope) => {
                    this.listContract = scope;
                  }}
                  filterBarPlaceholder='Search a Contract'
                  allowFiltering={true}
                  filterType='Contains'
                  fields={this.cfields}
                  change={handleContractChange}
                  placeholder='Select Contract'
                  popupHeight='220px'
                  style={{ fontSize: '15px' }}
                ></DropDownListComponent>
              </div>
            </div>
          </div>
          <div>
            {data.showDialog === true ? (
              <DialogComponent
                id='defaultDialog1'
                showCloseIcon={true}
                visible={data.showDialog}
                width={'20vw'}
                ///height={'25vh'}
                close={() => this.setState({ showDialog: false })}
                content={this.dialogContent.bind(this)}
                isModal={true}
                header={this.extradialogheader}
                buttons={this.extradialogbuttons}
                statelessTemplates={[]}
              ></DialogComponent>
            ) : null}
          </div>
        </>
      );
    };

    const dialogTemplateCorrespondence = (
      data: correspondencebiomodel
    ): JSX.Element => {
      // let drpSender: DropDownTreeComponent;
      // let drpRecipient: DropDownTreeComponent;
      if (data['correspondence.subject'] === undefined) {
        data['correspondence.subject'] = data.accountname;
      }
      let fields = {
        dataSource: data.jsonpersoncompany,
        value: 'id',
        text: 'name',
        parentValue: 'parentid',
        hasChildren: 'hasChild',
      };
      let showCheckBox = true;
      let treeSettings: TreeSettingsModel = { expandOn: 'Click' };
      let customTemplate = 'Selected items count: ${value.length} item(s) ';

      const handleDropDownTreeChange = (e: any) => {
        let itemid: string = `#${e.item.id}`;
        if (e.item.id === '') {
          if (e.itemData.parentID === '-1') {
            //itemid = '#correspondence-dropdowntree-sender';
            (document.getElementById('correspondence-sender-id') as any).value =
              e.itemData.id;
          } else if (e.itemData.parentID === '-2') {
            //itemid = '#correspondence-dropdowntree-sender';
            (
              document.getElementById('correspondence-recipient-id') as any
            ).value = e.itemData.id;
          }
          return;
        } else {
          let drp: HTMLSelectElement = document
            .querySelector(itemid)
            ?.parentElement?.closest('.e-treeview') as HTMLSelectElement;

          if (
            Number(e.itemData.id) === -999 ||
            Number(e.itemData.id) === -888
          ) {
            if (Number(e.itemData.id) === -999) {
              this.setState({ showDialog: true });
            } else if (Number(e.itemData.id) === -888) {
              this.setState({ showDialog: true, showDialogCompany: true });
            }
            if (drp.id === this.drpSender.element.id + '_tree') {
              //this.drpSender.clear();
              (
                document.getElementById('correspondence-sender-id') as any
              ).value = null;
            } else if (drp.id === this.drpRecipient.element.id + '_tree') {
              //this.drpRecipient.clear();
              (
                document.getElementById('correspondence-recipient-id') as any
              ).value = null;
            }
            return;
          } else if (
            Number(e.itemData.id) === -1 ||
            Number(e.itemData.id) === -2
          ) {
            if (Number(e.itemData.id) === -1) {
              this.drpSender.showPopup();
            } else if (Number(e.itemData.id) === -2) {
              this.drpRecipient.showPopup();
            }
          } else {
            if (drp.id === this.drpSender.element.id + '_tree') {
              (
                document.getElementById('correspondence-sender-id') as any
              ).value = e.itemData.id;
            } else if (drp.id === this.drpRecipient.element.id + '_tree') {
              (
                document.getElementById('correspondence-recipient-id') as any
              ).value = e.itemData.id;
            }
          }
        }
      };

      const handleDataBoundSender = (e: any) => {
        if (this.drpSender && this.drpSender.value.length <= 0) {
          setTimeout(() => {
            let s: string[] = [this.user.company.id.toString()];
            this.drpSender.value = s;
            this.drpSender.ensureVisible(s[0]);
          }, 500);
        }
      };

      return (
        <div style={{ width: '28vw', height: '100%', padding: '7px' }}>
          <input
            id='correspondence-accountid'
            name='correspondence.accountid'
            type='hidden'
            value={data.accountid!}
            onChange={onChange}
          />
          <input
            id='correspondence-sender-id'
            name='correspondence.sender.id'
            type='hidden'
            autoComplete='off'
            onChange={onChange}
          />
          <input
            id='correspondence-recipient-id'
            name='correspondence.recipient.id'
            type='hidden'
            autoComplete='off'
            onChange={onChange}
          />
          <div className='row align-items-center'>
            <div className='col-3 py-2'>
              <label style={{ fontSize: '13px' }}>Sender:*</label>
            </div>
            <div className='col-9 py-2'>
              <DropDownTreeComponent
                id='correspondence-sender-id'
                name='correspondence.sender.id'
                allowFiltering={true}
                filterType='Contains'
                fields={fields}
                treeSettings={treeSettings}
                //customTemplate={customTemplate}
                mode='Custom'
                placeholder='Select a sender'
                popupHeight='250px'
                select={handleDropDownTreeChange}
                ref={(obj: any) => (this.drpSender = obj)}
                style={{ fontSize: '15px' }}
                //value={[data.senderid ? data.senderid!.toString() : '']}
                dataBound={handleDataBoundSender}
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-3 py-2'>
              <label style={{ fontSize: '13px' }}>Recipient:*</label>
            </div>
            <div className='col-9 py-2'>
              <DropDownTreeComponent
                id='correspondence-recipient-id'
                name='correspondence.recipient.id'
                allowFiltering={true}
                filterType='Contains'
                fields={fields}
                treeSettings={treeSettings}
                customTemplate={customTemplate}
                mode='Custom'
                placeholder='Select a recipient'
                popupHeight='250px'
                select={handleDropDownTreeChange}
                ref={(obj: any) => (this.drpRecipient = obj)}
                style={{ fontSize: '15px' }}
                //value={[data.recipientid ? data.recipientid!.toString() : '']}
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-3 py-2'>
              <label style={{ fontSize: '13px' }}>Date:*</label>
            </div>
            <div className='col-9 py-2'>
              <DatePickerComponent
                id='correspondencedate'
                name='correspondence.date'
                disabled={!data.isAdd}
                value={data.correspondencedate}
                format={this.user.settings.formats.DateShort}
                showClearButton={false}
                cssClass='e-hidedate-icon'
                onChange={onChange}
                openOnFocus={true}
                showTodayButton={false}
                //placeholder={this.user.settings.formats.DateShort}
                placeholder='Select Date'
                style={{ fontSize: '15px' }}
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-3 py-2'>
              <label style={{ fontSize: '13px' }}>Subject:*</label>
            </div>
            <div className='col-9 py-2'>
              <input
                id='correspondence-subject'
                name='correspondence.subject'
                type='text'
                className='input-text-border'
                disabled={!data.isAdd}
                value={data['correspondence.subject']!}
                autoComplete='off'
                onChange={onChange}
                required={true}
                placeholder='Enter Subject'
                style={{ fontSize: '15px' }}
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-3 py-2'>
              <label style={{ fontSize: '13px' }}>Protocol:</label>
            </div>
            <div className='col-9 py-2'>
              <input
                id='correspondence-protocol'
                name='correspondence.protocol'
                type='text'
                className='input-text-border'
                disabled={!data.isAdd}
                value={data.protocol!}
                autoComplete='off'
                onChange={onChange}
                //onClick={handleTextAccountClick}
                placeholder='Enter Protocol'
                style={{ fontSize: '15px' }}
              />
            </div>
          </div>
          <div>
            {/* <ContextMenuComponent
              id={'dlgformtemplate-contextmenu'}
              ref={(scope) => (this.cMenu = scope as ContextMenuComponent)}
              items={data.menuItem!}
              select={handleCntxMenuClick}
            /> */}
          </div>
          {data.showDialog === true ? (
            <DialogComponent
              id='defaultDialogCorrespondence'
              showCloseIcon={true}
              visible={data.showDialog}
              width={'20vw'}
              //height={data.showDialogCompany === true ? '165px' : '200px'}
              close={() =>
                this.setState({ showDialog: false, showDialogCompany: false })
              }
              content={
                data.showDialogCompany === true
                  ? this.dialogContentCompany.bind(this)
                  : this.dialogContent.bind(this)
              }
              isModal={true}
              header={
                data.showDialogCompany === true
                  ? 'New Company'
                  : this.extradialogheader
              }
              buttons={this.extradialogbuttons}
              statelessTemplates={[]}
            ></DialogComponent>
          ) : null}
        </div>
      );
    };

    switch (this.srvcname?.split('/')!.pop()!.toLowerCase()) {
      case '5005':
      case 'person':
        let dataperson: personbiomodel = this.state;
        return <> {dialogTemplatePerson(dataperson)}</>;
        break;

      case 'employee':
      case '5006':
        let dataemp: employeebiomodel = this.state;
        return <> {dialogTemplateEmployee(dataemp)}</>;
        break;

      case 'correspondence':
        let datacorresp: correspondencebiomodel = this.state;
        return <> {dialogTemplateCorrespondence(datacorresp)}</>;
        break;

      default:
        return <></>;
        break;
    }
  }

  public render(): any {
    return <>{this.dialogTemplate()}</>;
  }
}

export default DialogFormTemplate;
