import * as React from 'react';
import { useState } from 'react';

import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';

import './GSignup.css';
interface props3 {
  email: string;
  nextStep: () => void;
  prevStep: () => void;
  handleFormData: (
    input: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  values: {
    name: string;
    birthday: string;
    gender: string;
    phone: string;
    company: string;
    access_code: string;
  };
}

const Confirm = (props3: any) => {
  const [showDetails, setShowDetails] = useState(false);

  const submitFormData = (e: any) => {
    e.preventDefault();
    props3.nextStep();
  };

  const handlePrevious = () => {
    props3.prevStep();
  };
  const handleChecked = (e: any) => {
    if (e.value === 'Yes') {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  };
  return (
    <>
      <div className='row mx-5'>
        <div className='col-12'>
          <form onSubmit={submitFormData}>
            <div className='row mt-3'>
              <div className='col-md-4 confirm-centre3'>
                <div className='row mx-3 mt-3'>
                  <div className='col-md-12'>
                    if you are a member of a company registered with Aruti,you
                    can request for an Access Code that allow you to Access the
                    system according to your Company's Policy and your Access
                    Privilege.
                  </div>
                </div>
              </div>
              <div className='col-md-8'>
                <div className='row mt-4 mx-4'>
                  <div className='col-12'>
                    <div className='row'>
                      <div className='col-md-8' style={{ fontSize: '14px' }}>
                        Are you member of a Company registered with Aruti?
                      </div>
                      <div className='col-md-4'>
                        <div className='radiobutton-control row'>
                          <div className='col-md-4'>
                            <RadioButtonComponent
                              label='Yes'
                              name='isCompanyEmployee'
                              value='Yes'
                              change={handleChecked}
                            ></RadioButtonComponent>
                          </div>
                          <div className='col-md-4 mx-3'>
                            <RadioButtonComponent
                              label='No'
                              name='isCompanyEmployee'
                              value='false'
                              change={handleChecked}
                              checked={true}
                            ></RadioButtonComponent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {showDetails && (
                  <div className='row my-3 mx-4'>
                    <label
                      htmlFor='company'
                      className='col-md-4  col-form-label'
                    >
                      Name of the Company
                    </label>
                    <div className='col-md-7'>
                      <input
                        type='text'
                        className='form-control'
                        id='company'
                        name='company'
                        defaultValue={props3.values.company}
                        onChange={props3.handleFormData('company')}
                        required
                        style={{
                          border: 'none',
                          borderBottom: '1px solid black',
                        }}
                        autoComplete='off'
                      />
                    </div>
                  </div>
                )}
                {showDetails && (
                  <div className='row mt-4 mx-4'>
                    <div className='col-12' style={{ fontSize: '14px' }}>
                      {' '}
                      Please enter your
                    </div>
                  </div>
                )}
                {showDetails && (
                  <div className='row mt-1 mx-4'>
                    <div className='col-12'>
                      <div className='row'>
                        <div className='confirm-centre1 col-md-3'>
                          Access Code
                        </div>
                        <div className='col-md-3'>
                          <input
                            type='text'
                            className='form-control'
                            id='access_code'
                            placeholder='Access Code'
                            name='access_code'
                            value={props3.values.access_code}
                            onChange={props3.handleFormData('access_code')}
                          />
                        </div>
                        <div className='col-md-1 confirm-centre2'>or</div>
                        <div className='col-md-4'>
                          <button
                            style={{
                              backgroundColor: 'rgb(237,125,49)',
                              color: '#fff',
                              border: '1px solid black',
                              height: '100%',
                              width: '100%',
                            }}
                          >
                            Request Access Code
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {showDetails && (
              <div className='row' style={{ marginTop: '70px' }}>
                <div
                  className='col-12'
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    alignItems: 'right',
                  }}
                >
                  <button className='flat-button fs-6'>Exit</button>
                  <button
                    className='flat-button fs-6 mx-1'
                    onClick={handlePrevious}
                  >
                    Back
                  </button>
                  <button type='submit' className='flat-button fs-6'>
                    Next
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
export default Confirm;
