// * ---------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SUHAIL - 2023-11-28 - 30 - meeting with fabio and saurav on google login
// * SUHAIL - 2023-11-28 - 210 - Show validation message in username and password text as place holder
// * SUHAIL - 2023-11-28 - 240 - Add new component Dashboard and show dashboard layout on dashboard sidebar menu click

// * ---------------------------------------------------------------------------------------------------------------2.Initialize
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import {
  DashboardLayoutComponent,
  PanelModel,
} from '@syncfusion/ej2-react-layouts';
import React from 'react';
import './Dashboard.css';

// * ---------------------------------------------------------------------------------------------------------------2.Initialize
interface Props {}

// * ------------------------------------------------------------------------------------------------------------------3.Style

// * -----------------------------------------------------------------------------------------------------------------DOTENV VAR DECLARATION

// * -------------------------------------------------------------------------------------------------------------------CLASS DECLARATION
class Dashboard extends React.Component<Props> {
  private dashboardObj: DashboardLayoutComponent | any;
  private cellSpacing: number[] = [5, 5];
  private count: number = 8;

  // * ------------------------------------------------------------------------------------------------------------------2.Initialize Component
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.rendereComplete();
  }
  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = { showdashboard: false };

  onCloseIconHandler(event: any) {
    let proxy: any = this;
    let panel: any = event.target;
    if (panel.offsetParent) {
      proxy.dashboardObj.removePanel(panel.offsetParent.id);
    }
  }
  btnClick() {
    let proxy: any = this;
    let panel: PanelModel[] = [
      {
        id: this.count.toString() + '_layout',
        sizeX: 1,
        sizeY: 1,
        row: 0,
        col: 0,
        content:
          '<span id="close" class="e-close-icon e-clear-icon"></span><div class="text-align">' +
          this.count.toString() +
          '</div>',
      },
    ];
    proxy.dashboardObj.addPanel(panel[0]);
    let closeIcon = document
      .getElementById(this.count.toString() + '_layout')!
      .querySelector('.e-clear-icon');
    closeIcon!.addEventListener('click', this.onCloseIconHandler.bind(this));
    this.count = this.count + 1;
  }
  rendereComplete() {
    let closeElement = document.querySelectorAll('.e-clear-icon');
    for (let i = 0; i < closeElement.length; i++) {
      closeElement[i].addEventListener(
        'click',
        this.onCloseIconHandler.bind(this)
      );
    }
    setTimeout(() => {
      this.setState({ showdashboard: true });
    }, 500);
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  render() {
    // * --------------------------------------------------------------------------------------------------------------------HTML
    return (
      <div className='w-100'>
        {this.state.showdashboard && (
          <div id='default_target' className='control-section dashboard-main'>
            <div className='addContainer'>
              <ButtonComponent
                id='add'
                cssClass='e-info'
                onClick={this.btnClick.bind(this)}
              >
                Add Panel
              </ButtonComponent>
            </div>
            <DashboardLayoutComponent
              id='default_dashboard'
              columns={5}
              ref={(scope) => {
                this.dashboardObj = scope;
              }}
              cellSpacing={this.cellSpacing}
              allowResizing={true}
            >
              <div
                id='one'
                className='e-panel'
                data-row='0'
                data-col='0'
                data-sizeX='1'
                data-sizeY='1'
              >
                <span id='close' className='e-close-icon e-clear-icon' />
                <div className='e-panel-container'>
                  <div className='text-align'></div>
                </div>
              </div>
              <div
                id='two'
                className='e-panel'
                data-row='1'
                data-col='0'
                data-sizeX='1'
                data-sizeY='2'
              >
                <span id='close' className='e-close-icon e-clear-icon' />
                <div className='e-panel-container'>
                  <div className='text-align'></div>
                </div>
              </div>
              <div
                id='three'
                className='e-panel'
                data-row='0'
                data-col='1'
                data-sizeX='2'
                data-sizeY='2'
              >
                <span id='close' className='e-close-icon e-clear-icon' />
                <div className='e-panel-container'>
                  <div className='text-align'></div>
                </div>
              </div>
              <div
                id='four'
                className='e-panel'
                data-row='2'
                data-col='1'
                data-sizeX='1'
                data-sizeY='1'
              >
                <span id='close' className='e-close-icon e-clear-icon' />
                <div className='e-panel-container'>
                  <div className='text-align'></div>
                </div>
              </div>
              <div
                id='five'
                className='e-panel'
                data-row='2'
                data-col='2'
                data-sizeX='2'
                data-sizeY='1'
              >
                <span id='close' className='e-close-icon e-clear-icon' />
                <div className='e-panel-container'>
                  <div className='text-align'></div>
                </div>
              </div>
              <div
                id='six'
                className='e-panel'
                data-row='0'
                data-col='3'
                data-sizeX='1'
                data-sizeY='1'
              >
                <span id='close' className='e-close-icon e-clear-icon' />
                <div className='e-panel-container'>
                  <div className='text-align'></div>
                </div>
              </div>
              <div
                id='seven'
                className='e-panel'
                data-row='1'
                data-col='3'
                data-sizeX='1'
                data-sizeY='1'
              >
                <span id='close' className='e-close-icon e-clear-icon' />
                <div className='e-panel-container'>
                  <div className='text-align'></div>
                </div>
              </div>
              <div
                id='eight'
                className='e-panel'
                data-row='0'
                data-col='4'
                data-sizeX='1'
                data-sizeY='3'
              >
                <span id='close' className='e-close-icon e-clear-icon' />
                <div className='e-panel-container'>
                  <div className='text-align'></div>
                </div>
              </div>
            </DashboardLayoutComponent>
          </div>
        )}
      </div>
    );
  }
}

export default Dashboard;
