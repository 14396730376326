// * SUHAIL - 2023-12-04 - 30 - attended weekly status meeting
// * SUHAIL - 2023-12-04 - 230 - Contract - change aruti logo and title, set new correspondence subject by default account name and other small changes
// * SUHAIL - 2023-12-04 - 220 - Show splash screen animation when login with google
// * SUHAIL - 2023-12-05 - 480 - Fixing issues on registration identity page
// * SUHAIL - 2023-12-06 - 100 - attended PI planning meeting
// * SUHAIL - 2023-12-06 - 380 - Fixing issues on registration personal data page

import React, { useEffect, useState, useRef, useContext } from 'react';
import { Step, Stepper } from 'react-form-stepper';
import Identity1 from './Identity1';
import UserDetails from './UserDetails';
import Confirm1 from './Confirm1';
import Message1 from './Message1';
import './Signup.css';
import { AppContext } from '../AppState';
import Common from '../../../Util/Common';

interface props {
  exitStep: any;
}
function CustomStepper(props: any) {
  const { state, dispatch } = useContext(AppContext);
  let { error, step_no } = state.registration;

  const handleStepClick = (e: any) => {
    dispatch({
      type: 'registration-activestep',
      stepno: Number(e.target.textContent) - 1,
    });
  };
  return (
    <Stepper
      {...props}
      connectorStateColors={true}
      connectorStyleConfig={{
        completedColor: '#ed7d31',
        activeColor: '#ed7d31',
        disabledColor: '#f4b285',
        size: 6,
        stepSize: '2.5em',
      }}
      styleConfig={{
        activeBgColor: '#ed7d31',
        completedBgColor: '#ed7d31',
        inactiveBgColor: '#f4b285',
        activeTextColor: '#111',
        completedTextColor: '#222',
        inactiveTextColor: '#444',
        size: '2.7em',
        labelFontSize: '0.8rem',
      }}
      className='custom-stepper'
      //stepClassName={'stepper__step fa-solid fa-user fs-5'}
    >
      <Step label='Identity' onClick={handleStepClick} />
      <Step label='Personal Data' onClick={handleStepClick} />
      <Step label='Access Code' onClick={handleStepClick} />
      <Step label='Log in' onClick={handleStepClick} />
    </Stepper>
  );
}

function Main(props: any) {
  const { state, dispatch } = useContext(AppContext);
  let {
    username,
    password,
    cpassword,
    error,
    step_no,
    employeeid,
    fullname,
    birthdate,
    phoneno,
    companyregistered,
    companycode,
    accesscode,
  } = state.registration;
  //const [activeStep, setActiveStep] = useState(0);
  //const [gName, setGname] = useState(null);
  //const [googleEmail,setGoogle]
  // const [formData, setFormData] = useState({
  //   userName: '',
  //   password: '',
  //   cpassword: '',
  //   name: '',
  //   birthday: '',
  //   gender: '',
  //   phone: '',
  //   company: '',
  //   access_code: '',
  // });
  // const steps = [
  //   { label: 'Identity' },
  //   { label: 'Personal Data' },
  //   { label: 'Access Code' },
  //   { label: 'Log in' },
  // ];

  // const nextStep = () => {
  //   setActiveStep(activeStep + 1);
  // };

  // const prevStep = () => {
  //   setActiveStep(activeStep - 1);
  // };

  // const handleInputData = (input: any) => (e: any) => {
  //   const { value } = e.target;

  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [input]: value,
  //   }));
  // };
  // const getGoogleData = (data: any) => {
  //   setGname(data.name);
  // };
  // const getExitStep = (data: any) => {
  //   props.exitStep(data);
  // };
  const exitHandler = () => {
    props.exitStep();
  };
  const handleNextClick = (e: any) => {
    switch (step_no) {
      case 0:
        if (!username || !password || !cpassword) {
          dispatch({
            type: 'registration-error',
            message: 'Please enter all required fields!',
          });
          return;
        } else if (!Common.validateEmail(username)) {
          dispatch({
            type: 'registration-error',
            message: 'Sorry, Invalid Email!',
          });
          return;
        } else if (password !== cpassword) {
          dispatch({
            type: 'registration-error',
            message: 'Sorry, Password and confirm does not match!',
          });
          return;
        }

        dispatch({ type: 'registration-activestep', stepno: step_no + 1 });
        break;

      case 1:
        if (!employeeid || !fullname || !birthdate || !phoneno) {
          dispatch({
            type: 'registration-error',
            message: 'Please enter all required fields!',
          });
          return;
        }
        dispatch({ type: 'registration-activestep', stepno: step_no + 1 });
        break;

      case 2:
        if (!companyregistered || !companycode || !accesscode) {
          dispatch({
            type: 'registration-error',
            message: 'Please enter all required fields!',
          });
          return;
        }
        dispatch({ type: 'registration-activestep', stepno: step_no + 1 });
        break;

      default:
        break;
    }
  };

  const handlePreviousClick = (e: any) => {
    dispatch({ type: 'registration-activestep', stepno: step_no - 1 });
  };

  function getSectionComponent() {
    switch (step_no) {
      case 0:
        return (
          <Identity1
          //nextStep={nextStep}
          //handleFormData={handleInputData}
          //values={formData}
          //getGdetails={getGdetails}
          // gName={getGoogleData}
          // exitStep={getExitStep}
          />
        );
      case 1:
        return (
          <UserDetails
          //nextStep={nextStep}
          //prevStep={prevStep}
          //handleFormData={handleInputData}
          //values={formData}
          //fullName={gName}
          //exitStep={getExitStep}
          />
        );
      case 2:
        return (
          <Confirm1
          //nextStep={nextStep}
          //prevStep={prevStep}
          //handleFormData={handleInputData}
          //values={formData}
          //exitStep={getExitStep}
          />
        );
      case 3:
        return (
          <Message1
          //handleFormData={handleInputData}
          //prevStep={prevStep}
          //values={formData}
          //exitStep={getExitStep}
          />
        );
      default:
        return null;
    }
  }

  return (
    <>
      <div className='modal-header custom-modal-header'>
        <h1 className='modal-title fs-5' id='exampleModalLabel'>
          Sign Up
        </h1>
        <button
          type='button'
          className='btn-close'
          data-bs-dismiss='modal'
          aria-label='Close'
        ></button>
      </div>
      <div className='modal-body' id='fixedModelHeight1'>
        {/* <div className='row mx-2'>
          <div className='signup-text col-12 fs-4'>Sign Up</div>
        </div> */}
        <div className='row'>
          <div className='col-12'>
            <CustomStepper activeStep={step_no} />
          </div>
        </div>
        <div className='row'>
          <div className='col-12 custom-stepper-body'>
            {getSectionComponent()}
          </div>
        </div>
        <div className='mt-1 text-center border-0' style={{ height: '16px' }}>
          {error ? <p className='text-danger fw-bold small'>{error}</p> : null}
        </div>
      </div>
      <div className='modal-footer custom-modal-footer'>
        <div className='nextbtn col-12'>
          <button
            className='flat-button fs-6'
            data-bs-dismiss='modal'
            onClick={exitHandler}
          >
            Exit
          </button>
          {step_no > 0 && (
            <button
              className='flat-button mx-1 fs-6'
              onClick={handlePreviousClick}
            >
              Back
            </button>
          )}
          {(step_no !== 2 || companyregistered) && step_no <= 2 && (
            <button
              type='submit'
              className='flat-button mx-1 fs-6'
              onClick={handleNextClick}
            >
              Next
            </button>
          )}
        </div>
      </div>

      {/* <div style={{ padding: '20px' }}>
        {getSectionComponent()} */}
      {/* <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'right',
          }}
        >
          <button className='mx-1'>Exit</button>
          {activeStep !== 1 && activeStep !== steps.length && (
            <button
              className='mx-1'
              onClick={() => setActiveStep(activeStep - 1)}
            >
              <i className='fa-solid fa-angle-left'></i>
            </button>
          )}
          {activeStep !== steps.length - 1 && (
            <button
              className='mx-1'
              type='submit'
              //onClick={handleSubmit}
            >
              <i className='fa-solid fa-angle-right'></i>
            </button>
          )}
        </div> */}
      {/* </div> */}
    </>
  );
}

export default Main;
